import { CrawlContextCrawlReport } from "../../CrawlContext";
import { ImpactBadge } from "../../../_common/report-helpers/ReportImpactBadge";
import { getReportImpact } from "../../../_common/report-helpers/reportImpact";

export function ReportImpactCell({
  report,
}: {
  report: CrawlContextCrawlReport;
}): JSX.Element {
  return <ImpactBadge impact={getReportImpact(report.templateTotalSign)} />;
}
