import React, { useContext } from "react";
import { FormControlLabel, makeStyles, Switch } from "@material-ui/core";
import { useTranslation } from "@lumar/shared";
import {
  GridApiContext,
  GridState,
  useGridApi,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import useKonami from "react-use-konami";

import { assert } from "../_common/assert";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  switch: {
    marginLeft: "auto",
    marginRight: theme.spacing(1.5),
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    color: theme.palette.grey[700],
    marginRight: theme.spacing(1.25),
  },
}));

export function ShowResolvedFilter({
  disabled,
}: {
  disabled?: boolean;
}): JSX.Element | null {
  const classes = useStyles();
  const { t } = useTranslation("taskManager");

  const apiRef = useContext(GridApiContext);
  assert(apiRef);
  const api = useGridApi(apiRef);
  const filters = useGridSelector(
    apiRef,
    (state: GridState): GridState["filter"] => state.filter,
  );

  useKonami(
    () =>
      (window.location.href = atob(
        "aHR0cHM6Ly93d3cueW91dHViZS5jb20vd2F0Y2g/dj1kUXc0dzlXZ1hjUQ==",
      )),
  );

  const filter = filters.items.find((x) => x.id === "showResolved");
  const isActive = !filter || filter.value;

  return (
    <div className={classes.root}>
      <FormControlLabel
        control={
          <Switch
            checked={isActive}
            onChange={() => {
              api.upsertFilter({
                id: "showResolved",
                columnField: "statusDeadline",
                operatorValue: "showResolved",
                value: !isActive,
              });
            }}
            disabled={disabled}
            data-testid="tasks-show-resolved"
          />
        }
        label={t("showResolved")}
        labelPlacement="start"
        classes={{ root: classes.switch, label: classes.label }}
      />
    </div>
  );
}
