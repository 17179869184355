import { CrawlContextCrawlReport } from "../../../CrawlContext";
import {
  CustomDataConfig,
  ReportWithCustomValues,
  isCustomDataAggregateMetricConfig,
} from "./types";
import { useReportsAggregateMetricData } from "./useReportsAggregateMetricData";

interface Props {
  reports: CrawlContextCrawlReport[];
  fields: { field: string; value: CustomDataConfig }[];
  aggregateMetricData: ReturnType<typeof useReportsAggregateMetricData>["data"];
}

export function getReportsWithCustomValues({
  reports,
  fields,
  aggregateMetricData,
}: Props): ReportWithCustomValues[] {
  const maxTotalRows = Math.max(0, ...reports.map((x) => x.totalRows ?? 0)); // Math.max(...[].map(a=>a)) is equal to -Infinity
  const maxChanges = Math.max(
    0,
    ...reports.map((x) => Math.abs(x.change ?? 0)),
  );

  return reports.map((report) => {
    const priority = report.templateTotalWeight ?? 0;
    const total = report.totalRows ?? 0;

    return {
      ...report,
      weightedChange: maxChanges
        ? (priority * Math.abs(report.change ?? 0)) / maxChanges
        : null,
      weightedTotal: maxTotalRows ? (priority * total) / maxTotalRows : null,
      customValues: Object.fromEntries(
        fields.map((field) => {
          const customValue = (() => {
            if (isCustomDataAggregateMetricConfig(field.value)) {
              return {
                value: aggregateMetricData.getValue(report.id, field.value),
                unit: aggregateMetricData.getUnit(field.value),
              };
            }

            return {
              value: field.value({
                report,
                reports,
                maxTotalRows,
              }),
            };
          })();

          return [field.field, customValue];
        }),
      ),
    };
  });
}
