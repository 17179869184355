import React from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import { Typography } from "@lumar/shared";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { TaskTooltipContent } from "./TaskTooltipContent";
import { Task } from "../../data/types";
import { isNonNullable } from "../../../_common/isNonNullable";

const useStyles = makeStyles((theme) => ({
  narrowTooltip: {
    minWidth: 480,
    maxWidth: 480,
  },
  wideTooltip: {
    minWidth: 600,
    maxWidth: 600,
  },
  shortText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.grey[900],
    lineHeight: "normal",
  },
}));

export function TaskCell(params: GridRenderCellParams): JSX.Element {
  const classes = useStyles();
  const task = params.row as Task;

  const description = task.description || "";

  const categoryName =
    task.reportTemplate?.reportCategories.find(
      (c) => c.code === task.reportTemplate?.primaryReportCategoryCode,
    )?.name ?? task.reportTemplate?.reportCategories[0]?.name;

  const reportName = task.reportTemplate?.name;

  const chain = [categoryName, reportName].filter(isNonNullable);

  return (
    <Tooltip
      title={
        <TaskTooltipContent
          chain={chain}
          description={description}
          title={task.title}
        />
      }
      classes={{
        tooltip:
          description.length < 1000
            ? classes.narrowTooltip
            : classes.wideTooltip,
      }}
      arrow
      placement="bottom-start"
      PopperProps={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        "data-testid": "task-tooltip",
      }}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <Typography
          variant="captionSemiBold"
          component="div"
          className={classes.shortText}
          data-testid="task-title"
        >
          {task.title}
        </Typography>
        <Typography
          variant="caption"
          component="div"
          className={classes.shortText}
        >
          {description}
        </Typography>
      </div>
    </Tooltip>
  );
}
