import { VariableType } from "json-to-graphql-query";

import { ReportTypeCode } from "../../../../graphql";
import { ChartConfigItem, TileChartConfigItem } from "../../types/ChartConfig";
import { AdditionalConfig, ChartQueryBuilder } from "../generateChartQuery";

export function getAllPagesCount(
  config: ChartConfigItem | TileChartConfigItem,
  additionalConfig: AdditionalConfig,
): ChartQueryBuilder {
  if (!config.moreLink && !config.totalUrlsCount) return {};

  const segmentId = additionalConfig.segmentId ?? null;
  const reportTemplateCodes =
    config.totalUrlsCount?.reportTemplateCode ?? "all_pages";

  return {
    inputs: {
      allPagesInput: "CrawlReportsByCodeInput!",
    },
    variables: {
      allPagesInput: {
        reportTemplateCodes,
        reportTypeCodes: ReportTypeCode.Basic,
        segmentId,
      },
    },
    fields: {
      allPages: {
        __aliasFor: "reportsByCode",
        __args: {
          input: new VariableType("allPagesInput"),
        },
        id: true,
        totalRows: true,
      },
    },
  };
}
