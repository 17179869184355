/* eslint-disable fp/no-mutating-methods */
/* eslint-disable react/display-name */
/* eslint-disable fp/no-this */
import { Theme } from "@material-ui/core";
import { TFunction } from "i18next";
import {
  ChartConfigItem,
  LinkData,
  VisualisationTypes,
} from "../types/ChartConfig";
import { ModuleCode, ReportTrendItem, ReportTypeCode } from "../../../graphql";
import { Routes } from "../../routing/routes";
import { ReportInResponse } from "../utils/generateChartQuery";
import { DateFormatter, getRawCrawlId } from "@lumar/shared";

export function getSiteSpeedAvgLCPChart(
  t: TFunction<"charts">,
  theme: Theme,
  formatter: DateFormatter,
): ChartConfigItem {
  return {
    supportedModules: [ModuleCode.SiteSpeed],
    visualisationFamily: "MultiDimensionalSeries",
    visualisationType: VisualisationTypes.Column,
    title: () => t("siteSpeedLCPMetricTrend.title"),
    description: () => t("siteSpeedLCPMetricTrend.description"),
    legendEnabled: false,
    showXAxisLabel: false,
    showYAxisLabel: false,
    showPercentageInSeriesTooltip: false,
    presentLastValue: true,
    showBorderAtColumnTop: true,
    pointPadding: 0,
    reports: () => ({
      filter: {
        reportTemplateCode: "site_speed_pages:largest_contentful_paint_avg",
      },
      fields: {
        reportTrend: {
          basic: true,
          crawlId: true,
          createdAt: true,
        },
      },
    }),
    categoryCsvColumnHeaderName: "DateTime",
    bindings: {
      categories: (series) => {
        return (
          series[0]?.data.map((data) => String(data.metadata?.createdAt)) || []
        );
      },
      series: (
        reports: ReportInResponse[],
        { accountId, projectId, segmentId }: LinkData,
      ) =>
        reports.map((report) => {
          return {
            name: report.reportTemplateName || report.reportTemplate.name,
            reportTemplateCode:
              report.reportTemplateCode || report.reportTemplate.code,
            data: (report.reportTrend ?? [])
              .filter(
                (
                  trend,
                ): trend is ReportTrendItem & {
                  createdAt: string;
                } => !!trend?.createdAt,
              )
              .sort((a, b) => a.crawlId - b.crawlId)
              .map((trend, index) => {
                const createdAt = new Date(trend.createdAt);
                return {
                  x: index,
                  y: trend.basic ?? 0,
                  url: Routes.Report.getUrl({
                    accountId,
                    projectId,
                    crawlId: getRawCrawlId(String(trend.crawlId)),
                    segmentId,
                    reportTemplateCode: report.reportTemplate.code,
                    reportTypeCode: ReportTypeCode.Basic.toLowerCase(),
                  }),
                  additionalTooltipLines: [
                    formatter(createdAt, {
                      dateStyle: "medium",
                      timeStyle: "short",
                    }),
                  ],
                  metadata: {
                    createdAt: trend.createdAt,
                  },
                };
              }),
          };
        }),
    },
  };
}
