/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ReportTypeCode } from "../../../graphql";
export function getCrawlabilityChart(t: TFunction<"charts">): ChartConfigItem {
  return {
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    title: () => t("crawlabilityChart.title"),
    description: () => t("crawlabilityChart.description"),
    reports: () => ({
      filter: {
        reportTypeCode: ReportTypeCode.Basic,
        reportTemplateCode: [
          "primary_pages_in_serp",
          "primary_pages_not_in_serp",
        ],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        totalRows: true,
      },
    }),
    showPercentageInSeriesTooltip: true,
    bindings: {
      name: (report) => {
        return report.reportTemplate.name;
      },
      count: (report) => {
        return report.totalRows ?? 0;
      },
    },
  };
}
