import { ReportCategoryChartConfig } from "../../types";
import { getSiteSpeedCLSTrend } from "../../../../../_common/charts/config/getSiteSpeedCLSTrend";
import { getSiteSpeedFCPTrend } from "../../../../../_common/charts/config/getSiteSpeedFCPTrend";
import { getSiteSpeedLCPTrend } from "../../../../../_common/charts/config/getSiteSpeedLCPTrend";
import { getSiteSpeedSITrend } from "../../../../../_common/charts/config/getSiteSpeedSITrend";
import { getSiteSpeedTBTTrend } from "../../../../../_common/charts/config/getSiteSpeedTBTTrend";
import { getSiteSpeedAvgFCPTileChart } from "../../../../../_common/charts/config/getSiteSpeedAvgFCPTileChart";
import { getSiteSpeedAvgLCPTileChart } from "../../../../../_common/charts/config/getSiteSpeedAvgLCPTileChart";
import { getSiteSpeedAvgTBTTileChart } from "../../../../../_common/charts/config/getSiteSpeedAvgTBTTileChart";
import { getSiteSpeedAvgCLSTileChart } from "../../../../../_common/charts/config/getSiteSpeedAvgCLSTileChart";
import { getSiteSpeedAvgSpeedIndexTileChart } from "../../../../../_common/charts/config/getSiteSpeedAvgSpeedIndexTileChart";
import { getSiteSpeedErrorsChart } from "../../../../../_common/charts/config/getSiteSpeedErrorsChart";
import { getSiteSpeedErrorsTable } from "../../../../../_common/charts/config/getSiteSpeedErrorsTable";

export const generateSiteSpeedOverviewCategoryItems: ReportCategoryChartConfig =
  {
    errorsChart: getSiteSpeedErrorsChart,
    errorsTable: getSiteSpeedErrorsTable,
    // allReportsTable: getSiteSpeedAllReportsTable,
    tileCharts: [
      getSiteSpeedAvgFCPTileChart,
      getSiteSpeedAvgLCPTileChart,
      getSiteSpeedAvgTBTTileChart,
      getSiteSpeedAvgCLSTileChart,
      getSiteSpeedAvgSpeedIndexTileChart,
    ],
    mainCharts: [
      // { predefinedChartType: "topChanges" },
      // getSiteSpeedMainKPIsChart,
      // getSiteSpeedMainKPIsTrend,
      getSiteSpeedFCPTrend,
      getSiteSpeedLCPTrend,
      getSiteSpeedTBTTrend,
      getSiteSpeedCLSTrend,
      getSiteSpeedSITrend,
    ],
  };
