import React from "react";
import { makeStyles, MenuItem, Box } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation, Select, SelectProps } from "@lumar/shared";

import { LegacyTaskPriority } from "../../graphql";

const useStyles = makeStyles((theme) => ({
  select: {
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "7px",
  },
  indicator: {
    width: "6px",
    height: "24px",
    borderRadius: "4px",
    marginRight: "14px",
    boxSizing: "border-box",
  },
  note: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.grey[300],
    background: theme.palette.background.paper,
  },
  low: {
    background: theme.palette.grey[300],
  },
  medium: {
    background: theme.palette.yellow[400],
  },
  high: {
    background: theme.palette.orange[500],
  },
  critical: {
    background: theme.palette.red[500],
  },
}));

export function PrioritySelect(props: SelectProps): JSX.Element {
  const classes = useStyles();

  const priorityOptions = [
    LegacyTaskPriority.Note,
    LegacyTaskPriority.Low,
    LegacyTaskPriority.Medium,
    LegacyTaskPriority.High,
    LegacyTaskPriority.Critical,
  ];

  return (
    <Select
      {...props}
      classes={{ select: classes.select }}
      renderValue={(value) => (
        <PriorityValue priority={value as LegacyTaskPriority} />
      )}
      data-testid="edit-task-priority"
    >
      {priorityOptions.map((priority) => (
        <MenuItem key={priority} value={priority}>
          <PriorityValue priority={priority} />
        </MenuItem>
      ))}
    </Select>
  );
}

function PriorityValue({
  priority,
}: {
  priority: LegacyTaskPriority;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("taskManager");

  const getProps = (): { label: string; className: string } => {
    switch (priority) {
      case LegacyTaskPriority.Note:
        return { label: t("severity.note"), className: classes.note };
      case LegacyTaskPriority.Low:
        return { label: t("severity.low"), className: classes.low };
      case LegacyTaskPriority.Medium:
        return { label: t("severity.medium"), className: classes.medium };
      case LegacyTaskPriority.High:
        return { label: t("severity.high"), className: classes.high };
      case LegacyTaskPriority.Critical:
      default:
        return { label: t("severity.critical"), className: classes.critical };
    }
  };

  const { label, className } = getProps();

  return (
    <Box display="flex" alignItems="center">
      <div className={clsx(classes.indicator, className)} />
      {label}
    </Box>
  );
}
