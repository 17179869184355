/* eslint-disable react/display-name */
import React from "react";
import { Typography } from "@material-ui/core";
import { Trans } from "react-i18next";
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import {
  OrderDirection,
  ReportOrderField,
  ReportTypeCode,
} from "../../../graphql";

export function generateSegmentComparisonChart(reportTemplateCode: string) {
  return function (t: TFunction<"charts">): ChartConfigItem {
    return {
      visualisationFamily: "MultiSeries",
      visualisationType: VisualisationTypes.Pie,
      additionalVisualisationTypes: [VisualisationTypes.Bar],
      title: (reports) => {
        if (reports[0]) {
          return reports[0].reportTemplate.name;
        }
        return t("segmentComparison.fallbackTitle");
      },
      description: (_, reports) => {
        const reportName = reports[0]?.reportTemplate.name;
        return (
          <Trans ns="charts" i18nKey="segmentComparison.description">
            <Typography paragraph variant="inherit">
              This pie chart compares the currently selected segment URLs
              against all other URLs in this report.
            </Typography>
            <Typography variant="inherit">
              <b>Other:</b> Total number of URLs outside of your segment in the{" "}
              {{ reportName }} report. This will include URLs that belong to
              other segments and URLs that are not yet included in a segment.
            </Typography>
          </Trans>
        );
      },
      reports: (segmentIdFromRoute) => ({
        orderBy: {
          field: ReportOrderField.CreatedAt,
          direction: OrderDirection.Desc,
        },
        filter: {
          reportTypeCode: ReportTypeCode.Basic,
          reportTemplateCode,
          segmentId: undefined,
          _and: [
            {
              _or: [
                { segmentId: { eq: segmentIdFromRoute } },
                { segmentId: { isNull: true } },
              ],
            },
          ],
        },
        fields: {
          reportTemplate: {
            name: true,
          },
          segment: {
            name: true,
          },
          totalRows: true,
        },
      }),
      showPercentageInSeriesTooltip: true,
      dontLinkReports: true,
      bindings: {
        name: (report) => {
          return report?.segment?.name ?? "Other";
        },
        count: (report, reports) => {
          if (report?.segment?.name) {
            return report.totalRows ?? 0;
          }

          const segmentedReport =
            reports.find((report) => report?.segment)?.totalRows ?? 0;
          const unsegmentedReport =
            reports.find((report) => !report?.segment)?.totalRows ?? 0;

          return unsegmentedReport - segmentedReport;
        },
        nameInTooltip: (report) => {
          const reportName = report.reportTemplate.name;
          if (report?.segment?.name) {
            return reportName;
          } else {
            return `${reportName} not`;
          }
        },
      },
      testAttributePrefix: "segment-comparison-chart",
    };
  };
}
