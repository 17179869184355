/* eslint-disable react/display-name */
/* eslint-disable fp/no-this */
import { Theme } from "@material-ui/core";
import { TFunction } from "i18next";

import {
  ChartConfigItem,
  LinkData,
  VisualisationTypes,
} from "../types/ChartConfig";
import {
  ConnectionPredicate,
  CrawlType,
  ReportTypeCode,
} from "../../../graphql";
import { ReportInResponse } from "../utils/generateChartQuery";
import { Routes } from "../../routing/routes";
import { createConnectionFilter } from "../../connection-filtering/createConnectionFilter";

function generateUrl(
  linkData: LinkData,
  report: ReportInResponse,
  metricCode: string,
): string {
  return Routes.Report.getUrl({
    ...linkData,
    reportTemplateCode: report.reportTemplate.code,
    reportTypeCode: ReportTypeCode.Basic.toLowerCase(),
    filter: createConnectionFilter({
      or: [
        {
          and: [
            {
              metricCode,
              predicateKey: ConnectionPredicate.Gt,
              predicateValue: 0,
            },
          ],
        },
      ],
    }),
  });
}

export function getSearchClicksByDeviceChart(
  t: TFunction<"charts">,
  theme: Theme,
): ChartConfigItem {
  return {
    visualisationFamily: "MultiDimensionalSeries",
    visualisationType: VisualisationTypes.Table,
    visualisationColors: [
      theme.palette.green[500],
      theme.palette.grey[500],
      theme.palette.purple[600],
    ],
    secondaryVisualisationColors: [
      theme.palette.green[100],
      theme.palette.grey[100],
      theme.palette.purple[100],
    ],
    title: () => t("searchClicksByDevice.title"),
    description: () => t("searchClicksByDevice.description"),
    reports: () => ({
      filter: {
        reportTypeCode: ReportTypeCode.Basic,
        reportTemplateCode: [
          "indexable_pages",
          "non_indexable_pages",
          "mobile_alternates",
        ],
      },
      fields: {
        id: true,
        reportTemplate: {
          name: true,
        },
        totalRows: true,
        searchConsoleDesktopClicks: {
          __args: { filter: { searchConsoleDesktopClicks: { gt: 0 } } },
          __aliasFor: "crawlUrls",
          totalCount: true,
        },
        searchConsoleMobileClicks: {
          __args: { filter: { searchConsoleMobileClicks: { gt: 0 } } },
          __aliasFor: "crawlUrls",
          totalCount: true,
        },
        searchConsoleTabletClicks: {
          __args: { filter: { searchConsoleTabletClicks: { gt: 0 } } },
          __aliasFor: "crawlUrls",
          totalCount: true,
        },
      },
    }),
    requiredSources: {
      sources: [CrawlType.GoogleSearchConsole],
      incompleteMessage: t("searchClicksByDevice.requiredSourcesMessage"),
    },
    bindings: {
      series: (reports: ReportInResponse[], linkData: LinkData) => {
        return reports.map((report) => {
          const desktopClicks =
            report.searchConsoleDesktopClicks?.totalCount ?? 0;
          const mobileClicks =
            report.searchConsoleMobileClicks?.totalCount ?? 0;
          const tabletClicks =
            report.searchConsoleTabletClicks?.totalCount ?? 0;
          const total = report.totalRows ?? 0;

          const data = [
            {
              x: t("searchClicksByDevice.desktop"),
              y: desktopClicks,
              percentage: total ? desktopClicks / total : 0,
              url: generateUrl(linkData, report, "searchConsoleDesktopClicks"),
            },
            {
              x: t("searchClicksByDevice.mobile"),
              y: mobileClicks,
              percentage: total ? mobileClicks / total : 0,
              url: generateUrl(linkData, report, "searchConsoleMobileClicks"),
            },
            {
              x: t("searchClicksByDevice.tablet"),
              y: tabletClicks,
              percentage: total ? tabletClicks / total : 0,
              url: generateUrl(linkData, report, "searchConsoleTabletClicks"),
            },
          ];

          return {
            name: report.reportTemplate.name,
            reportTemplateCode: report.reportTemplate.code,
            data,
          };
        });
      },
    },
    pendoAttributePrefix: "pages-with-clicks-by-device-chart",
  };
}
