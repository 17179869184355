/* eslint-disable react/display-name */
/* eslint-disable fp/no-this */
import React from "react";
import { Theme, Typography } from "@material-ui/core";
import { TFunction } from "i18next";

import {
  ChartConfigItem,
  LinkData,
  VisualisationTypes,
} from "../types/ChartConfig";
import {
  ConnectionPredicate,
  CrawlUrlAggregateDimension,
  ReportTypeCode,
} from "../../../graphql";
import { Routes } from "../../routing/routes";
import { createConnectionFilter } from "../../connection-filtering/createConnectionFilter";
import { ReportInResponse } from "../utils/generateChartQuery";

export function getNon200PagesbyStatusCode(
  t: TFunction<"charts">,
  theme: Theme,
): ChartConfigItem {
  return {
    visualisationFamily: "MultiDimensionalSeries",
    visualisationType: VisualisationTypes.StackedColumn,
    visualisationColors: [theme.palette.red[500]],
    additionalVisualisationTypes: [
      VisualisationTypes.Line,
      VisualisationTypes.Area,
      VisualisationTypes.StackedArea,
      VisualisationTypes.FullStackedColumn,
      VisualisationTypes.FullStackedArea,
    ],
    title: () => t("non200PagesbyStatusCode.title"),
    description: () => (
      <>
        <Typography paragraph variant="inherit">
          {t("non200PagesbyStatusCode.description")}
        </Typography>
      </>
    ),
    reports: () => ({
      filter: {
        reportTypeCode: ReportTypeCode.Basic,
        reportTemplateCode: ["all_pages"],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        crawlUrlsAggregates: {
          args: {
            dimensions: [CrawlUrlAggregateDimension.HttpStatusCode],
            filter: { httpStatusCode: { gt: 299 } },
          },
          httpStatusCode: true,
          count: {
            url: true,
          },
        },
      },
    }),
    bindings: {
      xAxisLabel: "HTTP Status Code",
      categories: (series) => {
        const categories = series[0]?.data.map((data) => `${data.x}`) || [];
        return categories;
      },
      series: (reports: ReportInResponse[], linkData: LinkData) => {
        return reports.map((report) => ({
          name: report.reportTemplate.name,
          reportTemplateCode: report.reportTemplate.code,
          data: (report.crawlUrlsAggregates || []).map((crawlUrlsAggregate) => {
            const depthHttpStatusCode = crawlUrlsAggregate?.httpStatusCode ?? 0;
            const urlCount = crawlUrlsAggregate?.count?.url ?? 0;

            return {
              x: depthHttpStatusCode,
              y: urlCount,
              url: Routes.Report.getUrl({
                ...linkData,
                reportTemplateCode: report.reportTemplate.code,
                reportTypeCode: ReportTypeCode.Basic.toLowerCase(),
                filter: createConnectionFilter({
                  or: [
                    {
                      and: [
                        {
                          metricCode: "httpStatusCode",
                          predicateKey: ConnectionPredicate.Eq,
                          predicateValue: depthHttpStatusCode,
                        },
                      ],
                    },
                  ],
                }),
              }),
              additionalTooltipLines: [
                t("non200PagesbyStatusCode.depthHttpStatusCode", {
                  depthHttpStatusCode,
                }),
              ],
            };
          }),
        }));
      },
    },
  };
}
