import {
  SignalFullIcon,
  SignalHighIcon,
  SignalLowIcon,
  SignalMediumIcon,
  SignalNoneIcon,
} from "@lumar/shared";
import { CrawlContextCrawlReport } from "../../CrawlContext";
import { ReportLink } from "./ReportLink";
import { useTheme } from "@material-ui/core";
import { ReportEntity } from "../../../report/Report.types";
import { ReportTypeCode } from "../../../graphql";

export function WeightedTotalCell({
  report,
  value,
}: {
  report: CrawlContextCrawlReport;
  value?: number;
}): JSX.Element {
  const theme = useTheme();

  const Icon = (() => {
    if (!value) return SignalNoneIcon;
    if (value > 0.75) return SignalFullIcon;
    if (value > 0.5) return SignalHighIcon;
    if (value > 0.25) return SignalMediumIcon;
    if (value > 0) return SignalLowIcon;
    return SignalNoneIcon;
  })();

  const isCustomReport = report.id.startsWith("customReport");

  return (
    <ReportLink
      reportInput={
        isCustomReport
          ? {
              reportEntity: ReportEntity.CustomReport,
              customReportTemplateId: report.id.split("_")[1],
              crawlId: report.crawlId,
              reportTypeCode: ReportTypeCode.Basic,
              reportTemplateCode: report.reportTemplate.code,
            }
          : {
              reportTemplateCode: report.reportTemplate.code,
              reportEntity: ReportEntity.ReportStat,
              reportTypeCode: ReportTypeCode.Basic,
              crawlId: report.crawlId,
            }
      }
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {Icon ? (
          <Icon
            style={{
              width: theme.typography.pxToRem(28),
              height: theme.typography.pxToRem(24),
              stroke: theme.palette.grey[300],
              color: theme.palette.grey[700],
            }}
          />
        ) : null}
      </div>
    </ReportLink>
  );
}
