import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from "@material-ui/core";
import { Typography } from "../../typography/Typography";
import { HealthScoreChange } from "./HealthScoreChange";
import { HealthScoreResult } from "./HealthScoreResult";
import { SmallHealthScoreTrend } from "./SmallHealthScoreTrend";
import clsx from "clsx";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        margin: theme.spacing(0, 1),
    },
    title: {
        lineHeight: theme.typography.pxToRem(20),
        minHeight: theme.typography.pxToRem(40),
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 600,
        margin: theme.spacing(0.75, 0.75, 0, 0.75),
        overflow: "hidden",
    },
    healthScoreContainer: {
        margin: theme.spacing(0, 0.75, 0, 0.75),
    },
    percentageContainer: {
        margin: theme.spacing(0, 0.75, 0.5, 0.75),
    },
    hidden: {
        visibility: "hidden",
    },
}));
export function SmallHealthScoreChart(props) {
    const classes = useStyles();
    return (_jsxs("div", { className: classes.container, "data-pendo": `health-score-${props.id}`, "data-testid": `health-score-${props.id}`, children: [props.link ? (_jsx(Link, { to: props.link, style: { color: "inherit", textDecoration: "none" }, children: _jsx(Typography, { component: "div", className: classes.title, children: props.getTitleText(props.name) }) })) : (_jsx(Typography, { component: "div", className: classes.title, children: props.getTitleText(props.name) })), _jsx("div", { className: classes.healthScoreContainer, children: _jsx(HealthScoreResult, { healthScore: props.healthScore, industryBenchmark: props.industryBenchmark }) }), _jsx("div", { className: clsx(classes.percentageContainer, {
                    [classes.hidden]: props.healthScoreChangePercentage === null,
                }), children: _jsx(HealthScoreChange, { changePercentage: props.healthScoreChangePercentage || 0 }) }), props.healthScoreTrend && props.healthScoreTrend.length > 0 ? (_jsx(SmallHealthScoreTrend, { healthScoreTrend: props.healthScoreTrend, industryBenchmark: props.industryBenchmark })) : null] }));
}
