import React from "react";
import {
  CrawlContextCrawlSetting,
  useCrawlContextData,
} from "../../../../crawl-overview/CrawlContext";
import { useSearchParam } from "../../../routing/useSearchParam";
import { ChartConfigItem, TileChartConfigItem } from "../../types/ChartConfig";
import { useGenerateChartQuery } from "../../utils/useGenerateChartQuery";
import { getReportsFromCrawl, isChartMissingRequiredSource } from "../utils";
import { ChartDataContext } from "./ChartDataContext";
import { useParams } from "react-router-dom";
import { isCustomExtractionReportTemplateCode } from "../../../custom-extraction-labels/isCustomExtractionReportTemplateCode";
import { ReportInResponse } from "../../utils/generateChartQuery";
import { useGetModuleMetricsQuery } from "../../../../graphql";
import {
  getAggregatedMetric,
  getReportUnit,
  hasAggregatedReport,
} from "./chartDataHelpers";

type ChartComponentProps = (ChartConfigItem | TileChartConfigItem) & {
  name: string;
  includeMultipleCrawlTypes?: boolean;
  noTrendsTemplate?: React.ReactElement;
  noReportsTemplate?: React.ReactElement;
  children?: React.ReactNode;
};

export function ChartData(props: ChartComponentProps): JSX.Element {
  const { crawlId } = useParams<{ crawlId?: string }>();
  const { selectedCrawlSegment, crawlSetting, moduleCode } =
    useCrawlContextData();
  // FIXME: Should be a prop to properly decouple
  const category = useSearchParam("category");

  const {
    data: queryData,
    loading,
    error: queryError,
  } = useGenerateChartQuery({
    chartConfig: props,
    additionalConfig: {
      crawlId: crawlId || "",
      segmentId: selectedCrawlSegment?.segment.id,
      category,
      includeComparedToCrawlId: Boolean(props.noTrendsTemplate),
    },
    skip: !crawlId,
  });

  const {
    data: moduleMetics,
    loading: moduleMeticsLoading,
    error: moduleMeticsError,
  } = useGetModuleMetricsQuery({
    variables: { moduleCode },
    fetchPolicy: "cache-first",
    context: {
      includeInBatch: true,
    },
    skip: !hasAggregatedReport(props),
  });

  const { data, error } = props.processData?.(queryData, queryError) ?? {
    data: queryData,
    error: queryError,
  };

  const isMissingRequiredSource = isChartMissingRequiredSource(
    props.requiredSources,
    data,
  );

  const isChartUnavailable =
    Boolean(!data?.getCrawl || isMissingRequiredSource) || !!error;

  const segmentName = selectedCrawlSegment?.segment.name;
  const reports = getReportsFromCrawl(data).map((report) =>
    transformCustomExtractionReport(report, crawlSetting),
  );

  const totalUrls = data?.getCrawl?.allPages?.[0]?.totalRows ?? undefined;

  const getReportUnitCallback = React.useCallback<
    ChartDataContext["getReportUnit"]
  >((report) => getReportUnit(report, moduleMetics), [moduleMetics]);

  const getAggregatedMetricCallback = React.useCallback<
    ChartDataContext["getAggregatedMetric"]
  >((report) => getAggregatedMetric(report, moduleMetics), [moduleMetics]);

  return (
    <ChartDataContext.Provider
      value={{
        loading: loading || moduleMeticsLoading,
        error: error || moduleMeticsError,
        data,
        segmentName,
        reports,
        isChartUnavailable,
        totalUrls,
        getReportUnit: getReportUnitCallback,
        getAggregatedMetric: getAggregatedMetricCallback,
      }}
    >
      {props.children}
    </ChartDataContext.Provider>
  );
}

function transformCustomExtractionReport(
  report: ReportInResponse,
  crawlSetting?: CrawlContextCrawlSetting,
): ReportInResponse {
  const code = report.reportTemplateCode ?? report.reportTemplate.code;
  if (!isCustomExtractionReportTemplateCode(code)) return report;
  const label = crawlSetting?.customExtractions?.find(
    (ce) => code === ce.reportTemplateCode,
  )?.label;
  if (!label) return report;
  return {
    ...report,
    reportTemplateName: label,
    reportTemplate: report.reportTemplate && {
      ...report.reportTemplate,
      name: label,
    },
  } as ReportInResponse;
}
