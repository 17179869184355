/* eslint-disable react/display-name */
import { Trans } from "@lumar/shared";
import { Typography } from "@material-ui/core";
import {
  ModuleCode,
  ReportTemplateConnectionFilterInput,
} from "../../../graphql";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { RequiredSources } from "../types/ChartConfigItemBase";
interface Options {
  visualisationType: VisualisationTypes.Pie | VisualisationTypes.Bar;
  title: string;
  description: string;
  reportTemplateCodes: string[];
  testAttributePrefix?: string;
  pendoAttributePrefix?: string;
  reportTemplateFilter?: ReportTemplateConnectionFilterInput;
  requiredSources?: RequiredSources;
  supportedModules?: ModuleCode[];
}

export function generateSimpleMultiSeriesChartConfig({
  visualisationType,
  title,
  description,
  reportTemplateCodes,
  testAttributePrefix,
  pendoAttributePrefix,
  reportTemplateFilter,
  requiredSources,
  supportedModules,
}: Options) {
  return function (): ChartConfigItem {
    return {
      visualisationFamily: "MultiSeries",
      visualisationType: visualisationType,
      supportedModules,
      additionalVisualisationTypes:
        visualisationType === VisualisationTypes.Pie
          ? [VisualisationTypes.Bar]
          : undefined,
      title: () => title,
      description: () => (
        <Trans
          t={(value: unknown) => value}
          defaults={description}
          components={[
            <Typography key="0" paragraph variant="inherit" />,
            <Typography key="1" variant="inherit" />,
          ]}
        />
      ),
      reports: () => ({
        first: 10,
        reportTemplateFilter,
        filter: {
          reportTemplateCode: reportTemplateCodes,
        },
        fields: {
          reportTemplateName: true,
          reportTemplate: {
            name: true,
          },
          totalRows: true,
          aggregateValue: true,
        },
      }),
      showPercentageInSeriesTooltip:
        visualisationType === VisualisationTypes.Pie ? true : false,
      bindings: {
        name: (report) => {
          return report.reportTemplateName || report.reportTemplate.name;
        },
        count: (report) => {
          return report.totalRows ?? report.aggregateValue ?? 0;
        },
      },
      testAttributePrefix,
      pendoAttributePrefix,
      requiredSources,
    };
  };
}
