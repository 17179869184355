import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import clsx from "clsx";
import { useTranslation } from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.green[500],
    fontWeight: 600,
    marginRight: theme.spacing(1),
  },
  unresolved: {
    color: theme.palette.red[500],
  },
}));

export function UnresolvedPercentCell({
  value,
}: GridRenderCellParams): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("units");

  const unresolved = value as number;

  return (
    <Typography
      variant="caption"
      className={clsx(classes.label, unresolved > 0 ? classes.unresolved : "")}
      data-testid="tasks-unresolved-percent"
    >
      {t("percent", { count: unresolved / 100 })}
    </Typography>
  );
}
