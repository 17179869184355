/* eslint-disable react/display-name */
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode, OrderDirection, ReportOrderField } from "../../../graphql";
import { TFunction } from "i18next";

export function getSiteSpeedIssuesFCP(t: TFunction<"charts">): ChartConfigItem {
  return {
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [ModuleCode.SiteSpeed],
    title: () => t("siteSpeedFCPIssues.title"),
    description: () => t("siteSpeedFCPIssues.description"),
    reports: () => ({
      orderBy: {
        field: ReportOrderField.TotalRows,
        direction: OrderDirection.Desc,
      },
      filter: {
        reportTemplateCode: [
          "no_document_write_failed_audits",
          "unminified_javascript_failed_audits",
          "render_blocking_resources_failed_audits",
          "uses_http2_failed_audits",
          "uses_long_cache_ttl_failed_audits",
          "server_response_time_failed_audits",
          "unminified_css_failed_audits",
          "uses_text_compression_failed_audits",
          "bf_cache_failed_audits",
          "font_display_failed_audits",
          "uses_rel_preload_failed_audits",
          "uses_rel_preconnect_failed_audits",
          "critical_request_chains_failed_audits",
          "redirects_failed_audits",
        ],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        totalRows: true,
      },
    }),
    bindings: {
      name: (report) => report.reportTemplate.name,
      count: (report) => {
        return report.totalRows ?? 0;
      },
    },
  };
}
