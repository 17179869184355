/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { CrawlType } from "../../../graphql";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getBacklinkIssuesChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    requiredSources: {
      sources: [CrawlType.Backlinks],
      incompleteMessage: t("requiredSourcesMessage.backlinksSource"),
    },
    title: () => t("backlinkIssues.title"),
    description: () => t("backlinkIssues.description"),
    reports: () => ({
      filter: {
        reportTemplateCode: [
          "backlink_pages_with_errors",
          "backlink_pages_disallowed_uncrawled",
          "backlink_pages_disallowed",
          "backlink_pages_non_indexable",
          "backlink_pages_no_links_out",
          "backlink_pages_with_nofollow",
          "backlink_pages_redirects",
        ],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        totalRows: true,
      },
    }),
    bindings: {
      name: (report) => report.reportTemplate.name,
      count: (report) => {
        return report.totalRows ?? 0;
      },
    },
  };
}
