import { DocumentNode } from "graphql";
import { ReportRowsFilter } from "../../../../_common/connection-filtering/types";
import {
  CustomReportGridRowsDocument,
  CustomReportGridRowsQuery,
  CustomReportGridRowsQueryVariables,
  DatasourceCode,
  OrderDirection,
  ReportStatGridRowsDocument,
  ReportStatGridRowsQuery,
  ReportStatGridRowsQueryVariables,
} from "../../../../graphql";
import { ReportEntity, ReportInput } from "../../../Report.types";
import { MetricData } from "../ReportGrid.types";
import { getMetrics } from "./getMetrics";
import { ReportData } from "./ReportGridRows.types";

export const getReportGridRowsQuery = (args: {
  isComparedToCrawlArchived: boolean;
  hasMultipleCrawls: boolean;
  reportInput: ReportInput;
  baseFilter?: ReportRowsFilter;
  filter?: ReportRowsFilter;
  first?: number;
  last?: number;
  before?: string;
  after?: string;
  orderBy:
    | {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        field: any;
        direction: OrderDirection;
      }[]
    | null;
}): {
  document: DocumentNode;
  variables:
    | ReportStatGridRowsQueryVariables
    | CustomReportGridRowsQueryVariables;
} => {
  const {
    reportInput,
    baseFilter,
    filter,
    hasMultipleCrawls,
    isComparedToCrawlArchived,
    ...pageInfo
  } = args;

  switch (reportInput.reportEntity) {
    case ReportEntity.ReportStat:
      return {
        document: ReportStatGridRowsDocument,
        variables: {
          input: {
            crawlId: reportInput.crawlId,
            reportTemplateCode: reportInput.reportTemplateCode,
            segmentId: reportInput.segmentId,
          },
          ...pageInfo,
          reportTypeCode: reportInput.reportTypeCode,
          baseFilter,
          fetchFilteredMissing:
            Boolean(filter) && hasMultipleCrawls && !isComparedToCrawlArchived,
          hasFilter: Boolean(filter),
          filter,
        },
      };
    case ReportEntity.CustomReport:
      return {
        document: CustomReportGridRowsDocument,
        variables: {
          input: {
            crawlId: reportInput.crawlId,
            customReportTemplateId: reportInput.customReportTemplateId,
            segmentId: reportInput.segmentId,
          },
          ...pageInfo,
          reportTypeCode: reportInput.reportTypeCode,
          baseFilter,
          fetchFilteredMissing:
            Boolean(filter) && hasMultipleCrawls && !isComparedToCrawlArchived,
          hasFilter: Boolean(filter),
          filter,
        },
      };
    default:
      throw new Error("Unimplemented report entity query");
  }
};

export const mapReportDTOToReportRowData = (args: {
  metricsData: MetricData[];
  data: ReportStatGridRowsQuery | CustomReportGridRowsQuery;
  datasourceCode?: DatasourceCode;
}): ReportData => {
  const { metricsData, datasourceCode, data } = args;
  const report = data?.report;

  const rows = (report?.rowsWithAllMetrics?.nodes || []).map((node) => ({
    ...node,
    // FIXME: Use uuid
    id: `row-${Math.random()}`,
    metrics: getMetrics(node, metricsData, datasourceCode),
  }));
  const totalRows = report?.allRows?.totalCount ?? 0;
  const totalCount = data?.report?.rowsWithAllMetrics?.totalCount ?? 0;
  const reportQueryVersion = isReportStat(report)
    ? report.queryVersion
    : isCustomReport(report)
      ? report.customReportTemplate.reportTemplateQueryVersion
      : undefined;

  return {
    rows,
    totalCount,
    totalRows,
    reportQueryVersion,
    diffs: {
      added: report?.filteredAdded?.totalCount ?? report?.added ?? 0,
      basic: report?.filteredBasic?.totalCount ?? report?.basic ?? 0,
      missing: report?.filteredMissing?.totalCount ?? report?.missing ?? 0,
      removed: report?.filteredRemoved?.totalCount ?? report?.removed ?? 0,
    },
  };
};

function isReportStat(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  report: any,
): report is NonNullable<ReportStatGridRowsQuery["report"]> {
  return report?.__typename === "ReportStat";
}

function isCustomReport(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  report: any,
): report is NonNullable<CustomReportGridRowsQuery["report"]> {
  return report?.__typename === "CustomReport";
}
