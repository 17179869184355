import React from "react";
import Highcharts, { PlotAreaOptions } from "highcharts";
import { HighchartsChart } from "../../../_common/charts/components/HighchartsChart";

export interface SparklinesMiniChartProps {
  data: number[] | { x: number; y: number }[];
  max?: number;
  colors?: string[];
  fillColor?: PlotAreaOptions["fillColor"];
}

export function TrendChart({
  data,
  max,
  colors,
  fillColor,
}: SparklinesMiniChartProps): JSX.Element {
  const options: Highcharts.Options = {
    chart: {
      type: "area",
      animation: false,
      width: 160,
      height: 50,
      backgroundColor: "transparent",
      margin: [0, 0, 0, 0],
    },
    colors: colors,
    plotOptions: {
      series: {
        lineWidth: 1,
      },
      area: {
        fillColor: fillColor,
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        fillOpacity: 0.2,
        data: data,
        enableMouseTracking: false,
        animation: false,
        type: "area",
      },
    ],
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
      max: max,
      min: 0,
    },
    legend: {
      enabled: false,
    },
  };

  return <HighchartsChart options={options} />;
}
