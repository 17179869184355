import { RequiredSources } from "../types/ChartConfigItemBase";
import { checkRequiredSources } from "../utils/checkRequiredSources";
import {
  ChartQueryResponse,
  ReportInResponse,
} from "../utils/generateChartQuery";

export function getReportsFromCrawl(
  data?: ChartQueryResponse,
): ReportInResponse[] {
  if (!data?.getCrawl) return [];
  return data.getCrawl.reports.edges.map((e) => e.node);
}

export function isChartMissingRequiredSource(
  requiredSources?: RequiredSources,
  data?: ChartQueryResponse,
): boolean {
  return (
    !!requiredSources &&
    !checkRequiredSources(
      requiredSources.sources,
      data?.getCrawl?.crawlTypes ?? [],
    )
  );
}
