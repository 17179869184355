import { FunnelDataPoint } from "@lumar/shared";
import { TFunction } from "i18next";
import { CrawlContextValue } from "../../../../CrawlContext";

const funnelReportTemplateCodes = [
  "200_pages",
  "non-200_pages",
  "indexable_pages",
  "non_indexable_pages",
  "unique_pages",
  "non_unique_pages",
  "primary_pages_in_serp",
  "primary_pages_not_in_serp",
  "primary_pages_with_serp_clicks",
  "primary_pages_in_serp_without_clicks",
  "good_ux_pages_with_clicks",
  "poor_ux_pages_with_clicks",
] as const;

export const urlFunnelDataMapper = (
  t: TFunction<"charts">,
  crawlContext: CrawlContextValue,
): FunnelDataPoint[] => {
  if (!crawlContext.data) return [];

  const reportTotalRowsMap = new Map(
    funnelReportTemplateCodes.map((reportTemplateCode) => [
      reportTemplateCode,
      crawlContext.data?.crawlReports.find(
        (crawlReport) => crawlReport.reportTemplate.code === reportTemplateCode,
      )?.totalRows ?? 0,
    ]),
  );

  // Type-safety guarantees existence of a value.
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  return [
    {
      title: t("urlFunnel.sections.availability.title"),
      passTitle: t("urlFunnel.sections.availability.passTitle"),
      failTitle: t("urlFunnel.sections.availability.failTitle"),
      pass: reportTotalRowsMap.get("200_pages")!,
      fail: reportTotalRowsMap.get("non-200_pages")!,
      passReportTemplateCode: "200_pages",
      failReportTemplateCode: "non-200_pages",
    },
    {
      title: t("urlFunnel.sections.indexability.title"),
      passTitle: t("urlFunnel.sections.indexability.passTitle"),
      failTitle: t("urlFunnel.sections.indexability.failTitle"),
      pass: reportTotalRowsMap.get("indexable_pages")!,
      fail: reportTotalRowsMap.get("non_indexable_pages")!,
      passReportTemplateCode: "indexable_pages",
      failReportTemplateCode: "non_indexable_pages",
    },
    {
      title: t("urlFunnel.sections.uniqueness.title"),
      passTitle: t("urlFunnel.sections.uniqueness.passTitle"),
      failTitle: t("urlFunnel.sections.uniqueness.failTitle"),
      pass: reportTotalRowsMap.get("unique_pages")!,
      fail: reportTotalRowsMap.get("non_unique_pages")!,
      passReportTemplateCode: "unique_pages",
      failReportTemplateCode: "non_unique_pages",
    },
    {
      title: t("urlFunnel.sections.crawlability.title"),
      passTitle: t("urlFunnel.sections.crawlability.passTitle"),
      failTitle: t("urlFunnel.sections.crawlability.failTitle"),
      pass: reportTotalRowsMap.get("primary_pages_in_serp")!,
      fail: reportTotalRowsMap.get("primary_pages_not_in_serp")!,
      passReportTemplateCode: "primary_pages_in_serp",
      failReportTemplateCode: "primary_pages_not_in_serp",
    },
    {
      title: t("urlFunnel.sections.rankability.title"),
      passTitle: t("urlFunnel.sections.rankability.passTitle"),
      failTitle: t("urlFunnel.sections.rankability.failTitle"),
      pass: reportTotalRowsMap.get("primary_pages_with_serp_clicks")!,
      fail: reportTotalRowsMap.get("primary_pages_in_serp_without_clicks")!,
      passReportTemplateCode: "primary_pages_with_serp_clicks",
      failReportTemplateCode: "primary_pages_in_serp_without_clicks",
    },
    {
      title: t("urlFunnel.sections.experience.title"),
      passTitle: t("urlFunnel.sections.experience.passTitle"),
      failTitle: t("urlFunnel.sections.experience.failTitle"),
      pass: reportTotalRowsMap.get("good_ux_pages_with_clicks")!,
      fail: reportTotalRowsMap.get("poor_ux_pages_with_clicks")!,
      passReportTemplateCode: "good_ux_pages_with_clicks",
      failReportTemplateCode: "poor_ux_pages_with_clicks",
    },
  ];
  /* eslint-enable @typescript-eslint/no-non-null-assertion */
};
