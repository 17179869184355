import {
  Alert,
  Button,
  Trans,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { Box, CircularProgress } from "@material-ui/core";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import { Routes } from "../../_common/routing/routes";
import { useURLSearchParams } from "../../_common/routing/useURLSearchParams";
import { TopNavigation } from "../../_common/top-navigation/TopNavigation";
import {
  CrawlOverviewContextProvider,
  useCrawlOverviewContext,
} from "../../crawl-overview/CrawlOverviewContext";
import { ReportsTable } from "../../crawl-overview/reports-table/ReportsTable";
import { ReportSearch } from "../../crawl-overview/ReportSearch";
import { OverviewType } from "../../crawl-overview/types";
import { CrawlSelector } from "../../crawl-selector/CrawlSelector";
import { CrawlSettingsComparison } from "../../report/report-rows/report-grid/rows/crawl-settings-comparison/CrawlSettingsComparison";
import { ReportEntity } from "../../report/Report.types";
import { SegmentSelect } from "../../segment-selector/SegmentSelect";
import { CustomReportOverviewType } from "../_common/CustomReportTypes";
import { CustomReportsTabs } from "./CustomReportsTabs";
import { RoleCode } from "../../graphql";

export function CustomReportsOverview(): JSX.Element {
  const { hasFeatureFlagEnabled } = useSession();

  const { accountId, projectId, crawlId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
  }>();

  if (!hasFeatureFlagEnabled("custom-reports")) {
    return (
      <Redirect
        to={Routes.CrawlOverview.getUrl({
          accountId,
          projectId,
          crawlId,
          type: "dashboard",
        })}
      />
    );
  }
  return (
    <CrawlOverviewContextProvider reportEntity={ReportEntity.CustomReport}>
      <CustomReports />
    </CrawlOverviewContextProvider>
  );
}

function CustomReports(): JSX.Element {
  const history = useHistory();
  const searchParams = useURLSearchParams();
  const selectedType = searchParams.get("type");
  const { loading, data, helpers, crawlContext, errors } =
    useCrawlOverviewContext();
  const { hasSufficientRole } = useSession();

  const { t } = useTranslation("crawlOverview");
  const { t: tCustomReports } = useTranslation("customReports");
  const { accountId, projectId, crawlId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
  }>();

  if (loading) {
    return <CircularProgress style={{ marginTop: 15 }} />;
  }

  if (errors) {
    return (
      <>
        <TopNavigation title={tCustomReports("pageTitle")} />
        <div style={{ marginTop: 15 }}>
          <Alert severity="error">{t("genericError")}</Alert>
        </div>
      </>
    );
  }

  if (!data || !crawlContext.data || !helpers || !crawlContext.helpers) {
    return (
      <>
        <TopNavigation title={t("pageTitleDefault")} />
        <div style={{ marginTop: 15 }}>
          <Alert severity="error">{t("error.noData")}</Alert>
        </div>
      </>
    );
  }

  const projectCustomReportTemplatesIds =
    crawlContext.data.projectAllCustomReportTemplates.map(
      (template) => template.id.split("_")[1],
    );

  const crawlCustomReportTemplatesIds =
    crawlContext.data.crawlAllCustomReportsTemplateIds;

  const isPendingUpdates =
    projectCustomReportTemplatesIds.some(
      (id) => !crawlCustomReportTemplatesIds.includes(id),
    ) ||
    crawlCustomReportTemplatesIds.some(
      (id) => !projectCustomReportTemplatesIds.includes(id),
    );

  return (
    <>
      <TopNavigation
        title={tCustomReports("pageTitle")}
        actions={[
          <ReportSearch key="report-search" />,
          <SegmentSelect key="segment-select" />,
          <CrawlSelector
            key="crawl-select"
            getPath={(crawlId) => {
              return Routes.CustomReports.getUrl({
                accountId,
                projectId,
                crawlId,
                type: selectedType ?? OverviewType.All,
                segmentId: crawlContext.data?.selectedCrawlSegment?.segment.id,
              });
            }}
          />,
          <CrawlSettingsComparison key="crawl-settings-comparison" />,
        ]}
        capitalizeTitle
        availableForShare
        breadcrumbs={[
          {
            label: tCustomReports("pageTitle"),
            link: Routes.CustomReports.getUrl({
              accountId,
              projectId,
              crawlId,
            }),
          },
        ]}
      />
      <Box marginTop={3}>
        <CustomReportsTabs
          selectedType={selectedType ?? CustomReportOverviewType.All}
          onTypeChange={(type) => {
            if (type === selectedType) return;
            searchParams.delete("sorting");
            searchParams.set("type", type);
            searchParams.apply();
          }}
        >
          {hasSufficientRole(RoleCode.Editor) && (
            <Button
              onClick={(event) => {
                event.preventDefault();
                history.push(
                  Routes.CustomReportTemplateManager.getUrl({
                    accountId,
                    projectId,
                    crawlId,
                  }),
                );
              }}
              variant="outlined"
              size="large"
              data-pendo="manage-custom-reports"
            >
              {tCustomReports("manageCustomReports")} (
              {crawlContext.data.projectAllCustomReportTemplates.length ?? 0})
            </Button>
          )}
        </CustomReportsTabs>
        {isPendingUpdates ? (
          <Alert severity="warning" style={{ marginTop: 10 }}>
            <Trans
              ns="customReports"
              i18nKey="pendingReportGenerationAlert"
              components={{
                customReportsManagerLink: (
                  <Link
                    to={Routes.CustomReportTemplateManager.getUrl({
                      accountId,
                      projectId,
                      crawlId,
                    })}
                  />
                ),
              }}
            />
          </Alert>
        ) : null}
        <Box marginTop="10px">
          <ReportsTable
            reportEntity={ReportEntity.CustomReport}
            key={selectedType}
            dataGridProps={{
              noRowsOverlay: {
                title: tCustomReports("emptyStateTitle"),
                detail: tCustomReports("emptyStateDetail"),
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
}
