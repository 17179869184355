import {
  DownloadSolid,
  Snackbar,
  ToggleIconButton,
  TrashOutlined,
  useDateFormatter,
  useTranslation,
} from "@lumar/shared";
import {
  CircularProgress,
  Grid,
  ListItemIcon,
  makeStyles,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import { GraphQLError } from "graphql";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  ReportDownload,
  ReportDownloadOutputType,
  ReportDownloadStatus,
  useDeleteExportedFileMutation,
} from "../../../../../../graphql";
import { FilterViewer } from "../../../_common/filter-viewer/FilterViewer";
import { ReportEntity, ReportInput } from "../../../../../Report.types";

export type ExportedRows = NonNullable<ReportDownload>[];

const useStyles = makeStyles((theme) => ({
  icon: { fontSize: 23 },
  itemIcon: { minWidth: 35 },
  availableFilesItem: {
    height: 48,
  },
  availableFilesItemOutPutType: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: 14,
  },
  availableFilesItemDate: {
    color: theme.palette.grey[700],
    fontWeight: 400,
    fontSize: 12,
  },
  defaultCursor: {
    cursor: "default",
  },
}));

const getRefetchQueries = (reportInput: ReportInput): string[] => {
  switch (reportInput.reportEntity) {
    case ReportEntity.CustomReport:
      return ["CustomReportExportedRows"];
    default:
      return ["ReportStatExportedRows"];
  }
};

export function ExportRowsItem({
  reportInput,
  download,
  downloadFile,
}: {
  reportInput: ReportInput;
  download: ExportedRows[number];
  downloadFile: (download: ExportedRows[number]) => void;
}): JSX.Element {
  const classes = useStyles();
  const [isDeleting, setIsDeleting] = useState(false);
  const { t } = useTranslation(["units", "report"]);
  const dateFormatter = useDateFormatter();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteFile] = useDeleteExportedFileMutation({
    refetchQueries: getRefetchQueries(reportInput),
    awaitRefetchQueries: true,
    errorPolicy: "all",
  });

  function showError(error: GraphQLError): void {
    if (error.extensions?.code === "ENTITY_NOT_FOUND") return;

    enqueueSnackbar(
      <Snackbar variant="error" title={t("report:deleteError")}>
        {error.message}
      </Snackbar>,
    );
  }

  function getLabel(): string {
    if (download.outputType === ReportDownloadOutputType.XmlZip) {
      return t("report:xml");
    }

    return download.filter ? t("report:filteredCSV") : t("report:csv");
  }

  const isGenerated = download.status === ReportDownloadStatus.Generated;

  return (
    <Tooltip
      title={
        download.filter ? (
          <FilterViewer filter={download.filter}></FilterViewer>
        ) : (
          t("report:notFiltered")
        )
      }
      placement="left"
    >
      <MenuItem
        key={download.id}
        className={clsx(
          !isGenerated ? classes.defaultCursor : "",
          classes.availableFilesItem,
        )}
        onClick={() => downloadFile(download)}
        data-testid="export-generated-item"
        autoFocus
      >
        <ListItemIcon className={classes.itemIcon}>
          {!isGenerated ? (
            <CircularProgress
              color="primary"
              size="15px"
              style={{ marginLeft: 5 }}
              data-testid="export-generated-item-loading"
            />
          ) : (
            <DownloadSolid
              className={classes.icon}
              data-testid="export-generated-item-download"
            />
          )}
        </ListItemIcon>
        <Grid container>
          <Grid item xs={10}>
            <span className={classes.availableFilesItemOutPutType}>
              {getLabel()}
            </span>
            <br />
            <span className={classes.availableFilesItemDate}>
              {dateFormatter(new Date(download.createdAt), {
                dateStyle: "medium",
                timeStyle: "short",
              })}
            </span>
          </Grid>
          <Grid item xs={2} container alignContent="center">
            {!isDeleting ? (
              <ToggleIconButton
                onClick={async (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setIsDeleting(true);
                  try {
                    const result = await deleteFile({
                      variables: { input: { reportDownloadId: download.id } },
                    });
                    if (result.errors?.length) showError(result.errors[0]);
                  } catch (error) {
                    showError(error as GraphQLError);
                  }
                  setIsDeleting(false);
                }}
                data-testid="export-generated-item-delete"
              >
                <TrashOutlined style={{ fontSize: 20 }} />
              </ToggleIconButton>
            ) : (
              <CircularProgress
                color="primary"
                size="15px"
                style={{ marginLeft: 15 }}
              />
            )}
          </Grid>
        </Grid>
      </MenuItem>
    </Tooltip>
  );
}
