/* eslint-disable react/display-name */
/* eslint-disable fp/no-this */
import React from "react";
import { Typography } from "@material-ui/core";
import { TFunction } from "i18next";

import {
  ChartConfigItem,
  LinkData,
  VisualisationTypes,
} from "../types/ChartConfig";
import {
  ConnectionPredicate,
  CrawlType,
  CrawlUrlAggregateDimension,
  ReportTypeCode,
} from "../../../graphql";
import { Routes } from "../../routing/routes";
import { createConnectionFilter } from "../../connection-filtering/createConnectionFilter";
import { ReportInResponse } from "../utils/generateChartQuery";

export function getIndexableDepthChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    visualisationFamily: "MultiDimensionalSeries",
    visualisationType: VisualisationTypes.StackedColumn,
    additionalVisualisationTypes: [
      VisualisationTypes.Line,
      VisualisationTypes.Area,
      VisualisationTypes.StackedArea,
      VisualisationTypes.FullStackedColumn,
      VisualisationTypes.FullStackedArea,
    ],
    title: () => t("indexableDepth.title"),
    description: () => (
      <>
        <Typography paragraph variant="inherit">
          {t("indexableDepth.description")}
        </Typography>
      </>
    ),
    reports: () => ({
      filter: {
        reportTypeCode: ReportTypeCode.Basic,
        reportTemplateCode: ["indexable_pages", "non_indexable_pages"],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        crawlUrlsAggregates: {
          args: {
            dimensions: [CrawlUrlAggregateDimension.Level],
            filter: { level: { isNull: false } },
          },
          level: true,
          count: {
            url: true,
          },
        },
      },
    }),
    requiredSources: {
      sources: [CrawlType.Web],
      incompleteMessage: t("webCrawlDepth.requiredSourcesMessage"),
    },
    bindings: {
      xAxisLabel: "Level",
      series: (reports: ReportInResponse[], linkData: LinkData) => {
        return reports.map((report) => ({
          name: report.reportTemplate.name,
          reportTemplateCode: report.reportTemplate.code,
          data: (report.crawlUrlsAggregates || []).map((crawlUrlsAggregate) => {
            const depthLevel = crawlUrlsAggregate?.level ?? 0;
            const urlCount = crawlUrlsAggregate?.count?.url ?? 0;

            return {
              x: depthLevel,
              y: urlCount,
              url: Routes.Report.getUrl({
                ...linkData,
                reportTemplateCode: report.reportTemplate.code,
                reportTypeCode: ReportTypeCode.Basic.toLowerCase(),
                filter: createConnectionFilter({
                  or: [
                    {
                      and: [
                        {
                          metricCode: "level",
                          predicateKey: ConnectionPredicate.Eq,
                          predicateValue: depthLevel,
                        },
                      ],
                    },
                  ],
                }),
              }),
              reportTemplateCode: report.reportTemplate.code,
              additionalTooltipLines: [
                t("webCrawlDepth.depthLevel", {
                  depthLevel,
                }),
              ],
            };
          }),
        }));
      },
    },
  };
}
