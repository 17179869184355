import React from "react";
import { useFormikContext } from "formik";
import {
  Button,
  DestructiveMenuActionConfirmation,
  EyeSolid,
  PlusSolid,
  useTranslation,
} from "@lumar/shared";

interface Props {
  onCancel: () => void;
  onSubmit: () => void;
  onEdit?: () => void;
  onPreview?: () => void;
  submitBtnText: string;
  submitDisabled?: boolean;
  children?: React.ReactNode;
}

export function SegmentDialogFooter(props: Props): JSX.Element | null {
  const {
    onCancel,
    onSubmit,
    onEdit,
    onPreview,
    submitBtnText,
    children,
    submitDisabled,
  } = props;
  const { isSubmitting } = useFormikContext();
  const { t } = useTranslation("segments");

  const [showConfirmPopup, setShowConfirmPopup] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  const handleCancel = (event: React.MouseEvent): void => {
    event.stopPropagation();
    setAnchorEl(buttonRef.current);
    setShowConfirmPopup(true);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ flexGrow: 1, minWidth: 0 }}>{children}</div>
      <div
        style={{ minWidth: 400, display: "flex", justifyContent: "flex-end" }}
      >
        {onEdit ? (
          <Button
            onClick={onEdit}
            disabled={isSubmitting}
            variant="outlined"
            size="large"
            autoFocus
            data-testid="segment-edit-button"
          >
            {t("edit")}
          </Button>
        ) : (
          <Button
            onClick={handleCancel}
            disabled={isSubmitting}
            variant="outlined"
            size="large"
            autoFocus
            data-testid="segment-cancel-button"
            ref={buttonRef}
          >
            {t("cancel")}
          </Button>
        )}
        <div style={{ paddingLeft: 8 }}>
          {onPreview ? (
            <Button
              onClick={onPreview}
              variant="contained"
              color="primary"
              size="large"
              startIcon={<EyeSolid />}
              disabled={isSubmitting}
              data-testid="segment-preview-button"
            >
              {t("previewSegment")}
            </Button>
          ) : (
            <Button
              onClick={onSubmit}
              variant="contained"
              color="primary"
              size="large"
              startIcon={<PlusSolid />}
              disabled={isSubmitting || submitDisabled}
              data-testid="segment-submit-button"
            >
              {submitBtnText}
            </Button>
          )}
        </div>
      </div>
      {showConfirmPopup && (
        <DestructiveMenuActionConfirmation
          onConfirm={onCancel}
          anchorEl={anchorEl}
          onCancel={() => setShowConfirmPopup(false)}
          title={t("destructiveActions.title")}
          cancelText={t("destructiveActions.cancel")}
          align="right"
          confirmText={t("destructiveActions.confirm")}
          description={t("destructiveActions.description")}
        />
      )}
    </div>
  );
}
