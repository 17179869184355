/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode, OrderDirection, ReportOrderField } from "../../../graphql";

export function getAccessibilityPagesWithIssuesLevelAChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    supportedModules: [ModuleCode.Accessibility],
    // visualisationColors: [theme.co.red[300]],
    title: () => t("accessibilityPagesWithIssuesLevelAChart.title"),
    description: () => t("accessibilityPagesWithIssuesLevelAChart.description"),
    reports: () => ({
      orderBy: {
        field: ReportOrderField.TotalRows,
        direction: OrderDirection.Desc,
      },
      filter: {
        reportTemplateCode: ["pages_with_a_issues", "pages_without_a_issues"],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        totalRows: true,
      },
    }),
    bindings: {
      name: (report) => report.reportTemplate.name,
      count: (report) => {
        return report.totalRows ?? 0;
      },
    },
  };
}
