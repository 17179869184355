import React from "react";
import { useTheme } from "@material-ui/core";
import {
  useNumberFormatter,
  useTranslation,
  ApolloError,
  SparklineTableChartDatum,
  SparklineTableChartProps,
  Chip,
  ChipColor,
  ArrowNarrowDownSolid,
  ArrowNarrowUpSolid,
} from "@lumar/shared";
import { useParams } from "react-router-dom";

import {
  CrawlContextCrawlReport,
  useCrawlContextData,
  useCrawlContextHelpers,
} from "../../../CrawlContext";
import { Routes } from "../../../../_common/routing/routes";
import { useCrawlOverviewContextData } from "../../../CrawlOverviewContext";
import { newCategoriesChartsMap } from "../../config/new/newCategoriesChartsMap";
import { useNumberMetricFormatter } from "../../../../resource-detail/metrics-value-presenter/default-presenters/NumberPresenter";
import {
  CustomDataConfig,
  isCustomDataAggregateMetricConfig,
} from "../report-custom-data/types";
import { useReportsAggregateMetricData } from "../report-custom-data/useReportsAggregateMetricData";
import {
  mapCrawlReportTrendToSparklineChartSeries,
  sortReports,
} from "./helpers";
import { getReportsWithCustomValues } from "../report-custom-data/getReportsWithCustomValues";

export type ReportsChartColumnCustomDefinition = {
  field: string;
  header?: string;
  width?: string;
  value: CustomDataConfig;
  hide?: boolean;
  maxFractionDigits?: number;
  minFractionDigits?: number;
  align?: "left" | "center" | "right";
  bold?: boolean;
  color?:
    | string
    | ((props: {
        value: number | null | undefined;
        report: CrawlContextCrawlReport;
      }) => string | undefined);
};

export function useTopErrorsSparklineTableChartData(): {
  data: SparklineTableChartDatum[];
  columns: SparklineTableChartProps["columns"];
  loading: boolean;
  noErrors: boolean;
  crawlArchived: boolean;
  error?: ApolloError;
} {
  const { crawlId, projectId, accountId } = useParams<{
    crawlId: string;
    accountId: string;
    projectId: string;
  }>();
  const { t } = useTranslation("charts");
  const theme = useTheme();
  const formatMetric = useNumberMetricFormatter();

  const helpers = useCrawlContextHelpers();
  const { selectedCategory } = useCrawlOverviewContextData();
  const { selectedCrawlSegment } = useCrawlContextData();

  const reports = helpers.getCrawlReportCategoryErrorReportsList(
    selectedCategory.code,
  );

  const errorsChartConfig = newCategoriesChartsMap
    .get(selectedCategory.code)
    ?.errorsChart?.({ t, theme });
  const columnDefinitions = errorsChartConfig?.columns || [
    "name",
    "total",
    "change",
    "trend",
  ];
  const orderBy = errorsChartConfig?.orderBy;

  const columnsWithCustomValue =
    columnDefinitions.filter(
      (value): value is ReportsChartColumnCustomDefinition =>
        typeof value !== "string",
    ) || [];

  const { data, loading, crawlArchived, error } = useReportsAggregateMetricData(
    {
      crawlId,
      configs: columnsWithCustomValue
        .map((x) => x.value)
        .filter(isCustomDataAggregateMetricConfig),
      skip: !reports.length,
    },
  );

  const reportsWithCustomValues = getReportsWithCustomValues({
    reports,
    aggregateMetricData: data,
    fields: columnsWithCustomValue,
  });

  const reportsToShow = sortReports(
    reportsWithCustomValues,
    orderBy?.field,
    orderBy?.direction,
    columnsWithCustomValue,
  )
    .slice(0, 6)
    .map((report) => ({
      report,
      series: mapCrawlReportTrendToSparklineChartSeries(report.reportTrend),
    }));

  const showPercentageChangeOverall = !!reportsToShow.find(
    ({ series }) => series.length > 1,
  );

  const filteredColumns = columnDefinitions.filter(
    (column) =>
      (showPercentageChangeOverall || column !== "change") &&
      !(typeof column !== "string" && column.hide),
  );

  const chartColumns = filteredColumns.map<
    Required<SparklineTableChartProps>["columns"][0]
  >((column, idx) => {
    if (column === "name" || column === "trend") return column;
    if (column === "total") {
      return { field: "total", columnWidth: "minmax(min-content, 1fr)" };
    }
    if (column === "change") {
      return { field: "change", columnWidth: "minmax(min-content, 1fr)" };
    }

    return {
      field: column.field || `column${idx}`,
      header: column.header,
      columnWidth: column.width || "minmax(60px, auto)",
    };
  });

  const chartData = reportsToShow.map<SparklineTableChartDatum>(
    ({ report, series }) => ({
      id: report.reportTemplate.code,
      href: Routes.Report.getUrl({
        accountId,
        crawlId,
        projectId,
        reportTemplateCode: report.reportTemplate.code,
        reportTypeCode: "basic",
        segmentId: selectedCrawlSegment?.segment.id,
      }),
      label: report.reportTemplate.name,
      values: filteredColumns.reduce<SparklineTableChartDatum["values"]>(
        (values, column, idx) => {
          if (column === "total") {
            return {
              ...values,
              total: (
                <ErrorReportTotalRows
                  key="rows-change"
                  totalRows={report.totalRows || 0}
                />
              ),
            };
          }

          if (column === "change") {
            return {
              ...values,
              change: (
                <ErrorReportChange
                  key="percentage-change"
                  totalRows={report.totalRows || 0}
                  change={report.change || 0}
                  hidden={series.length <= 1}
                />
              ),
            };
          }

          if (typeof column !== "string") {
            const { value, unit } = report.customValues[column.field] ?? {};

            const formattedValue = (() => {
              if (typeof value !== "number") {
                return value ?? "-";
              }

              const formattedValue = formatMetric(value, unit, {
                maximumFractionDigits: column.maxFractionDigits,
                minimumFractionDigits: column.minFractionDigits,
              });

              return `${formattedValue.value} ${formattedValue.unit || ""}`;
            })();

            return {
              ...values,
              [column.field]: (
                <div
                  key={idx}
                  style={{
                    fontWeight: column.bold ? 600 : 400,
                    color:
                      typeof column.color === "string"
                        ? column.color
                        : column.color?.({ value, report }),
                    textAlign: column.align || "right",
                  }}
                >
                  {formattedValue}
                </div>
              ),
            };
          }

          return values;
        },
        {},
      ),
      series,
    }),
  );

  return {
    data: chartData,
    columns: chartColumns,
    loading,
    noErrors: reports.length === 0,
    crawlArchived,
    error,
  };
}

function ErrorReportTotalRows(props: { totalRows: number }): JSX.Element {
  const theme = useTheme();
  const formatNumber = useNumberFormatter();
  return (
    <span
      key="total-rows"
      style={{
        color: theme.palette.red[600],
        width: "34px",
        textAlign: "right",
        display: "inline-block",
        fontWeight: 600,
      }}
    >
      {formatNumber(props.totalRows, {
        notation: "compact",
      })}
    </span>
  );
}

function ErrorReportChange(props: {
  totalRows: number;
  change: number;
  hidden?: boolean;
}): JSX.Element {
  const formatNumber = useNumberFormatter();
  const percentageChange = Math.abs(
    props.totalRows / (props.totalRows - props.change) - 1,
  );

  return (
    <Chip
      {...useChipProps(props.change)}
      style={{
        minWidth: "86px",
        alignSelf: "start",
        visibility: props.hidden ? "hidden" : undefined,
      }}
      label={formatNumber(percentageChange, {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    ></Chip>
  );
}

function useChipProps(change: number): {
  icon?: React.ReactElement;
  color: ChipColor;
} {
  const theme = useTheme();
  if (change < 0) {
    return {
      color: "green",
      icon: (
        <ArrowNarrowDownSolid
          fontSize="inherit"
          style={{ color: theme.palette.green[800] }}
        />
      ),
    };
  }

  if (change > 0) {
    return {
      color: "red",
      icon: (
        <ArrowNarrowUpSolid
          fontSize="inherit"
          style={{ color: theme.palette.red[800] }}
        />
      ),
    };
  }

  return {
    color: "lightgrey",
  };
}
