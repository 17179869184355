/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { CrawlType, ReportTypeCode } from "../../../graphql";

export function getPagesinSitemapsBreakdownChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    requiredSources: {
      sources: [CrawlType.Sitemap],
      incompleteMessage: t("requiredSourcesMessage.sitemapsSource"),
    },
    title: () => t("pagesinSitemapsBreakdownChart.title"),
    description: () => t("pagesinSitemapsBreakdownChart.description"),
    reports: () => ({
      filter: {
        reportTypeCode: ReportTypeCode.Basic,
        reportTemplateCode: [
          "primary_pages_in_sitemaps",
          "not_in_sitemaps_primary_indexable",
          "duplicate_pages_in_sitemaps",
          "sitemaps_non_indexable_links",
          "redirecting_pages_in_sitemaps",
          "error_pages_in_sitemaps",
        ],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        totalRows: true,
      },
    }),
    showPercentageInSeriesTooltip: true,
    bindings: {
      name: (report) => {
        return report.reportTemplate.name;
      },
      count: (report) => {
        return report.totalRows ?? 0;
      },
    },
  };
}
