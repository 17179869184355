import { EnumType } from "json-to-graphql-query";

import { CrawlType } from "../../../../graphql";
import { ChartConfigItem, TileChartConfigItem } from "../../types/ChartConfig";
import { AdditionalConfig, ChartQueryBuilder } from "../generateChartQuery";

const CRAWL_TYPES = [
  CrawlType.Web,
  CrawlType.List,
  CrawlType.Backlinks,
  CrawlType.LogSummary,
  CrawlType.Sitemap,
  CrawlType.GoogleAnalytics,
  CrawlType.GoogleSearchConsole,
];

export function getCrawlTypeCountsFields(
  config: ChartConfigItem | TileChartConfigItem,
  additionalConfig: AdditionalConfig,
): ChartQueryBuilder {
  if (!config.crawlTypeCounts) return {};

  const segmentId = additionalConfig.segmentId ?? null;
  const reportTemplateCode = config.crawlTypeCounts.reportTemplateCode ?? null;

  return {
    fields: {
      crawlTypeCounts: {
        __args: {
          input: {
            crawlTypes: CRAWL_TYPES.map((crawlType) => new EnumType(crawlType)),
            reportTemplateCode,
            segmentId,
          },
        },
        crawlTypes: true,
        count: true,
      },
    },
  };
}
