import { ReportCategoryChartConfig } from "../../types";
import { getSiteSpeedFCPChart } from "../../../../../_common/charts/config/getSiteSpeedFCPChart";
import { getSiteSpeedFCPTrend } from "../../../../../_common/charts/config/getSiteSpeedFCPTrend";
import { getSiteSpeedErrorsChart } from "../../../../../_common/charts/config/getSiteSpeedErrorsChart";
import { getSiteSpeedErrorsTable } from "../../../../../_common/charts/config/getSiteSpeedErrorsTable";
import { getSiteSpeedAvgFCPChart } from "../../../../../_common/charts/config/getSiteSpeedAvgFCPChart";
import { getSiteSpeedAllReportsTable } from "../../../../../_common/charts/config/getSiteSpeedAllReportsTable";

export const generateSiteSpeedFirstContentfulPaintCategoryItems: ReportCategoryChartConfig =
  {
    errorsChart: getSiteSpeedErrorsChart,
    errorsTable: getSiteSpeedErrorsTable,
    allReportsTable: getSiteSpeedAllReportsTable,
    mainCharts: [
      // getSiteSpeedFCPMetricTrend,
      getSiteSpeedAvgFCPChart,
      getSiteSpeedFCPChart,
      getSiteSpeedFCPTrend,
    ],
  };
