import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";
import { ModuleCode } from "../../../graphql";

export function getSiteSpeedFCPChart(t: TFunction<"charts">): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Pie,
    supportedModules: [ModuleCode.SiteSpeed],

    title: t("siteSpeedFCPChart.title"),
    reportTemplateCodes: [
      "first_contentful_paint_slow",
      "first_contentful_paint_moderate",
      "first_contentful_paint_fast",
    ],
    description: t("siteSpeedFCPChart.description"),
  })();
}
