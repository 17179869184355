import { useLocation, useParams } from "react-router-dom";
import { desanitizeDigestFromPageId } from "../../_common/routing/desanitizeDigestFromPageId";

type ResouceDetailParams = {
  accountId: string;
  projectId: string;
  crawlId: string;
  reportTemplateCodeWithTypeCode: string;
  resourceId: string;
  sourceReportTemplateCode?: string;
  type?: string;
};

export function useResourceDetailParams(): ResouceDetailParams {
  const {
    accountId,
    projectId,
    crawlId,
    reportTemplateCodeWithTypeCode,
    resourceId,
  } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
    reportTemplateCodeWithTypeCode: string;
    resourceId: string;
  }>();

  const location = useLocation();

  const search = new URLSearchParams(location.search);
  const type = search.get("type") || undefined;
  const sourceReportTemplateCode = search.get("source") || undefined;

  return {
    accountId,
    projectId,
    crawlId,
    reportTemplateCodeWithTypeCode,
    sourceReportTemplateCode,
    resourceId: desanitizeDigestFromPageId(resourceId),
    type,
  };
}
