import React from "react";
import { useTranslation } from "react-i18next";
import { RequiredSources } from "../../types/ChartConfigItemBase";
import { ArchivedCrawlMessage } from "../chart-messages/ArchivedCrawlMessage";
import { useChartDataContext } from "./ChartDataContext";
import { ChartPanelErrorMessage } from "../chart-messages/ChartPanelErrorMessage";
import { ChartPanelMessageWrapper } from "../chart-messages/ChartPanelMessageWrapper";
import { MissingSourceMessage } from "../chart-messages/MissingSourceMessage";
import { isChartMissingRequiredSource } from "../utils";
import { UnarchivingCrawlMessage } from "../chart-messages/UnarchivingCrawlMessage";
import { ApolloError } from "@lumar/shared";

export function ChartErrorGuard(props: {
  children: React.ReactNode;
  requiredSources?: RequiredSources;
}): JSX.Element {
  const { error, data } = useChartDataContext();
  const { t } = useTranslation(["charts", "common"]);

  if (error) {
    if (isCrawlArchivedError(error)) {
      return <ArchivedCrawlMessage />;
    }

    if (isCrawlUnarchivingError(error)) {
      return <UnarchivingCrawlMessage />;
    }

    return (
      <ChartPanelMessageWrapper>
        <ChartPanelErrorMessage allowRefresh>
          {t("common:genericErrorExtended")}
        </ChartPanelErrorMessage>
      </ChartPanelMessageWrapper>
    );
  }

  if (!data?.getCrawl) {
    return (
      <ChartPanelMessageWrapper>
        <ChartPanelErrorMessage>
          {t("common:crawlNotFound")}
        </ChartPanelErrorMessage>
      </ChartPanelMessageWrapper>
    );
  }

  const isMissingRequiredSource = isChartMissingRequiredSource(
    props.requiredSources,
    data,
  );

  if (isMissingRequiredSource) {
    return (
      <ChartPanelMessageWrapper>
        <MissingSourceMessage requiredSources={props.requiredSources} />
      </ChartPanelMessageWrapper>
    );
  }

  return <>{props.children}</>;
}

function isCrawlArchivedError(error: ApolloError): boolean {
  return Boolean(
    error.graphQLErrors.find(
      (x) => x.extensions?.code === "REPORT_DATA_UNAVAILABLE_CRAWL_ARCHIVED",
    ),
  );
}

function isCrawlUnarchivingError(error: ApolloError): boolean {
  return Boolean(
    error.graphQLErrors.find(
      (x) => x.extensions?.code === "REPORT_DATA_UNAVAILABLE_CRAWL_UNARCHIVING",
    ),
  );
}
