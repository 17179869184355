import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useTranslation } from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginRight: theme.spacing(1),
  },
}));

export function UnresolvedCell({ value }: GridRenderCellParams): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("units");

  const unresolved = value as number;

  return (
    <Typography
      variant="caption"
      className={classes.label}
      data-testid="tasks-unresolved"
    >
      {t("number", { count: unresolved })}
    </Typography>
  );
}
