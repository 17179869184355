import React from "react";
import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import { GridRowParams } from "@mui/x-data-grid-pro";
import { useHistory } from "react-router";
import { Routes } from "../_common/routing/routes";
import {
  getRawCrawlId,
  getRawProjectId,
  useAccountGuard,
  useTranslation,
} from "@lumar/shared";
import { useAccountTaskTableColumns } from "./useTaskTableColumns";
import { isNotEmptyConnectionFilter } from "../_common/connection-filtering/isNotEmptyConnectionFilter";
import { TasksGrid } from "./TasksGrid";
import { useParams } from "react-router-dom";
import { Task } from "./data/types";
import { useAccountTasksData } from "./data/useAccountTasksData";
import { ShowResolvedFilter } from "./ShowResolvedFilter";

export function AccountTaskManager(): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation("taskManager");
  const { accountId } = useParams<{
    accountId: string;
  }>();

  useAccountGuard();

  const { rows, rowCount, totalRowCount, loading, error, pagination } =
    useAccountTasksData({
      accountId,
    });

  const { columns, defaultColumns, setColumnState } =
    useAccountTaskTableColumns();

  function onRowClick(params: GridRowParams): void {
    const rowData = params.row as Task;

    if (
      !rowData.project?.lastFinishedCrawl ||
      !rowData.reportTemplate ||
      !rowData.reportType
    ) {
      return;
    }

    const isValidFilter =
      rowData.connectionFilter &&
      !isNotEmptyConnectionFilter(rowData.connectionFilter);

    history.push(
      Routes.Report.getUrl({
        accountId,
        projectId: getRawProjectId(rowData.project.id),
        crawlId: getRawCrawlId(rowData.project.lastFinishedCrawl.id),
        reportTemplateCode: rowData.reportTemplate.code,
        reportTypeCode: rowData.reportType.code.toLowerCase(),
        segmentId: rowData.segment?.id,
        filter: isValidFilter
          ? { _or: [rowData.connectionFilter] }
          : rowData.connectionFilter,
      }),
    );
  }

  return (
    <>
      <TopNavigation
        title={t("accountTasksTitle")}
        breadcrumbs={[{ label: t("accountTasksBreadcrumb") }]}
      />
      <TasksGrid
        rows={rows}
        rowCount={rowCount}
        totalRowCount={totalRowCount}
        columns={columns}
        defaultColumns={defaultColumns}
        loading={loading}
        error={Boolean(error)}
        onRowClick={onRowClick}
        setColumnState={setColumnState}
        searchColumn="task"
        components={{
          ToolbarRight: [ShowResolvedFilter],
        }}
        componentsProps={{ searchField: { searchDebounce: 300 } }}
        {...pagination}
      />
    </>
  );
}
