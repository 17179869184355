import React from "react";
import {
  GridCellValue,
  GridRenderCellParams,
  GridSortCellParams,
} from "@mui/x-data-grid-pro";
import { makeStyles, Typography } from "@material-ui/core";

import { LegacyTask } from "../../graphql";

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.grey[500],
  },
}));

export function StatusDeadlineCell(params: GridRenderCellParams): JSX.Element {
  const classes = useStyles();
  const { value } = params;

  return (
    <div>
      <Typography
        variant="caption"
        className={classes.text}
        data-testid="tasks-status-deadline"
      >
        {value}
      </Typography>
    </div>
  );
}

export function statusDeadlineSort(
  p1deadline: GridCellValue,
  p2deadline: GridCellValue,
  param1: GridSortCellParams,
  param2: GridSortCellParams,
): number {
  const p1fixedAt = param1.api.getRow(param1.id).fixedAt;
  const p2fixedAt = param1.api.getRow(param2.id).fixedAt;
  const p1deadlineAt = param1.api.getRow(param1.id).deadlineAt;
  const p2deadlineAt = param2.api.getRow(param2.id).deadlineAt;

  if (p1deadlineAt && !p1fixedAt && p2deadlineAt && !p2fixedAt)
    return p1deadlineAt.localeCompare(p2deadlineAt);

  return (
    getStatusOrder(p2deadlineAt as LegacyTask["deadlineAt"], p2fixedAt) -
    getStatusOrder(p1deadlineAt as LegacyTask["deadlineAt"], p1fixedAt)
  );
}

function getStatusOrder(
  deadlineAt: LegacyTask["deadlineAt"],
  fixedAt: LegacyTask["fixedAt"],
): number {
  if (fixedAt) return 3;
  if (!deadlineAt) return 2;
  return 1;
}
