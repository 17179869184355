import React from "react";
import { useSnackbar } from "notistack";
import {
  ProjectTasksDocument,
  ProjectTasksQuery,
  RemoveProjectTaskMutation,
  useRemoveProjectTaskMutation,
  useUpdateResolvedMutation,
} from "../../graphql";
import {
  Snackbar,
  useTranslation,
  ApolloCache,
  getRawProjectId,
} from "@lumar/shared";
import { useParams } from "react-router-dom";
import { Task } from "./types";

export interface TaskMutations {
  setResolved: (task: Task, isResolved: boolean) => Promise<void>;
  removeTask: (task: Task) => Promise<void>;
}

export function useTaskMutations(): TaskMutations {
  const { t } = useTranslation("taskManager");
  const { enqueueSnackbar } = useSnackbar();

  const { projectId } = useParams<{
    projectId?: string;
  }>();

  const [updateResolvedMutation] = useUpdateResolvedMutation({
    onError: (error) =>
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("actionsMenu.updateError", { message: error.message })}
        />,
      ),
  });

  const [removeTaskMutation] = useRemoveProjectTaskMutation({
    onError: (error) =>
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("actionsMenu.removeError", { message: error.message })}
        />,
      ),
  });

  const setResolved = React.useCallback(
    async (task: Task, isResolved: boolean) => {
      const fixedAt = isResolved ? new Date().toISOString() : null;

      await updateResolvedMutation({
        variables: {
          legacyTaskId: task.id,
          fixedAt,
        },
        optimisticResponse: {
          updateLegacyTask: {
            legacyTask: {
              id: task.id,
              fixedAt,
              __typename: "LegacyTask",
            },
          },
        },
        refetchQueries: ["AccountTasks", "UnresolvedProjectTasksCountForChip"],
        awaitRefetchQueries: true,
      });
    },
    [updateResolvedMutation],
  );

  const removeTask = React.useCallback(
    async (task: Task) => {
      const projectIdResolved = task.project
        ? getRawProjectId(task.project.id)
        : projectId;

      await removeTaskMutation({
        variables: {
          legacyTaskId: task.id,
        },
        optimisticResponse: {
          deleteLegacyTask: {
            legacyTask: {
              id: task.id,
              __typename: "LegacyTask",
            },
          },
        },
        refetchQueries: ["AccountTasks", "UnresolvedProjectTasksCountForChip"],
        awaitRefetchQueries: true,
        update: (cache, { data }) => {
          if (projectIdResolved) {
            updateProjectTasksQuery(cache, data, projectIdResolved);
          }
        },
      });
    },
    [removeTaskMutation, projectId],
  );

  return {
    setResolved,
    removeTask,
  };
}

function updateProjectTasksQuery(
  cache: ApolloCache<RemoveProjectTaskMutation>,
  data: RemoveProjectTaskMutation | null | undefined,
  projectId: string,
): void {
  const cachedData: ProjectTasksQuery | null = cache.readQuery({
    query: ProjectTasksDocument,
    variables: { projectId },
  });
  if (!cachedData?.getProject) return;

  cache.writeQuery({
    query: ProjectTasksDocument,
    variables: { projectId },
    data: {
      getProject: {
        ...cachedData.getProject,
        legacyTasks: {
          ...cachedData.getProject.legacyTasks,
          edges: cachedData.getProject.legacyTasks.edges.filter(
            (x) => x.node.id !== data?.deleteLegacyTask?.legacyTask?.id,
          ),
        },
        legacyTasksTotalCount: cachedData.getProject.legacyTasksTotalCount - 1,
      },
    },
  });
}
