import React, { useState } from "react";
import { makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import {
  DotsHorizontalOutlined,
  CheckCircleOutlined,
  Edit,
  TrashOutlined,
  ToggleIconButton,
  DestructiveMenuActionConfirmation,
  SparklesOutlined,
  useSession,
} from "@lumar/shared";
import { useTranslation } from "react-i18next";

import { EditTaskDialog } from "../../edit-task/EditTaskDialog";
import { Task } from "../../data/types";
import { TaskMutations } from "../../data/useTaskMutations";
import { GenerateDeliveryTicketDialog } from "../../generate-delivery-ticket/GenerateDeliveryTicketDialog";

const useStyles = makeStyles((theme) => ({
  menu: {
    border: 0,
    width: 224,
    paddingLeft: 8,
    paddingRight: 8,
    boxShadow:
      "0px -1px 4px rgba(0, 0, 0, 0.35), 0px 10px 15px -3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08)",
    color: theme.palette.grey[700],
  },
  menuItem: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  icons: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.grey[400],
    fontSize: theme.typography.pxToRem(20),
  },
  removeIcon: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.red[600],
    fontSize: theme.typography.pxToRem(20),
  },
  text: {
    color: theme.palette.grey[800],
  },
}));

// FIXME: Should be reusable.
export function ActionsCell({
  row,
  setResolved,
  removeTask,
}: GridRenderCellParams & TaskMutations): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("taskManager");
  const { account, hasFeatureFlagEnabled } = useSession();

  const [isEditing, setIsEditing] = useState(false);
  const [isGeneratingDeliveryTicket, setGeneratingDeliveryTicket] =
    useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const [actionsAnchor, setActionsAnchor] = React.useState<null | HTMLElement>(
    null,
  );

  const task = row as Task;
  const isMenuOpen = Boolean(actionsAnchor);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    setActionsAnchor(event.currentTarget);
  };

  const handleClose = (): void => {
    setActionsAnchor(null);
    setIsRemoving(false);
  };

  const handleMarkAsResolved = (): void => {
    setResolved(task, !task.fixedAt);
    setActionsAnchor(null);
  };

  const handleRemove = async (): Promise<void> => {
    await removeTask(task);
    handleClose();
  };

  const showGenerateDeliveryTicket =
    account.aiFeaturesEnabled &&
    hasFeatureFlagEnabled("generate-delivery-ticket");

  return (
    <>
      <ToggleIconButton
        size="large"
        onClick={handleClick}
        data-testid="tasks-actions"
      >
        <DotsHorizontalOutlined />
      </ToggleIconButton>
      <Menu
        classes={{
          paper: classes.menu,
        }}
        open={isMenuOpen && !isRemoving}
        onClose={handleClose}
        anchorEl={actionsAnchor}
        keepMounted
        // Explanation why it's `null` here: https://github.com/mui-org/material-ui/issues/7961
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        data-testid="tasks-actions-menu"
      >
        <MenuItem
          onClick={handleMarkAsResolved}
          className={classes.menuItem}
          data-testid="tasks-actions-mark-resolved"
        >
          <CheckCircleOutlined className={classes.icons} />
          <Typography variant="body2" className={classes.text}>
            {task.fixedAt
              ? t("actionsMenu.markAsUnResolved")
              : t("actionsMenu.markAsResolved")}
          </Typography>
        </MenuItem>
        {showGenerateDeliveryTicket && (
          <MenuItem
            data-testid="tasks-actions-generate-delivery-ticket"
            className={classes.menuItem}
            onClick={() => {
              setGeneratingDeliveryTicket(true);
              handleClose();
            }}
          >
            <SparklesOutlined className={classes.icons} />
            <Typography variant="body2" className={classes.text}>
              {t("actionsMenu.generateDeliveryTicket")}
            </Typography>
          </MenuItem>
        )}
        <MenuItem
          data-testid="tasks-actions-edit"
          className={classes.menuItem}
          onClick={() => {
            setIsEditing(true);
            handleClose();
          }}
        >
          <Edit className={classes.icons} />
          <Typography variant="body2" className={classes.text}>
            {t("actionsMenu.editTask")}
          </Typography>
        </MenuItem>
        <MenuItem
          data-testid="tasks-actions-remove"
          className={classes.menuItem}
          onClick={() => setIsRemoving(true)}
        >
          <TrashOutlined className={classes.removeIcon} />
          <Typography variant="body2" className={classes.text}>
            {t("actionsMenu.remove")}
          </Typography>
        </MenuItem>
      </Menu>
      {isRemoving ? (
        <DestructiveMenuActionConfirmation
          title={t("actionsMenu.removeTitle")}
          description={t("actionsMenu.removeDescription")}
          confirmText={t("actionsMenu.removeButton")}
          cancelText={t("actionsMenu.cancelButton")}
          anchorEl={actionsAnchor}
          onConfirm={handleRemove}
          onCancel={handleClose}
        />
      ) : null}
      {isEditing && (
        <EditTaskDialog taskId={task.id} onClose={() => setIsEditing(false)} />
      )}
      {isGeneratingDeliveryTicket && (
        <GenerateDeliveryTicketDialog
          taskId={task.id}
          onClose={() => setGeneratingDeliveryTicket(false)}
        />
      )}
    </>
  );
}
