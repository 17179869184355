/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { OrderDirection, ReportOrderField } from "../../../graphql";

export function getDuplicateSetsChart(t: TFunction<"charts">): ChartConfigItem {
  return {
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    title: () => t("duplicateSets.title"),
    description: () => t("duplicateSets.description"),
    reports: () => ({
      orderBy: {
        field: ReportOrderField.TotalRows,
        direction: OrderDirection.Desc,
      },
      filter: {
        reportTemplateCode: [
          "duplicate_pages_2",
          "duplicate_titles_2",
          "duplicate_descriptions_2",
          "duplicate_content_2",
          "duplicate_url_sets",
        ],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        totalRows: true,
      },
    }),
    bindings: {
      name: (report) => report.reportTemplate.name,
      count: (report) => {
        return report.totalRows ?? 0;
      },
    },
  };
}
