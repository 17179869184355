/* eslint-disable react/display-name */
import React from "react";
import { Theme, Typography } from "@material-ui/core";
import { TFunction } from "i18next";

import {
  ChartConfigItem,
  LinkData,
  VisualisationTypes,
} from "../types/ChartConfig";
import { ReportTrendItem, ReportTypeCode } from "../../../graphql";

import { Routes } from "../../routing/routes";
import { getRawCrawlId, DateFormatter } from "@lumar/shared";
import { ReportInResponse } from "../utils/generateChartQuery";
import { RunNewCrawlMessage } from "../components/chart-messages/RunNewCrawlMessage";

export function getHttpStatusTrend(
  t: TFunction<"charts">,
  _: Theme,
  formatter: DateFormatter,
): ChartConfigItem {
  return {
    visualisationFamily: "MultiDimensionalSeries",
    visualisationType: VisualisationTypes.Line,
    additionalVisualisationTypes: [
      VisualisationTypes.Area,
      VisualisationTypes.StackedArea,
      VisualisationTypes.FullStackedArea,
    ],
    title: () => t("httpStatusTrend.title"),
    description: () => (
      <>
        <Typography paragraph variant="inherit">
          {t("httpStatusTrend.description")}
        </Typography>
      </>
    ),
    reports: () => ({
      filter: {
        reportTypeCode: ReportTypeCode.Basic,
        reportTemplateCode: [
          "200_pages",
          "301_redirects",
          "non_301_redirects",
          "unauthorised_pages",
          "uncategorised_http_response_codes",
          "4xx_errors",
          "5xx_errors",
          "failed_urls",
        ],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        totalRows: true,
        reportTrend: {
          crawlId: true,
          createdAt: true,
          basic: true,
        },
      },
    }),
    bindings: {
      series: (
        reports: ReportInResponse[],
        { accountId, projectId, segmentId }: LinkData,
      ) =>
        reports.map((report) => {
          return {
            name: report.reportTemplate.name,
            reportTemplateCode: report.reportTemplate.code,
            data: (report.reportTrend ?? [])
              .filter(
                (
                  trend,
                ): trend is ReportTrendItem & {
                  createdAt: string;
                } => !!trend?.createdAt,
              )
              .map((trend) => {
                const createdAt = new Date(trend.createdAt);
                return {
                  x: createdAt,
                  y: trend.basic ?? 0,
                  url: Routes.Report.getUrl({
                    accountId,
                    projectId,
                    crawlId: getRawCrawlId(String(trend.crawlId)),
                    segmentId,
                    reportTemplateCode: report.reportTemplate.code,
                    reportTypeCode: ReportTypeCode.Basic.toLowerCase(),
                  }),
                  additionalTooltipLines: [
                    formatter(createdAt, {
                      dateStyle: "medium",
                      timeStyle: "short",
                    }),
                  ],
                };
              }),
          };
        }),
    },
    incompleteSeriesTemplate: (
      <RunNewCrawlMessage message={t("runNewCrawlForTrendMessage")} />
    ),
  };
}
