import React from "react";
import {
  Box,
  LinearProgress,
  LinearProgressProps,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { assert } from "../../assert";
import { useBrand, useTranslation } from "@lumar/shared";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { MultiDimensionalSeriesChartProps } from "./MultiDimensionalSeriesChart";
import { useChartReportColorGetter } from "../chart-colors/useChartReportColors";

const END_CELL_WIDTH = 12;

const useStyles = makeStyles({
  table: {
    width: "100%",
    flexGrow: 0.7,
    tableLayout: "auto",
    marginTop: 20,
  },
  th: {
    textAlign: "left",
    padding: 5,
    verticalAlign: "bottom",
    fontWeight: 400,
  },
  td: { padding: 5, verticalAlign: "bottom" },
  tableCell: {
    width: `${(100 - END_CELL_WIDTH) / 4}%`,
  },
  link: { textDecoration: "none", color: "black" },
});

const useLinearProgressStyles = makeStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 3,
  },
  bar: (props: { color?: string; secondaryColor?: string }) => ({
    borderRadius: 3,
    backgroundColor: props.color ?? theme.palette.primary.main,
  }),
  colorPrimary: (props: { color?: string; secondaryColor?: string }) => ({
    backgroundColor: props.secondaryColor ?? theme.palette.grey[200],
  }),
}));

interface CustomLinearProgressProps extends Omit<LinearProgressProps, "color"> {
  color?: string;
  secondaryColor?: string;
}

function CustomLinearProgress({
  color,
  secondaryColor,
  ...otherProps
}: CustomLinearProgressProps): JSX.Element {
  const classes = useLinearProgressStyles({ color, secondaryColor });
  return <LinearProgress {...otherProps} classes={classes} />;
}

export function TableChart({
  visualisationColors,
  secondaryVisualisationColors,
  series,
}: MultiDimensionalSeriesChartProps): JSX.Element {
  const brand = useBrand();
  const classes = useStyles();
  const { t } = useTranslation("units");
  const tableHeaders = series[0]?.data.map((d) => d.x) ?? [];
  const getReportColor = useChartReportColorGetter();
  const shouldApplyReportColors =
    brand.featureAvailability.chartReportSerieColor;

  return (
    <table className={classes.table}>
      <thead>
        <tr className={classes.tableCell}>
          <th />
          {tableHeaders.map((header) => {
            assert(typeof header !== "object");

            return (
              <th key={header} className={clsx(classes.th, classes.tableCell)}>
                <Typography>{header}</Typography>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {series.map(({ name, reportTemplateCode, data }, seriesIndex) => {
          const color = shouldApplyReportColors
            ? getReportColor(reportTemplateCode)
            : undefined;
          return (
            <tr key={name}>
              <td className={clsx(classes.td, classes.tableCell)}>
                <Typography>{name}</Typography>
              </td>
              {data.map(({ x, y, percentage, url }) => {
                const cellContent =
                  typeof percentage === "number" ? (
                    <Tooltip title={`${y} ${name} with ${x} clicks`}>
                      <Box>
                        <Typography>
                          {t("percent", { count: percentage })}
                        </Typography>
                        <CustomLinearProgress
                          variant="determinate"
                          value={percentage * 100}
                          color={
                            visualisationColors
                              ? visualisationColors[seriesIndex]
                              : color
                          }
                          secondaryColor={
                            secondaryVisualisationColors
                              ? secondaryVisualisationColors[seriesIndex]
                              : color
                          }
                        />
                      </Box>
                    </Tooltip>
                  ) : (
                    <Typography>{t("number", { count: y })}</Typography>
                  );

                return (
                  <td
                    key={`${name}-${x}`}
                    className={clsx(classes.td, classes.tableCell)}
                  >
                    {url ? (
                      <Link className={classes.link} to={url}>
                        {cellContent}
                      </Link>
                    ) : (
                      cellContent
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
