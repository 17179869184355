import { getAmpValidationChart } from "../../../_common/charts/config/getAmpValidationChart";
import { getAnalyticsBreakdownChart } from "../../../_common/charts/config/getAnalyticsBreakdownChart";
import { getBacklinkIssuesChart } from "../../../_common/charts/config/getBacklinkIssuesChart";
import { getBacklinksBreakdownChart } from "../../../_common/charts/config/getBacklinksBreakdownChart";
import { getBotHitsBreakdownChart } from "../../../_common/charts/config/getBotHitsBreakdownChart";
import { getBotHitsByDeviceChart } from "../../../_common/charts/config/getBotHitsByDeviceChart";
import { getBrokenLinksChart } from "../../../_common/charts/config/getBrokenLinksChart";
import { getCanonicalBreakdownChart } from "../../../_common/charts/config/getCanonicalBreakdownChart";
import { getCanonicalIssuesChart } from "../../../_common/charts/config/getCanonicalIssuesChart";
import { getContentDuplicationChart } from "../../../_common/charts/config/getContentDuplicationChart";
import { getContentIssuesChart } from "../../../_common/charts/config/getContentIssuesChart";
import { getContentTypeBreakdownChart } from "../../../_common/charts/config/getContentTypeBreakdownChart";
import { getCrawlSourceOverlapChart } from "../../../_common/charts/config/getCrawlSourceOverlapChart";
import { getCumulativeLayoutShiftChart } from "../../../_common/charts/config/getCumulativeLayoutShiftChart";
import { getCustomExtractionChart } from "../../../_common/charts/config/getCustomExtractionChart";
import { getDomContentLoadChart } from "../../../_common/charts/config/getDomContentLoadChart";
import { getDuplicateSetsChart } from "../../../_common/charts/config/getDuplicateSetsChart";
import { getExternalLinkBreakdownChart } from "../../../_common/charts/config/getExternalLinkBreakdownChart";
import { getExtractReportsChart } from "../../../_common/charts/config/getExtractReportsChart";
import { getFirstContentfulPaintChart } from "../../../_common/charts/config/getFirstContentfulPaintChart";
import { getGoogleSearchConsoleBreakdownChart } from "../../../_common/charts/config/getGoogleSearchConsoleBreakdownChart";
import { getHreflangBreakdownChart } from "../../../_common/charts/config/getHreflangBreakdownChart";
import { getHreflangIssuesChart } from "../../../_common/charts/config/getHreflangIssuesChart";
import { getHttpAndHttpsChart } from "../../../_common/charts/config/getHttpAndHttpsChart";
import { getHttpsIssuesChart } from "../../../_common/charts/config/getHttpsIssuesChart";
import { getHttpStatusBreakdownChart } from "../../../_common/charts/config/getHttpStatusBreakdownChart";
import { getIndexabilityChart } from "../../../_common/charts/config/getIndexabilityChart";
import { getIndexablePagesInSearchChart } from "../../../_common/charts/config/getIndexablePagesInSearchChart";
import { getIndexablePageUniquenessChart } from "../../../_common/charts/config/getIndexablePageUniquenessChart";
import { getLargestContentfulPaintChart } from "../../../_common/charts/config/getLargestContentfulPaintChart";
import { getLinksBreakdownChart } from "../../../_common/charts/config/getLinksBreakdownChart";
import { getListBreakdownChart } from "../../../_common/charts/config/getListBreakdownChart";
import { getLogSummaryIssuesChart } from "../../../_common/charts/config/getLogSummaryIssuesChart";
import { getMobileConfigurationChart } from "../../../_common/charts/config/getMobileConfigurationChart";
import { getMobileIssuesChart } from "../../../_common/charts/config/getMobileIssuesChart";
import { getNewsArticleStructuredDataValidationBreakdownChart } from "../../../_common/charts/config/getNewsArticleStructuredDataValidationBreakdownChart";
import { getNonIndexablePagesBySourceChart } from "../../../_common/charts/config/getNonIndexablePagesBySourceChart";
import { getNonIndexablePagesChartWithFixedReportOrder } from "../../../_common/charts/config/getNonIndexablePagesChart";
import { getOrphanedPagesReportChart } from "../../../_common/charts/config/getOrphanedPagesReportChart";
import { getPageIssuesChart } from "../../../_common/charts/config/getPageIssuesChart";
import { getPagesBreakdownChart } from "../../../_common/charts/config/getPagesBreakdownSingle";
import { getPagesBreakdownTrendChart } from "../../../_common/charts/config/getPagesBreakdownTrendChart";
import { getPagesWithSchemaMarkupChart } from "../../../_common/charts/config/getPagesWithSchemaMarkupChart";
import { getPaginationChart } from "../../../_common/charts/config/getPaginationChart";
import { getPerformanceBreakdownChart } from "../../../_common/charts/config/getPerformanceBreakdownChart";
import { getProductStructuredDataValidationBreakdownChart } from "../../../_common/charts/config/getProductStructuredDataValidationBreakdownChart";
import { getRedirectBreakdownChart } from "../../../_common/charts/config/getRedirectBreakdownChart";
import { getRenderingIssuesChart } from "../../../_common/charts/config/getRenderingIssuesChart";
import { getResourceIssuesChart } from "../../../_common/charts/config/getResourceIssuesChart";
import { getSearchImpressionDistributionChart } from "../../../_common/charts/config/getSearchImpressionDistributionChart";
import { getServerResponseTimeChart } from "../../../_common/charts/config/getServerResponseTimeChart";
import { getSitemapsChart } from "../../../_common/charts/config/getSitemapsChart";
import { getSitemapLinksIssuesChart } from "../../../_common/charts/config/getSitemapLinksIssuesChart";
import { getSitemapsBreakdownChart } from "../../../_common/charts/config/getSitemapsBreakdownChart";
import { getStructuredDataTypesChart } from "../../../_common/charts/config/getStructuredDataTypesChart";
import { getTimeToInteractiveChart } from "../../../_common/charts/config/getTimeToInteractiveChart";
import { getTitleAndDescriptionIssuesChart } from "../../../_common/charts/config/getTitleAndDescriptionIssuesChart";
import { getUrlFetchTimeChart } from "../../../_common/charts/config/getUrlFetchTimeChart";
import { getWebCrawlBreakdownChart } from "../../../_common/charts/config/getWebCrawlBreakdownChart";
import { getSocialTagsBreakdownChart } from "../../../_common/charts/config/getSocialTagsBreakdownChart";
import { ChartConfigs, ReportMap } from "./types";
import { getUncrawledUrlsBreakdownChart } from "../../../_common/charts/config/getUncrawledUrlsBreakdownChart";
import { getLogSummaryBreakdownChart } from "../../../_common/charts/config/getLogSummaryBreakdownChart";
import { getGscTrafficFunnel } from "../../../_common/charts/config/getGscTrafficFunnel";
import { getGscTrafficLevel } from "../../../_common/charts/config/getGscTrafficLevel";
import { getBotHitsCrawlBudget } from "../../../_common/charts/config/getBotHitsCrawlBudget";
import { getAnalyticsTrafficFunnel } from "../../../_common/charts/config/getAnalyticsTrafficFunnel";
import { getEngagementBreakdownChart } from "../../../_common/charts/config/getEngagementBreakdownChart";
import { getPageViewsPerSessionChart } from "../../../_common/charts/config/getPageViewsPerSessionChart";
import { getBounceRateBreakdownChart } from "../../../_common/charts/config/getBounceRateBreakdownChart";
import { getAvgTimeOnPageChart } from "../../../_common/charts/config/getAvgTimeOnPageChart";
import { getServerResponseTimeTrend } from "../../../_common/charts/config/getServerResponseTimeTrend";
import { getPagesinSitemapsBreakdownChart } from "../../../_common/charts/config/getPagesinSitemapsBreakdownChart";
import { getPrimaryPagesNotInSerps } from "../../../_common/charts/config/getPrimaryPagesNotInSerps";
import { getExperienceBrokenChart } from "../../../_common/charts/config/getExperienceBrokenChart";
import { getAccessibilityColorChart } from "../../../_common/charts/config/getAccessibilityColorChart";
import { getAccessibilityIssuesAndInstancesTrend } from "../../../_common/charts/config/getAccessibilityIssuesandInstancesTrend";
import { getAccessibilitySemanticsChart } from "../../../_common/charts/config/getAccessibilitySemanticsChart";
import { getAccessibilityFormsChart } from "../../../_common/charts/config/getAccessibilityFormsChart";
import { getAccessibilityLangugeChart } from "../../../_common/charts/config/getAccessibilityLanguageChart";
import { getAccessibilityStructureChart } from "../../../_common/charts/config/getAccessibilityStructureChart";
import { getAccessibilityLevelsChart } from "../../../_common/charts/config/getAccessibilityLevelsChart";
import { getAccessibilityIssuesandInstancesAAATrend } from "../../../_common/charts/config/getAccessibilityIssuesandInstancesAAATrend";
import { getAccessibilityIssuesandInstancesAATrend } from "../../../_common/charts/config/getAccessibilityIssuesandInstancesAATrend";
import { getAccessibilityIssuesandInstancesATrend } from "../../../_common/charts/config/getAccessibilityIssuesandInstancesATrend";
import { getAccessibilityIssuesandInstancesBPTrend } from "../../../_common/charts/config/getAccessibilityIssuesandInstancesBPTrend";
import { getAccessibilitySeverityChart } from "../../../_common/charts/config/getAccessibilitySeverityChart";
import { getAccessibilityAriaAChart } from "../../../_common/charts/config/getAccessibilityAriaAChart";
import { getAccessibilityAriaBPChart } from "../../../_common/charts/config/getAccessibilityAriaBPChart";
import { getAccessibilityColorAChart } from "../../../_common/charts/config/getAccessibilityColorAChart";
import { getAccessibilityFormsAChart } from "../../../_common/charts/config/getAccessibilityFormsAChart";
import { getAccessibilityFormsBPChart } from "../../../_common/charts/config/getAccessibilityFormsBPChart";
import { getAccessibilityKeyboardAChart } from "../../../_common/charts/config/getAccessibilityKeyboardAChart";
import { getAccessibilityKeyboardBPChart } from "../../../_common/charts/config/getAccessibilityKeyboardBPChart";
import { getAccessibilityLanguageAChart } from "../../../_common/charts/config/getAccessibilityLanguageAChart";
import { getAccessibilityNameRoleValueAChart } from "../../../_common/charts/config/getAccessibilityNameRoleValueAChart";
import { getAccessibilityNameRoleValueBPChart } from "../../../_common/charts/config/getAccessibilityNameRoleValueBPChart";
import { getAccessibilityParsingAChart } from "../../../_common/charts/config/getAccessibilityParsingAChart";
import { getAccessibilitySemanticsAChart } from "../../../_common/charts/config/getAccessibilitySemanticsAChart";
import { getAccessibilitySemanticsBPChart } from "../../../_common/charts/config/getAccessibilitySemanticsBPChart";
import { getAccessibilitySensoryAndVisualBPChart } from "../../../_common/charts/config/getAccessibilitySensoryAndVisualBPChart";
import { getAccessibilityStructureAChart } from "../../../_common/charts/config/getAccessibilityStructureAChart";
import { getAccessibilityStructureBPChart } from "../../../_common/charts/config/getAccessibilityStructureBPChart";
import { getAccessibilityTablesAChart } from "../../../_common/charts/config/getAccessibilityTablesAChart";
import { getAccessibilityTablesBPChart } from "../../../_common/charts/config/getAccessibilityTablesBPChart";
import { getAccessibilityTextAlternativesAChart } from "../../../_common/charts/config/getAccessibilityTextAlternativesAChart";
import { getAccessibilityTextAlternativesBPChart } from "../../../_common/charts/config/getAccessibilityTextAlternativesBPChart";
import { getAccessibilityTimeAndMediaAChart } from "../../../_common/charts/config/getAccessibilityTimeAndMediaAChart";
import { getAccessibilityColorAAChart } from "../../../_common/charts/config/getAccessibilityColorAAChart";
import { getAccessibilityLanguageAAChart } from "../../../_common/charts/config/getAccessibilityLanguageAAChart";
import { getAccessibilityLevelAAChart } from "../../../_common/charts/config/getAccessibilityLevelAAChart";
import { getAccessibilityLevelAAAChart } from "../../../_common/charts/config/getAccessibilityLevelAAAChart";
import { getAccessibilityLevelAChart } from "../../../_common/charts/config/getAccessibilityLevelAChart";
import { getAccessibilitySensoryAndVisualAAChart } from "../../../_common/charts/config/getAccessibilitySensoryAndVisualAAChart";
import { getSiteSpeedIssuesCLS } from "../../../_common/charts/config/getSiteSpeedIssuesCLS";
import { getSiteSpeedCLSLayoutShiftElements } from "../../../_common/charts/config/getSiteSpeedCLSLayoutShiftElements";
import { getSiteSpeedIssuesTBT } from "../../../_common/charts/config/getSiteSpeedIssuesTBT";
import { getSiteSpeedIssuesFCP } from "../../../_common/charts/config/getSiteSpeedIssuesFCP";
import { getSiteSpeedIssuesLCP } from "../../../_common/charts/config/getSiteSpeedIssuesLCP";
import { getSiteSpeedMainthreadChart } from "../../../_common/charts/config/getSiteSpeedMainthreadChart";
import { getSiteSpeedMainthreadTrend } from "../../../_common/charts/config/getSiteSpeedMainthreadTrend";

const CUSTOM_EXTRACTION_REPORT_MAPPINGS: ReportMap = Array.from(
  { length: 30 },
  (_, i) => `custom_extraction_${i + 1}`,
).map((customExtractionReportTemplateCode: string) => [
  customExtractionReportTemplateCode,
  [
    { predefinedChartType: "reportTrend" },
    getCustomExtractionChart,
    getExtractReportsChart,
  ],
]);

export const reportsChartsMap = new Map<string, ChartConfigs>([
  // INDEXATION
  [
    "all_pages",
    [
      { predefinedChartType: "reportTrend" },
      getPagesBreakdownChart,
      getHttpStatusBreakdownChart,
      getNonIndexablePagesChartWithFixedReportOrder,
    ],
  ],
  [
    "unique_pages",
    [
      { predefinedChartType: "reportTrend" },
      getIndexablePageUniquenessChart,
      getPagesBreakdownChart,
      getPagesBreakdownTrendChart,
    ],
  ],
  [
    "indexable_pages",
    [
      { predefinedChartType: "reportTrend" },
      getIndexablePageUniquenessChart,
      getIndexabilityChart,
    ],
  ],
  [
    "non_indexable_pages",
    [
      { predefinedChartType: "reportTrend" },
      getNonIndexablePagesChartWithFixedReportOrder,
      getIndexabilityChart,
      getPagesBreakdownChart,
      getPagesBreakdownTrendChart,
    ],
  ],
  [
    "mobile_alternates",
    [
      { predefinedChartType: "reportTrend" },
      getIndexabilityChart,
      getCanonicalBreakdownChart,
      getPagesBreakdownChart,
      getPagesBreakdownTrendChart,
    ],
  ],
  [
    "200_pages",
    [{ predefinedChartType: "reportTrend" }, getHttpStatusBreakdownChart],
  ],
  [
    "non-200_pages",
    [
      { predefinedChartType: "reportTrend" },
      getHttpStatusBreakdownChart,
      getPagesBreakdownChart,
      getPagesBreakdownTrendChart,
    ],
  ],
  [
    "uncrawled_urls",
    [{ predefinedChartType: "reportTrend" }, getUncrawledUrlsBreakdownChart],
  ],
  ["non_html_pages", [{ predefinedChartType: "reportTrend" }]],
  ["external_urls_crawled", [{ predefinedChartType: "reportTrend" }]],
  [
    "external_urls",
    [{ predefinedChartType: "reportTrend" }, getUncrawledUrlsBreakdownChart],
  ],
  [
    "primary_duplicates",
    [{ predefinedChartType: "reportTrend" }, getIndexablePageUniquenessChart],
  ],
  [
    "duplicate_pages_including_primary",
    [{ predefinedChartType: "reportTrend" }, getContentDuplicationChart],
  ],
  [
    "primary_pages",
    [
      { predefinedChartType: "reportTrend" },
      getIndexablePageUniquenessChart,
      getPagesBreakdownChart,
      getPagesBreakdownTrendChart,
    ],
  ],
  [
    "duplicate_pages",
    [
      { predefinedChartType: "reportTrend" },
      getContentDuplicationChart,
      getIndexablePageUniquenessChart,
      getPagesBreakdownChart,
      getPagesBreakdownTrendChart,
    ],
  ],
  [
    "true_uniques",
    [{ predefinedChartType: "reportTrend" }, getIndexablePageUniquenessChart],
  ],
  [
    "duplicate_pages_2",
    [{ predefinedChartType: "reportTrend" }, getDuplicateSetsChart],
  ],
  [
    "paginated_pages",
    [
      { predefinedChartType: "reportTrend" },
      getPaginationChart,
      getPagesBreakdownChart,
      getPagesBreakdownTrendChart,
    ],
  ],
  [
    "self_canonicalized_pages",
    [{ predefinedChartType: "reportTrend" }, getCanonicalBreakdownChart],
  ],
  [
    "duplicate_pages_inc_non_indexable",
    [{ predefinedChartType: "reportTrend" }],
  ],
  [
    "noindex_pages",
    [
      { predefinedChartType: "reportTrend" },
      getNonIndexablePagesChartWithFixedReportOrder,
    ],
  ],
  [
    "canonicalized_pages",
    [
      { predefinedChartType: "reportTrend" },
      getCanonicalBreakdownChart,
      getNonIndexablePagesChartWithFixedReportOrder,
    ],
  ],
  ["canonicalized_noindexed_pages", [{ predefinedChartType: "reportTrend" }]],
  [
    "301_redirects",
    [
      { predefinedChartType: "reportTrend" },
      getHttpStatusBreakdownChart,
      getRedirectBreakdownChart,
    ],
  ],
  [
    "non_301_redirects",
    [
      { predefinedChartType: "reportTrend" },
      getHttpStatusBreakdownChart,
      getRedirectBreakdownChart,
    ],
  ],
  [
    "5xx_errors",
    [{ predefinedChartType: "reportTrend" }, getHttpStatusBreakdownChart],
  ],
  [
    "4xx_errors",
    [{ predefinedChartType: "reportTrend" }, getHttpStatusBreakdownChart],
  ],
  [
    "unauthorised_pages",
    [{ predefinedChartType: "reportTrend" }, getHttpStatusBreakdownChart],
  ],
  [
    "uncategorised_http_response_codes",
    [{ predefinedChartType: "reportTrend" }, getHttpStatusBreakdownChart],
  ],
  [
    "failed_urls",
    [
      { predefinedChartType: "reportTrend" },
      getPagesBreakdownChart,
      getHttpStatusBreakdownChart,
      getPagesBreakdownTrendChart,
    ],
  ],
  [
    "disallowed_pages",
    [
      { predefinedChartType: "reportTrend" },
      getNonIndexablePagesChartWithFixedReportOrder,
    ],
  ],
  [
    "disallowed_urls",
    [{ predefinedChartType: "reportTrend" }, getUncrawledUrlsBreakdownChart],
  ],
  [
    "robots_noindexed_urls",
    [{ predefinedChartType: "reportTrend" }, getUncrawledUrlsBreakdownChart],
  ],
  [
    "malformed_urls",
    [{ predefinedChartType: "reportTrend" }, getUncrawledUrlsBreakdownChart],
  ],
  [
    "excluded_urls",
    [{ predefinedChartType: "reportTrend" }, getUncrawledUrlsBreakdownChart],
  ],
  [
    "not_included_urls",
    [{ predefinedChartType: "reportTrend" }, getUncrawledUrlsBreakdownChart],
  ],
  [
    "page_group_restricted_urls",
    [{ predefinedChartType: "reportTrend" }, getUncrawledUrlsBreakdownChart],
  ],
  [
    "other_uncrawled_(invalid)",
    [{ predefinedChartType: "reportTrend" }, getUncrawledUrlsBreakdownChart],
  ],
  // ADMIN
  [
    "crawl_limit_restricted_urls",
    [{ predefinedChartType: "reportTrend" }, getUncrawledUrlsBreakdownChart],
  ],
  ["aaa_crawl_hreflangs", [{ predefinedChartType: "reportTrend" }]],
  ["aaa_crawl_links", [{ predefinedChartType: "reportTrend" }]],
  ["aaa_crawl_unique_links", [{ predefinedChartType: "reportTrend" }]],
  ["aaa_crawl_linked_domains", [{ predefinedChartType: "reportTrend" }]],
  ["aaa_crawl_duplicate_urls", [{ predefinedChartType: "reportTrend" }]],
  ["aaa_crawl_sitemaps", [{ predefinedChartType: "reportTrend" }]],
  ["aaa_crawl_uncrawled_urls", [{ predefinedChartType: "reportTrend" }]],
  ["aaa_crawl_urls_ordered", [{ predefinedChartType: "reportTrend" }]],
  // CONTENT
  [
    "missing_titles",
    [{ predefinedChartType: "reportTrend" }, getTitleAndDescriptionIssuesChart],
  ],
  [
    "short_titles",
    [{ predefinedChartType: "reportTrend" }, getTitleAndDescriptionIssuesChart],
  ],
  [
    "max_title_length",
    [{ predefinedChartType: "reportTrend" }, getTitleAndDescriptionIssuesChart],
  ],
  [
    "pages_with_duplicate_titles",
    [{ predefinedChartType: "reportTrend" }, getContentDuplicationChart],
  ],
  [
    "duplicate_titles_2",
    [{ predefinedChartType: "reportTrend" }, getDuplicateSetsChart],
  ],
  [
    "missing_descriptions",
    [{ predefinedChartType: "reportTrend" }, getTitleAndDescriptionIssuesChart],
  ],
  [
    "short_descriptions",
    [{ predefinedChartType: "reportTrend" }, getTitleAndDescriptionIssuesChart],
  ],
  [
    "max_description_length",
    [{ predefinedChartType: "reportTrend" }, getTitleAndDescriptionIssuesChart],
  ],
  ["max_mobile_description_length", [{ predefinedChartType: "reportTrend" }]],
  [
    "pages_with_duplicate_descriptions",
    [{ predefinedChartType: "reportTrend" }, getContentDuplicationChart],
  ],
  [
    "duplicate_descriptions_2",
    [{ predefinedChartType: "reportTrend" }, getDuplicateSetsChart],
  ],
  [
    "empty_pages",
    [{ predefinedChartType: "reportTrend" }, getContentIssuesChart],
  ],
  [
    "thin_pages",
    [{ predefinedChartType: "reportTrend" }, getContentIssuesChart],
  ],
  [
    "max_content_size",
    [{ predefinedChartType: "reportTrend" }, getContentIssuesChart],
  ],
  [
    "duplicate_content_2",
    [{ predefinedChartType: "reportTrend" }, getDuplicateSetsChart],
  ],
  [
    "duplicate_body_content",
    [{ predefinedChartType: "reportTrend" }, getContentDuplicationChart],
  ],
  [
    "missing_h1_tags",
    [{ predefinedChartType: "reportTrend" }, getPageIssuesChart],
  ],
  [
    "multiple_h1_tag_pages",
    [{ predefinedChartType: "reportTrend" }, getPageIssuesChart],
  ],
  [
    "noarchive_pages",
    [{ predefinedChartType: "reportTrend" }, getPageIssuesChart],
  ],
  [
    "javascript_files",
    [{ predefinedChartType: "reportTrend" }, getContentTypeBreakdownChart],
  ],
  [
    "css_files",
    [{ predefinedChartType: "reportTrend" }, getContentTypeBreakdownChart],
  ],
  ["pdf_files", [{ predefinedChartType: "reportTrend" }]],
  [
    "broken_js_css",
    [{ predefinedChartType: "reportTrend" }, getResourceIssuesChart],
  ],
  [
    "disallowed_js_css",
    [{ predefinedChartType: "reportTrend" }, getResourceIssuesChart],
  ],
  [
    "disallowed_js_css_uncrawled",
    [{ predefinedChartType: "reportTrend" }, getResourceIssuesChart],
  ],
  [
    "all_images_2",
    [{ predefinedChartType: "reportTrend" }, getContentTypeBreakdownChart],
  ],
  [
    "broken_images",
    [{ predefinedChartType: "reportTrend" }, getExperienceBrokenChart],
  ],
  [
    "unique_redirecting_images",
    [{ predefinedChartType: "reportTrend" }, getResourceIssuesChart],
  ],
  [
    "linked_non_html_files",
    [{ predefinedChartType: "reportTrend" }, getContentTypeBreakdownChart],
  ],
  [
    "pages_without_social_markup",
    [{ predefinedChartType: "reportTrend" }, getSocialTagsBreakdownChart],
  ],
  [
    "pages_with_social_markup",
    [{ predefinedChartType: "reportTrend" }, getSocialTagsBreakdownChart],
  ],
  // CONFIG
  [
    "pages_without_canonical_tag",
    [
      { predefinedChartType: "reportTrend" },
      getCanonicalIssuesChart,
      getCanonicalBreakdownChart,
    ],
  ],
  [
    "all_rel_links",
    [{ predefinedChartType: "reportTrend" }, getLinksBreakdownChart],
  ],
  [
    "unlinked_canonical_pages",
    [
      { predefinedChartType: "reportTrend" },
      getCanonicalIssuesChart,
      getOrphanedPagesReportChart,
    ],
  ],
  ["canonical_pages", [{ predefinedChartType: "reportTrend" }]],
  ["high_canonicals_in", [{ predefinedChartType: "reportTrend" }]],
  [
    "conflicting_canonical_tags",
    [{ predefinedChartType: "reportTrend" }, getCanonicalIssuesChart],
  ],
  [
    "canonical_to_non_200",
    [
      { predefinedChartType: "reportTrend" },
      getBrokenLinksChart,
      getCanonicalIssuesChart,
    ],
  ],
  [
    "non_indexable_canonical_links_in",
    [{ predefinedChartType: "reportTrend" }],
  ],
  [
    "redirect_chains",
    [{ predefinedChartType: "reportTrend" }, getRedirectBreakdownChart],
  ],
  [
    "all_redirects",
    [{ predefinedChartType: "reportTrend" }, getRedirectBreakdownChart],
  ],
  ["excessive_redirects_in", [{ predefinedChartType: "reportTrend" }]],
  [
    "all_broken_redirects",
    [{ predefinedChartType: "reportTrend" }, getRedirectBreakdownChart],
  ],
  ["internal_redirects_found_in_web", [{ predefinedChartType: "reportTrend" }]],
  [
    "max_redirections",
    [{ predefinedChartType: "reportTrend" }, getRedirectBreakdownChart],
  ],
  [
    "redirection_loop",
    [{ predefinedChartType: "reportTrend" }, getRedirectBreakdownChart],
  ],
  [
    "meta_refresh_redirect",
    [{ predefinedChartType: "reportTrend" }, getRedirectBreakdownChart],
  ],
  ["javascript_redirects", [{ predefinedChartType: "reportTrend" }]],
  [
    "http_pages",
    [{ predefinedChartType: "reportTrend" }, getHttpAndHttpsChart],
  ],
  [
    "https_pages",
    [{ predefinedChartType: "reportTrend" }, getHttpAndHttpsChart],
  ],
  [
    "http_scripts_on_https_pages",
    [
      { predefinedChartType: "reportTrend" },
      getHttpsIssuesChart,
      getResourceIssuesChart,
    ],
  ],
  [
    "non_secure_form_fields",
    [{ predefinedChartType: "reportTrend" }, getHttpsIssuesChart],
  ],
  ["pages_with_hsts", [{ predefinedChartType: "reportTrend" }]],
  [
    "links_between_protocols",
    [{ predefinedChartType: "reportTrend" }, getHttpsIssuesChart],
  ],
  [
    "http_pages_with_hsts",
    [{ predefinedChartType: "reportTrend" }, getCanonicalBreakdownChart],
  ],
  [
    "pages_without_hreflang_tags",
    [{ predefinedChartType: "reportTrend" }, getHreflangIssuesChart],
  ],
  [
    "pages_with_hreflang_tags",
    [{ predefinedChartType: "reportTrend" }, getHreflangIssuesChart],
  ],
  ["not_supported_hreflang_links", [{ predefinedChartType: "reportTrend" }]],
  [
    "all_hreflang_links",
    [
      { predefinedChartType: "reportTrend" },
      getHreflangBreakdownChart,
      getLinksBreakdownChart,
    ],
  ],
  [
    "unsupported_hreflang_links",
    [{ predefinedChartType: "reportTrend" }, getHreflangBreakdownChart],
  ],
  [
    "broken_hreflang_links",
    [{ predefinedChartType: "reportTrend" }, getBrokenLinksChart],
  ],
  [
    "all_hreflang_combinations",
    [{ predefinedChartType: "reportTrend" }, getHreflangBreakdownChart],
  ],
  [
    "hreflang_link_non_200",
    [{ predefinedChartType: "reportTrend" }, getHreflangBreakdownChart],
  ],
  ["non_indexable_hreflang_links_in", [{ predefinedChartType: "reportTrend" }]],
  ["first_pages", [{ predefinedChartType: "reportTrend" }, getPaginationChart]],
  [
    "unlinked_paginated_pages",
    [{ predefinedChartType: "reportTrend" }, getOrphanedPagesReportChart],
  ],
  ["double_slash_urls", [{ predefinedChartType: "reportTrend" }]],
  [
    "max_url_length",
    [{ predefinedChartType: "reportTrend" }, getPageIssuesChart],
  ],
  [
    "broken_head_warning",
    [{ predefinedChartType: "reportTrend" }, getRenderingIssuesChart],
  ],
  [
    "canonical_link_mismatch",
    [{ predefinedChartType: "reportTrend" }, getRenderingIssuesChart],
  ],
  [
    "rendered_link_count_mismatch",
    [{ predefinedChartType: "reportTrend" }, getRenderingIssuesChart],
  ],
  [
    "rendered_word_count_mismatch",
    [{ predefinedChartType: "reportTrend" }, getRenderingIssuesChart],
  ],
  // LINKS
  [
    "unique_internal_links",
    [{ predefinedChartType: "reportTrend" }, getLinksBreakdownChart],
  ],
  [
    "all_broken_links",
    [{ predefinedChartType: "reportTrend" }, getBrokenLinksChart],
  ],
  [
    "unique_broken_links",
    [{ predefinedChartType: "reportTrend" }, getBrokenLinksChart],
  ],
  [
    "missing_image_link_alt_tags",
    [{ predefinedChartType: "reportTrend" }, getResourceIssuesChart],
  ],
  ["max_links", [{ predefinedChartType: "reportTrend" }, getPageIssuesChart]],
  [
    "meta_nofollow_pages",
    [{ predefinedChartType: "reportTrend" }, getPageIssuesChart],
  ],
  [
    "nofollowed_pages",
    [
      { predefinedChartType: "reportTrend" },
      getNonIndexablePagesChartWithFixedReportOrder,
    ],
  ],
  [
    "max_external_links",
    [{ predefinedChartType: "reportTrend" }, getExternalLinkBreakdownChart],
  ],
  [
    "linked_domains",
    [{ predefinedChartType: "reportTrend" }, getExternalLinkBreakdownChart],
  ],
  [
    "unique_external_links",
    [{ predefinedChartType: "reportTrend" }, getExternalLinkBreakdownChart],
  ],
  ["broken_links_external", [{ predefinedChartType: "reportTrend" }]],
  [
    "external_redirects",
    [{ predefinedChartType: "reportTrend" }, getExternalLinkBreakdownChart],
  ],
  ["unique_external_redirects", [{ predefinedChartType: "reportTrend" }]],
  [
    "all_sitemaps_links",
    [{ predefinedChartType: "reportTrend" }, getLinksBreakdownChart],
  ],
  ["xml_sitemaps", [{ predefinedChartType: "reportTrend" }, getSitemapsChart]],
  [
    "empty_sitemaps",
    [{ predefinedChartType: "reportTrend" }, getSitemapsChart],
  ],
  [
    "not_in_sitemaps_primary_indexable",
    [{ predefinedChartType: "reportTrend" }],
  ],
  [
    "broken_sitemap_links",
    [
      { predefinedChartType: "reportTrend" },
      getBrokenLinksChart,
      getSitemapLinksIssuesChart,
    ],
  ],
  [
    "sitemaps_non_indexable_links",
    [
      { predefinedChartType: "reportTrend" },
      getNonIndexablePagesBySourceChart,
      getSitemapLinksIssuesChart,
    ],
  ],
  [
    "mobile_alternates_in_sitemaps",
    [{ predefinedChartType: "reportTrend" }, getSitemapLinksIssuesChart],
  ],
  [
    "sitemaps_disallowed_malformed_links",
    [{ predefinedChartType: "reportTrend" }, getSitemapLinksIssuesChart],
  ],
  [
    "sitemaps_too_many_urls",
    [{ predefinedChartType: "reportTrend" }, getSitemapsBreakdownChart],
  ],
  [
    "sitemaps_broken_disallowed",
    [{ predefinedChartType: "reportTrend" }, getSitemapsBreakdownChart],
  ],
  [
    "backlink_pages_redirects",
    [{ predefinedChartType: "reportTrend" }, getBacklinkIssuesChart],
  ],
  [
    "backlink_pages_with_errors",
    [
      { predefinedChartType: "reportTrend" },
      getBrokenLinksChart,
      getBacklinkIssuesChart,
    ],
  ],
  [
    "backlink_pages_disallowed",
    [{ predefinedChartType: "reportTrend" }, getBacklinkIssuesChart],
  ],
  [
    "backlink_pages_disallowed_uncrawled",
    [{ predefinedChartType: "reportTrend" }, getBacklinkIssuesChart],
  ],
  [
    "backlink_pages_with_nofollow",
    [{ predefinedChartType: "reportTrend" }, getBacklinkIssuesChart],
  ],
  ["backlink_pages_indexable", [{ predefinedChartType: "reportTrend" }]],
  [
    "backlink_pages_no_links_out",
    [{ predefinedChartType: "reportTrend" }, getBacklinkIssuesChart],
  ],
  [
    "backlink_pages_non_indexable",
    [
      { predefinedChartType: "reportTrend" },
      getNonIndexablePagesBySourceChart,
      getBacklinkIssuesChart,
    ],
  ],
  [
    "mobile_alternates_with_backlinks",
    [{ predefinedChartType: "reportTrend" }],
  ],
  // STRUCTURED DATA
  [
    "pages_with_breadcrumb_schema",
    [{ predefinedChartType: "reportTrend" }, getStructuredDataTypesChart],
  ],
  [
    "pages_with_event_schema",
    [{ predefinedChartType: "reportTrend" }, getStructuredDataTypesChart],
  ],
  [
    "pages_with_faqpage_schema",
    [{ predefinedChartType: "reportTrend" }, getStructuredDataTypesChart],
  ],
  [
    "pages_with_howto_schema",
    [{ predefinedChartType: "reportTrend" }, getStructuredDataTypesChart],
  ],
  [
    "pages_with_news_article_schema",
    [{ predefinedChartType: "reportTrend" }, getStructuredDataTypesChart],
  ],
  [
    "pages_with_product_schema",
    [{ predefinedChartType: "reportTrend" }, getStructuredDataTypesChart],
  ],
  [
    "pages_with_qapage_schema",
    [{ predefinedChartType: "reportTrend" }, getStructuredDataTypesChart],
  ],
  [
    "pages_with_recipe_schema",
    [{ predefinedChartType: "reportTrend" }, getStructuredDataTypesChart],
  ],
  [
    "pages_with_review_schema",
    [{ predefinedChartType: "reportTrend" }, getStructuredDataTypesChart],
  ],
  [
    "pages_with_videoobject_schema",
    [{ predefinedChartType: "reportTrend" }, getStructuredDataTypesChart],
  ],
  [
    "pages_with_invalid_news_article_schema",
    [
      { predefinedChartType: "reportTrend" },
      getNewsArticleStructuredDataValidationBreakdownChart,
    ],
  ],
  [
    "pages_with_invalid_product_schema",
    [
      { predefinedChartType: "reportTrend" },
      getProductStructuredDataValidationBreakdownChart,
    ],
  ],
  [
    "pages_with_valid_news_article_schema",
    [
      { predefinedChartType: "reportTrend" },
      getNewsArticleStructuredDataValidationBreakdownChart,
    ],
  ],
  [
    "pages_with_valid_product_schema",
    [
      { predefinedChartType: "reportTrend" },
      getProductStructuredDataValidationBreakdownChart,
    ],
  ],
  [
    "pages_with_structured_data",
    [{ predefinedChartType: "reportTrend" }, getPagesWithSchemaMarkupChart],
  ],
  [
    "pages_without_structured_data",
    [{ predefinedChartType: "reportTrend" }, getPagesWithSchemaMarkupChart],
  ],
  // TRAFFIC
  [
    "indexable_pages_in_search",
    [
      { predefinedChartType: "reportTrend" },
      getSearchImpressionDistributionChart,
      getIndexablePagesInSearchChart,
    ],
  ],
  [
    "non_indexable_pages_in_search",
    [
      { predefinedChartType: "reportTrend" },
      getSearchImpressionDistributionChart,
    ],
  ],
  [
    "mobile_amphtml_pages_in_search",
    [
      { predefinedChartType: "reportTrend" },
      getSearchImpressionDistributionChart,
    ],
  ],
  [
    "primary_pages_with_serp_clicks",
    [{ predefinedChartType: "reportTrend" }, getGscTrafficFunnel],
  ],
  [
    "primary_pages_in_serp_without_clicks",
    [{ predefinedChartType: "reportTrend" }, getGscTrafficFunnel],
  ],
  [
    "primary_pages_not_in_serp",
    [
      { predefinedChartType: "reportTrend" },
      getPrimaryPagesNotInSerps,
      getGscTrafficFunnel,
    ],
  ],
  [
    "primary_pages_not_in_serp_not_in_sitemaps",
    [{ predefinedChartType: "reportTrend" }, getPagesinSitemapsBreakdownChart],
  ],
  [
    "primary_pages_not_in_serp_without_followed_links",
    [{ predefinedChartType: "reportTrend" }, getPagesinSitemapsBreakdownChart],
  ],
  [
    "primary_pages_not_in_serp_with_low_deeprank",
    [{ predefinedChartType: "reportTrend" }, getPagesinSitemapsBreakdownChart],
  ],
  [
    "primary_pages_not_in_serp_with_no_backlinks",
    [{ predefinedChartType: "reportTrend" }, getPagesinSitemapsBreakdownChart],
  ],
  [
    "duplicate_pages_in_serp",
    [{ predefinedChartType: "reportTrend" }, getGscTrafficFunnel],
  ],
  [
    "non_indexable_pages_in_serp",
    [{ predefinedChartType: "reportTrend" }, getGscTrafficFunnel],
  ],
  [
    "redirecting_pages_in_serp",
    [{ predefinedChartType: "reportTrend" }, getGscTrafficFunnel],
  ],
  [
    "error_pages_in_serp",
    [{ predefinedChartType: "reportTrend" }, getGscTrafficFunnel],
  ],
  [
    "primary_pages_with_1_to_10_clicks",
    [{ predefinedChartType: "reportTrend" }, getGscTrafficLevel],
  ],
  [
    "primary_pages_with_10_to_100_clicks",
    [{ predefinedChartType: "reportTrend" }, getGscTrafficLevel],
  ],
  [
    "primary_pages_with_100_to_1000_clicks",
    [{ predefinedChartType: "reportTrend" }, getGscTrafficLevel],
  ],
  [
    "primary_pages_with_1000_to_10000_clicks",
    [{ predefinedChartType: "reportTrend" }, getGscTrafficLevel],
  ],
  [
    "primary_pages_with_10000_plus_clicks",
    [{ predefinedChartType: "reportTrend" }, getGscTrafficLevel],
  ],
  [
    "primary_pages_with_visits",
    [{ predefinedChartType: "reportTrend" }, getAnalyticsTrafficFunnel],
  ],
  [
    "primary_pages_with_no_visits",
    [{ predefinedChartType: "reportTrend" }, getAnalyticsTrafficFunnel],
  ],
  [
    "duplicate_pages_with_visits",
    [{ predefinedChartType: "reportTrend" }, getAnalyticsTrafficFunnel],
  ],
  [
    "non_indexable_pages_with_visits",
    [{ predefinedChartType: "reportTrend" }, getAnalyticsTrafficFunnel],
  ],
  [
    "redirecting_pages_with_visits",
    [{ predefinedChartType: "reportTrend" }, getAnalyticsTrafficFunnel],
  ],
  [
    "error_pages_with_visits",
    [{ predefinedChartType: "reportTrend" }, getAnalyticsTrafficFunnel],
  ],

  // HS EXPERIENCE
  [
    "good_engagement",
    [{ predefinedChartType: "reportTrend" }, getEngagementBreakdownChart],
  ],
  [
    "moderate_engagement",
    [{ predefinedChartType: "reportTrend" }, getEngagementBreakdownChart],
  ],
  [
    "poor_engagement",
    [{ predefinedChartType: "reportTrend" }, getEngagementBreakdownChart],
  ],
  [
    "bad_engagement",
    [{ predefinedChartType: "reportTrend" }, getEngagementBreakdownChart],
  ],

  [
    "low_pageviews_per_session",
    [{ predefinedChartType: "reportTrend" }, getPageViewsPerSessionChart],
  ],
  [
    "moderate_pageviews_per_session",
    [{ predefinedChartType: "reportTrend" }, getPageViewsPerSessionChart],
  ],
  [
    "high_pageviews_per_session",
    [{ predefinedChartType: "reportTrend" }, getPageViewsPerSessionChart],
  ],

  [
    "high_bounce_rate",
    [{ predefinedChartType: "reportTrend" }, getBounceRateBreakdownChart],
  ],
  [
    "moderate_bounce_rate",
    [{ predefinedChartType: "reportTrend" }, getBounceRateBreakdownChart],
  ],
  [
    "low_bounce_rate",
    [{ predefinedChartType: "reportTrend" }, getBounceRateBreakdownChart],
  ],

  [
    "low_avg_time_on_page",
    [{ predefinedChartType: "reportTrend" }, getAvgTimeOnPageChart],
  ],
  [
    "moderate_avg_time_on_page",
    [{ predefinedChartType: "reportTrend" }, getAvgTimeOnPageChart],
  ],
  [
    "high_avg_time_on_page",
    [{ predefinedChartType: "reportTrend" }, getAvgTimeOnPageChart],
  ],

  [
    "broken_image_urls",
    [{ predefinedChartType: "reportTrend" }, getResourceIssuesChart],
  ],
  [
    "redirecting_image_urls",
    [{ predefinedChartType: "reportTrend" }, getResourceIssuesChart],
  ],
  [
    "broken_js",
    [{ predefinedChartType: "reportTrend" }, getResourceIssuesChart],
  ],
  [
    "broken_css",
    [{ predefinedChartType: "reportTrend" }, getResourceIssuesChart],
  ],
  [
    "http_scripts_on_https_pages",
    [{ predefinedChartType: "reportTrend" }, getResourceIssuesChart],
  ],
  [
    "disallowed_js",
    [{ predefinedChartType: "reportTrend" }, getResourceIssuesChart],
  ],
  [
    "disallowed_css",
    [{ predefinedChartType: "reportTrend" }, getResourceIssuesChart],
  ],
  [
    "disallowed_js_uncrawled",
    [{ predefinedChartType: "reportTrend" }, getResourceIssuesChart],
  ],
  [
    "disallowed_css_uncrawled",
    [{ predefinedChartType: "reportTrend" }, getResourceIssuesChart],
  ],

  // MOBILE
  ["discouraged_viewport_types", [{ predefinedChartType: "reportTrend" }]],
  [
    "non_200_separate_mobile_amp",
    [
      { predefinedChartType: "reportTrend" },
      getBrokenLinksChart,
      getMobileIssuesChart,
    ],
  ],
  [
    "non_reciprocal_mobile_amp_desktop",
    [{ predefinedChartType: "reportTrend" }, getMobileIssuesChart],
  ],
  [
    "mobile_alternates_noindex",
    [{ predefinedChartType: "reportTrend" }, getMobileIssuesChart],
  ],
  [
    "separate_mobile_content_mismatch",
    [{ predefinedChartType: "reportTrend" }, getMobileIssuesChart],
  ],
  [
    "separate_mobile_wordcount_mismatch",
    [{ predefinedChartType: "reportTrend" }, getMobileIssuesChart],
  ],
  [
    "separate_mobile_links_in_mismatch",
    [{ predefinedChartType: "reportTrend" }, getMobileIssuesChart],
  ],
  [
    "separate_mobile_links_out_mismatch",
    [{ predefinedChartType: "reportTrend" }, getMobileIssuesChart],
  ],
  [
    "amphtml_non_rel_alted",
    [{ predefinedChartType: "reportTrend" }, getMobileIssuesChart],
  ],
  [
    "mobile_desktop_responsive",
    [{ predefinedChartType: "reportTrend" }, getMobileConfigurationChart],
  ],
  [
    "mobile_desktop_dynamic",
    [{ predefinedChartType: "reportTrend" }, getMobileConfigurationChart],
  ],
  [
    "mobile_desktop_separate_desktop",
    [{ predefinedChartType: "reportTrend" }, getMobileConfigurationChart],
  ],
  [
    "mobile_desktop_separate_mobile",
    [{ predefinedChartType: "reportTrend" }, getMobileConfigurationChart],
  ],
  [
    "mobile_desktop_amphtml",
    [{ predefinedChartType: "reportTrend" }, getMobileConfigurationChart],
  ],
  ["mobile_app_links", [{ predefinedChartType: "reportTrend" }]],
  [
    "no_mobile_configuration",
    [{ predefinedChartType: "reportTrend" }, getMobileConfigurationChart],
  ],
  [
    "valid_amp_pages",
    [{ predefinedChartType: "reportTrend" }, getAmpValidationChart],
  ],
  [
    "invalid_amp_pages",
    [{ predefinedChartType: "reportTrend" }, getAmpValidationChart],
  ],
  // LOG FILES
  [
    "pages_with_no_bot_hits",
    [{ predefinedChartType: "reportTrend" }, getBotHitsBreakdownChart],
  ],
  [
    "pages_with_low_bot_hits",
    [{ predefinedChartType: "reportTrend" }, getBotHitsBreakdownChart],
  ],
  [
    "pages_with_medium_bot_hits",
    [{ predefinedChartType: "reportTrend" }, getBotHitsBreakdownChart],
  ],
  [
    "pages_with_high_bot_hits",
    [{ predefinedChartType: "reportTrend" }, getBotHitsBreakdownChart],
  ],
  [
    "pages_with_desktop_bot_hits",
    [{ predefinedChartType: "reportTrend" }, getBotHitsByDeviceChart],
  ],
  [
    "pages_with_mobile_bot_hits",
    [{ predefinedChartType: "reportTrend" }, getBotHitsByDeviceChart],
  ],
  [
    "desktop_pages_with_low_desktop_bot_hits",
    [{ predefinedChartType: "reportTrend" }, getLogSummaryIssuesChart],
  ],
  [
    "mobile_alternates_with_low_mobile_bot_hits",
    [{ predefinedChartType: "reportTrend" }, getLogSummaryIssuesChart],
  ],
  [
    "disallowed_pages_with_bot_hits",
    [{ predefinedChartType: "reportTrend" }, getLogSummaryIssuesChart],
  ],
  [
    "disallowed_pages_with_bot_hits_uncrawled",
    [{ predefinedChartType: "reportTrend" }, getLogSummaryIssuesChart],
  ],
  [
    "pages_in_sitemap_with_no_bot_hits",
    [{ predefinedChartType: "reportTrend" }, getLogSummaryIssuesChart],
  ],
  [
    "indexable_pages_with_no_bot_hits",
    [{ predefinedChartType: "reportTrend" }, getLogSummaryIssuesChart],
  ],
  [
    "primary_pages_with_bot_hits",
    [{ predefinedChartType: "reportTrend" }, getBotHitsCrawlBudget],
  ],
  [
    "primary_pages_with_no_bot_hits",
    [{ predefinedChartType: "reportTrend" }, getBotHitsCrawlBudget],
  ],
  [
    "duplicate_pages_with_bot_hits",
    [{ predefinedChartType: "reportTrend" }, getBotHitsCrawlBudget],
  ],
  [
    "non_indexable_pages_with_bot_hits",
    [
      { predefinedChartType: "reportTrend" },
      getBotHitsCrawlBudget,
      getLogSummaryIssuesChart,
    ],
  ],
  [
    "redirects_with_bot_hits",
    [{ predefinedChartType: "reportTrend" }, getBotHitsCrawlBudget],
  ],
  [
    "error_pages_with_bot_hits",
    [
      { predefinedChartType: "reportTrend" },
      getBotHitsCrawlBudget,
      getLogSummaryIssuesChart,
    ],
  ],

  // PERFORMANCE
  [
    "fast_load_time",
    [{ predefinedChartType: "reportTrend" }, getUrlFetchTimeChart],
  ],
  [
    "medium_load_time",
    [{ predefinedChartType: "reportTrend" }, getUrlFetchTimeChart],
  ],
  [
    "max_load_time",
    [
      { predefinedChartType: "reportTrend" },
      getUrlFetchTimeChart,
      getPerformanceBreakdownChart,
    ],
  ],
  [
    "fast_server_response",
    [{ predefinedChartType: "reportTrend" }, getServerResponseTimeChart],
  ],
  [
    "medium_server_response",
    [{ predefinedChartType: "reportTrend" }, getServerResponseTimeChart],
  ],
  [
    "slow_server_response",
    [
      { predefinedChartType: "reportTrend" },
      getServerResponseTimeChart,
      getServerResponseTimeTrend,
    ],
  ],
  [
    "fast_fcp",
    [{ predefinedChartType: "reportTrend" }, getFirstContentfulPaintChart],
  ],
  [
    "average_fcp",
    [{ predefinedChartType: "reportTrend" }, getFirstContentfulPaintChart],
  ],
  [
    "slow_fcp",
    [{ predefinedChartType: "reportTrend" }, getFirstContentfulPaintChart],
  ],
  [
    "fast_lcp",
    [{ predefinedChartType: "reportTrend" }, getLargestContentfulPaintChart],
  ],
  [
    "average_lcp",
    [{ predefinedChartType: "reportTrend" }, getLargestContentfulPaintChart],
  ],
  [
    "slow_lcp",
    [{ predefinedChartType: "reportTrend" }, getLargestContentfulPaintChart],
  ],
  [
    "good_cls",
    [{ predefinedChartType: "reportTrend" }, getCumulativeLayoutShiftChart],
  ],
  [
    "average_cls",
    [{ predefinedChartType: "reportTrend" }, getCumulativeLayoutShiftChart],
  ],
  [
    "poor_cls",
    [{ predefinedChartType: "reportTrend" }, getCumulativeLayoutShiftChart],
  ],
  [
    "fast_tti",
    [{ predefinedChartType: "reportTrend" }, getTimeToInteractiveChart],
  ],
  [
    "average_tti",
    [{ predefinedChartType: "reportTrend" }, getTimeToInteractiveChart],
  ],
  [
    "slow_tti",
    [{ predefinedChartType: "reportTrend" }, getTimeToInteractiveChart],
  ],
  [
    "fast_dcl",
    [{ predefinedChartType: "reportTrend" }, getDomContentLoadChart],
  ],
  [
    "average_dcl",
    [{ predefinedChartType: "reportTrend" }, getDomContentLoadChart],
  ],
  [
    "slow_dcl",
    [{ predefinedChartType: "reportTrend" }, getDomContentLoadChart],
  ],
  [
    "max_html_size",
    [{ predefinedChartType: "reportTrend" }, getPerformanceBreakdownChart],
  ],
  // SOURCE GAP
  [
    "pages_not_in_web_crawl",
    [
      { predefinedChartType: "reportTrend" },
      getWebCrawlBreakdownChart,
      getCrawlSourceOverlapChart,
    ],
  ],
  [
    "all_web_crawl_urls",
    [
      { predefinedChartType: "reportTrend" },
      getWebCrawlBreakdownChart,
      getCrawlSourceOverlapChart,
    ],
  ],
  [
    "pages_not_in_list",
    [
      { predefinedChartType: "reportTrend" },
      getListBreakdownChart,
      getCrawlSourceOverlapChart,
    ],
  ],
  [
    "all_crawled_urls_in_list",
    [
      { predefinedChartType: "reportTrend" },
      getListBreakdownChart,
      getCrawlSourceOverlapChart,
    ],
  ],
  [
    "orphaned_list_pages",
    [{ predefinedChartType: "reportTrend" }, getOrphanedPagesReportChart],
  ],
  [
    "all_sitemaps_links",
    [{ predefinedChartType: "reportTrend" }, getLinksBreakdownChart],
  ],
  ["xml_sitemaps", [{ predefinedChartType: "reportTrend" }, getSitemapsChart]],
  [
    "all_crawled_urls_in_xml_sitemaps",
    [
      { predefinedChartType: "reportTrend" },
      getSitemapsBreakdownChart,
      getCrawlSourceOverlapChart,
    ],
  ],
  [
    "not_in_sitemaps_primary_indexable",
    [{ predefinedChartType: "reportTrend" }],
  ],
  [
    "broken_sitemap_links",
    [{ predefinedChartType: "reportTrend" }, getSitemapLinksIssuesChart],
  ],
  [
    "sitemaps_non_indexable_links",
    [
      { predefinedChartType: "reportTrend" },
      getNonIndexablePagesBySourceChart,
      getSitemapLinksIssuesChart,
    ],
  ],
  [
    "mobile_alternates_in_sitemaps",
    [{ predefinedChartType: "reportTrend" }, getSitemapLinksIssuesChart],
  ],
  [
    "sitemaps_disallowed_malformed_links",
    [{ predefinedChartType: "reportTrend" }, getSitemapLinksIssuesChart],
  ],
  [
    "sitemaps_too_many_urls",
    [{ predefinedChartType: "reportTrend" }, getSitemapsChart],
  ],
  [
    "sitemaps_broken_disallowed",
    [{ predefinedChartType: "reportTrend" }, getSitemapsChart],
  ],
  [
    "orphaned_sitemaps_pages",
    [{ predefinedChartType: "reportTrend" }, getOrphanedPagesReportChart],
  ],
  [
    "missing_in_sitemaps",
    [
      { predefinedChartType: "reportTrend" },
      getSitemapsBreakdownChart,
      getCrawlSourceOverlapChart,
    ],
  ],
  [
    "backlink_pages_redirects",
    [{ predefinedChartType: "reportTrend" }, getBacklinkIssuesChart],
  ],
  [
    "backlink_pages_with_errors",
    [
      { predefinedChartType: "reportTrend" },
      getBrokenLinksChart,
      getBacklinkIssuesChart,
    ],
  ],
  [
    "backlink_pages_disallowed",
    [{ predefinedChartType: "reportTrend" }, getBacklinkIssuesChart],
  ],
  [
    "backlink_pages_disallowed_uncrawled",
    [{ predefinedChartType: "reportTrend" }, getBacklinkIssuesChart],
  ],
  [
    "backlink_pages_with_nofollow",
    [{ predefinedChartType: "reportTrend" }, getBacklinkIssuesChart],
  ],
  ["backlink_pages_indexable", [{ predefinedChartType: "reportTrend" }]],
  [
    "backlink_pages_no_links_out",
    [{ predefinedChartType: "reportTrend" }, getBacklinkIssuesChart],
  ],
  [
    "backlink_pages_non_indexable",
    [
      { predefinedChartType: "reportTrend" },
      getNonIndexablePagesBySourceChart,
      getBacklinkIssuesChart,
    ],
  ],
  [
    "mobile_alternates_with_backlinks",
    [{ predefinedChartType: "reportTrend" }],
  ],
  [
    "all_cralwed_urls_with_backlinks",
    [
      { predefinedChartType: "reportTrend" },
      getBacklinksBreakdownChart,
      getCrawlSourceOverlapChart,
    ],
  ],
  [
    "orphaned_pages_with_backlinks",
    [{ predefinedChartType: "reportTrend" }, getOrphanedPagesReportChart],
  ],
  [
    "missing_in_backlinks_crawl",
    [
      { predefinedChartType: "reportTrend" },
      getBacklinksBreakdownChart,
      getCrawlSourceOverlapChart,
    ],
  ],
  [
    "all_crawled_organic_landing_pages",
    [
      { predefinedChartType: "reportTrend" },
      getAnalyticsBreakdownChart,
      getCrawlSourceOverlapChart,
    ],
  ],
  [
    "orphaned_organic_landing_pages",
    [{ predefinedChartType: "reportTrend" }, getOrphanedPagesReportChart],
  ],
  [
    "missing_in_organic_landing_pages",
    [
      { predefinedChartType: "reportTrend" },
      getAnalyticsBreakdownChart,
      getCrawlSourceOverlapChart,
    ],
  ],
  [
    "pages_in_google_search_console",
    [
      { predefinedChartType: "reportTrend" },
      getGoogleSearchConsoleBreakdownChart,
      getCrawlSourceOverlapChart,
    ],
  ],
  [
    "pages_not_in_google_search_console",
    [
      { predefinedChartType: "reportTrend" },
      getGoogleSearchConsoleBreakdownChart,
      getCrawlSourceOverlapChart,
    ],
  ],
  [
    "orphaned_google_search_console_pages",
    [{ predefinedChartType: "reportTrend" }, getOrphanedPagesReportChart],
  ],
  [
    "pages_in_log_summary",
    [
      { predefinedChartType: "reportTrend" },
      getLogSummaryBreakdownChart,
      getCrawlSourceOverlapChart,
    ],
  ],
  [
    "pages_not_in_log_summary",
    [
      { predefinedChartType: "reportTrend" },
      getLogSummaryBreakdownChart,
      getCrawlSourceOverlapChart,
    ],
  ],
  [
    "orphaned_log_summary_pages",
    [{ predefinedChartType: "reportTrend" }, getOrphanedPagesReportChart],
  ],
  // EXTRACTION
  [
    "extract_report",
    [
      { predefinedChartType: "reportTrend" },
      getCustomExtractionChart,
      getExtractReportsChart,
    ],
  ],
  [
    "extract_complement_report",
    [
      { predefinedChartType: "reportTrend" },
      getCustomExtractionChart,
      getExtractReportsChart,
    ],
  ],
  ...CUSTOM_EXTRACTION_REPORT_MAPPINGS,
  // NO CATEGORY
  [
    "duplicate_body_content_excl_duplicate_pages",
    [{ predefinedChartType: "reportTrend" }],
  ],
  [
    "pages_with_duplicate_descriptions_excl_duplicate_pages",
    [{ predefinedChartType: "reportTrend" }],
  ],
  [
    "pages_with_duplicate_titles_excl_duplicate_pages",
    [{ predefinedChartType: "reportTrend" }],
  ],

  // Accessibility Issue Reports
  [
    "accessibility_issue_instances",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityIssuesAndInstancesTrend,
    ],
  ],
  [
    "accessibility_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityIssuesAndInstancesTrend,
    ],
  ],
  [
    "autocomplete_valid_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityFormsChart],
  ],
  [
    "form_field_multiple_labels_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityFormsChart],
  ],
  [
    "select_name_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityFormsChart],
  ],
  [
    "label_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityFormsChart],
  ],
  [
    "valid_lang_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityLangugeChart],
  ],
  [
    "html_xml_lang_mismatch_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityLangugeChart],
  ],
  [
    "html_lang_valid_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityLangugeChart],
  ],
  [
    "html_has_lang_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityLangugeChart],
  ],
  [
    "wcag_a_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityLevelAChart],
  ],
  [
    "wcag_aa_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityLevelAAChart],
  ],
  [
    "wcag_aaa_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityLevelAAAChart],
  ],
  [
    "best_practices_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityLevelsChart],
  ],
  [
    "wcag_a_issue_instances",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityIssuesandInstancesATrend,
    ],
  ],
  [
    "wcag_aa_issue_instances",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityIssuesandInstancesAATrend,
    ],
  ],
  [
    "wcag_aaa_issue_instances",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityIssuesandInstancesAAATrend,
    ],
  ],
  [
    "best_practices_issue_instances",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityIssuesandInstancesBPTrend,
    ],
  ],
  [
    "critical_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilitySeverityChart],
  ],
  [
    "serious_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilitySeverityChart],
  ],
  [
    "moderate_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilitySeverityChart],
  ],
  [
    "minor_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilitySeverityChart],
  ],
  [
    "area_alt_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityTextAlternativesAChart,
    ],
  ],
  [
    "aria_allowed_attr_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaAChart],
  ],
  [
    "aria_command_name_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaAChart],
  ],
  [
    "aria_hidden_body_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaAChart],
  ],
  [
    "aria_hidden_focus_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityNameRoleValueAChart,
    ],
  ],
  [
    "aria_input_field_name_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaAChart],
  ],
  [
    "aria_meter_name_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaAChart],
  ],
  [
    "aria_progressbar_name_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaAChart],
  ],
  [
    "aria_required_attr_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaAChart],
  ],
  [
    "aria_required_children_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaAChart],
  ],
  [
    "aria_required_parent_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaAChart],
  ],
  [
    "aria_roledescription_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaAChart],
  ],
  [
    "aria_roles_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaAChart],
  ],
  [
    "aria_toggle_field_name_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaAChart],
  ],
  [
    "aria_tooltip_name_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaAChart],
  ],
  [
    "aria_valid_attr_value_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaAChart],
  ],
  [
    "aria_valid_attr_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaAChart],
  ],
  [
    "audio_caption_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityTimeAndMediaAChart,
    ],
  ],
  [
    "blink_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityTimeAndMediaAChart,
    ],
  ],
  [
    "button_name_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityNameRoleValueAChart,
    ],
  ],
  [
    "bypass_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityKeyboardAChart],
  ],
  [
    "color_contrast_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityColorAAChart],
  ],
  [
    "definition_list_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityStructureChart,
      getAccessibilityStructureAChart,
    ],
  ],
  [
    "dlitem_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityStructureChart,
      getAccessibilityStructureAChart,
    ],
  ],
  [
    "document_title_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityTextAlternativesAChart,
    ],
  ],
  [
    "duplicate_id_active_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityParsingAChart],
  ],
  [
    "duplicate_id_aria_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityParsingAChart],
  ],
  [
    "duplicate_id_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityParsingAChart],
  ],
  [
    "form_field_multiple_labels_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityFormsAChart],
  ],
  [
    "frame_focusable_content_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityKeyboardAChart],
  ],
  [
    "frame_title_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityTextAlternativesAChart,
    ],
  ],
  [
    "html_has_lang_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityLanguageAChart],
  ],
  [
    "html_lang_valid_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityLanguageAChart],
  ],
  [
    "html_xml_lang_mismatch_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityLanguageAChart],
  ],
  [
    "image_alt_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityTextAlternativesAChart,
    ],
  ],
  [
    "input_button_name_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityNameRoleValueAChart,
    ],
  ],
  [
    "input_image_alt_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityTextAlternativesAChart,
    ],
  ],
  [
    "label_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityFormsAChart],
  ],
  [
    "link_name_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityNameRoleValueAChart,
    ],
  ],
  [
    "list_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityStructureChart,
      getAccessibilityStructureAChart,
    ],
  ],
  [
    "listitem_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityStructureChart,
      getAccessibilityStructureAChart,
    ],
  ],
  [
    "marquee_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityParsingAChart],
  ],
  [
    "meta_refresh_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityTimeAndMediaAChart,
    ],
  ],
  [
    "nested_interactive_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityKeyboardAChart],
  ],
  [
    "object_alt_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityTextAlternativesAChart,
    ],
  ],
  [
    "role_img_alt_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityTextAlternativesAChart,
    ],
  ],
  [
    "scrollable_region_focusable_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityKeyboardAChart],
  ],
  [
    "select_name_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityFormsAChart],
  ],
  [
    "server_side_image_map_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityTextAlternativesAChart,
    ],
  ],
  [
    "svg_img_alt_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityTextAlternativesAChart,
    ],
  ],
  [
    "td_headers_attr_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityTablesAChart],
  ],
  [
    "th_has_data_cells_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityTablesAChart],
  ],
  [
    "valid_lang_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityLanguageAAChart],
  ],
  [
    "video_caption_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityTextAlternativesAChart,
    ],
  ],
  [
    "empty_table_header_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaBPChart],
  ],
  [
    "accesskeys_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityKeyboardBPChart],
  ],
  [
    "aria_allowed_role_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaBPChart],
  ],
  [
    "aria_dialog_name_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaBPChart],
  ],
  [
    "aria_text_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaBPChart],
  ],
  [
    "aria_treeitem_name_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaBPChart],
  ],
  [
    "empty_heading_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityNameRoleValueBPChart,
    ],
  ],
  [
    "frame_tested_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityStructureBPChart],
  ],
  [
    "frame_title_unique_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityTextAlternativesBPChart,
    ],
  ],
  [
    "heading_order_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilitySemanticsBPChart],
  ],
  [
    "image_redundant_alt_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityTextAlternativesBPChart,
    ],
  ],
  [
    "label_title_only_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityFormsBPChart],
  ],
  [
    "landmark_banner_is_top_level_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilitySemanticsBPChart],
  ],
  [
    "landmark_complementary_is_top_level_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilitySemanticsBPChart],
  ],
  [
    "landmark_contentinfo_is_top_level_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilitySemanticsBPChart],
  ],
  [
    "landmark_main_is_top_level_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilitySemanticsBPChart],
  ],
  [
    "landmark_no_duplicate_banner_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilitySemanticsBPChart],
  ],
  [
    "landmark_no_duplicate_contentinfo_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilitySemanticsBPChart],
  ],
  [
    "landmark_no_duplicate_main_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilitySemanticsBPChart],
  ],
  [
    "landmark_one_main_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilitySemanticsBPChart],
  ],
  [
    "landmark_unique_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilitySemanticsBPChart],
  ],
  [
    "meta_viewport_large_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilitySensoryAndVisualBPChart,
    ],
  ],
  [
    "meta_viewport_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilitySensoryAndVisualAAChart,
    ],
  ],
  [
    "target_size_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilitySensoryAndVisualAAChart,
    ],
  ],
  [
    "page_has_heading_one_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilitySemanticsBPChart],
  ],
  [
    "presentation_role_conflict_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaBPChart],
  ],
  [
    "region_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityKeyboardBPChart],
  ],
  [
    "scope_attr_valid_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityTablesBPChart],
  ],
  [
    "skip_link_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityKeyboardBPChart],
  ],
  [
    "tabindex_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityKeyboardBPChart],
  ],
  [
    "table_duplicate_name_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityTablesBPChart],
  ],
  [
    "css_orientation_lock_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityStructureBPChart],
  ],
  [
    "focus_order_semantics_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityKeyboardBPChart],
  ],
  [
    "hidden_content_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityStructureBPChart],
  ],
  [
    "link_in_text_block_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityColorAChart],
  ],
  [
    "no_autoplay_audio_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityTimeAndMediaAChart,
    ],
  ],
  [
    "p_as_heading_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilitySemanticsAChart],
  ],
  [
    "table_fake_caption_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityTablesAChart],
  ],
  [
    "td_has_header_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityTablesAChart],
  ],
  [
    "color_contrast_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityLevelAAChart],
  ],
  [
    "valid_lang_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityLevelAAChart],
  ],
  [
    "autocomplete_valid_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityLevelAAChart],
  ],
  [
    "avoid_inline_spacing_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityLevelAAChart],
  ],
  [
    "color_contrast_enhanced_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityLevelAAAChart],
  ],
  [
    "identical_links_same_purpose_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityLevelAAAChart,
      getAccessibilitySemanticsChart,
    ],
  ],
  [
    "color_contrast_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityColorChart,
      getAccessibilityLevelAAChart,
    ],
  ],
  [
    "color_contrast_enhanced_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityColorChart,
      getAccessibilityLevelAAAChart,
    ],
  ],
  [
    "link_in_text_block_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityColorChart],
  ],
  [
    "identical_links_same_purpose_issues",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityLevelAAAChart,
      getAccessibilitySemanticsChart,
    ],
  ],
  [
    "label_content_name_mismatch_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilitySemanticsChart],
  ],
  [
    "p_as_heading_issues",
    [{ predefinedChartType: "reportTrend" }, getAccessibilitySemanticsChart],
  ],

  // <category)
  [
    "aria_a",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityAriaAChart],
  ],
  [
    "forms_a",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityFormsAChart],
  ],
  [
    "keyboard_a",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityKeyboardAChart],
  ],
  [
    "language_a",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityLanguageAChart],
  ],
  [
    "name_role_value_a",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityNameRoleValueAChart,
    ],
  ],
  [
    "parsing_a",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityParsingAChart],
  ],
  [
    "structure_a",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityStructureAChart],
  ],
  [
    "tables_a",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityTablesAChart],
  ],
  [
    "text_alternatives_a",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityTextAlternativesAChart,
    ],
  ],
  [
    "time_and_media_a",
    [
      { predefinedChartType: "reportTrend" },
      getAccessibilityTimeAndMediaAChart,
    ],
  ],
  [
    "semantics_a",
    [{ predefinedChartType: "reportTrend" }, getAccessibilitySemanticsAChart],
  ],
  [
    "color",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityColorChart],
  ],
  [
    "forms",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityFormsChart],
  ],
  [
    "language",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityLangugeChart],
  ],
  [
    "structure",
    [{ predefinedChartType: "reportTrend" }, getAccessibilityStructureChart],
  ],
  [
    "semantics",
    [{ predefinedChartType: "reportTrend" }, getAccessibilitySemanticsChart],
  ],

  // SITE SPEED
  [
    "cumulative_layout_shift_slow",
    [{ predefinedChartType: "reportTrend" }, getSiteSpeedIssuesCLS],
  ],
  [
    "cumulative_layout_shift_moderate",
    [{ predefinedChartType: "reportTrend" }, getSiteSpeedIssuesCLS],
  ],
  [
    "total_blocking_time_slow",
    [{ predefinedChartType: "reportTrend" }, getSiteSpeedIssuesTBT],
  ],
  [
    "total_blocking_time_moderate",
    [{ predefinedChartType: "reportTrend" }, getSiteSpeedIssuesTBT],
  ],
  [
    "first_contentful_paint_slow",
    [{ predefinedChartType: "reportTrend" }, getSiteSpeedIssuesFCP],
  ],
  [
    "first_contentful_paint_moderate",
    [{ predefinedChartType: "reportTrend" }, getSiteSpeedIssuesFCP],
  ],
  [
    "largest_contentful_paint_slow",
    [{ predefinedChartType: "reportTrend" }, getSiteSpeedIssuesLCP],
  ],
  [
    "largest_contentful_paint_moderate",
    [{ predefinedChartType: "reportTrend" }, getSiteSpeedIssuesLCP],
  ],
  [
    "layout_shift_elements_failed_audits",
    [
      { predefinedChartType: "reportTrend" },
      getSiteSpeedCLSLayoutShiftElements,
    ],
  ],
  [
    "layout_shift_elements_failed_items",
    [
      { predefinedChartType: "reportTrend" },
      getSiteSpeedCLSLayoutShiftElements,
    ],
  ],
  [
    "mainthread_script_evaluation_items",
    [getSiteSpeedMainthreadChart, getSiteSpeedMainthreadTrend],
  ],
  [
    "mainthread_other_items",
    [getSiteSpeedMainthreadChart, getSiteSpeedMainthreadTrend],
  ],
  [
    "mainthread_style_layout_items",
    [getSiteSpeedMainthreadChart, getSiteSpeedMainthreadTrend],
  ],
  [
    "mainthread_script_parsing_items",
    [getSiteSpeedMainthreadChart, getSiteSpeedMainthreadTrend],
  ],
  [
    "mainthread_parse_html_css_items",
    [getSiteSpeedMainthreadChart, getSiteSpeedMainthreadTrend],
  ],
  [
    "mainthread_rendering_items",
    [getSiteSpeedMainthreadChart, getSiteSpeedMainthreadTrend],
  ],
  [
    "mainthread_garbage_collection_items",
    [getSiteSpeedMainthreadChart, getSiteSpeedMainthreadTrend],
  ],
]);
