import {
  Maybe,
  Project,
  UpdateProjectInput,
  UserAgent,
} from "../../../../../graphql";
import { MobileSiteSettings } from "../types";
import { convertString } from "./helpers";

type QueryData = Pick<
  Project,
  | "userAgentStringMobile"
  | "userAgentTokenMobile"
  | "useMobileSettings"
  | "mobileHomepageUrl"
  | "mobileUrlPattern"
  | "moduleCode"
  | "userAgentMobileIsMobile"
  | "mobileViewportHeight"
  | "mobileViewportWidth"
> & {
  mobileUserAgent?: Maybe<Pick<UserAgent, "code">>;
};

export function formatMobileSiteSettingsFrom(
  data?: QueryData | null,
): MobileSiteSettings {
  const isCustom = data?.userAgentStringMobile || data?.userAgentTokenMobile;

  return {
    enable: data?.useMobileSettings ?? false,
    homePage: data?.mobileHomepageUrl ?? "",
    pattern: data?.mobileUrlPattern ?? "",
    agent: isCustom ? "Custom" : data?.mobileUserAgent?.code ?? "None",
    customFull: data?.userAgentStringMobile ?? "",
    customShort: data?.userAgentTokenMobile ?? "",
    userAgentIsMobile: data?.userAgentMobileIsMobile ?? false,
    viewportHeight: data?.mobileViewportWidth ?? undefined,
    viewportWidth: data?.mobileViewportHeight ?? undefined,
  };
}

export function formatMobileSiteSettingsTo(
  formValues: MobileSiteSettings,
): Pick<
  UpdateProjectInput,
  | "useMobileSettings"
  | "mobileHomepageUrl"
  | "mobileUrlPattern"
  | "mobileUserAgentCode"
  | "userAgentStringMobile"
  | "userAgentTokenMobile"
  | "userAgentMobileIsMobile"
  | "mobileViewportWidth"
  | "mobileViewportHeight"
> {
  const isCustom = formValues.agent === "Custom";

  return {
    useMobileSettings: formValues.enable,
    mobileHomepageUrl: convertString(formValues.homePage),
    mobileUrlPattern: convertString(formValues.pattern),
    mobileUserAgentCode:
      isCustom || formValues.agent === "None" ? null : formValues.agent,
    userAgentStringMobile: isCustom ? formValues.customFull : null,
    userAgentTokenMobile: isCustom ? formValues.customShort : null,
    mobileViewportWidth: formatViewport(formValues.viewportWidth),
    mobileViewportHeight: formatViewport(formValues.viewportHeight),
    userAgentMobileIsMobile: isCustom ? formValues.userAgentIsMobile : null,
  };
}

function formatViewport(
  value: number | string | undefined | null,
): number | null {
  if (typeof value === "string") {
    const result = parseInt(value);
    return Number.isNaN(result) ? null : result;
  }

  return value ?? null;
}
