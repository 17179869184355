/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable fp/no-this */
import { TFunction } from "i18next";
import { Options, SeriesColumnOptions } from "highcharts";
import { CrawlProgressFragment } from "../../../../graphql";
import { baseChartOptions } from "../_common/charts/base-chart-options";
import { baseTooltipOptions } from "../_common/charts/tooltip-config";

function levelsChartOptions(t: TFunction<"crawlProgress">): Options {
  return {
    ...baseChartOptions,
    chart: {
      height: 172,
      marginRight: 16,
    },
    xAxis: { type: "category" },
    yAxis: { type: "linear", title: { style: { display: "none" } } },
    legend: { enabled: false },
    tooltip: {
      formatter: function format() {
        const valueX = +(this.x || "");
        const valueY = +(this.y || "");

        if (isNaN(valueX))
          return t("charts.crawledUrl", { count: valueY, level: this.x });
        return t("charts.crawledUrlAtLevel", {
          count: valueY,
          level: valueX,
        });
      },
      ...baseTooltipOptions,
      shared: true,
      outside: true,
    },
    series: [
      {
        color: "#3B82F6",
        type: "column",
        data: [],
        events: { legendItemClick: () => false },
      },
    ],
  };
}

export const getLevelsChartOptions = (
  t: TFunction<"crawlProgress">,
  crawl?: Pick<CrawlProgressFragment, "crawlStat">,
): Options => {
  const options = levelsChartOptions(t);
  if (!crawl) return options;

  const currentSeries = options.series?.[0] as SeriesColumnOptions;
  const levelsData = crawl.crawlStat?.crawlLevels.map(
    ({ level, stepsProcessed }) => {
      const levelName =
        level === undefined || level === null ? t("charts.notInWeb") : level; // add level to axis
      return [levelName, stepsProcessed];
    },
  );

  return {
    ...options,
    series: [{ ...currentSeries, data: levelsData }],
  };
};
