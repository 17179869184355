import React from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import { useGenericParams } from "../../routing/useGenericParams";
import { assert } from "../../assert";
import { Routes } from "../../routing/routes";
import { ReportTemplate, ReportTypeCode } from "../../../graphql";
import { PercentageChangeChip, Typography } from "@lumar/shared";
import { MonospaceNumber } from "../../monospace-number/MonospaceNumber";
import {
  getReportSignColor,
  ReportSignColors,
} from "../../report-change-details/getReportChangeDetails";
import { SparklinesMiniChart } from "../components/SparklinesMiniChart";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useCrawlContextData } from "../../../crawl-overview/CrawlContext";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    transition: theme.transitions.create(["background-color"], {
      duration: theme.transitions.duration.short,
    }),
    "& td:first-child $anchor": {
      paddingLeft: 0,
    },
    "& td:last-child $anchor": {
      paddingRight: 0,
    },
    "&:hover": {
      backgroundColor: "#FAFAFA",
      "& td:first-child": {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
      },
      "& td:last-child": {
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
      },
    },
  },
  // Note: anchor styles needed to override legacy CSS leakage
  anchor: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
  },
  rowName: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "block",
  },
  secondColumn: {
    width: 50,
    textAlign: "right",
  },
  secondColumnAnchor: {
    paddingRight: theme.spacing(1),
  },
  thirdColumn: { width: 80 },
}));

interface SparklineListItemProps {
  reportTemplate: Pick<ReportTemplate, "id" | "name" | "code">;
  reportTemplateName: string;
  sign: number;
  count: number;
  trendValues: {
    x: Date;
    y: number;
  }[];
  showChangePercentage: boolean;
  change?: number;
  colors?: string[];
}

export function SparklineListItem(props: SparklineListItemProps): JSX.Element {
  const {
    sign,
    count,
    change,
    trendValues,
    showChangePercentage,
    reportTemplate,
    reportTemplateName,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const { accountId, projectId, crawlId } = useGenericParams();
  const { selectedCrawlSegment } = useCrawlContextData();
  const { t, i18n } = useTranslation("units");

  assert(accountId);
  assert(projectId);
  assert(crawlId);

  const reportUrl = Routes.Report.getUrl({
    crawlId,
    accountId,
    projectId,
    reportTemplateCode: reportTemplate.code,
    reportTypeCode: ReportTypeCode.Basic.toLowerCase(),
    segmentId: selectedCrawlSegment?.segment.id,
  });

  const customSignColorMap = {
    [ReportSignColors.red]: theme.palette.error.main,
    [ReportSignColors.green]: theme.palette.success.main,
    [ReportSignColors.grey]: "#525151",
  };
  const customSignColor = customSignColorMap[getReportSignColor(sign)];

  const total = trendValues[0]?.y;
  const hasTrendData = Boolean(trendValues[1]);

  const changeNum = change ?? 0;
  const value = Math.abs(changeNum / ((total ?? 0) - changeNum));

  return (
    <tr className={classes.tableRow}>
      <td>
        <Typography
          component={RouterLink}
          to={reportUrl}
          variant="body2"
          className={clsx(classes.anchor, classes.rowName)}
        >
          {reportTemplateName}
        </Typography>
      </td>

      <td className={classes.secondColumn}>
        <RouterLink
          to={reportUrl}
          className={clsx(classes.anchor, classes.secondColumnAnchor)}
          style={{
            color: customSignColor,
          }}
        >
          <MonospaceNumber>
            {showChangePercentage && count === change && count > 0 && "+"}
            {t("compactNumber", { count })}
          </MonospaceNumber>
        </RouterLink>
      </td>

      <td className={clsx(classes.anchor, classes.thirdColumn)}>
        <RouterLink to={reportUrl} className={classes.anchor}>
          {showChangePercentage &&
            hasTrendData &&
            typeof change === "number" &&
            typeof total == "number" && (
              <PercentageChangeChip
                sign={sign}
                total={value > 10 ? change : total}
                change={change}
                lang={i18n.language}
              />
            )}
        </RouterLink>
      </td>

      <td>
        <RouterLink to={reportUrl}>
          {hasTrendData && (
            <SparklinesMiniChart
              height={25}
              colors={[theme.palette.grey[600]]}
              fillColor={theme.palette.grey[100]}
              data={trendValues.map(({ x, y }) => ({
                x: x.getTime(),
                y,
              }))}
            />
          )}
        </RouterLink>
      </td>
    </tr>
  );
}
