import { TileChartConfigItem } from "../types/ChartConfig";
import { TFunction } from "i18next";
import { ModuleCode } from "../../../graphql";

export function getSiteSpeedAvgSpeedIndexTileChart(
  t: TFunction<"charts">,
): TileChartConfigItem {
  return {
    supportedModules: [ModuleCode.SiteSpeed],
    title: () => t("siteSpeedSIMetricTrend.tile_title"),
    description: () => t("siteSpeedSIMetricTrend.description"),
    columnCount: null,
    reports: () => ({
      filter: {
        reportTemplateCode: "site_speed_pages:speed_index_avg",
      },
      fields: {
        reportTrend: {
          basic: true,
          crawlId: true,
          createdAt: true,
        },
      },
    }),
  };
}
