import React from "react";
import { alpha, makeStyles, Typography, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LegacyTaskTrendEntry } from "../../../graphql";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { TrendChart } from "./TrendChart";

const useStyles = makeStyles((theme) => ({
  noTrend: {
    position: "relative",
  },
  noTrendLabel: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(13),
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  noTrendChart: {
    opacity: 0.3,
  },
}));

const noTrendData = [
  ...[
    { x: 0, y: 0.6 },
    { x: 2.1, y: 0.6 },
    { x: 2.9, y: 0.96 },
    { x: 3.07, y: 0.6 },
    { x: 5.1, y: 0.6 },
    { x: 5.48, y: 0.77 },
    { x: 5.92, y: 0.6 },
    { x: 6.58, y: 0.77 },
    { x: 7.03, y: 0.6 },
    { x: 7.73, y: 0.6 },
    { x: 8.11, y: 1 },
    { x: 8.11, y: 1 },
    { x: 8.76, y: 1 },
    { x: 9.35, y: 1.25 },
    { x: 9.87, y: 1 },
    { x: 10.45, y: 1.25 },
    { x: 10.94, y: 1 },
    { x: 11.45, y: 0.8 },
    { x: 11.91, y: 1.51 },
    { x: 12.56, y: 1.28 },
    { x: 13.25, y: 1.91 },
    { x: 13.96, y: 1.91 },
  ],
];

const noTrendMax = 4.43;

export function TrendCell(params: GridRenderCellParams): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("taskManager");

  const theme = useTheme();

  const trend = params.value as LegacyTaskTrendEntry[] | undefined;

  // Trend needs to be sorted as we can't assume that the array will be coming ordered from API - Alex S.
  const sortedTrend = trend
    ? // We need to copy the array as due to strict mode the array is frozen and don't let you do sorting
      // eslint-disable-next-line fp/no-mutating-methods
      [...trend].sort((a, b) =>
        a.crawlFinishedAt > b.crawlFinishedAt ? 1 : -1,
      )
    : [];

  const data = sortedTrend.slice(-30).map((item) => item.identified ?? 0) ?? [];

  if (data.length < 2) {
    return (
      <div className={classes.noTrend} data-testid="tasks-trend-no-data">
        <Typography className={classes.noTrendLabel}>{t("noTrend")}</Typography>
        <div className={classes.noTrendChart}>
          <TrendChart
            data={noTrendData}
            max={noTrendMax}
            colors={[theme.palette.grey[600]]}
            fillColor={alpha(theme.palette.grey[200], 0.5)}
          />
        </div>
      </div>
    );
  }

  const identified: number | undefined = params.row.identified;
  const trendMax = Math.max(...data, identified ?? 0);

  return (
    <div data-testid="tasks-trend">
      <TrendChart
        data={data}
        max={trendMax}
        colors={[theme.palette.grey[600]]}
        fillColor={alpha(theme.palette.grey[200], 0.5)}
      />
    </div>
  );
}
