import React from "react";
import { makeStyles } from "@material-ui/core";
import { ChartConfigItemSparkline } from "./ChartConfigItemSparkline";
import { assert } from "../../assert";
import { SparklineListItem } from "./SparklineListItem";
import { useGenericParams } from "../../routing/useGenericParams";
import { ChartPanelMessageWrapper } from "../components/chart-messages/ChartPanelMessageWrapper";
import { ChartPanelTitle } from "../components/chart-components/ChartPanelTitle";
import { ChartWrapper } from "../components/chart-components/ChartWrapper";
import { useChartDataContext } from "../components/chart-components/ChartDataContext";
import { ChartPanelContent } from "../components/chart-components/ChartPanelContent";
import { ChartPanelButton } from "../components/chart-components/ChartPanelButton";
import { useCrawlContextData } from "../../../crawl-overview/CrawlContext";
import { useSearchParam } from "../../routing/useSearchParam";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingRight: theme.spacing(1),
  },
  table: {
    flexGrow: 1,
    width: "100%",
    tableLayout: "fixed",
    // NOTE: This is needed instead of cellspacing because of bootstrap style leak - Saul
    borderSpacing: 0,
    borderCollapse: "collapse",
  },
}));

function SparklineChart(props: SparklineListProps): JSX.Element {
  const classes = useStyles();
  const { reports } = useChartDataContext();
  const { accountId, projectId, crawlId } = useGenericParams();

  assert(accountId);
  assert(projectId);
  assert(crawlId);

  return (
    <table className={classes.table}>
      <tbody>
        {reports.map((report) => {
          const sign = props.bindings.sign(report);
          assert(
            typeof sign === "number",
            'Could not pick a sign from the report using the "sign" binding. Check report data and the binding.',
          );

          return (
            <SparklineListItem
              key={report.id}
              reportTemplate={report.reportTemplate}
              reportTemplateName={
                report.reportTemplateName || report.reportTemplate.name
              }
              sign={sign}
              count={props.bindings.count(report, reports)}
              showChangePercentage={Boolean(props.showChangePercentage)}
              change={
                props.bindings.change
                  ? props.bindings.change(report)
                  : undefined
              }
              trendValues={props.bindings.trendValues(report)}
              colors={props.visualisationColors}
            />
          );
        })}
      </tbody>
    </table>
  );
}

export type SparklineListProps = ChartConfigItemSparkline;

export function SparklineList(props: SparklineListProps): JSX.Element {
  const {
    noTrendsTemplate,
    noReportsTemplate,
    requiredSources,
    description,
    title,
  } = props;
  const { accountId, projectId, crawlId } = useGenericParams();
  const { data, reports, segmentName, totalUrls } = useChartDataContext();
  const { selectedCrawlSegment } = useCrawlContextData();
  const category = useSearchParam("category"); // FIXME: Should be a prop to properly decouple

  const showNoTrendDataTemplate =
    Boolean(noTrendsTemplate) && !data?.getCrawl?.comparedToCrawlId;

  assert(accountId);
  assert(projectId);
  assert(crawlId);

  const moreLink = props.moreLink?.href({
    accountId,
    projectId,
    crawlId,
    segmentId: selectedCrawlSegment?.segment.id,
    category: category,
  });

  const totalCount = data?.getCrawl?.reports.totalCount ?? 0;

  const moreLinkText = props.moreLink?.title(
    totalCount,
    reports,
    totalUrls ?? 0,
  );

  return (
    <ChartWrapper
      data-testid={props.testAttributePrefix}
      data-pendo={props.pendoAttributePrefix}
    >
      <ChartPanelTitle
        title={title(reports)}
        description={
          description
            ? description({ accountId, projectId, crawlId }, reports)
            : undefined
        }
        segmentName={segmentName}
      >
        {
          <ChartPanelButton
            link={moreLink}
            label={moreLinkText}
            data-pendo={
              props.pendoAttributePrefix
                ? `${props.pendoAttributePrefix}-view-all`
                : undefined
            }
          />
        }
      </ChartPanelTitle>
      <ChartPanelContent requiredSources={requiredSources}>
        {showNoTrendDataTemplate ? (
          <ChartPanelMessageWrapper>
            {noTrendsTemplate}
          </ChartPanelMessageWrapper>
        ) : reports.length === 0 ? (
          <ChartPanelMessageWrapper>
            {noReportsTemplate}
          </ChartPanelMessageWrapper>
        ) : (
          <SparklineChart {...props} />
        )}
      </ChartPanelContent>
    </ChartWrapper>
  );
}
