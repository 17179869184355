/* eslint-disable react/display-name */
import { Theme } from "@material-ui/core";
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getPerformanceSlow(
  t: TFunction<"charts">,
  theme: Theme,
): ChartConfigItem {
  return {
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    visualisationColors: [theme.palette.red[500]],
    title: () => t("performanceSlow.title"),
    description: () => t("performanceSlow.description"),
    reports: () => ({
      filter: {
        reportTemplateCode: [
          "slow_server_response",
          "max_load_time",
          "slow_dcl",
          "slow_fcp",
          "slow_lcp",
          "slow_tti",
          "poor_cls",
        ],
      },
      fields: {
        reportTemplate: {
          code: true,
        },
        totalRows: true,
      },
    }),
    bindings: {
      name: (report) => {
        const reportTemplateCode = report.reportTemplate.code;
        return reportTemplateCode === "non-200_pages"
          ? t("non200Pages.total")
          : report.reportTemplate.name;
      },
      count: (report) => {
        return report.totalRows ?? 0;
      },
    },
    testAttributePrefix: "non-200-pages-chart",
  };
}
