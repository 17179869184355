/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getMobileIssuesChart(t: TFunction<"charts">): ChartConfigItem {
  return {
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    title: () => t("mobileIssues.title"),
    description: () => t("mobileIssues.description"),
    reports: () => ({
      filter: {
        reportTemplateCode: [
          "non_200_separate_mobile_amp",
          "mobile_alternates_noindex",
          "non_reciprocal_mobile_amp_desktop",
          "amphtml_non_rel_alted",
        ],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        totalRows: true,
      },
    }),
    bindings: {
      name: (report) => report.reportTemplate.name,
      count: (report) => {
        return report.totalRows ?? 0;
      },
    },
  };
}
