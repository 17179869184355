import React from "react";
import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import { GridRowParams } from "@mui/x-data-grid-pro";
import { useHistory } from "react-router";
import { Routes } from "../_common/routing/routes";
import {
  useAccountGuard,
  useProjectGuard,
  TimeZoneContext,
  useTranslation,
} from "@lumar/shared";
import { useProjectTaskTableColumns } from "./useTaskTableColumns";
import { isNotEmptyConnectionFilter } from "../_common/connection-filtering/isNotEmptyConnectionFilter";
import { format, addMinutes } from "date-fns";
import { TasksGrid } from "./TasksGrid";
import { useParams } from "react-router-dom";
import { useProjectTasksData } from "./data/useProjectTasksData";
import { ReportTypeCode } from "../graphql";
import { Task } from "./data/types";
import { FilterByReportFilter } from "./FilterByReportFilter";
import { ShowResolvedFilter } from "./ShowResolvedFilter";

export function ProjectTaskManager(): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation("taskManager");
  const { timeZone } = React.useContext(TimeZoneContext);
  const { accountId, projectId, crawlId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
  }>();

  useAccountGuard();
  useProjectGuard();

  const { project, rows, loading, error } = useProjectTasksData({
    projectId,
    crawlId,
  });

  const { columns, defaultColumns, setColumnState } =
    useProjectTaskTableColumns();

  function getExportName(): string {
    const domainName = project.primaryDomain
      ?.replace("http://", "")
      .replace("https://", "")
      .replace("www.", "")
      .replace(".", "-")
      .split(/[/?#]/)[0];

    if (project.lastFinishedCrawl?.finishedAt) {
      const date = project.lastFinishedCrawl.finishedAt;
      return `${domainName}_${format(
        addMinutes(date, date.getTimezoneOffset() + timeZone.offset),
        "dd-MM-yyyy",
      )}_Tasks`;
    }

    return `${domainName}_Tasks`;
  }

  function onRowClick(params: GridRowParams): void {
    const rowData = params.row as Task;

    if (
      !rowData.reportTemplate ||
      !rowData.reportType ||
      !project.lastFinishedCrawl
    ) {
      return;
    }

    const isValidFilter =
      rowData.connectionFilter &&
      !isNotEmptyConnectionFilter(rowData.connectionFilter);

    history.push(
      Routes.Report.getUrl({
        accountId,
        projectId,
        crawlId: project.lastFinishedCrawl.id,
        reportTemplateCode: rowData.reportTemplate.code,
        reportTypeCode: rowData.reportType.code.toLowerCase(),
        segmentId: rowData.segment?.id,
        filter: isValidFilter
          ? { _or: [rowData.connectionFilter] }
          : rowData.connectionFilter,
      }),
    );
  }

  function navigateToLastCrawl(): void {
    if (!project.lastFinishedCrawl) {
      return;
    }

    history.push(
      Routes.Report.getUrl({
        accountId,
        projectId,
        crawlId: project.lastFinishedCrawl.id,
        reportTemplateCode: "all_pages",
        reportTypeCode: ReportTypeCode.Basic.toLowerCase(),
      }),
    );
  }

  return (
    <>
      <TopNavigation
        title={t("title")}
        breadcrumbs={[{ label: t("breadcrumb") }]}
        availableForShare
      />
      <TasksGrid
        loading={loading}
        error={Boolean(error)}
        rows={rows}
        pageSize={20}
        columns={columns}
        defaultColumns={defaultColumns}
        setColumnState={setColumnState}
        enableExport
        onRowClick={onRowClick}
        searchColumn="task"
        defaultPaginationState={{
          filterModel: {
            items: [
              {
                id: "showResolved",
                columnField: "statusDeadline",
                operatorValue: "showResolved",
                value: false,
              },
            ],
          },
          sortModel: [{ field: "severity", sort: "desc" }],
        }}
        components={{
          ToolbarRight: [FilterByReportFilter, ShowResolvedFilter],
        }}
        componentsProps={{
          toolbar: {
            exportFileName: getExportName,
            toolbarRight: { rows: rows },
          },
          noRowsOverlay: { onClick: navigateToLastCrawl },
        }}
      />
    </>
  );
}
