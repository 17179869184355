/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode } from "@lumar/shared/dist/graphql";

export function getExtractReportsChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    supportedModules: [
      ModuleCode.Accessibility,
      ModuleCode.Seo,
      ModuleCode.Basic,
    ],
    title: () => t("extractReports.title"),
    description: () => t("extractReports.description"),
    reports: () => ({
      filter: {
        reportTemplateCode: ["extract_report", "extract_complement_report"],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        totalRows: true,
      },
    }),
    showPercentageInSeriesTooltip: true,
    bindings: {
      name: (report) => {
        return report.reportTemplate.name;
      },
      count: (report) => {
        return report.totalRows ?? 0;
      },
    },
  };
}
