import React from "react";
import {
  EmptyState,
  SmileySad,
  useAccountGuard,
  useCrawlGuard,
  useProjectGuard,
  useTranslation,
} from "@lumar/shared";
import { Paper, makeStyles } from "@material-ui/core";
import { CrawlSelector } from "../crawl-selector/CrawlSelector";
import { useReportBreadcrumbs } from "../report/data/useReportBreadcrumbs";
import { Routes } from "../_common/routing/routes";
import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import { ResourceDetailData } from "./data/types";
import { useResourceDetailData } from "./data/useResourceDetailData";
import { ResourceDetailSummarySkeleton } from "./ResourceDetailSummary";
import { useResourceDetailParams } from "./data/useResourceDetailParams";
import { useLocation } from "react-router-dom";
import { useCrawlContext } from "../crawl-overview/CrawlContext";

const useStyles = makeStyles((theme) => ({
  root: { marginTop: theme.spacing(2) },
  overlay: {
    height: 500,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  errorIcon: {
    fontSize: theme.typography.pxToRem(32),
    color: theme.palette.red[400],
  },
  errorMessage: {
    margin: theme.spacing(1.875, 0),
  },
}));

export function ResourceDetail(): JSX.Element {
  useAccountGuard();
  useProjectGuard();
  useCrawlGuard();

  const result = useResourceDetailData();
  const { loading: crawlContextLoading, errors: crawlContextErrors } =
    useCrawlContext();

  if (
    result.loading ||
    result.error !== undefined ||
    crawlContextLoading ||
    crawlContextErrors
  ) {
    return (
      <ResourceDetailBase
        loading={result.loading || crawlContextLoading}
        error={result.error || crawlContextErrors?.[0]?.message}
      />
    );
  }

  const DatasourceView = result.data.view.view;

  return <DatasourceView data={result.data} />;
}

export function ResourceDetailBase({
  title,
  loading,
  error,
  navigationReportTemplate,
  children,
}: {
  title?: string;
  loading?: boolean;
  error?: string;
  navigationReportTemplate?: ResourceDetailData["navigationReportTemplate"];
  children?: React.ReactNode;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("resourceDetail");
  const {
    accountId,
    projectId,
    crawlId,
    reportTemplateCodeWithTypeCode,
    resourceId,
    sourceReportTemplateCode,
    type,
  } = useResourceDetailParams();
  const rootRef = React.useRef<HTMLDivElement | null>(null);

  const titleResolved = title || t("defaultTitle");

  useScrollIntoView({ type, rootRef });

  const reportLink = Routes.Report.getUrl({
    accountId,
    crawlId,
    projectId,
    reportTemplateCodeWithTypeCode,
  });

  const { loading: breadrumbsLoading, breadcrumbs } = useReportBreadcrumbs({
    reportTemplate: navigationReportTemplate,
    accountId,
    projectId,
    crawlId,
    segmentId: undefined,
    title: titleResolved,
  });

  return (
    <>
      <TopNavigation
        loading={loading || breadrumbsLoading}
        title={titleResolved}
        breadcrumbs={breadcrumbs}
        availableForShare
        actions={
          <CrawlSelector
            key="crawl-selector"
            getPath={(crawlId) =>
              Routes.ResourceDetail.getUrl({
                accountId,
                projectId,
                crawlId,
                reportTemplateCodeWithTypeCode,
                resourceId,
                sourceReportTemplateCode,
                type,
              })
            }
          />
        }
      />
      <div className={classes.root} ref={rootRef}>
        {loading ? (
          <ResourceDetailSummarySkeleton />
        ) : error !== undefined ? (
          <Paper className={classes.overlay}>
            <EmptyState
              icon={
                <SmileySad fontSize="large" className={classes.errorIcon} />
              }
              title={error}
              actions={[
                {
                  type: "internalLink",
                  to: reportLink,
                  title: t("backToReport"),
                },
              ]}
            />
          </Paper>
        ) : (
          children
        )}
      </div>
    </>
  );
}

function useScrollIntoView({
  type,
  rootRef,
}: {
  type: string | undefined;
  rootRef: React.MutableRefObject<HTMLElement | null>;
}): void {
  const location = useLocation();

  const typeRef = React.useRef<string | undefined>();
  const typeChanged = typeRef.current !== type;
  typeRef.current = type;

  React.useEffect(() => {
    const element = location.hash
      ? rootRef.current?.querySelector(
          `[data-metric=${location.hash.slice(1)}]`,
        )
      : undefined;

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    } else if (typeChanged) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location.hash, rootRef, type, typeChanged]);
}
