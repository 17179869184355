import { ReportTrendItem } from "../../../../graphql";
import * as Yup from "yup";
import { orderBy } from "lodash";
import { ReportsChartColumnCustomDefinition } from "./useTopErrorsSparklineTableChartData";
import { ReportWithCustomValues } from "../report-custom-data/types";

const definedTrendValidator = Yup.object().shape({
  basic: Yup.number(),
  crawlId: Yup.number(),
  createdAt: Yup.string(),
});

export function mapCrawlReportTrendToSparklineChartSeries(
  reportTrend: ReportTrendItem[],
): { x: number; y: number }[] {
  // eslint-disable-next-line fp/no-mutating-methods
  return reportTrend
    .filter(
      (
        trend,
      ): trend is {
        basic: number;
        createdAt: string;
        crawlId: number;
      } => {
        return definedTrendValidator.isValidSync(trend);
      },
    )
    .map((trend) => {
      return {
        x: new Date(trend.createdAt).getTime(),
        y: trend.basic,
      };
    })
    .sort((a, b) => a.x - b.x);
}

export function sortReports(
  reports: ReportWithCustomValues[],
  field: string | undefined,
  direction: "asc" | "desc" | undefined,
  customColumns: ReportsChartColumnCustomDefinition[],
): ReportWithCustomValues[] {
  if (!field) return reports;

  const directionResolved = direction || "asc";

  return orderBy(
    reports,
    (report) => {
      if (field === "name") {
        return report.reportTemplate.name;
      }

      if (field === "total") {
        return report.totalRows || 0;
      }

      if (field === "change") {
        const totalRows = report.totalRows || 0;
        const change = report.change || 0;
        return Math.abs(totalRows / (totalRows - change) - 1);
      }

      // If the value is undefined, negative infinity is used, ensuring
      // that the sort order matches the sorting in the reports table.
      const customColumn = customColumns.find((x) => x.field === field);
      if (customColumn) {
        return report.customValues[field]?.value ?? Number.NEGATIVE_INFINITY;
      }
    },
    directionResolved,
  );
}
