/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Trans } from "@lumar/shared";
import { TFunction } from "i18next";
import { Theme, Typography } from "@material-ui/core";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { CrawlType, OrderDirection, ReportOrderField } from "../../../graphql";

function generateOrphanedPagesReportChart(fixOrdering = false) {
  return function (t: TFunction<"charts">, theme: Theme): ChartConfigItem {
    return {
      visualisationFamily: "MultiSeries",
      visualisationType: VisualisationTypes.Bar,
      requiredSources: {
        sources: [CrawlType.Web],
        incompleteMessage: t("requiredSourcesMessage.webSource"),
      },
      //visualisationColors: [theme.palette.grey[500]],
      title: () => t("orphanedPagesReport.title"),
      description: () => (
        <Trans ns="charts" i18nKey="orphanedPagesReport.description">
          <Typography paragraph variant="inherit">
            dfdfdf
          </Typography>
          <Typography variant="inherit">dfdfdf</Typography>
        </Trans>
      ),
      reports: () => ({
        orderBy: fixOrdering
          ? undefined
          : {
              field: ReportOrderField.TotalRows,
              direction: OrderDirection.Desc,
            },
        filter: {
          reportTemplateCode: [
            "all_orphaned_pages",
            "orphaned_organic_landing_pages",
            "orphaned_google_search_console_pages",
            "orphaned_pages_with_backlinks",
            "orphaned_sitemaps_pages",
            "orphaned_log_summary_pages",
            "orphaned_list_pages",
            "indexable_orphaned_web_pages",
          ],
        },
        fields: {
          reportTemplate: {
            code: true,
            name: true,
          },
          totalRows: true,
        },
      }),
      bindings: {
        name: (report) => {
          const reportTemplateCode = report.reportTemplate.code;
          return reportTemplateCode === "all_orphaned_pages"
            ? t("non200Pages.total")
            : report.reportTemplate.name;
        },
        count: (report) => {
          return report.totalRows ?? 0;
        },
      },
      testAttributePrefix: "orphaned-pages-chart",
    };
  };
}

export const getOrphanedPagesReportChart = generateOrphanedPagesReportChart();
