import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { makeStyles } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { Routes } from "../../_common/routing/routes";
import { isNotEmptyConnectionFilter } from "../../_common/connection-filtering/isNotEmptyConnectionFilter";
import {
  ExternalLinkSolid,
  getRawCrawlId,
  getRawProjectId,
} from "@lumar/shared";
import { Task } from "../data/types";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.grey[600],
    "&:hover": {
      color: theme.palette.blue[600],
    },
  },
  icon: {
    fontSize: theme.typography.pxToRem(20),
  },
}));

export function LinkCell(params: GridRenderCellParams): JSX.Element {
  const row = params.row as Task;
  const classes = useStyles();

  const { accountId, ...urlParams } = useParams<{
    accountId: string;
    projectId?: string;
    crawlId?: string;
  }>();

  const projectId = urlParams.projectId ?? row.project?.id ?? undefined;
  const crawlId =
    urlParams.crawlId ?? row.project?.lastFinishedCrawl?.id ?? undefined;

  if (!projectId || !crawlId) return <></>;

  const isValidFilter =
    row.connectionFilter && !isNotEmptyConnectionFilter(row.connectionFilter);

  const link = Routes.Report.getUrl({
    accountId,
    projectId: getRawProjectId(projectId),
    crawlId: getRawCrawlId(crawlId),
    reportTemplateCode: row.reportTemplate?.code ?? "all_pages",
    reportTypeCode: row.reportType?.code.toLowerCase() ?? "basic",
    segmentId: row.segment?.id,
    filter: isValidFilter
      ? { _or: [row.connectionFilter] }
      : row.connectionFilter,
  });

  return (
    <Link
      to={link}
      className={classes.container}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ExternalLinkSolid className={classes.icon} />
    </Link>
  );
}
