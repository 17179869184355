import { Chip } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { MetricsValuePresenterProps } from "../../../../../resource-detail/data/types";
import { getJsonList } from "../../../../../resource-detail/metrics-value-presenter/default-presenters/ArrayPresenter";
import clsx from "clsx";
import { isObject, isString } from "lodash";

const useStyles = makeStyles(() => ({
  chip: {
    display: "inline-block",
    height: "auto",
    "& > span": {
      display: "block",
      whiteSpace: "nowrap",
    },
  },
  block: {
    display: "block",
    width: "fit-content",
  },
  wrap: {
    "& > span": {
      whiteSpace: "break-spaces",
    },
  },
}));

export function ArrayInterpreter(
  props: MetricsValuePresenterProps & {
    isGridView?: boolean;
  },
): JSX.Element {
  const classes = useStyles();
  if (!Array.isArray(props.value) || !props.value.length) return <></>;

  return (
    <div
      style={{
        whiteSpace: props.isGridView ? "nowrap" : "normal",
        display: "grid",
        gap: 8,
      }}
    >
      <div style={{ height: 8 }} />
      {props.value.map((value, idx) => {
        const object = (() => {
          if (props.isGridView) return;
          if (isObject(value)) {
            return value;
          }
          try {
            return JSON.parse(value);
          } catch {}
        })();

        const isBlock =
          !props.isGridView &&
          (typeof object === "object" ||
            (isString(value) && value.length > 100));

        const shouldWrap = !props.isGridView;

        return (
          <Chip
            key={idx}
            label={object ? getJsonList(object) : value || "-"}
            color="cyan"
            className={clsx(classes.chip, {
              [classes.wrap]: shouldWrap,
              [classes.block]: isBlock,
            })}
          />
        );
      })}
      <div style={{ height: 8 }} />
    </div>
  );
}
