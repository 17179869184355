/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React from "react";
import { Theme, Typography } from "@material-ui/core";
import { Trans } from "react-i18next";
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { OrderDirection, ReportOrderField } from "../../../graphql";

function generateNonIndexablePagesChart(fixOrdering = false) {
  return function (t: TFunction<"charts">, theme: Theme): ChartConfigItem {
    return {
      visualisationFamily: "MultiSeries",
      visualisationType: VisualisationTypes.Bar,
      //visualisationColors: [theme.palette.grey[500]],
      title: () => t("nonIndexablePages.title"),
      description: () => (
        <Trans ns="charts" i18nKey="nonIndexablePages.description">
          <Typography paragraph variant="inherit">
            dfdfdf
          </Typography>
          <Typography variant="inherit">dfdfdf</Typography>
        </Trans>
      ),
      reports: () => ({
        orderBy: fixOrdering
          ? undefined
          : {
              field: ReportOrderField.TotalRows,
              direction: OrderDirection.Desc,
            },
        filter: {
          reportTemplateCode: fixOrdering
            ? [
                "non_indexable_pages",
                "mobile_alternates",
                "noindex_pages",
                "canonicalized_pages",
                "nofollowed_pages",
                "disallowed_pages",
                "pages_with_unavailable_after_expired",
              ]
            : [
                "mobile_alternates",
                "canonicalized_pages",
                "disallowed_pages",
                "nofollowed_pages",
                "noindex_pages",
                "non_indexable_pages",
                "pages_with_unavailable_after_expired",
              ],
        },
        fields: {
          reportTemplate: {
            code: true,
            name: true,
          },
          totalRows: true,
        },
      }),
      bindings: {
        name: (report) => {
          const reportTemplateCode = report.reportTemplate.code;
          return reportTemplateCode === "non_indexable_pages"
            ? t("non200Pages.total")
            : report.reportTemplate.name;
        },
        count: (report) => {
          return report.totalRows ?? 0;
        },
      },
      testAttributePrefix: "non-indexable-pages-chart",
    };
  };
}

export const getNonIndexablePagesChart = generateNonIndexablePagesChart();

export const getNonIndexablePagesChartWithFixedReportOrder =
  generateNonIndexablePagesChart(true);
