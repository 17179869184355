import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { generateSimpleMultiSeriesChartConfig } from "../utils/generateSimpleMultiSeriesChartConfig";
import { ModuleCode } from "../../../graphql";

export function getSiteSpeedMainthreadChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return generateSimpleMultiSeriesChartConfig({
    visualisationType: VisualisationTypes.Bar,
    title: t("siteSpeedMainthreadChart.title"),
    description: t("siteSpeedMainthreadChart.description"),
    supportedModules: [ModuleCode.SiteSpeed],
    reportTemplateCodes: [
      "mainthread_script_evaluation_items:wasted_secs_avg",
      "mainthread_other_items:wasted_secs_avg",
      "mainthread_style_layout_items:wasted_secs_avg",
      "mainthread_script_parsing_items:wasted_secs_avg",
      "mainthread_parse_html_css_items:wasted_secs_avg",
      "mainthread_rendering_items:wasted_secs_avg",
      "mainthread_garbage_collection_items:wasted_secs_avg",
    ],
  })();
}
