import React from "react";
import { useTranslation } from "@lumar/shared";
import { makeStyles, Typography } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { IndexInterpreter } from "../interpreters/IndexInterpreter";

import { FoundInSources } from "./sources/FoundInSources";
import { ReportGridColumn } from "../ReportGridColumns.types";

const useStyles = makeStyles((theme) => ({
  grid: {
    height: "100%",
    width: "100%",
    lineHeight: theme.spacing(2.5),
    alignContent: "center",
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(1.375),
    rowGap: theme.spacing(0.625),
  },
  label: {
    paddingLeft: theme.spacing(0.625),
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[500],
    textAlign: "end",
  },
  value: {
    color: theme.palette.grey[800],
  },
}));

export function CardCell(
  props: GridRenderCellParams & {
    cardMetrics: ReportGridColumn[];
    foundInSources: ReportGridColumn[];
    isGridView: boolean;
    containerWidth?: number;
    reportTemplateCode?: string;
  },
): JSX.Element {
  const { t } = useTranslation("report");
  const classes = useStyles();
  return (
    <div className={classes.grid}>
      {props.cardMetrics.map((metric) => (
        <React.Fragment key={metric.code}>
          <Typography className={classes.label}>{metric.name}</Typography>
          <IndexInterpreter
            {...props}
            {...metric}
            value={props.row[metric.code]}
            containerWidth={props.containerWidth}
            isCard
          />
        </React.Fragment>
      ))}
      {props.foundInSources.length > 0 && (
        <>
          <Typography className={classes.label}>{t("foundIn")}</Typography>
          <FoundInSources
            foundInSources={props.foundInSources}
            row={props.row}
          />
        </>
      )}
    </div>
  );
}
