/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getTitleAndDescriptionIssuesChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    //CEMM: ANLC-276 - PROD: potentially replace with multidimentional graph, where titles and descriptions issues are grouped into a bar show missing, short, OK and too long
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    title: () => t("titleAndDescriptionIssues.title"),
    description: () => t("titleAndDescriptionIssues.description"),
    reports: () => ({
      filter: {
        reportTemplateCode: [
          "missing_titles",
          "missing_descriptions",
          "short_titles",
          "short_descriptions",
          "max_title_length",
          "max_description_length",
          "no_descriptions_&_no_snippets",
        ],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        totalRows: true,
      },
    }),
    bindings: {
      name: (report) => report.reportTemplate.name,
      count: (report) => {
        return report.totalRows ?? 0;
      },
    },
  };
}
