import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import { GridCellValue } from "@mui/x-data-grid-pro";
import { Typography, ArrowNarrowRightSolid } from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  category: {
    textTransform: "capitalize",
  },
  icon: {
    paddingTop: theme.spacing(0.8),
    fontSize: theme.typography.pxToRem(16),
    margin: theme.spacing(0, 0.2, 0, 0.2),
  },
}));

export function TaskTooltipContent(props: {
  chain: string[];
  description: string;
  title: GridCellValue;
}): JSX.Element {
  const classes = useStyles();
  return (
    <>
      {props.chain.map((name, index) => {
        const isLast = props.chain.length === index + 1;
        return (
          <Typography
            variant="caption"
            key={index}
            className={classes.category}
          >
            {name}
            {!isLast && <ArrowNarrowRightSolid className={classes.icon} />}
          </Typography>
        );
      })}
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle3">{props.title}</Typography>
        <Typography variant="caption">{props.description}</Typography>
      </Box>
    </>
  );
}
