/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { OrderDirection, ReportOrderField } from "../../../graphql";

export function getNonIndexablePagesBySourceChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    //visualisationColors: [theme.palette.yellow[300]],
    title: () => t("nonIndexablePagesBySource.title"),
    description: () => t("nonIndexablePagesBySource.description"),
    reports: () => ({
      orderBy: {
        field: ReportOrderField.TotalRows,
        direction: OrderDirection.Desc,
      },
      filter: {
        reportTemplateCode: [
          "non_indexable_pages_with_visits",
          "non_indexable_pages_in_serp",
          "backlink_pages_non_indexable",
          "sitemaps_non_indexable_links",
          "non_indexable_pages_with_bot_hits",
        ],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        totalRows: true,
      },
    }),
    bindings: {
      name: (report) => report.reportTemplate.name,
      count: (report) => {
        return report.totalRows ?? 0;
      },
    },
  };
}
