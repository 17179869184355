import {
  QueryResult,
  useQuery,
  useSession,
  DateFormatter,
} from "@lumar/shared";
import { TFunction } from "i18next";

import { generateChartQuery, ChartQueryResponse } from "./generateChartQuery";
import { transformQueryResultData } from "./transformChartQueryResult";
import {
  ChartConfigItem,
  GenerateChartConfigItemFunction,
  GenerateTileChartConfigItemFunction,
  TileChartConfigItem,
} from "../types/ChartConfig";
import { Theme } from "@material-ui/core";
import { isString } from "formik";

interface UseGenerateChartQueryArgs {
  chartConfig: (ChartConfigItem | TileChartConfigItem) & { name: string };
  additionalConfig: {
    crawlId: string;
    includeComparedToCrawlId?: boolean;
    segmentId?: string;
    category?: string;
  };
  skip?: boolean;
}

export function useGenerateChartQuery(
  args: UseGenerateChartQueryArgs,
): QueryResult<ChartQueryResponse> {
  const { chartConfig, additionalConfig, skip } = args;

  const session = useSession();

  const { document, variables } = generateChartQuery(
    chartConfig,
    additionalConfig,
    session.isDeepCrawlAdminEnabled,
  );

  const { data, ...rest } = useQuery<ChartQueryResponse>(document, {
    errorPolicy: "all",
    fetchPolicy: "cache-first",
    context: {
      includeInBatch: true,
    },
    variables,
    skip,
  });

  return {
    data: data ? transformQueryResultData(data, chartConfig) : data,
    ...rest,
  };
}

export function getChartConfigName(
  configFn:
    | GenerateChartConfigItemFunction
    | GenerateTileChartConfigItemFunction,
  t: TFunction<"charts">,
  theme: Theme,
  formatter: DateFormatter,
): string {
  return configFn(
    // FIXME: This stuff works in runtime.
    // For some reason TS doesn't like identical arguments that come
    // from the same place. Needs figuring out why.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (key, options) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      t(key, { ...(isString(options) ? {} : options), lng: "en" }),
    theme,
    formatter,
  )
    .title([])
    .replaceAll(" ", "")
    .replaceAll("(", "")
    .replaceAll(")", "")
    .replaceAll("-", "")
    .replaceAll("+", "")
    .replaceAll("<", "")
    .replaceAll(">", "")
    .replaceAll("&", "");
}
