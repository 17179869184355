import React from "react";
import { ApolloError, getRawCrawlId } from "@lumar/shared";
import {
  ProjectTasksQuery,
  useCustomExtractionLabelsQuery,
  useProjectTasksQuery,
} from "../../graphql";
import { Task } from "./types";
import { formatTasksRows } from "./formatTaskRows";

interface ProjectTasksProps {
  projectId: string;
  crawlId: string;
}

interface ProjectTasksData {
  project: {
    primaryDomain: string;
    lastFinishedCrawl?: {
      id: string;
      finishedAt: Date;
    };
  };
  rows: Task[];
  error: ApolloError | undefined;
  loading: boolean;
}

export function useProjectTasksData({
  projectId,
  crawlId,
}: ProjectTasksProps): ProjectTasksData {
  const { data, loading, error } = useProjectTasks(projectId);

  const { data: customExtractionLabelsData } = useCustomExtractionLabelsQuery({
    variables: {
      crawlId,
    },
    fetchPolicy: "cache-first",
  });

  const { project, rows }: Pick<ProjectTasksData, "project" | "rows"> =
    React.useMemo(() => {
      const lastFinishedCrawl = data?.getProject?.lastFinishedCrawl;

      return {
        rows: formatTasksRows({
          tasks: data?.getProject?.legacyTasks.edges.map((x) => x.node),
          customExtractionLabelsData,
        }),
        project: {
          primaryDomain: data?.getProject?.primaryDomain || "",
          lastFinishedCrawl: lastFinishedCrawl
            ? {
                id: getRawCrawlId(lastFinishedCrawl.id),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                finishedAt: new Date(lastFinishedCrawl.finishedAt!),
              }
            : undefined,
        },
      };
    }, [data, customExtractionLabelsData]);

  return {
    project,
    rows,
    error,
    loading,
  };
}

function useProjectTasks(projectId: string): {
  data: ProjectTasksQuery | undefined;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const {
    data: projectTaskData,
    error,
    loading: queryLoading,
    fetchMore,
  } = useProjectTasksQuery({
    variables: { projectId },
    fetchPolicy: "cache-and-network",
  });

  const pageInfo = projectTaskData?.getProject?.legacyTasks?.pageInfo;
  React.useEffect(() => {
    if (!pageInfo?.hasNextPage) return;
    fetchMore({ variables: { projectId, cursor: pageInfo.endCursor } });
  }, [pageInfo, fetchMore, projectId]);

  // Note: The API does not allow to request all the task at once, to prevent
  // returning partial data, it returs only when all the pages has arrived - Csaba
  const dataRef = React.useRef<ProjectTasksQuery | undefined>(projectTaskData);
  if (!pageInfo?.hasNextPage) dataRef.current = projectTaskData;

  return {
    data: dataRef.current,
    loading: Boolean(queryLoading || pageInfo?.hasNextPage),
    error,
  };
}
