import { TileChartConfigItem } from "../types/ChartConfig";
import { TFunction } from "i18next";
import { ModuleCode } from "../../../graphql";

export function getSiteSpeedAvgTBTTileChart(
  t: TFunction<"charts">,
): TileChartConfigItem {
  return {
    supportedModules: [ModuleCode.SiteSpeed],
    title: () => t("siteSpeedTBTMetricTrend.tile_title"),
    description: () => t("siteSpeedTBTMetricTrend.description"),
    columnCount: null,
    reports: () => ({
      filter: {
        reportTemplateCode: "site_speed_pages:total_blocking_time_avg",
      },
      fields: {
        reportTrend: {
          basic: true,
          crawlId: true,
          createdAt: true,
        },
      },
    }),
  };
}
