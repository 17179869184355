import React from "react";
import { GridState } from "@mui/x-data-grid-pro";
import {
  BlueDataGrid,
  BlueDataGridProps,
  ChartPieSolid,
  CheckSquareOffset,
  useTranslation,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { ColumnSelector } from "../report/report-rows/report-grid/columns/column-selector/ColumnSelector";
import { ColumnSelectorButton } from "../report/report-rows/report-grid/rows/columns-selector-button/ColumnSelectorButton";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1.875),
  },
  row: {
    cursor: "pointer",
  },
}));

interface TasksGridProps extends BlueDataGridProps {
  defaultColumns: string[];
  setColumnState?: (gridState: GridState) => void;
}

export function TasksGrid({
  defaultColumns,
  setColumnState,
  components,
  componentsProps,
  ...props
}: TasksGridProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("taskManager");

  return (
    <div className={classes.root} data-testid="task-manager-table">
      <BlueDataGrid
        name="task"
        classes={{ row: classes.row }}
        autoRowHeight
        rowHeight={72}
        sortingOrder={["desc", "asc", null]}
        components={{
          ToolbarLeft: ColumnSelectorButton,
          ColumnsPanel: ColumnSelector,
          ...components,
        }}
        disableColumnsButton
        disableColumnSelector={false}
        componentsProps={{
          toolbar: {
            disabled: Boolean(props.error),
            ...componentsProps?.toolbar,
          },
          searchField: {
            placeholder: t("searchTasks"),
            ...componentsProps?.searchField,
          },
          columnsPanel: {
            defaultColumns,
            hideColumns: ["description"],
          },
          noRowsOverlay: {
            icon: CheckSquareOffset,
            title: t("noTasks.title"),
            detail: t("noTasks.explanation"),
            buttonIcon: ChartPieSolid,
            buttonText: t("noTasks.latestCrawlCta"),
            ...componentsProps?.noRowsOverlay,
          },
          noResultsOverlay: {
            title: t("noResult.title"),
            detail: t("noResult.explanation"),
          },
          errorOverlay: {
            title: t("error.title"),
            detail: t("error.explanation"),
            buttonText: t("error.reload"),
          },
        }}
        onColumnResize={(p, e, d) => setColumnState?.(d.api.state)}
        onColumnOrderChange={(p, e, d) => setColumnState?.(d.api.state)}
        onColumnVisibilityChange={(p, e, d) => setColumnState?.(d.api.state)}
        {...props}
      />
    </div>
  );
}
