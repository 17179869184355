import React from "react";
import { makeStyles } from "@material-ui/core";
import { GridCellValue, GridRenderCellParams } from "@mui/x-data-grid-pro";
import clsx from "clsx";

import { LegacyTaskPriority } from "../../graphql";
import { Task } from "../data/types";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
  },
  badge: {
    display: "inline-block",
    borderRadius: "4px",
    fontSize: "11px",
    width: "53px",
    lineHeight: "24px",
    background: theme.palette.grey[300],
  },
  medium: {
    background: theme.palette.yellow[400],
  },
  high: {
    background: theme.palette.orange[500],
    color: "white",
  },
  critical: {
    background: theme.palette.red[500],
    color: "white",
  },
}));

export function SeverityCell(params: GridRenderCellParams): JSX.Element {
  const classes = useStyles();
  const severity = params.row.priority as Task["priority"];
  const severityClass = getSeverityClass(classes, severity);
  return (
    <div style={{ width: params.colDef.width }} className={classes.container}>
      <span
        className={clsx(classes.badge, severityClass)}
        data-testid="tasks-severity-badge"
      >
        {severity}
      </span>
    </div>
  );
}

function getSeverityClass(
  classes: ReturnType<typeof useStyles>,
  severity: LegacyTaskPriority,
): string | undefined {
  switch (severity) {
    case LegacyTaskPriority.Medium:
      return classes.medium;
    case LegacyTaskPriority.High:
      return classes.high;
    case LegacyTaskPriority.Critical:
      return classes.critical;
  }
}

export function severitySort(a: GridCellValue, b: GridCellValue): number {
  const severityA = a as LegacyTaskPriority;
  const severityB = b as LegacyTaskPriority;
  return (
    Number(severityMap.get(severityA)) - Number(severityMap.get(severityB))
  );
}

const severityMap = new Map([
  [LegacyTaskPriority.Note, 0],
  [LegacyTaskPriority.Low, 1],
  [LegacyTaskPriority.Medium, 2],
  [LegacyTaskPriority.High, 3],
  [LegacyTaskPriority.Critical, 4],
]);
