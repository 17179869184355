import { ConnectionStringFilterInput } from "../../../graphql";
import { ReportTemplateCodeFilterInputForRequiredSource } from "../types/ChartConfigItemBase";

export function isReportTemplateCodeFilterInputForRequiredSource(
  value:
    | ConnectionStringFilterInput
    | ReportTemplateCodeFilterInputForRequiredSource,
): value is ReportTemplateCodeFilterInputForRequiredSource {
  return Boolean(value?.hasOwnProperty("requiredSource"));
}
