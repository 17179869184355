import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from "@material-ui/core";
import { createSvgIcon } from "../../../icons";
import { Chip } from "../../chip/Chip";
export function HealthScoreChange(props) {
    return (_jsx(Chip, Object.assign({ "data-testid": "health-score-change" }, useChipProps(props.changePercentage), { rounded: true, size: "small" })));
}
function useChipProps(change) {
    const theme = useTheme();
    // To prevent rounding down to 0, we're showing up to 2 decimal places instead.
    const displayPercentageChange = Math.abs(change * 100) < 0.5
        ? Math.abs(change * 100).toFixed(2)
        : Math.round(Math.abs(change * 100));
    if (change > 0) {
        return {
            label: `${displayPercentageChange}%`,
            color: "green",
            icon: (_jsx(ArrowUpIcon, { style: { color: theme.palette.green[500], marginLeft: 8 } })),
        };
    }
    if (change < 0) {
        return {
            label: `${displayPercentageChange}%`,
            color: "red",
            icon: (_jsx(ArrowDownIcon, { style: { color: theme.palette.red[500], marginLeft: 8 } })),
        };
    }
    return {
        label: "0%",
        color: "lightgrey",
        icon: (_jsx(NeutralIcon, { style: { color: theme.palette.grey[400], marginLeft: 8 } })),
    };
}
const ArrowDownIcon = createSvgIcon(_jsxs(_Fragment, { children: [_jsx("path", { d: "M6 2.375V10.625", stroke: "currentColor", fill: "none", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M2.625 7.25L6 10.625L9.375 7.25", stroke: "currentColor", fill: "none", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] }), "ArrowDownIcon", { viewBox: "0 0 13 13", fontSize: "inherit" });
const ArrowUpIcon = createSvgIcon(_jsxs(_Fragment, { children: [_jsx("path", { d: "M6 10.625V2.375", stroke: "currentColor", fill: "none", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M2.625 5.75L6 2.375L9.375 5.75", stroke: "currentColor", fill: "none", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] }), "ArrowUpIcon", { viewBox: "0 0 13 13", fontSize: "inherit" });
const NeutralIcon = createSvgIcon(_jsx("path", { d: "M1.79889 5.89898C1.63948 6.05839 1.54993 6.27459 1.54993 6.50002C1.54993 6.72546 1.63948 6.94166 1.79889 7.10107C1.95829 7.26047 2.17449 7.35003 2.39993 7.35003H9.59993C9.82536 7.35003 10.0416 7.26047 10.201 7.10107C10.3604 6.94166 10.4499 6.72546 10.4499 6.50002C10.4499 6.27459 10.3604 6.05839 10.201 5.89898C10.0416 5.73958 9.82536 5.65002 9.59993 5.65002H2.39993C2.17449 5.65002 1.95829 5.73958 1.79889 5.89898Z", stroke: "currentColor", fill: "currentColor", strokeWidth: "0.5" }), "NeutralIcon", { viewBox: "0 0 13 13", fontSize: "inherit" });
