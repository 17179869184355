import React from "react";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@lumar/shared";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Task } from "../data/types";

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.grey[500],
    lineHeight: "normal",
  },
}));

export function ProjectCell(params: GridRenderCellParams): JSX.Element {
  const classes = useStyles();
  const task = params.row as Task;

  return (
    <div>
      <Typography
        variant="captionSemiBold"
        component="div"
        className={classes.text}
      >
        {task.project?.name}
      </Typography>
      <Typography variant="caption" component="div" className={classes.text}>
        {task.project?.primaryDomain}
      </Typography>
    </div>
  );
}
