import { Typography, useNumberFormatter } from "@lumar/shared";
import { CrawlContextCrawlReport } from "../../CrawlContext";
import { ReportLink } from "./ReportLink";
import { useTheme } from "@material-ui/core";
import { ReportEntity } from "../../../report/Report.types";
import { ReportTypeCode } from "../../../graphql";

export function TotalRowsCell({
  report,
}: {
  report: CrawlContextCrawlReport;
}): JSX.Element {
  const formatNumber = useNumberFormatter();
  const theme = useTheme();

  const isCustomReport = report.id.startsWith("customReport");

  return (
    <ReportLink
      reportInput={
        isCustomReport
          ? {
              reportEntity: ReportEntity.CustomReport,
              customReportTemplateId: report.id.split("_")[1],
              crawlId: report.crawlId,
              reportTypeCode: ReportTypeCode.Basic,
              reportTemplateCode: report.reportTemplate.code,
            }
          : {
              reportTemplateCode: report.reportTemplate.code,
              reportEntity: ReportEntity.ReportStat,
              crawlId: report.crawlId,
              reportTypeCode: ReportTypeCode.Basic,
            }
      }
    >
      <Typography
        style={{
          color: report.totalRows
            ? (report.templateTotalSign ?? 0) === -1
              ? theme.palette.error.main
              : (report.templateTotalSign ?? 0) === 1
                ? theme.palette.success.main
                : theme.palette.text.primary
            : theme.palette.text.primary,
          fontWeight: 500,
        }}
      >
        {formatNumber(report.totalRows || 0)}
      </Typography>
    </ReportLink>
  );
}
