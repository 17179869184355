import React, { useEffect, useState } from "react";
import {
  FormControl,
  TextField,
  InputAdornment,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import { useDebounedFunction } from "../useDebounedFunction";

const useStyles = makeStyles({
  textField: {
    width: 290,
  },
  input: {
    height: 36,
  },
});

export interface SearchWithDebounceProps {
  className?: string;
  debounceInMs: number;
  placeholder?: string;
  value?: string;
  valueChangeCallbackFn: (value: string) => void;
  style?: React.CSSProperties;
}

export function SearchWithDebounce(
  props: SearchWithDebounceProps,
): JSX.Element {
  const {
    debounceInMs,
    value = "",
    valueChangeCallbackFn,
    placeholder,
  } = props;
  const classes = useStyles();
  const [searchValueShown, setSearchValueShown] = useState<string>(value);

  const { debounce, cancel } = useDebounedFunction(debounceInMs);
  function changeValue(value: string): void {
    setSearchValueShown(value);
    debounce(() => {
      valueChangeCallbackFn(value);
    });
  }

  function clearValue(): void {
    setSearchValueShown("");
    cancel();
    valueChangeCallbackFn("");
  }

  useEffect(() => {
    setSearchValueShown(value);
  }, [value]);

  return (
    <FormControl>
      <TextField
        value={searchValueShown}
        onChange={(e) => changeValue(e.target.value)}
        placeholder={placeholder}
        variant="outlined"
        className={classes.textField}
        style={props.style}
        InputProps={{
          className: classes.input,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          "data-testid": "search-with-debounce",
          startAdornment: (
            <InputAdornment position="start">
              <SearchRoundedIcon />
            </InputAdornment>
          ),
          endAdornment: searchValueShown ? (
            <InputAdornment position="start">
              <IconButton
                onClick={() => clearValue()}
                data-testid="search-with-debounce-x"
                size="small"
              >
                <ClearRoundedIcon />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
    </FormControl>
  );
}
