import React from "react";
import { makeStyles } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Chip } from "@lumar/shared";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    lineHeight: "20px",
    whiteSpace: "break-spaces",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export function AssignedToCell({ row }: GridRenderCellParams): JSX.Element {
  const classes = useStyles();

  const asignedTo = row.assignedTo as string[] | undefined;

  return (
    <span className={classes.container} data-testid="tasks-assigned-to">
      {asignedTo?.map((asignee, idx) => (
        <Chip
          key={idx}
          label={asignee}
          color="primary"
          className={classes.chip}
        />
      ))}
    </span>
  );
}
