/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getHttpsIssuesChart(t: TFunction<"charts">): ChartConfigItem {
  return {
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    //visualisationColors: [theme.palette.yellow[300]],
    title: () => t("httpsIssues.title"),
    description: () => t("httpsIssues.description"),
    reports: () => ({
      filter: {
        reportTemplateCode: [
          "http_scripts_on_https_pages",
          "links_between_protocols",
          "https_pages_without_hsts",
          "http_non_redirecting_to_https",
          "http_pages_with_clicks",
        ],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        totalRows: true,
      },
    }),
    bindings: {
      name: (report) => report.reportTemplate.name,
      count: (report) => {
        return report.totalRows ?? 0;
      },
    },
  };
}
