/* eslint-disable react/display-name */
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode, OrderDirection, ReportOrderField } from "../../../graphql";
import { TFunction } from "i18next";

export function getSiteSpeedIssuesTBT(t: TFunction<"charts">): ChartConfigItem {
  return {
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [ModuleCode.SiteSpeed],
    title: () => t("siteSpeedTBTIssues.title"),
    description: () => t("siteSpeedTBTIssues.description"),
    reports: () => ({
      orderBy: {
        field: ReportOrderField.TotalRows,
        direction: OrderDirection.Desc,
      },
      filter: {
        reportTemplateCode: [
          "bootup_time_failed_audits",
          "dom_size_failed_audits",
          "third_party_summary_failed_audits",
          "viewport_failed_audits",
          "legacy_javascript_failed_audits",
          "mainthread_work_breakdown_failed_audits",
          "third_party_facades_failed_audits",
          "duplicated_javascript_failed_audits",
          "long_tasks_failed_audits",
        ],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        totalRows: true,
      },
    }),
    bindings: {
      name: (report) => report.reportTemplate.name,
      count: (report) => {
        return report.totalRows ?? 0;
      },
    },
  };
}
