import {
  useTranslation,
  DoubleChartContainer,
  useSession,
} from "@lumar/shared";
import { TopErrorsButton, TopErrorsChart } from "../TopErrorsChart";
import { CurrentCategoryHealthScoreChart } from "./CurrentCategoryHealthScoreChart";
import { ChildCategoryHealthScoreChart } from "./ChildCategoryHealthScoreChart";
import { makeStyles } from "@material-ui/core";
import { useCrawlOverviewContext } from "../../../../CrawlOverviewContext";
import { HealthScoreExport } from "./HealthScoreExport";
import { ChartCategoryHealthScoreProvider } from "../../providers/ChartCategoryHealthScore";

/**
 * Renders all health score related charts if the account is allowed to.
 * @author Michal Pietraszko
 */
export function CurrentCategoryHealthScoreSection(): JSX.Element | null {
  const session = useSession();
  const { t } = useTranslation("crawlOverview");
  const { data, crawlContext } = useCrawlOverviewContext();

  if (
    !session.hasAddon("health-scores") ||
    !data?.selectedCategory?.hasHealthScore
  ) {
    return null;
  }

  function getDescription(category: string, categoryWeight?: number): string {
    if (categoryWeight !== undefined) {
      return `${t("categoryHealthScoreChartDescription", {
        category,
      })}\n\n${t("categoryHealthScoreChartDescriptionCategoryWeight", {
        categoryWeight,
      })}`;
    }

    return t("categoryHealthScoreChartDescription", {
      category,
    });
  }

  return (
    <>
      <ChartCategoryHealthScoreProvider>
        {({ trend }) => (
          <DoubleChartContainer
            leftColumn={{
              title: t("categoryHealthScoreChartTitle", {
                category: data.selectedCategory.name,
              }),
              subtitle: crawlContext.data?.selectedCrawlSegment?.segment.name,
              description: getDescription(
                data.selectedCategory.name,
                trend[trend.length - 1]?.categoryWeight,
              ),
              content: <CurrentCategoryHealthScoreChart />,
              header: <HealthScoreExport />,
            }}
            rightColumn={{
              title: t("categoryHealthScoreErrorsChartTitle", {
                category: data.selectedCategory.name,
              }),
              subtitle: crawlContext.data?.selectedCrawlSegment?.segment.name,
              description: t("categoryHealthScoreErrorsChartDescription", {
                category: data.selectedCategory.name,
              }),
              content: <TopErrorsChart />,
              header: <TopErrorsButton />,
            }}
          />
        )}
      </ChartCategoryHealthScoreProvider>
      <ChildCategoriesHealthScoreChartContainer />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 15,
    marginTop: "16px",
    width: "100%",
    justifyContent: "center",
  },
  item: {
    display: "block",
    flexGrow: 1,
    flexBasis: 0,
    textDecoration: "none",
    color: "inherit",
    background: theme.palette.background.paper,
    borderRadius: 8,
    padding: 8,
    height: 169,
    minWidth: 210,
    [theme.breakpoints.down("md")]: {
      minWidth: 292,
      maxWidth: "100%",
    },
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    // Note: Using "overflow: hidden" to prevent the chart from taking as much width as possible.
    // https://github.com/highcharts/highcharts/issues/6427#issuecomment-953025663
    // - Michal
    overflow: "hidden",
  },
}));

function ChildCategoriesHealthScoreChartContainer(): JSX.Element | null {
  const classes = useStyles();
  const {
    data: crawlOverviewData,
    crawlContext: { data: crawlContextData, helpers },
  } = useCrawlOverviewContext();

  const selectedCategory = crawlOverviewData?.selectedCategory;

  if (!selectedCategory || !crawlContextData) {
    return null;
  }

  const selectedCategoryTreeNode = helpers?.getCrawlReportCategoryTreeNode(
    selectedCategory.code,
  );

  if (
    !selectedCategoryTreeNode ||
    selectedCategoryTreeNode.nodes.length === 0
  ) {
    return null;
  }

  return (
    <div className={classes.container}>
      {selectedCategoryTreeNode.nodes.map((node) => {
        return (
          <div className={classes.item} key={node.code}>
            <ChildCategoryHealthScoreChart reportCategoryCode={node.code} />
          </div>
        );
      })}
    </div>
  );
}
