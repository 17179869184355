/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import {
  OrderDirection,
  ReportOrderField,
  ReportTrendItem,
} from "../../../graphql";
import { Routes } from "../../routing/routes";

import { ChartPanelMessageWrapper } from "../components/chart-messages/ChartPanelMessageWrapper";
import { OverviewType } from "../../../crawl-overview/types";
import { ChartPanelSuccessMessage } from "../components/chart-messages/ChartPanelSuccessMessage";
import { isNumber } from "lodash";

export function getIssuesChartIndexation(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    visualisationFamily: "Sparkline",
    visualisationType: VisualisationTypes.SparklineList,
    title: () => t("issues.title"),
    description: () => t("issues.description"),
    moreLink: {
      title: (reportCount) => t("issues.viewAll", { count: reportCount }),
      href: ({ accountId, projectId, crawlId, segmentId, category }) =>
        Routes.CrawlOverview.getUrl({
          accountId,
          projectId,
          crawlId,
          segmentId,
          type: OverviewType.Errors,
          category,
        }),
    },
    reports: () => ({
      includeOnlyCurrentlyViewedCategoryReports: true,
      orderBy: {
        field: ReportOrderField.TotalWeight,
        direction: OrderDirection.Asc,
      },
      reportTemplateFilter: {
        totalSign: { lt: 0 },
        categoriesRaw: {
          contains: "indexation",
        },
      },
      filter: {
        totalWeight: {
          lt: 0,
        },
      },
      fields: {
        reportTemplateCode: true,
        totalRows: true,
        change: true,
        reportTrend: {
          crawlId: true,
          basic: true,
          createdAt: true,
        },
      },
    }),
    noReportsTemplate: (
      <ChartPanelMessageWrapper>
        <ChartPanelSuccessMessage>
          {t("issues.noIssuesFound")}
        </ChartPanelSuccessMessage>
      </ChartPanelMessageWrapper>
    ),
    bindings: {
      count: (report) => {
        return report.totalRows ?? 0;
      },
      sign: () => -1,
      change: (report) => {
        if (isNumber(report?.change)) return report.change;
        if (report.reportTrend) {
          return report.reportTrend[0]?.basic && report.reportTrend[1]?.basic
            ? report.reportTrend[0].basic - report.reportTrend[1].basic
            : 0;
        }
        return 0;
      },
      trendValues: (report) =>
        (report.reportTrend ?? [])
          .filter(
            (
              trend,
            ): trend is ReportTrendItem & {
              createdAt: string;
            } => !!trend?.createdAt,
          )
          .map((report) => {
            const totalRows = report?.basic ?? 0;
            const createdAt = report?.createdAt;
            return { y: totalRows || 0, x: new Date(createdAt) };
          }),
    },
    showChangePercentage: true,
    testAttributePrefix: "top-issues-chart",
  };
}
