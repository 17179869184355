import React from "react";
import { useChartReportColorGetter } from "../chart-colors/useChartReportColors";
import { VisualisationTypes } from "../types/ChartConfig";
import { MultiDimensionalSeries } from "./ChartConfigItemMultiDimensionalSeries";

interface PointWithUrl extends Highcharts.Point {
  url?: string;
}

interface DataPoint {
  x: number;
  y: number;
  url?: string;
}

export function useSeriesData(
  series: MultiDimensionalSeries,
  visualisationType: VisualisationTypes,
  shouldApplyReportColor: boolean,
  visualisationFillColors?: string[],
): Highcharts.SeriesOptionsType[] {
  const getReportColor = useChartReportColorGetter();
  return React.useMemo(
    () =>
      createSeriesData(
        series,
        visualisationType,
        getReportColor,
        shouldApplyReportColor,
        visualisationFillColors,
      ),
    [
      series,
      visualisationType,
      getReportColor,
      shouldApplyReportColor,
      visualisationFillColors,
    ],
  );
}

function createSeriesData(
  series: MultiDimensionalSeries,
  visualisationType: VisualisationTypes,
  getReportColor: (
    reportTemplateCode?: string | undefined,
  ) => string | undefined,
  shouldApplyReportColor: boolean,
  visualisationFillColors?: string[],
): Highcharts.SeriesOptionsType[] {
  return series.map((serie, index) => {
    const mappedData = serie.data.map((dataPoint) =>
      dataPoint.x instanceof Date
        ? { ...dataPoint, x: dataPoint.x.getTime() }
        : (dataPoint as DataPoint),
    );

    // Note: Highcharts requires us to sort the x values in ascending order
    // eslint-disable-next-line fp/no-mutating-methods
    const data = [...mappedData].sort((a, b) => a.x - b.x);

    const colorSettings = shouldApplyReportColor
      ? { color: getReportColor(serie.reportTemplateCode) }
      : { fillColor: visualisationFillColors?.[index] };

    return {
      ...serie,
      ...colorSettings,
      data,
      type: visualisationType === VisualisationTypes.Line ? "line" : "area",
      marker: {
        symbol: "circle",
        radius: 3,
        enabled: true,
      },
    };
  });
}

export function isPointWithUrl(point: Highcharts.Point): point is PointWithUrl {
  return "url" in point;
}

interface PointWithAdditionalTooltipLines extends Highcharts.Point {
  additionalTooltipLines: string[];
}

export function doesPointContainAdditionalTooltipLines(
  value: Highcharts.Point,
): value is PointWithAdditionalTooltipLines {
  const point = value as Highcharts.Point &
    Partial<Record<"additionalTooltipLines", unknown>>;

  return (
    Array.isArray(point.additionalTooltipLines) &&
    point.additionalTooltipLines.every((line) => typeof line === "string")
  );
}

export function mapThresholdsToYAxisPlotlines(
  thresholds: { value: number; color: string; textColor: string }[],
): Highcharts.YAxisPlotLinesOptions[] {
  // eslint-disable-next-line fp/no-mutating-methods
  const sortedThresholds = [...thresholds].sort((a, b) => a.value - b.value);

  if (thresholds.length === 3) {
    return [
      { ...sortedThresholds[1], color: sortedThresholds[0].color },
      sortedThresholds[2],
    ].map((threshold) => {
      return {
        zIndex: 2,
        width: 2,
        dashStyle: "Dash" as const,
        color: threshold.color,
        value: threshold.value,
      };
    });
  }

  return sortedThresholds
    .filter((t) => t.value > 0)
    .map((threshold) => {
      return {
        zIndex: 2,
        width: 2,
        dashStyle: "Dash" as const,
        color: threshold.color,
        value: threshold.value,
      };
    });
}

export function getDataPointThreshold(
  value: number,
  thresholds: { value: number; color: string; textColor: string }[],
): { value: number; color: string; textColor: string } | undefined {
  // eslint-disable-next-line fp/no-mutating-methods
  return [...thresholds]
    .sort((a, b) => b.value - a.value)
    .find((t) => t.value <= value);
}
