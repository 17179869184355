import { ApolloError, isNonEmpty } from "@lumar/shared";
import React from "react";
import {
  LegacyTask,
  useGenerateDeliveryTicketMutation,
  useGetTaskDeliveryTicketQuery,
} from "../../graphql";

export function useGeneratedDeliveryTicket(taskId: string): {
  loading: boolean;
  error?: string;
  task?: Pick<
    LegacyTask,
    | "id"
    | "ticketDetails"
    | "ticketGenerationFinishedAt"
    | "ticketGenerationRequestedAt"
  >;
} {
  const { data, loading, error, startPolling, stopPolling } =
    useGetTaskDeliveryTicketQuery({
      variables: {
        id: taskId,
      },
      onError: () => {
        stopPolling();
      },
    });

  const [generateDeliveryTicket, { error: createError }] =
    useGenerateDeliveryTicketMutation({
      variables: {
        id: taskId,
      },
      refetchQueries: ["GetTaskDeliveryTicket"],
      awaitRefetchQueries: true,
    });

  const errorMessage = getErrorMessage(error ?? createError);

  const task = isNonEmpty(data?.node) ? data?.node ?? undefined : undefined;

  const generating =
    task !== undefined &&
    !!task.ticketGenerationRequestedAt &&
    !task.ticketGenerationFinishedAt;

  const shouldGenerate =
    task !== undefined && !task.ticketDetails && !generating;

  React.useEffect(() => {
    if (!shouldGenerate) return;

    generateDeliveryTicket();
  }, [shouldGenerate, generateDeliveryTicket]);

  React.useEffect(() => {
    if (!generating || error) return;

    startPolling(1000);
    return () => stopPolling();
  }, [error, generating, loading, startPolling, stopPolling]);

  return {
    loading: !errorMessage && (loading || !task?.ticketDetails),
    error: errorMessage,
    task,
  };
}

function getErrorMessage(error?: ApolloError): string | undefined {
  if (error?.graphQLErrors[0]) return error.graphQLErrors[0].message;
  if (error?.clientErrors?.[0]) return error.clientErrors[0].message;
  if (error?.protocolErrors?.[0]) return error.protocolErrors[0].message;
  if (error?.networkError) return error.networkError.message;
}
