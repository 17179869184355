import { LargeHealthScoreChart, useTranslation } from "@lumar/shared";
import { Routes } from "../../../../../_common/routing/routes";
import { useCrawlContextData } from "../../../../CrawlContext";
import { useChartCategoryHealthScore } from "../../providers/ChartCategoryHealthScore";
import { CircularProgress } from "@material-ui/core";
import { ChartPanelErrorMessage } from "../../../../../_common/charts/components/chart-messages/ChartPanelErrorMessage";

export function CurrentCategoryHealthScoreChart(): JSX.Element {
  const { t } = useTranslation(["charts", "crawlOverview"]);

  const {
    loading,
    error,
    healthScore,
    healthScoreChangePercentage,
    trend,
    industry,
    category,
  } = useChartCategoryHealthScore();

  const data = useCrawlContextData();

  if (loading) {
    return (
      <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
        <div style={{ margin: "auto" }}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
        <ChartPanelErrorMessage>
          {t("charts:errors.queryError")}
        </ChartPanelErrorMessage>
      </div>
    );
  }

  return (
    <LargeHealthScoreChart
      healthScore={healthScore}
      healthScoreTrend={trend}
      healthScoreChangePercentage={healthScoreChangePercentage}
      industryBenchmark={industry?.healthScoreBenchmark}
      industryCode={industry?.code}
      categoryName={category.name || category.code}
      getIndustryBenchmarkLegendText={(name) =>
        t("crawlOverview:healthScoreIndustryLegend", { name })
      }
      getScoreCategoryText={(category) =>
        t("crawlOverview:healthScoreNameInTrend", { category })
      }
      getTooltipScoreText={(score) =>
        t("crawlOverview:healthScoreOutOf100", { score })
      }
      getTrendDataPointUrl={(t) =>
        Routes.CrawlOverview.getUrl({
          accountId: data.crawlProject.account.rawID,
          projectId: data.crawlProject.rawID,
          crawlId: String(t.crawlId),
          category: category.code,
          segmentId: data?.selectedCrawlSegment?.segment.id,
        })
      }
    />
  );
}
