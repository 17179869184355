import { useParams } from "react-router-dom";
import { TileChartPanelTitle } from "./TileChartPanelTitle";
import { TileChartConfigItem } from "../../../../../_common/charts/types/ChartConfig";
import { useChartDataContext } from "../../../../../_common/charts/components/chart-components/ChartDataContext";
import { SmallColumnChart } from "../../../../../_common/charts/small-column-chart/SmallColumnChart";
import { ChartPanelErrorMessage } from "../../../../../_common/charts/components/chart-messages/ChartPanelErrorMessage";
import { useTranslation } from "@lumar/shared";

export function TileChartComponent(props: TileChartConfigItem): JSX.Element {
  const { t } = useTranslation("charts");
  const { accountId, projectId, crawlId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
  }>();

  const { reports, error } = useChartDataContext();

  if (error) {
    return (
      <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
        <ChartPanelErrorMessage>
          {t("errors.queryError")}
        </ChartPanelErrorMessage>
      </div>
    );
  }

  return (
    <>
      <TileChartPanelTitle
        title={props.title(reports)}
        description={props.description?.(
          { accountId, crawlId, projectId },
          reports,
        )}
      />
      <SmallColumnChart {...props} />
    </>
  );
}
