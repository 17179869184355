/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getLinksBreakdownChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    hideChartWithoutData: true,
    title: () => t("linksBreakdown.title"),
    description: () => t("linksBreakdown.description"),
    reports: () => ({
      filter: {
        reportTemplateCode: [
          "unique_internal_links",
          "all_hreflang_links",
          "all_rel_links", // Is Canonical Links
          "mobile_links",
          "rel_links",
          "pagination_links",
        ],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        totalRows: true,
      },
    }),
    showPercentageInSeriesTooltip: true,
    bindings: {
      name: (report) => {
        return report.reportTemplate.name;
      },
      count: (report) => {
        return report.totalRows ?? 0;
      },
    },
  };
}
