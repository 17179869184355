import { ApolloError, isNonEmpty } from "@lumar/shared";
import {
  GetTaskForEditQuery,
  LegacyTask,
  LegacyTaskPriority,
  ReportTypeCode,
  useCreateReportTaskMutation,
  useGetTaskForEditQuery,
  useUpdateTaskMutation,
} from "../../graphql";
import { ConnectionFilter } from "../../_common/connection-filtering/types";

export interface FormValues {
  resolved: boolean;
  priority: LegacyTaskPriority;
  deadline: Date | null;
  title: string;
  description: string | undefined;
  assigneeEmail: string[];
  howToFix: string | undefined;
}

export interface EditTaskFormValuesProps {
  taskId: string;
  onLoadError?: (error: ApolloError) => void;
  onSaveError?: (error: ApolloError) => void;
}

interface EditTaskFormValuesResult {
  values: FormValues;
  loading: boolean;
  loaded: boolean;
  updateTask: (values: FormValues) => Promise<boolean>;
}

export function useEditTaskFormValues({
  taskId,
  onLoadError,
  onSaveError,
}: EditTaskFormValuesProps): EditTaskFormValuesResult {
  const { data, loading } = useGetTaskForEditQuery({
    variables: {
      legacyTaskId: taskId,
    },
    onError: onLoadError,
  });

  const [updateTask] = useUpdateTaskMutation({
    onError: onSaveError,
  });

  return {
    values: getFormValues(data),
    loading,
    loaded: Boolean(data),
    updateTask: async (values: FormValues) => {
      const variables = getMutationData(
        values,
        isNonEmpty(data?.node) ? data?.node?.fixedAt : undefined,
      );

      const result = await updateTask({
        variables: {
          input: {
            legacyTaskId: taskId,
            ...variables,
          },
        },
        optimisticResponse: {
          updateLegacyTask: {
            legacyTask: {
              id: taskId,
              title: variables.title,
              description: variables.description,
              deadlineAt: variables.deadlineAt,
              fixedAt: variables.fixedAt,
              priority: values.priority,
              howToFix: values.howToFix,
            },
          },
        },
        refetchQueries: ["AccountTasks", "UnresolvedProjectTasksCountForChip"],
        awaitRefetchQueries: true,
      });
      return Boolean(result);
    },
  };
}

interface CreateTaskFormValuesProps {
  refetchQueries?: string[];
  onSaveError?: (error: ApolloError) => void;
}

interface CreateTaskFormValuesResult {
  values: FormValues;
  createReportTask: (
    values: FormValues,
    crawlId: string,
    reportTemplateCode: string,
    reportTypeCode: ReportTypeCode,
    segmentId: string | undefined,
    identified: number,
    filters?: ConnectionFilter,
  ) => Promise<boolean>;
}

export function useCreateTaskFormValues({
  refetchQueries,
  onSaveError,
}: CreateTaskFormValuesProps): CreateTaskFormValuesResult {
  const [createReportTask] = useCreateReportTaskMutation({
    onError: onSaveError,
    ...(refetchQueries
      ? {
          refetchQueries: refetchQueries,
          awaitRefetchQueries: true,
        }
      : {}),
  });

  return {
    values: getFormValues(undefined),
    createReportTask: async (
      values: FormValues,
      crawlId: string,
      reportTemplateCode: string,
      reportTypeCode: ReportTypeCode,
      segmentId: string | undefined,
      identified: number,
      filters?: ConnectionFilter,
    ) => {
      const variables = getMutationData(values);
      const result = await createReportTask({
        variables: {
          input: {
            crawlId,
            reportTemplateCode,
            reportTypeCode,
            segmentId,
            identified,
            filters, // FIXME: Doesn't accept gql filters. Expects Ruby Filters
            ...variables,
          },
        },
      });
      return Boolean(result.data);
    },
  };
}

function getFormValues(data: GetTaskForEditQuery | undefined): FormValues {
  if (!data?.node || !isNonEmpty(data?.node))
    return {
      resolved: false,
      priority: LegacyTaskPriority.Note,
      deadline: null,
      title: "",
      description: undefined,
      assigneeEmail: [],
      howToFix: undefined,
    };

  const task = data.node;
  return {
    resolved: Boolean(task.fixedAt),
    priority: task.priority,
    deadline: task.deadlineAt ? new Date(task.deadlineAt) : null,
    title: task.title,
    description: task.description ?? undefined,
    assigneeEmail: task.assignedTo ?? [],
    howToFix: task.howToFix ?? "",
  };
}

function getMutationData(
  values: FormValues,
  previousFixedAt?: LegacyTask["fixedAt"],
): Pick<
  LegacyTask,
  | "fixedAt"
  | "priority"
  | "deadlineAt"
  | "title"
  | "description"
  | "assignedTo"
  | "howToFix"
> {
  return {
    fixedAt: values.resolved
      ? previousFixedAt ?? new Date().toISOString()
      : null,
    priority: values.priority,
    deadlineAt: values.deadline ? values.deadline?.toISOString() : null,
    title: values.title,
    description: values.description,
    assignedTo: values.assigneeEmail,
    howToFix: values.howToFix,
  };
}
