import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  ChartConfig,
  TileChartConfigItem,
} from "../../../_common/charts/types/ChartConfig";
import { getChartConfigName } from "../../../_common/charts/utils/useGenerateChartQuery";
import { useDateFormatter } from "@lumar/shared";
import { newCategoriesChartsMap } from "./new/newCategoriesChartsMap";
import { useCrawlOverviewContextData } from "../../CrawlOverviewContext";
import { ModuleCode } from "../../../graphql";
import { PredefinedChartConfig, isPredefinedChartConfig } from "./types";

export function useReportCategoryChartConfig(): {
  tileCharts: (TileChartConfigItem & { name: string })[];
  mainCharts: (ChartConfig | PredefinedChartConfig)[];
} {
  const { t } = useTranslation("charts");
  const theme = useTheme();
  const dateFormatter = useDateFormatter();

  const { selectedCategory, moduleCode } = useCrawlOverviewContextData();

  const predefinedCharts = newCategoriesChartsMap.get(selectedCategory.code);

  if (!predefinedCharts) return { tileCharts: [], mainCharts: [] };

  function moduleFilter(chart: { supportedModules?: ModuleCode[] }): boolean {
    return (chart.supportedModules || [ModuleCode.Seo]).includes(moduleCode);
  }

  return {
    tileCharts: (predefinedCharts.tileCharts || [])
      .map((configItem) => ({
        ...configItem(t, theme, dateFormatter),
        name: getChartConfigName(configItem, t, theme, dateFormatter),
      }))
      .filter(moduleFilter),
    mainCharts: predefinedCharts.mainCharts
      .map((configItem) =>
        isPredefinedChartConfig(configItem)
          ? {
              ...configItem,
              supportedModules:
                configItem.supportedModules || Object.values(ModuleCode),
            }
          : {
              ...configItem(t, theme, dateFormatter),
              name: getChartConfigName(configItem, t, theme, dateFormatter),
            },
      )
      .filter(moduleFilter),
  };
}
