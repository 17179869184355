import { OverviewType } from "../types";
import {
  BlueDataGrid,
  EmptyState,
  Package,
  useTranslation,
} from "@lumar/shared";
import {
  CrawlContextCrawlReport,
  CrawlReportCategoryListNode,
  useCrawlContextData,
  useCrawlContextHelpers,
} from "../CrawlContext";
import { useCrawlOverviewContextData } from "../CrawlOverviewContext";
import React from "react";
import { SearchWithDebounce } from "../../_common/searchWithDebounce/SearchWithDebounce";
import { useReportsTableColumns } from "./useReportsTableColumns";
import { useParams } from "react-router-dom";
import { ColumnSelectorButton } from "../../report/report-rows/report-grid/rows/columns-selector-button/ColumnSelectorButton";
import { ColumnSelector } from "../../report/report-rows/report-grid/columns/column-selector/ColumnSelector";
import { Paper, makeStyles } from "@material-ui/core";
import { Routes } from "../../_common/routing/routes";
import { useDebounedFunction } from "../../_common/useDebounedFunction";
import { useURLSearchParams } from "../../_common/routing/useURLSearchParams";
import { ReportEntity } from "../../report/Report.types";

type ReportTableProps = {
  reportEntity: ReportEntity;
  dataGridProps?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    noRowsOverlay?: any;
  };
};

export function ReportsTable(props: ReportTableProps): JSX.Element {
  const [searchTerm, setSearchTerm] = React.useState("");
  const { accountId, projectId, crawlId } = useParams<{
    accountId: string;
    projectId: string;
    crawlId: string;
  }>();
  const { t } = useTranslation("crawlOverview");
  const classes = useStyles();
  const params = useURLSearchParams();

  const { reports, totalCount, type } = useCategoryReports({
    reportEntity: props.reportEntity,
    searchTerm,
  });

  const {
    loading,
    error,
    crawlArchived,
    reports: reportsWithCustomValues,
    columns,
    defaultColumns,
    defaultOrderBy,
    saveColumnsState,
  } = useReportsTableColumns({ crawlId, type, reports });

  React.useEffect(() => {
    const isOrderedByColumnVisible = Boolean(
      columns.find((x) => x.field === defaultOrderBy?.field),
    );
    if (params.get("sorting") || !defaultOrderBy || !isOrderedByColumnVisible) {
      return;
    }

    params.set("sorting", btoa(JSON.stringify([defaultOrderBy])));
    params.apply();
  }, [columns, defaultOrderBy, params]);

  const { debounce } = useDebounedFunction(300);

  if (crawlArchived) {
    return (
      <Paper
        style={{
          height: 400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <EmptyState
          icon={<Package fontSize="large" className={classes.archiedIcon} />}
          title={t("reportsDataArchived")}
          actions={[
            {
              type: "internalLink",
              to: Routes.Crawls.getUrl({
                accountId,
                projectId,
                tab: "history",
                unarchive: crawlId,
              }),
              title: t("reportsDataUnarchive"),
            },
          ]}
        />
      </Paper>
    );
  }

  return (
    <BlueDataGrid
      name="reports-table"
      columns={columns}
      rows={loading ? [] : reportsWithCustomValues}
      totalRowCount={loading ? 0 : totalCount}
      loading={loading}
      error={error}
      components={{
        ToolbarLeft: [SearchWithDebounce, ColumnSelectorButton],
        ColumnsPanel: ColumnSelector,
      }}
      componentsProps={{
        toolbar: {
          toolbarLeft: {
            debounceInMs: 200,
            placeholder: "Search reports",
            value: searchTerm,
            valueChangeCallbackFn: setSearchTerm,
          },
        },
        columnsPanel: {
          defaultColumns,
        },
        noRowsOverlay: props.dataGridProps?.noRowsOverlay,
      }}
      sticky
      disableColumnsButton
      disableColumnSelector={false}
      enableExport
      sortingOrder={["desc", "asc", null]}
      pageSize={100}
      onColumnResize={(p, e, d) =>
        debounce(() => saveColumnsState(d.api.state))
      }
      onColumnOrderChange={(p, e, d) =>
        debounce(() => saveColumnsState(d.api.state))
      }
      onColumnVisibilityChange={(p, e, d) =>
        debounce(() => saveColumnsState(d.api.state))
      }
      className={classes.grid}
    />
  );
}

function useCategoryReports({
  searchTerm,
  reportEntity,
}: {
  reportEntity: ReportEntity;
  searchTerm?: string;
}): {
  reports: CrawlContextCrawlReport[];
  totalCount: number;
  type: OverviewType;
} {
  const { crawlReportCategoriesList } = useCrawlContextData();
  const overviewData = useCrawlOverviewContextData();
  const {
    getCrawlReportCategoryReportsList,
    getCrawlReportCategoryErrorReportsList,
    getCrawlCustomReportsList,
    getCrawlErrorCustomReportsList,
  } = useCrawlContextHelpers();

  const category = overviewData.selectedCategory.code;
  const type = overviewData.selectedOverviewType;

  return React.useMemo(() => {
    const reports = (() => {
      switch (reportEntity) {
        case ReportEntity.CustomReport:
          return type === OverviewType.All
            ? getCrawlCustomReportsList()
            : type === OverviewType.Errors
              ? getCrawlErrorCustomReportsList()
              : undefined;

        default:
          return type === OverviewType.All
            ? getCrawlReportCategoryReportsList(category)
            : type === OverviewType.Errors
              ? getCrawlReportCategoryErrorReportsList(category)
              : undefined;
      }
    })();

    const filteredReports = (() => {
      switch (reportEntity) {
        case ReportEntity.CustomReport:
          return type === OverviewType.All
            ? getCrawlCustomReportsList(searchTerm)
            : type === OverviewType.Errors
              ? getCrawlErrorCustomReportsList(searchTerm)
              : undefined;

        default:
          return type === OverviewType.All
            ? getCrawlReportCategoryReportsList(category, searchTerm)
            : type === OverviewType.Errors
              ? getCrawlReportCategoryErrorReportsList(category, searchTerm)
              : undefined;
      }
    })();

    return {
      reports:
        filteredReports?.map((report) => ({
          ...report,
          parentCategory: getReportParentCategory(
            crawlReportCategoriesList,
            report,
          ),
        })) || [],
      totalCount: reports?.length ?? 0,
      type,
    };
  }, [
    category,
    crawlReportCategoriesList,
    getCrawlReportCategoryReportsList,
    getCrawlReportCategoryErrorReportsList,
    searchTerm,
    type,
    getCrawlCustomReportsList,
    getCrawlErrorCustomReportsList,
    reportEntity,
  ]);
}

export function getReportParentCategory(
  categories: CrawlReportCategoryListNode[],
  report: CrawlContextCrawlReport,
): CrawlReportCategoryListNode | undefined {
  const category = categories.find(
    (x) => x.code === report.reportTemplate.primaryReportCategoryCode,
  );
  if (!category?.parentCode) return category;

  const parentCategory = categories.find(
    (x) => x.code === category?.parentCode,
  );
  const parentIsTopCategory = parentCategory && !parentCategory.parentCode;
  if (parentIsTopCategory) return category;

  return parentCategory;
}

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: 15,
    color: theme.palette.yellow[900],
    backgroundColor: theme.palette.yellow[100],
    alignItems: "center",
  },
  archiedIcon: { color: theme.palette.yellow[500] },
  grid: {
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      padding: "0px 0px 0px 6px",
    },
  },
}));
