import { Snackbar, useSession, useTranslation } from "@lumar/shared";
import { useSnackbar } from "notistack";
import React from "react";
import { useParams } from "react-router-dom";

import { createAdminFilter } from "../../_common/api/createAdminFilter";
import { findCustomExtractionLabel } from "../../_common/custom-extraction-labels/findCustomExtractionLabel";
import { isCustomExtractionReportTemplateCode } from "../../_common/custom-extraction-labels/isCustomExtractionReportTemplateCode";
import { useCrawlContext } from "../../crawl-overview/CrawlContext";
import {
  DatasourceCode,
  ReportTypeCode,
  useCustomExtractionLabelsQuery,
  useReportPageDataQuery,
} from "../../graphql";
import { getReportCodes } from "../helpers/getReportCodes";
import { getReportInfo } from "./getReportInfo";
import { ReportPageData } from "./types";
import { useReportBreadcrumbs } from "./useReportBreadcrumbs";

export function useReportPageData(): ReportPageData {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("report");

  const { isDeepCrawlAdminEnabled } = useSession();

  const { accountId, projectId, crawlId, reportTemplateCodeWithTypeCode } =
    useParams<{
      accountId: string;
      projectId: string;
      crawlId: string;
      reportTemplateCodeWithTypeCode: string;
    }>();

  const { reportTemplateCode, reportTypeCode } = getReportCodes(
    reportTemplateCodeWithTypeCode,
  );
  const isCustomExtractionReport =
    isCustomExtractionReportTemplateCode(reportTemplateCode);

  const { data: crawlContextData, loading: crawlContextLoading } =
    useCrawlContext();

  const selectedSegment = crawlContextData?.selectedCrawlSegment;
  const lastFinishedCrawlId =
    crawlContextData?.crawlProject.lastFinishedCrawl?.rawID;

  const {
    loading: customExtractionLabelsLoading,
    data: customExtractionLabelsData,
  } = useCustomExtractionLabelsQuery({
    variables: { crawlId },
    fetchPolicy: "cache-first",
    skip: !isCustomExtractionReport,
    onError: (error) =>
      enqueueSnackbar(<Snackbar variant="error" title={error.message} />),
  });

  const customExtractionLabel = findCustomExtractionLabel(
    customExtractionLabelsData,
    reportTemplateCode,
  );

  const { data, loading } = useReportPageDataQuery({
    variables: {
      crawlId,
      reportsByCodeInput: {
        reportTemplateCodes: [reportTemplateCode],
        reportTypeCodes: Object.values(ReportTypeCode),
        segmentId: selectedSegment?.segment.id || null,
      },
      templateFilter: {
        code: { eq: reportTemplateCode },
        ...createAdminFilter(isDeepCrawlAdminEnabled),
      },
    },
    fetchPolicy: "cache-first",
    skip: crawlContextLoading,
    onError: (error) =>
      enqueueSnackbar(<Snackbar variant="error" title={error.message} />),
  });

  const reportsCount = data?.getCrawl?.reportsByCode?.length ?? 0;
  const report = data?.getCrawl?.reportsByCode.find(
    (x) => x.reportTypeCodeEnum.toLowerCase() === reportTypeCode,
  );
  const reportTemplate = data?.reportTemplates.edges[0]?.node;
  const datasourceCode = reportTemplate?.datasourceCodeEnum;
  const reportTemplateQueryVersion = reportTemplate?.queryVersion ?? 1;

  const title = customExtractionLabel || reportTemplate?.name || "Reports";

  const info = React.useMemo(() => {
    return getReportInfo(report, reportTemplate, customExtractionLabel);
  }, [report, reportTemplate, customExtractionLabel]);

  const { loading: breadrumbsLoading, breadcrumbs } = useReportBreadcrumbs({
    reportTemplate,
    accountId,
    projectId,
    crawlId,
    segmentId: selectedSegment?.segment.id,
    title,
  });

  function getError(): Error | undefined {
    if (loading) return;

    if (!reportTemplate) return new Error(t("reportTemplateNotFound"));

    const supportedDatasources = [
      DatasourceCode.CrawlUrls,
      DatasourceCode.CrawlAccessibilityIssues,
      DatasourceCode.CrawlAccessibilityIssueInstances,
    ];

    if (!report)
      return selectedSegment &&
        !supportedDatasources.includes(reportTemplate.datasourceCodeEnum)
        ? new Error(t("noSegmentedReport"))
        : new Error(t("reportNotFound"));
  }

  return {
    loading:
      loading ||
      customExtractionLabelsLoading ||
      breadrumbsLoading ||
      crawlContextLoading,
    error: getError(),
    title,
    breadcrumbs,
    info,
    datasourceCode,
    reportTemplateCode,
    reportTemplateQueryVersion,
    lastFinishedCrawlId,
    reportAdviceCollapsed: Boolean(data?.me.reportAdviceCollapsed),
    tabsMode: reportsCount === 1 ? "onlyTotalRowsVisible" : "visible",
  };
}
