/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getMobileConfigurationChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    additionalVisualisationTypes: [VisualisationTypes.Bar],
    title: () => t("mobileConfiguration.title"),
    description: () => t("mobileConfiguration.description"),
    reports: () => ({
      filter: {
        reportTemplateCode: [
          "mobile_desktop_responsive",
          "mobile_desktop_dynamic",
          "mobile_desktop_separate_desktop",
          "mobile_desktop_separate_mobile",
          "mobile_desktop_amphtml",
          "no_mobile_configuration",
        ],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        totalRows: true,
      },
    }),
    showPercentageInSeriesTooltip: true,
    bindings: {
      name: (report) => {
        return report.reportTemplate.name;
      },
      count: (report) => {
        return report.totalRows ?? 0;
      },
    },
  };
}
