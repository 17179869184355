import React from "react";
import { Select, Typography, useTranslation } from "@lumar/shared";
import { Box, makeStyles, MenuItem } from "@material-ui/core";
import { useContext } from "react";
import { isString, uniq } from "lodash";
import {
  GridApiContext,
  GridState,
  useGridApi,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { assert } from "../_common/assert";
import { Task } from "./data/types";

const useStyles = makeStyles((theme) => ({
  label: {
    display: "flex",
    alignItems: "center",
    minWidth: 105,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    color: theme.palette.grey[700],
  },
  select: {
    maxWidth: 150,
    minWidth: 150,
  },
  value: {
    borderRightColor: theme.palette.grey[300],
    lineHeight: theme.typography.pxToRem(17),
  },
}));

export function FilterByReportFilter({
  rows,
  disabled,
}: {
  rows?: Task[];
  disabled?: boolean;
}): JSX.Element {
  const { t } = useTranslation("taskManager");
  const classes = useStyles();

  const DEFAULT_OPTION = t("filterByReport.all");
  const apiRef = useContext(GridApiContext);
  assert(apiRef);
  const api = useGridApi(apiRef);

  const filters = useGridSelector(
    apiRef,
    (state: GridState): GridState["filter"] => state.filter,
  );
  const filter = filters.items.find((x) => x.id === "filterByReport");

  const value = filter && filter.value !== "" ? filter.value : DEFAULT_OPTION;
  const options = [
    DEFAULT_OPTION,
    ...uniq(rows?.map((r) => r.reportTemplate?.name)).filter(isString),
  ];

  const removeFilter = !options.includes(value);
  React.useLayoutEffect(() => {
    if (removeFilter) {
      api.upsertFilter({
        id: "filterByReport",
        columnField: "report",
        operatorValue: "equals",
        value: "",
      });
    }
  }, [api, removeFilter]);

  return (
    <Box display="flex" flexDirection="row">
      <Typography variant="button" component="label" className={classes.label}>
        {t("filterByReport.filterLabel")}
      </Typography>
      <Select
        value={value}
        onChange={(e) => {
          api.upsertFilter({
            id: "filterByReport",
            columnField: "report",
            operatorValue: "equals",
            value: e.target.value === DEFAULT_OPTION ? "" : e.target.value,
          });
        }}
        disabled={disabled}
        className={classes.select}
        data-testid="filter-by-report-select"
      >
        {options?.map((option) => (
          <MenuItem
            key={option}
            value={option}
            data-testid="filter-by-report-option"
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
