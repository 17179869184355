/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  TimeZoneContext,
  useConnectionFilterParam,
  useQuery,
  useUrlServerPagination,
} from "@lumar/shared";
import React, { useEffect, useMemo } from "react";
import { formatFilters } from "../../../../_common/connection-filtering/formatFilters";
import { ReportRowsFilter } from "../../../../_common/connection-filtering/types";
import { useValueChanged } from "../../../../_common/useValueChanged";
import {
  getReportGridRowsQuery,
  mapReportDTOToReportRowData,
} from "./ReportGridRows.helpers";
import {
  ReportGridRowsResult,
  UseReportGridRowsArgs,
} from "./ReportGridRows.types";

export function useReportGridRows({
  pageSize,
  metricsData,
  datasourceCode,
  baseFilter,
  reportInput,
  hasMultipleCrawls,
  isComparedToCrawlArchived,
}: UseReportGridRowsArgs): ReportGridRowsResult {
  const { pageInfo, ...pagination } = useUrlServerPagination(pageSize);

  const filter = useConvertFilter(baseFilter);

  const { document, variables } = getReportGridRowsQuery({
    reportInput,
    baseFilter,
    filter,
    hasMultipleCrawls,
    isComparedToCrawlArchived,
    ...pageInfo,
  });

  const { loading, data, error } = useQuery(document, {
    variables,
    fetchPolicy: "cache-first",
  });

  const { isChanged: hasParamChanged } = useValueChanged({
    filter,
    crawlId: reportInput.crawlId,
    reportTypeCode: reportInput.reportTypeCode,
  });

  useEffect(() => {
    if (hasParamChanged) {
      pagination.onPaginationStateChange(
        {
          ...pagination.paginationState,
          page: 0,
        },
        { replaceHistory: true },
      );
    }
  }, [hasParamChanged, pagination]);

  const { totalCount, totalRows, diffs, rows, reportQueryVersion } =
    useMemo(() => {
      if (!data) {
        return {
          reportId: "",
          totalCount: 0,
          totalRows: 0,
          diffs: {
            added: 0,
            basic: 0,
            missing: 0,
            removed: 0,
          },
          rows: [],
          reportQueryVersion: undefined,
        };
      }

      const mappedData = mapReportDTOToReportRowData({
        data,
        metricsData,
        datasourceCode,
      });
      return mappedData;
    }, [data, metricsData, datasourceCode]);

  return {
    rows,
    reportQueryVersion,
    diffs,
    error,
    totalRows,
    totalCount,
    pagination,
    loading,
    customFilter: filter,
  };
}

function useConvertFilter(
  baseFilter: ReportRowsFilter | undefined,
): ReportRowsFilter | undefined {
  const [urlFilter] = useConnectionFilterParam();
  const { timeZone } = React.useContext(TimeZoneContext);

  const formatFilter = urlFilter._or?.length
    ? formatFilters(urlFilter, timeZone.offset, ["crawlDatetime"])
    : undefined;

  if (!formatFilter && !baseFilter) return;

  return {
    _and: [
      ...(formatFilter ? [formatFilter] : []),
      ...(baseFilter ? [baseFilter] : []),
    ],
  };
}
