/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode, OrderDirection, ReportOrderField } from "../../../graphql";

export function getAccessibilityTextAlternativesAChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [ModuleCode.Accessibility],
    // visualisationColors: [theme.co.red[300]],
    title: () => t("accessibilityTextAlternativesAChart.title"),
    description: () => t("accessibilityTextAlternativesAChart.description"),
    reports: () => ({
      orderBy: {
        field: ReportOrderField.TotalRows,
        direction: OrderDirection.Desc,
      },
      filter: {
        reportTemplateCode: [
          "area_alt_issues",
          "document_title_issues",
          "frame_title_issues",
          "image_alt_issues",
          "input_image_alt_issues",
          "object_alt_issues",
          "role_img_alt_issues",
          "server_side_image_map_issues",
          "svg_img_alt_issues",
          "video_caption_issues",
        ],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        totalRows: true,
      },
    }),
    bindings: {
      name: (report) => report.reportTemplate.name,
      count: (report) => {
        return report.totalRows ?? 0;
      },
    },
  };
}
