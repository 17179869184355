import { DocumentNode, gql, OperationVariables } from "@lumar/shared";
import { jsonToGraphQLQuery, VariableType } from "json-to-graphql-query";
import { merge } from "lodash";
import { PartialDeep } from "type-fest";

import {
  Crawl,
  Report,
  ReportConnection,
  ReportTemplate,
  Maybe,
  CrawlUrlConnection,
  ReportTemplateMetadata,
} from "../../../graphql";
import { ChartConfigItem, TileChartConfigItem } from "../types/ChartConfig";
import { getCrawlTypeCountsFields } from "./chart-query/getCrawlTypeCountsFields";
import { getAllPagesCount } from "./chart-query/getAllPagesCount";
import { getReportFields } from "./chart-query/getReportFields";

type AliasedCrawlUrlFields = Record<string, Maybe<CrawlUrlConnection>>;

export type ReportInResponse = PartialDeep<Report> & {
  id: Report["id"];
  reportTemplate: Required<
    Pick<ReportTemplate, "id" | "name" | "code" | "datasourceCodeEnum">
  > & {
    metadata: Pick<ReportTemplateMetadata, "unit">;
  };
  searchConsoleMobileClicks?: Maybe<CrawlUrlConnection>;
  searchConsoleDesktopClicks?: Maybe<CrawlUrlConnection>;
  searchConsoleTabletClicks?: Maybe<CrawlUrlConnection>;
} & AliasedCrawlUrlFields;

export interface ChartQueryResponseReportEdge {
  node: ReportInResponse;
}

type CrawlWithAllPages = Pick<
  Crawl,
  | "id"
  | "crawlTypes"
  | "comparedToCrawlId"
  | "archivedAt"
  | "createdAt"
  | "crawlTypeCounts"
  | "project"
> & {
  allPages: Partial<Report>[];
};

export type CrawlBase = Maybe<CrawlWithAllPages>;

export type CrawlWithReports = CrawlBase & {
  reports: Pick<ReportConnection, "totalCount"> & {
    edges: ChartQueryResponseReportEdge[];
  };
};

export interface ChartQueryResponse {
  getCrawl?: CrawlWithReports;
}

export interface AdditionalConfig {
  crawlId: string;
  includeComparedToCrawlId?: boolean;
  segmentId?: string;
  category?: string;
}

export interface ChartQueryResult {
  document: DocumentNode;
  variables: OperationVariables;
}

export type ChartQueryBuilder = {
  inputs?: Record<string, unknown>;
  variables?: Record<string, unknown>;
  fields?: Record<string, unknown>;
};

export function generateChartQuery(
  config: (ChartConfigItem | TileChartConfigItem) & { name: string },
  additionalConfig: AdditionalConfig,
  isDeepCrawlAdminEnabled: boolean | undefined,
): ChartQueryResult {
  const builders: ChartQueryBuilder[] = [
    getCrawlTypeCountsFields(config, additionalConfig),
    getAllPagesCount(config, additionalConfig),
    getReportFields(config, additionalConfig, isDeepCrawlAdminEnabled),
  ];

  const query = {
    query: {
      __name: config.name ? "Chart" + config.name + "Data" : undefined,
      __variables: merge(
        {
          crawlId: "ObjectID!",
        },
        ...builders.map((x) => x.inputs),
      ),
      getCrawl: merge(
        {
          __args: {
            id: new VariableType("crawlId"),
          },
          project: {
            id: true,
            name: true,
            primaryDomain: true,
          },
          id: true,
          archivedAt: true,
          createdAt: true,
          crawlTypes: true,
          comparedToCrawlId: Boolean(additionalConfig.includeComparedToCrawlId),
        },
        ...builders.map((x) => x.fields),
      ),
    },
  };

  return {
    document: gql`
      ${jsonToGraphQLQuery(query, {
        pretty: true,
      })}
    `,
    variables: merge(
      { crawlId: additionalConfig.crawlId },
      ...builders.map((x) => x.variables),
    ),
  };
}
