import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useTranslation, Button } from "@lumar/shared";

import { EditTaskForm } from "./EditTaskForm";
import { useEditTaskFormValues } from "./useFormValues";

export interface EditTaskDialogProps {
  taskId: string;
  onClose: () => void;
}

export function EditTaskDialog({
  taskId,
  onClose,
}: EditTaskDialogProps): JSX.Element {
  const { t } = useTranslation("taskManager");

  const [open, setOpen] = useState(true);
  const [error, setError] = useState<
    { title: string; message: string } | undefined
  >(undefined);

  const { values, loading, loaded, updateTask } = useEditTaskFormValues({
    taskId,
    onLoadError: (error) =>
      setError({ title: t("editDialog.loadError"), message: error.message }),
    onSaveError: (error) =>
      setError({ title: t("editDialog.saveError"), message: error.message }),
  });

  const handleSave = async (
    submitForm: () => Promise<boolean>,
  ): Promise<void> => {
    const saved = await submitForm();
    if (!saved) return;

    setOpen(false);
  };

  return (
    <EditTaskForm
      values={values}
      loading={loading}
      error={error}
      disabled={!loaded}
      showStatus
      onSubmit={(values) => updateTask(values)}
    >
      {(form, { isSubmitting, isValid, submitForm }) => (
        <Dialog
          open={open}
          TransitionProps={{ onExited: () => onClose() }}
          maxWidth="sm"
          fullWidth
          // Note: This prevents the event to be handled by the data grid
          onClick={(event) => event.stopPropagation()}
          onClose={() => setOpen(false)}
          aria-labelledby="edit-task-dialog"
        >
          <DialogTitle id="edit-task-dialog">
            {t("editDialog.dialogTitle")}
          </DialogTitle>
          <DialogContent>{form}</DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => setOpen(false)}
              disabled={isSubmitting}
              data-testid="edit-task-cancel"
            >
              {t("editDialog.cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (isSubmitting) return;
                setError(undefined);
                handleSave(submitForm as () => Promise<boolean>);
              }}
              disabled={!loaded || !isValid}
              loading={isSubmitting}
              data-testid="edit-task-update"
            >
              {t("editDialog.update")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </EditTaskForm>
  );
}
