import {
  CustomExtractionLabelsQuery,
  CustomExtractionSetting,
} from "../../graphql";
import { Task } from "./types";

export function formatTasksRows({
  tasks,
  customExtractionLabelsData,
}: {
  tasks?: Task[];
  customExtractionLabelsData?: CustomExtractionLabelsQuery;
}): Task[] {
  return (
    tasks?.map((task) => ({
      ...task,
      reportTemplate: task.reportTemplate && {
        ...task.reportTemplate,
        name:
          getCustomExtractionLabel(
            customExtractionLabelsData?.crawl?.crawlSetting
              ?.customExtractions ?? task.project?.customExtractions,
            task.reportTemplate.code,
          ) ??
          task.reportTemplate.name ??
          "",
      },
    })) || []
  );
}

function getCustomExtractionLabel(
  customExtractions:
    | Array<Pick<CustomExtractionSetting, "label" | "reportTemplateCode">>
    | undefined
    | null,
  reportTemplateCode?: string,
): string | undefined {
  return customExtractions?.find(
    (customExtractionSetting) =>
      customExtractionSetting.reportTemplateCode === reportTemplateCode,
  )?.label;
}
