/* eslint-disable react/display-name */
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode, OrderDirection, ReportOrderField } from "../../../graphql";
import { TFunction } from "i18next";

export function getSiteSpeedIssuesLCP(t: TFunction<"charts">): ChartConfigItem {
  return {
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    supportedModules: [ModuleCode.SiteSpeed],
    title: () => t("siteSpeedLCPIssues.title"),
    description: () => t("siteSpeedLCPIssues.description"),
    reports: () => ({
      orderBy: {
        field: ReportOrderField.TotalRows,
        direction: OrderDirection.Desc,
      },
      filter: {
        reportTemplateCode: [
          "unused_javascript_failed_audits",
          "uses_responsive_images_failed_audits",
          "efficient_animated_content_failed_audits",
          "lcp_lazy_loaded_failed_audits",
          "modern_image_formats_failed_audits",
          "total_byte_weight_failed_audits",
          "prioritize_lcp_image_failed_audits",
          "offscreen_images_failed_audits",
          "unused_css_rules_failed_audits",
          "uses_optimized_images_failed_audits",
          "largest_contentful_paint_element_failed_audits",
        ],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        totalRows: true,
      },
    }),
    bindings: {
      name: (report) => report.reportTemplate.name,
      count: (report) => {
        return report.totalRows ?? 0;
      },
    },
  };
}
