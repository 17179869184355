import { useParams } from "react-router-dom";

import { Routes } from "../../_common/routing/routes";
import { CrawlSelector } from "../../crawl-selector/CrawlSelector";
import { ReportTypeCode } from "../../graphql";
import { ReportEntity, ReportInput } from "../Report.types";

type ReportCrawlSelectorProps = {
  reportInput: ReportInput;
};

export function ReportCrawlSelector(
  props: ReportCrawlSelectorProps,
): JSX.Element {
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId: string;
  }>();

  const onCrawlChange = (crawlId: string): string => {
    switch (props.reportInput.reportEntity) {
      case ReportEntity.LegacyReport:
        return Routes.Report.getUrl({
          accountId,
          projectId,
          crawlId,
          reportTemplateCode: props.reportInput.reportTemplateCode,
          reportTypeCode: ReportTypeCode.Basic.toLowerCase(),
          filter: "preserve",
          segmentId: props.reportInput.segmentId,
        });
      case ReportEntity.ReportStat:
        return Routes.Report.getUrl({
          accountId,
          projectId,
          crawlId,
          reportTemplateCode: props.reportInput.reportTemplateCode,
          reportTypeCode: ReportTypeCode.Basic.toLowerCase(),
          filter: "preserve",
          segmentId: props.reportInput.segmentId,
        });
      case ReportEntity.CustomReport:
        return Routes.CustomReport.getUrl({
          accountId,
          projectId,
          crawlId,
          customReportTemplateId: props.reportInput.customReportTemplateId,
          segmentId: props.reportInput.segmentId,
        });
      default:
        console.error("Unknown report entity");
        return "";
    }
  };

  return <CrawlSelector getPath={onCrawlChange} />;
}
