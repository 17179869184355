import { EnumType, VariableType } from "json-to-graphql-query";
import { isArray, isString, omit } from "lodash";

import {
  ReportTypeCode,
  ReportTemplateConnectionFilterInput,
  ReportConnectionFilterInput,
} from "../../../../graphql";
import { ChartConfigItem, TileChartConfigItem } from "../../types/ChartConfig";
import {
  ChartConfigItemBaseWithReports,
  EnhancedReportConnectionFilterInput,
  EnhancedReportTemplateCodeValue,
  EnhancedReportTypeCodeValue,
} from "../../types/ChartConfigItemBase";
import { isArrayOfStrings } from "../../../isArrayOfStrings";
import { AdditionalConfig, ChartQueryBuilder } from "../generateChartQuery";
import { createAdminFilter } from "../../../api/createAdminFilter";
import { hasAggregatedReport } from "../../components/chart-components/chartDataHelpers";

export function getReportFields(
  config: ChartConfigItem | TileChartConfigItem,
  additionalConfig: AdditionalConfig,
  isDeepCrawlAdminEnabled: boolean | undefined,
): ChartQueryBuilder {
  const reports = config.reports(additionalConfig.segmentId);

  const fields = {
    id: true,
    reportTemplateCode: true,
    reportTemplateName: true,
    ...reports.fields,
    reportTemplate: {
      id: true,
      name: true,
      code: true,
      datasourceCodeEnum: true,
      metadata: {
        unit: true,
      },
      ...(hasAggregatedReport(config) ? { aggregatesByCode: true } : {}),
      ...reports.fields.reportTemplate,
    },
    ...getCrawlUrlsAggregatesFields(reports),
    ...getSegmentFields(reports),
  };

  const filters = getFilters(
    reports,
    additionalConfig.category,
    additionalConfig.segmentId,
  );
  if (isOptimizedFilter(filters))
    return {
      inputs: {
        reportsInput: "CrawlReportsByCodeInput!",
      },
      variables: {
        reportsInput: {
          reportTemplateCodes: filters.reportTemplateCodes,
          reportTypeCodes: filters.reportTypeCodes,
          segmentId: filters.segmentId,
          ...(reports.orderBy ? { orderBy: reports.orderBy } : {}),
        },
      },
      fields: {
        reports: {
          __aliasFor: "reportsByCode",
          __args: {
            input: new VariableType("reportsInput"),
          },
          ...fields,
        },
      },
    };

  return {
    inputs: {
      reportTemplateFilter: "ReportTemplateConnectionFilterInput!",
      reportFilter: "ReportConnectionFilterInput!",
    },
    variables: {
      reportTemplateFilter: {
        ...filters.reportTemplateFilter,
        ...createAdminFilter(isDeepCrawlAdminEnabled),
      },
      reportFilter: filters.reportFilter,
    },
    fields: {
      reports: {
        __args: {
          reportTemplateFilter: new VariableType("reportTemplateFilter"),
          filter: new VariableType("reportFilter"),
          first: filters.first,
          ...getReportsOrderByArgument(reports),
        },
        totalCount: true,
        edges: {
          cursor: true,
          node: fields,
        },
      },
    },
  };
}

function getReportsOrderByArgument(
  reports: ReturnType<ChartConfigItemBaseWithReports["reports"]>,
): { orderBy?: Record<string, unknown>[] } {
  const orderBy = reports.orderBy;

  return orderBy
    ? {
        orderBy: (Array.isArray(orderBy) ? orderBy : [orderBy]).map(
          (reportOrder) => ({
            field: new EnumType(reportOrder.field),
            direction: new EnumType(reportOrder.direction),
          }),
        ),
      }
    : {};
}

function getSegmentFields(
  reports: ReturnType<ChartConfigItemBaseWithReports["reports"]>,
): Record<string, unknown> {
  const segment = reports.fields.segment;

  return segment
    ? {
        segment: {
          id: true,
          ...segment,
        },
      }
    : {};
}

function getCrawlUrlsAggregatesFields(
  reports: ReturnType<ChartConfigItemBaseWithReports["reports"]>,
): Record<string, unknown> {
  const crawlUrlsAggregates = reports.fields.crawlUrlsAggregates;

  return crawlUrlsAggregates
    ? {
        crawlUrlsAggregates: {
          __args: {
            ...crawlUrlsAggregates.args,
            dimensions: crawlUrlsAggregates.args.dimensions.map(
              (dimension) => new EnumType(dimension),
            ),
          },
          ...omit(crawlUrlsAggregates, "args"),
        },
      }
    : {};
}

interface OptimizedReportFilter {
  reportTemplateCodes: string[] | string;
  reportTypeCodes: string[] | string;
  segmentId: string | null;
}

interface ReportFilter {
  first: number;
  reportFilter: ReportConnectionFilterInput;
  reportTemplateFilter: ReportTemplateConnectionFilterInput;
}

function getFilters(
  reports: ReturnType<ChartConfigItemBaseWithReports["reports"]>,
  category?: string,
  segmentIdFromRoute?: string,
): OptimizedReportFilter | ReportFilter {
  const reportTemplateFilter = getReportTemplateFilter(reports, category);
  const {
    reportTypeCode,
    reportTemplateCode,
    segmentId: segmentIdFromFilter,
    ...filter
  } = reports.filter ?? {};

  const reportTemplateCodes: string[] | string =
    isString(reportTemplateCode) || isArrayOfStrings(reportTemplateCode)
      ? reportTemplateCode
      : [];
  const reportTypeCodes: string[] | string = !reportTypeCode
    ? ReportTypeCode.Basic
    : isString(reportTypeCode) || isArrayOfStrings(reportTypeCode)
      ? reportTypeCode
      : [];
  const segmentId: string | null | undefined = getSegmentId(
    reports.filter ?? {},
    segmentIdFromFilter,
    segmentIdFromRoute,
  );

  // Note: The reportsByCode field only allows filtering by template code, type code and
  // segment id and does not supports ordering by multiple fields. If the config doesn't
  // match this criteria, reports filed should be used. - Csaba
  if (
    Object.keys(reportTemplateFilter).length === 0 &&
    Object.keys(filter).length === 0 &&
    reportTemplateCodes.length &&
    reportTypeCodes.length &&
    (reports.first === undefined ||
      reports.first >= reportTemplateCodes.length) &&
    segmentId !== undefined &&
    !isArray(reports.orderBy)
  ) {
    return {
      reportTemplateCodes,
      reportTypeCodes,
      segmentId,
    };
  }

  return {
    first: reports.first || 6,
    reportTemplateFilter: reportTemplateFilter,
    reportFilter: {
      ...filter,
      reportTypeCode: transformReportTypeCodeFilterValue(reportTypeCode),
      ...transformReportTemplateCodeFilterValue(reportTemplateCode),
      ...transformSegmentIdFilter(segmentIdFromRoute, reports.filter ?? {}),
    },
  };
}

function isOptimizedFilter(
  filter: OptimizedReportFilter | ReportFilter,
): filter is OptimizedReportFilter {
  return filter.hasOwnProperty("reportTemplateCodes");
}

function getSegmentId(
  reportFilter: EnhancedReportConnectionFilterInput,
  segmentIdFromFilter: EnhancedReportConnectionFilterInput["segmentId"],
  segmentIdFromRoute: string | undefined,
): string | null | undefined {
  if (segmentIdFromFilter) {
    return isString(segmentIdFromFilter) ? segmentIdFromFilter : undefined;
  }

  if (reportFilter.hasOwnProperty("segmentId") && !segmentIdFromFilter) {
    return undefined;
  }

  return segmentIdFromRoute ?? null;
}

function getReportTemplateFilter(
  reports: ReturnType<ChartConfigItemBaseWithReports["reports"]>,
  category?: string,
): ReportTemplateConnectionFilterInput {
  const {
    reportTemplateFilter = {},
    includeOnlyCurrentlyViewedCategoryReports,
  } = reports;

  if (includeOnlyCurrentlyViewedCategoryReports) {
    return {
      ...reportTemplateFilter,
      categoriesRaw: {
        contains: category,
      },
    };
  }

  return reportTemplateFilter;
}

type TransformReportTypeCodeFilterReturnValue =
  ReportConnectionFilterInput["reportTypeCode"];

function transformReportTypeCodeFilterValue(
  value?: EnhancedReportTypeCodeValue,
): TransformReportTypeCodeFilterReturnValue {
  if (typeof value === "string" || !value) {
    return {
      eq: (value || ReportTypeCode.Basic).toLowerCase(),
    };
  } else if (Array.isArray(value)) {
    return {
      in: value,
    };
  } else {
    return value;
  }
}

type TransformReportTemplateCodeFilterReturnValue =
  | {
      reportTemplateCode: ReportConnectionFilterInput["reportTemplateCode"];
    }
  | undefined;

function transformReportTemplateCodeFilterValue(
  value?: EnhancedReportTemplateCodeValue,
): TransformReportTemplateCodeFilterReturnValue {
  if (!value) {
    return undefined;
  } else if (typeof value === "string") {
    return {
      reportTemplateCode: {
        eq: value,
      },
    };
  } else if (isArrayOfStrings(value)) {
    return {
      reportTemplateCode: {
        in: value,
      },
    };
  } else if (Array.isArray(value)) {
    return {
      reportTemplateCode: {
        in: value.map(({ eq }) => eq),
      },
    };
  } else {
    return {
      reportTemplateCode: value,
    };
  }
}

function transformSegmentIdFilter(
  segmentIdFromRoute: string | undefined,
  reportFilter: EnhancedReportConnectionFilterInput,
): {
  segmentId?: ReportConnectionFilterInput["segmentId"];
} {
  const segmentIdFilter = reportFilter?.segmentId;

  if (segmentIdFilter) {
    return { segmentId: segmentIdFilter };
  }

  if (reportFilter.hasOwnProperty("segmentId") && !segmentIdFilter) {
    return {};
  }

  return {
    segmentId: segmentIdFromRoute
      ? { eq: segmentIdFromRoute }
      : { isNull: true },
  };
}
