import React from "react";
import { useDateFormatter, useTranslation, useSession } from "@lumar/shared";
import { GridColumns, GridState } from "@mui/x-data-grid-pro";
import { RoleCode } from "../graphql";
import { useDebounedFunction } from "../_common/useDebounedFunction";
import {
  readColumnsState,
  writeColumnsState,
} from "../_common/data-grid/column-persistance/columnsState";
import { useTaskMutations } from "./data/useTaskMutations";
import {
  ColumnProps,
  getActionsColumn,
  getAssignedToColumn,
  getCreatedAtColumn,
  getDeadlineColumn,
  getDescriptionColumn,
  getIdentifiedColumn,
  getLinkColumn,
  getProjectColumn,
  getReportColumn,
  getSeverityColumn,
  getStatusDeadlineColumn,
  getTaskColumn,
  getTrendColumn,
  getUnresolvedColumn,
  getUnresolvedPercentColumn,
  TaskManagerGridColumns,
} from "./cells/getTaskTableColumns";

export interface TaskTableColumns {
  columns: GridColumns;
  defaultColumns: string[];
  setColumnState: (gridState: GridState) => void;
}

export function useAccountTaskTableColumns(): TaskTableColumns {
  const { hasSufficientRole } = useSession();
  const { t } = useTranslation("taskManager");
  const dateFormatter = useDateFormatter();

  const { setResolved, removeTask } = useTaskMutations();

  const { columns, defaultColumns, setColumnState } = React.useMemo(() => {
    const columnProps: ColumnProps = {
      t,
      dateFormatter,
    };

    const columns: TaskManagerGridColumns[] = [
      getSeverityColumn(columnProps, true),
      getTaskColumn(columnProps),
      getProjectColumn(columnProps),
      getReportColumn(columnProps, false),
      getStatusDeadlineColumn(columnProps, false),
      getCreatedAtColumn(columnProps, true),
      getDeadlineColumn(columnProps, true),
      getTrendColumn(columnProps),
      getIdentifiedColumn(columnProps, true),
      getUnresolvedColumn(columnProps, true),
      getUnresolvedPercentColumn(columnProps, false),
      getAssignedToColumn(columnProps),
      getLinkColumn(columnProps),
      getActionsColumn(columnProps, { setResolved, removeTask }),
    ];

    return getColumnState({
      columns,
      columnStateKey: "account-tasks",
      hasSufficientRole,
    });
  }, [t, dateFormatter, setResolved, removeTask, hasSufficientRole]);

  const { debounce } = useDebounedFunction(300);

  return {
    columns,
    defaultColumns,
    setColumnState: (gridState) => debounce(() => setColumnState(gridState)),
  };
}

export function useProjectTaskTableColumns(): TaskTableColumns {
  const { hasSufficientRole } = useSession();
  const { t } = useTranslation("taskManager");
  const dateFormatter = useDateFormatter();

  const { setResolved, removeTask } = useTaskMutations();

  const { columns, defaultColumns, setColumnState } = React.useMemo(() => {
    const columnProps: ColumnProps = {
      t,
      dateFormatter,
    };
    const columns: TaskManagerGridColumns[] = [
      getSeverityColumn(columnProps, true),
      getTaskColumn(columnProps),
      getDescriptionColumn(columnProps),
      getReportColumn(columnProps, true),
      getStatusDeadlineColumn(columnProps, true),
      getCreatedAtColumn(columnProps, true),
      getDeadlineColumn(columnProps, true),
      getTrendColumn(columnProps),
      getIdentifiedColumn(columnProps, true),
      getUnresolvedColumn(columnProps, true),
      getUnresolvedPercentColumn(columnProps, true),
      getAssignedToColumn(columnProps),
      getLinkColumn(columnProps),
      getActionsColumn(columnProps, { setResolved, removeTask }),
    ];

    return getColumnState({
      columns,
      columnStateKey: "tasks",
      hasSufficientRole,
    });
  }, [t, dateFormatter, setResolved, removeTask, hasSufficientRole]);

  const { debounce } = useDebounedFunction(300);

  return {
    columns,
    defaultColumns,
    setColumnState: (gridState) => debounce(() => setColumnState(gridState)),
  };
}

function getColumnState({
  columns,
  columnStateKey,
  hasSufficientRole,
}: {
  columns: TaskManagerGridColumns[];
  columnStateKey: string;
  hasSufficientRole: (minimumRole?: RoleCode | undefined) => boolean;
}): TaskTableColumns {
  const defaultStates = columns.map((col) => ({
    code: col.field,
    hide: col.hide ?? false,
    width: col.width ?? 100,
  }));
  const storedStates = readColumnsState(columnStateKey, defaultStates);

  return {
    // eslint-disable-next-line fp/no-mutating-methods
    columns: columns
      .filter((col) => hasSufficientRole(col.minimumRole))
      .map((col) => {
        const state = storedStates.find((x) => x.code === col.field);
        return {
          ...col,
          width: state?.width,
          hide: state?.hide,
        };
      })
      .sort(
        (a, b) =>
          storedStates.findIndex((x) => x.code === a.field) -
          storedStates.findIndex((x) => x.code === b.field),
      ),
    defaultColumns: columns.filter((col) => !col.hide).map((col) => col.field),
    setColumnState: (gridState) => {
      writeColumnsState(columnStateKey, gridState, defaultStates);
    },
  };
}
