/* eslint-disable react/display-name */
import React from "react";
import { Typography } from "@material-ui/core";
import { Trans } from "react-i18next";
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import {
  OrderDirection,
  ReportOrderField,
  ReportTypeCode,
} from "../../../graphql";

import { ApolloError } from "@lumar/shared";

export function generateTopSegmentsChart(reportTemplateCode: string) {
  return function (t: TFunction<"charts">): ChartConfigItem {
    return {
      visualisationFamily: "MultiSeries",
      visualisationType: VisualisationTypes.Bar,
      title: (reports) => {
        if (reports[0]) {
          const reportName = reports[0]?.reportTemplate.name;
          return t("topSegments.title", { reportName });
        }
        return t("topSegments.title", { reportName: "" });
      },
      description: (_, reports) => {
        const reportName = reports[0]?.reportTemplate.name;
        return (
          <Trans ns="charts" i18nKey="topSegments.description">
            <Typography paragraph variant="inherit">
              The top five segments that have the most URLs in the{" "}
              {{ reportName }} report.
            </Typography>
            <Typography variant="inherit">
              <i>Note:</i> URLs can belong to more than one segment.
            </Typography>
          </Trans>
        );
      },
      reports: () => ({
        orderBy: {
          field: ReportOrderField.TotalRows,
          direction: OrderDirection.Desc,
        },
        filter: {
          reportTypeCode: ReportTypeCode.Basic,
          reportTemplateCode,
          segmentId: { isNull: false },
        },
        fields: {
          segment: {
            name: true,
            id: true,
          },
          reportTemplate: {
            name: true,
          },
          totalRows: true,
        },
      }),
      bindings: {
        name: (report) => {
          const segmentName = report?.segment?.name;
          return segmentName ?? "";
        },
        count: (report) => {
          return report.totalRows ?? 0;
        },
        nameInTooltip: (report) => {
          return report.reportTemplate.name;
        },
      },
      processData: (data, error) => {
        if (!isSegmentNotFound(error) || !data) return { data, error };

        return {
          data: data && {
            ...data,
            getCrawl: data?.getCrawl && {
              ...data?.getCrawl,
              reports: {
                ...data?.getCrawl?.reports,
                edges: data?.getCrawl?.reports.edges.filter(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (r: any) => r.node.segment,
                ),
              },
            },
          },
        };
      },
      testAttributePrefix: "top-segments-chart",
    };
  };
}

function isSegmentNotFound(error?: ApolloError): boolean {
  return (
    Boolean(error) &&
    !error?.networkError &&
    !error?.clientErrors?.length &&
    !error?.protocolErrors?.length &&
    !error?.graphQLErrors?.find(
      (e) =>
        !(
          e.extensions?.code === "ENTITY_NOT_FOUND" &&
          e.extensions?.entityType === "Segment"
        ),
    )
  );
}
