import { ApolloError } from "@lumar/shared";
import React from "react";
import {
  ReportInResponse,
  ChartQueryResponse,
} from "../../utils/generateChartQuery";
import { Maybe, MetricMetadata } from "../../../../graphql";

export interface ChartDataContext {
  loading: boolean;
  segmentName?: string;
  reports: ReportInResponse[];
  error?: ApolloError;
  isChartUnavailable: boolean;
  data?: ChartQueryResponse;
  totalUrls?: number;
  getReportUnit: (report?: ReportInResponse) => string | undefined;
  getAggregatedMetric: (
    report?: ReportInResponse,
  ) => ChartDataMetric | undefined;
}

export type ChartDataMetric = {
  code: string;
  name: string;
  aggregate: string;
  metadata?: Maybe<Pick<MetricMetadata, "unit" | "siteSpeedScoring">>;
};

export const ChartDataContext = React.createContext<ChartDataContext | null>(
  null,
);

export function useChartDataContext(): ChartDataContext {
  const context = React.useContext(ChartDataContext);

  if (!context) {
    throw new Error(
      "useChartDataContext must be used within a ChartDataContext provider",
    );
  }

  return context;
}
