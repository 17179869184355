import React from "react";
import { ApolloError, useApolloClient } from "@lumar/shared";
import { DataExplorerRow, DataExplorerTableConfig } from "../../types";
import { useExpandedRows } from "../expanded-data/useExpandedRows";
import {
  ExpandedRowsProps,
  ExpandedRowsResult,
  FragmentData,
  getExpandedRows,
} from "../expanded-data/getExpandedRows";
import { formatMetrics } from "../formatMetrics";
import { createConnectionFilter } from "../../../_common/connection-filtering/createConnectionFilter";
import { getBreadcrumDocument } from "./getBreadcrumbDocument";
import { useExpandedDataState } from "../expanded-data/useExpandedDataState";

export const BREADCRUMB_ALIAS = "expandedBreadcrumbs";
const FETCH_LIMIT = 30;
const BREADCRUMB_DEPTH_LIMIT = 8;

interface Props {
  crawlId: string;
  tableConfig: DataExplorerTableConfig;
}

interface Result {
  rows: DataExplorerRow[];
  loading: boolean;
  error?: ApolloError;
}

export function useBreadcrumbData({ crawlId, tableConfig }: Props): Result {
  const client = useApolloClient();

  const [{ loading, error, data }, updateState] = useExpandedDataState(crawlId);

  const {
    root: expandedRows,
    addRow,
    loadMoreRow,
    removeRow,
  } = useExpandedRows({
    localSotrageKey: "data-explorer-expanded-breadcrumbs",
    columnConfig: JSON.stringify(tableConfig.columns),
  });

  const { rows, fragmentsToFetch: allFragmentsToFetch } = React.useMemo(() => {
    return getBreadcrumbRows({
      data,
      expandedRows: {
        root: expandedRows,
        addRow,
        loadMoreRow,
        removeRow,
      },
      tableConfig,
    });
  }, [data, expandedRows, tableConfig, addRow, loadMoreRow, removeRow]);

  React.useEffect(() => {
    if (loading || error) return;

    const fragmentsToFetch = allFragmentsToFetch
      .filter((x) => x.values.length + 1 <= BREADCRUMB_DEPTH_LIMIT)
      .slice(0, FETCH_LIMIT);

    if (!fragmentsToFetch.length) return;

    updateState({
      loading: true,
      data: fragmentsToFetch.map(({ values, cursor }) => [
        getFragmentId(values, cursor),
        { loading: true },
      ]),
    });

    (async function fetchBreadcrumbs() {
      try {
        const sessionQuery = client.watchQuery({
          query: getBreadcrumDocument(crawlId, tableConfig, fragmentsToFetch),
          errorPolicy: "all",
        });
        await sessionQuery.result();
        const result = sessionQuery.getCurrentResult();

        updateState({
          loading: false,
          error: result.errors?.length
            ? new ApolloError({ graphQLErrors: result.errors })
            : undefined,
          data: Object.entries(result.data.report)
            .filter(([key]) => key.startsWith(BREADCRUMB_ALIAS))
            .map(([key, data]) => {
              const idx = Number(key.slice(BREADCRUMB_ALIAS.length));
              const breadcrumbId = getFragmentId(
                fragmentsToFetch[idx].values,
                fragmentsToFetch[idx].cursor,
              );

              return [
                breadcrumbId,
                { ...(data as FragmentData), loading: false },
              ];
            }),
        });
      } catch (error) {
        updateState({
          loading: false,
          error: error as ApolloError,
          data: fragmentsToFetch.map(({ values, cursor }) => [
            getFragmentId(values, cursor),
            { loading: false },
          ]),
        });
      }
    })();
  }, [
    crawlId,
    tableConfig,
    loading,
    error,
    client,
    data,
    expandedRows,
    allFragmentsToFetch,
    updateState,
  ]);

  return {
    loading: loading && !error && !data[""].nodes.length,
    error,
    rows,
  };
}

function getFragmentId(values: string[], cursor?: string): string {
  const breadcrumb = values.join(":") || "";
  return cursor ? `${breadcrumb}:${cursor}` : breadcrumb;
}

function getBreadcrumbRows(
  props: Pick<ExpandedRowsProps, "data" | "expandedRows"> & {
    tableConfig: DataExplorerTableConfig;
  },
): ExpandedRowsResult {
  const metrics = props.tableConfig.columns.map((x) => [
    x.aggregationCalculation,
    x.metric.code,
  ]);

  return getExpandedRows({
    ...props,
    getFragmentId,
    shouldRefetchFragment: (data) =>
      Boolean(
        data[0] &&
          metrics.find(([aggregate, metric]) => {
            const breadcrumbRow = data[0];
            return breadcrumbRow?.[aggregate]?.[metric] === undefined;
          }),
      ),
    getNodeValue: (data) => {
      return {
        value: Object.entries(data).find((x) =>
          x[0].startsWith("breadcrumb"),
        )?.[1],
        maxDepth: data.max?.breadcrumbCount || 0,
      };
    },
    formatMetrics: ({ data, value, values }) => ({
      breadcrumb: value,
      getFilter: () =>
        createConnectionFilter({
          or: [
            {
              and: values.map((breadcrumb, idx) => ({
                metricCode: `breadcrumb0${idx + 1}`,
                predicateKey: "eq",
                predicateValue: breadcrumb,
              })),
            },
          ],
        }),
      ...formatMetrics(data),
    }),
  });
}
