/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getRenderingIssuesChart(
  t: TFunction<"charts">,
): ChartConfigItem {
  return {
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    title: () => t("renderingIssues.title"),
    description: () => t("renderingIssues.description"),
    reports: () => ({
      filter: {
        reportTemplateCode: [
          "broken_head_warning",
          "canonical_link_mismatch",
          "rendered_word_count_mismatch",
          "rendered_link_count_mismatch",
        ],
      },
      fields: {
        reportTemplate: {
          name: true,
        },
        totalRows: true,
      },
    }),
    bindings: {
      name: (report) => report.reportTemplate.name,
      count: (report) => {
        return report.totalRows ?? 0;
      },
    },
  };
}
