import React from "react";
import {
  GridCellValue,
  GridRenderCellParams,
  GridSortCellParams,
} from "@mui/x-data-grid-pro";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.grey[500],
  },
}));

export function DeadlineDateCell(params: GridRenderCellParams): JSX.Element {
  const classes = useStyles();
  const { value } = params;

  return (
    <div>
      <Typography
        variant="caption"
        className={classes.text}
        data-testid="tasks-deadline"
      >
        {value}
      </Typography>
    </div>
  );
}

export function deadlineDateSort(
  value1: GridCellValue,
  value2: GridCellValue,
  param1: GridSortCellParams,
  param2: GridSortCellParams,
): number {
  const aDeadlineAt = param1.api.getRow(param1.id).deadlineAt ?? "";
  const bDeadlineAt = param1.api.getRow(param2.id).deadlineAt ?? "";
  return aDeadlineAt.localeCompare(bDeadlineAt);
}
