import React, { useState, useContext } from "react";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grow,
  Grid,
  TextField,
  IconButton,
  Tooltip,
  InputAdornment,
} from "@material-ui/core";
import { useFormikContext } from "formik";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import SettingsIcon from "@material-ui/icons/Settings";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import {
  ArrowRightSolid,
  Chip,
  EyeSolid,
  getProperty,
  SaveSolid,
  Typography,
} from "@lumar/shared";
import { SettingControl, SettingsGroup } from "./useAdvancedControls";
import { ContextValues, FormValues } from "./data/types";
import { CopySettingsField } from "./CopySettingsField";
import { FormSubmitContext } from "../components/FormSubmitContext";
import { SettingsContext } from "./data/useContextValues";
import { isEqual } from "lodash";
import { getScriptRenderingDefaultValues } from "./scriptRenderingDefault";

const useStyles = makeStyles((theme: Theme) => ({
  indent: {
    marginTop: theme.spacing(2),
  },
  fullWidth: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  category: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    lineHeight: 1.6,
  },
  badge: {
    marginLeft: theme.spacing(1),
  },
  field: {
    width: "100%",
    minWidth: 350,
    float: "right",
  },
  fieldIcon: {
    color: theme.palette.grey[500],
  },
  content: {
    flexDirection: "column",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(4),
  },
}));

export interface SettingsAdvancedProps {
  advancedSettings: SettingsGroup[];
  initialState: boolean;
  handleSave: (submitForm: () => Promise<boolean>) => Promise<void>;
  handleFinalise: (submitForm: () => Promise<boolean>) => Promise<void>;
  handleSaveAndPreview: (
    submitForm: () => Promise<boolean>,
    primaryDomain: string,
  ) => Promise<void>;
}

export function SettingsAdvanced({
  advancedSettings,
  initialState,
  handleSave,
  handleFinalise,
  handleSaveAndPreview,
}: SettingsAdvancedProps): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const [showAdvancedSettings, setShowAdvancedSettings] =
    useState(initialState);
  const [showOnlyActive, setShowOnlyActive] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const contextValues = useContext(SettingsContext);
  const formContext = useFormikContext<FormValues>();

  const { activeControls, activeControlCount, initialStates } =
    useControlStates(advancedSettings, formContext.values, contextValues);

  function shouldShowSettings(
    group: SettingsGroup,
    settings: SettingControl,
  ): boolean {
    const path = `${group.path}.${settings.path}`;
    if (showOnlyActive && !activeControls[path]) return false;

    if (
      searchValue &&
      settings.textForSearch.indexOf(searchValue.toLocaleLowerCase()) === -1
    )
      return false;

    return true;
  }

  const settingsButtons = (
    <FormSubmitContext>
      {({ submitForm, isSubmitting }) => {
        const disabled =
          isSubmitting ||
          (contextValues.hasCrawlInProgress &&
            !contextValues.hasCrawlUnarchiving);

        return (
          <div style={{ display: "flex" }} className={classes.indent}>
            <Button
              onClick={() => handleSave(submitForm)}
              disabled={disabled}
              variant="outlined"
              className={classes.button}
              startIcon={<SaveSolid />}
              data-testid="crawl-advanced-save"
            >
              {t("settings.saveSettings")}
            </Button>
            <Button
              onClick={() =>
                handleSaveAndPreview(
                  submitForm,
                  formContext.values.scope.domainScope.primaryDomain,
                )
              }
              disabled={disabled}
              variant="outlined"
              className={classes.button}
              startIcon={<EyeSolid />}
              data-testid="crawl-advanced-save-and-preview"
            >
              {t("saveAndPreview")}
            </Button>
            <Button
              onClick={() => handleFinalise(submitForm)}
              disabled={disabled}
              variant="contained"
              color="primary"
              style={{ marginLeft: "auto" }}
              endIcon={<ArrowRightSolid />}
              data-testid="crawl-advanced-finalise"
            >
              {t("settings.saveAndStart")}
            </Button>
          </div>
        );
      }}
    </FormSubmitContext>
  );

  return (
    <div className={classes.fullWidth}>
      <Button
        variant="outlined"
        size="small"
        startIcon={<SettingsIcon />}
        onClick={() => setShowAdvancedSettings((value) => !value)}
        className={classes.indent}
        data-testid="advanced-settings-button"
        data-pendo="advanced-settings-button"
      >
        {t("settings.advancedSettings")}
      </Button>
      {showAdvancedSettings && (
        <>
          <Grid container data-testid="advanced-settings-grid">
            <Grid item xs={11} md={6}>
              <div className={classes.titleContainer}>
                <Typography variant="h3">
                  {t("settings.advancedSettings")}
                </Typography>
                {(activeControlCount > 0 || showOnlyActive) && (
                  <Chip
                    label={
                      showOnlyActive
                        ? t("settings.showAll")
                        : t("settings.showActive", {
                            count: activeControlCount,
                          })
                    }
                    color="primary"
                    size="small"
                    className={classes.badge}
                    onClick={() => setShowOnlyActive(!showOnlyActive)}
                    data-testid="cawl-advanced-show-active"
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={1} md={3} />
            <Grid item xs={12} md={3}>
              <TextField
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                variant="outlined"
                hiddenLabel
                placeholder={t("settings.seachPlaceholder")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {!searchValue ? (
                        <SearchIcon className={classes.fieldIcon} />
                      ) : (
                        <IconButton
                          size="small"
                          onClick={() => setSearchValue("")}
                        >
                          <ClearIcon className={classes.fieldIcon} />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                  onKeyDown: (e) => e.key === "Escape" && setSearchValue(""),
                }}
                className={clsx(classes.field, classes.indent)}
                data-testid="crawl-advanced-settings-search"
              />
            </Grid>
            <Grid item xs={12}>
              {settingsButtons}
            </Grid>
            <Grid item md={9} />
            <Grid item xs={12} md={3}>
              <CopySettingsField />
            </Grid>
          </Grid>
          {advancedSettings.map((category, idx) => (
            <div key={idx}>
              <Typography variant="h6Medium" className={classes.category}>
                {category.title}
              </Typography>
              <div>
                {category.controls
                  .filter((settings) => shouldShowSettings(category, settings))
                  .map((settings) => {
                    const path = `${category.path}.${settings.path}`;
                    return (
                      <SettingsAccordion
                        key={settings.title}
                        settings={settings}
                        initialState={initialStates[path]}
                        isActive={activeControls[path]}
                        disabled={settings.disabled?.(
                          formContext,
                          contextValues,
                        )}
                      >
                        {settings.control(formContext, contextValues)}
                      </SettingsAccordion>
                    );
                  })}
              </div>
            </div>
          ))}
          {settingsButtons}
        </>
      )}
    </div>
  );
}

const SettingsAccordion = React.memo(function SettingsAccordion({
  settings,
  initialState,
  isActive,
  disabled,
  children,
}: {
  settings: SettingControl;
  initialState: boolean;
  isActive: boolean;
  disabled?: boolean;
  children: React.ReactNode;
}): JSX.Element {
  const { t } = useTranslation("crawlSettings");
  const classes = useStyles();

  const [expanded, setExpanded] = useState(initialState);

  return (
    <Tooltip
      title={disabled ? settings.disabledTooltip ?? "" : ""}
      placement="top"
      arrow
    >
      <Accordion
        expanded={expanded}
        onChange={(_, newValue) => setExpanded(newValue)}
        TransitionProps={{ unmountOnExit: true }}
        disabled={disabled}
        data-testid={settings.testId}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container alignItems="center">
            <Typography variant="subtitle1" style={{ lineHeight: 1.75 }}>
              {settings.title}
            </Typography>
            {settings.isNew && (
              <Chip
                label={t("settings.new")}
                color="primary"
                size="small"
                className={classes.badge}
              />
            )}
          </Grid>
          <Grow in={isActive} timeout={300}>
            <CheckIcon color="primary" className={classes.badge} />
          </Grow>
        </AccordionSummary>
        <AccordionDetails className={classes.content}>
          {children}
        </AccordionDetails>
      </Accordion>
    </Tooltip>
  );
});

function useControlStates(
  advancedSettings: SettingsGroup[],
  values: FormValues,
  contextValues: ContextValues,
): {
  activeControls: Record<string, boolean>;
  activeControlCount: number;
  initialStates: Record<string, boolean>;
} {
  const activeControls = advancedSettings.flatMap((group) =>
    group.controls.map((settings) => [
      `${group.path}.${settings.path}`,
      IsSettingsActive(
        group.path,
        settings.path,
        settings.activeValues,
        values,
        contextValues,
      ),
    ]),
  );

  const [initialStates] = useState<Record<string, boolean>>(
    Object.fromEntries(
      advancedSettings.flatMap((group) =>
        group.controls.map((settings) => [
          `${group.path}.${settings.path}`,
          IsSettingsActive(
            group.path,
            settings.path,
            settings.openOnInitValues,
            values,
            contextValues,
          ),
        ]),
      ),
    ),
  );

  return {
    activeControls: Object.fromEntries(activeControls),
    activeControlCount: activeControls.filter(([, active]) => active).length,
    initialStates: initialStates,
  };
}

function IsSettingsActive(
  groupPath: string,
  settingsPath: string,
  activeValues: string[] | undefined,
  values: FormValues,
  contextValues: ContextValues,
): boolean {
  const scriptRenderingDefaultValues =
    getScriptRenderingDefaultValues(contextValues);
  // This is a special case because even though the setting is set as enabled
  // and should be shown as active, we don't want to show it as active if the
  // default values are set. Reference: https://lumarhq.atlassian.net/browse/UI-2202
  if (groupPath === "spider" && settingsPath === "scriptRendering") {
    return !isEqual(
      scriptRenderingDefaultValues,
      values.spider.scriptRendering,
    );
  }

  return Boolean(
    activeValues?.find((value) => {
      const formValue =
        getProperty(values, `${settingsPath}.${value}`) ??
        getProperty(values, `${groupPath}.${settingsPath}.${value}`);
      return Array.isArray(formValue) ? formValue.length : formValue;
    }),
  );
}
