import { Typography, useNumberFormatter, useTranslation } from "@lumar/shared";
import { CrawlContextCrawlReport } from "../../CrawlContext";
import { ReportLink } from "./ReportLink";
import { ReportEntity } from "../../../report/Report.types";
import { ReportTypeCode } from "../../../graphql";

export function RemovedCell({
  report,
  value,
}: {
  report: CrawlContextCrawlReport;
  value?: number;
}): JSX.Element {
  const { t } = useTranslation("crawlOverview");
  const formatNumber = useNumberFormatter();

  const isCustomReport = report.id.startsWith("customReport");

  return (
    <ReportLink
      reportInput={
        isCustomReport
          ? {
              crawlId: report.crawlId,
              reportEntity: ReportEntity.CustomReport,
              customReportTemplateId: report.id.split("_")[1],
              reportTypeCode: ReportTypeCode.Removed,
              reportTemplateCode: report.reportTemplate.code,
            }
          : {
              crawlId: report.crawlId,
              reportTemplateCode: report.reportTemplate.code,
              reportEntity: ReportEntity.ReportStat,
              reportTypeCode: ReportTypeCode.Removed,
            }
      }
    >
      <Typography style={{ fontWeight: 500 }}>
        {typeof value !== "number" || Number.isNaN(value)
          ? t("data.na")
          : formatNumber(value)}
      </Typography>
    </ReportLink>
  );
}
