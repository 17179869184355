import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { ChartPanel } from "../../_common/charts/components/chart-components/ChartPanel";
import { useReportCategoryChartConfig } from "./config/useReportCategoryChartConfig";
import Alert from "@material-ui/lab/Alert";
import { useTranslation, useSession } from "@lumar/shared";
import {
  ChartConfigItem,
  TileChartConfigItem,
} from "../../_common/charts/types/ChartConfig";
import { CurrentCategoryHealthScoreSection } from "./data-visualization/charts/health-score/CurrentCategoryHealthScoreSection";
import { TopChangesChart } from "./data-visualization/charts/TopChangesChart";
import { URLFunnel } from "./data-visualization/charts/url-funnel/URLFunnel";
import { TopErrorsChartPanel } from "./data-visualization/charts/TopErrorsChart";
import { useCrawlOverviewContext } from "../CrawlOverviewContext";
import { ChartData } from "../../_common/charts/components/chart-components/ChartData";
import { useChartDataContext } from "../../_common/charts/components/chart-components/ChartDataContext";
import {
  ChartComponent,
  ChartComponentProps,
} from "../../_common/charts/components/chart-components/ChartComponent";
import { ChartSkeleton } from "../../_common/charts/components/chart-components/ChartSkeleton";
import { isPredefinedChartConfig } from "./config/types";
import { TileChartComponent } from "./data-visualization/charts/tile-chart/TileChartComponent";

function getKey(config: ChartConfigItem): string {
  return config.title([]) + config.visualisationType;
}

export function Dashboard(): JSX.Element | null {
  const { t } = useTranslation("crawlOverview");
  const { data } = useCrawlOverviewContext();
  const classes = useStyles();
  const { tileCharts, mainCharts } = useReportCategoryChartConfig();
  const session = useSession();

  return (
    <div style={{ marginTop: 14 }}>
      <CurrentCategoryHealthScoreSection />
      {Boolean(tileCharts.length) && (
        <div className={classes.tileContainer}>
          {tileCharts.map((chartConfigItem, idx) => (
            <div className={classes.tile} key={idx}>
              <ChartData {...chartConfigItem}>
                <TileChartRenderer {...chartConfigItem} />
              </ChartData>
            </div>
          ))}
        </div>
      )}
      {data?.selectedCategory?.code === "top" ? <URLFunnel /> : null}
      {mainCharts.length ? (
        <Grid container spacing={2} className={classes.root}>
          {!session.hasAddon("health-scores") ? (
            <Grid item xs={12} lg={6}>
              <TopErrorsChartPanel />
            </Grid>
          ) : null}
          {mainCharts.map((chartConfigItem, idx) => {
            if (!isPredefinedChartConfig(chartConfigItem)) {
              const key = getKey(chartConfigItem);
              return (
                <ChartData key={key} {...chartConfigItem}>
                  <ChartRender {...chartConfigItem} />
                </ChartData>
              );
            }

            if (chartConfigItem.predefinedChartType === "topChanges") {
              return (
                <Grid
                  key={`${chartConfigItem.predefinedChartType}-${idx}`}
                  item
                  xs={12}
                  lg={6}
                >
                  <TopChangesChart />
                </Grid>
              );
            }
          })}
        </Grid>
      ) : (
        <Alert severity="info">{t("dashboard.noCharts")}</Alert>
      )}
    </div>
  );
}

function ChartRender(props: ChartComponentProps): JSX.Element {
  const { reports, loading } = useChartDataContext();

  if (
    (props.hideChartWithoutData && !reports.length) ||
    (props.hideChartWithoutData && loading)
  ) {
    return <></>;
  }

  return (
    <Grid item xs={12} lg={6}>
      <ChartPanel>
        {loading ? <ChartSkeleton /> : <ChartComponent {...props} />}
      </ChartPanel>
    </Grid>
  );
}

function TileChartRenderer(props: TileChartConfigItem): JSX.Element {
  const { loading } = useChartDataContext();

  return (
    <>
      {loading ? (
        <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
          <div style={{ margin: "auto" }}>
            <CircularProgress />
          </div>
        </div>
      ) : (
        <TileChartComponent {...props} />
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "7px",
  },
  tileContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 15,
    marginTop: "16px",
    width: "100%",
    justifyContent: "center",
  },
  tile: {
    display: "block",
    background: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    flexGrow: 1,
    flexBasis: 0,
    color: "inherit",
    padding: 8,
    height: 169,
    minWidth: 210,
    [theme.breakpoints.down("md")]: {
      minWidth: 292,
      maxWidth: "100%",
    },
  },
}));
