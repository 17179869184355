import React, { useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { DatePicker, TimeZoneContext, useTranslation } from "@lumar/shared";
import { addMinutes } from "date-fns";

const useStyles = makeStyles((theme) => ({
  picker: {
    width: 181,
  },
  pickerToolbar: {
    order: 1,
  },
  clearBtn: {
    margin: theme.spacing(2, 0.7, 2, 3),
    color: theme.palette.grey[700],
  },
}));

interface DeadlineSelectProps {
  value: Date | null;
  disabled: boolean;
  onChange: (value: Date | null) => void;
}

export function DeadlineSelect({
  value,
  onChange,
  ...props
}: DeadlineSelectProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("taskManager");

  const [open, setOpen] = useState(false);

  const { timeZone } = React.useContext(TimeZoneContext);

  const convertedValue = value
    ? addMinutes(value, value.getTimezoneOffset() + timeZone.offset)
    : null;

  return (
    <DatePicker
      value={convertedValue}
      onChange={(value) => {
        const convertedValue = value
          ? addMinutes(value, -value.getTimezoneOffset() - timeZone.offset)
          : null;
        onChange(convertedValue);
      }}
      autoOk
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      disableToolbar={false}
      PopoverProps={{
        PaperProps: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          "data-testid": "deadline-select-popover",
        },
      }}
      ToolbarComponent={() => {
        return (
          <div className={classes.pickerToolbar}>
            <Button
              variant="outlined"
              onClick={() => {
                onChange(null);
                setOpen(false);
              }}
              className={classes.clearBtn}
              data-testid="deadline-select-clear"
            >
              {t("editDialog.clear")}
            </Button>
          </div>
        );
      }}
      clearable={true}
      className={classes.picker}
      {...props}
    />
  );
}
