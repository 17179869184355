/* eslint-disable react/display-name */
import { TFunction } from "i18next";
import { Theme } from "@material-ui/core";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";
import { ModuleCode, OrderDirection, ReportOrderField } from "../../../graphql";

export function getCustomExtractionChart(
  t: TFunction<"charts">,
  theme: Theme,
): ChartConfigItem {
  return {
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Bar,
    visualisationColors: [theme.palette.green[300]],
    supportedModules: [
      ModuleCode.Accessibility,
      ModuleCode.Seo,
      ModuleCode.Basic,
    ],
    title: () => t("customExtraction.title"),
    description: () => t("customExtraction.description"),
    reports: () => ({
      first: 9,
      orderBy: [
        {
          field: ReportOrderField.TotalRows,
          direction: OrderDirection.Desc,
        },
        {
          field: ReportOrderField.ReportTemplateCode,
          direction: OrderDirection.Asc,
        },
      ],
      filter: {
        totalRows: {
          gt: 0,
        },
        reportTemplateCode: Array.from(
          { length: 30 },
          (_, i) => `custom_extraction_${i + 1}`,
        ),
      },
      fields: {
        totalRows: true,
        reportTemplate: {
          code: true,
          name: true,
        },
      },
    }),
    bindings: {
      name: (report) => {
        return report.reportTemplate.name;
      },
      count: (report) => {
        return report.totalRows ?? 0;
      },
      nameInTooltip: (report) => {
        return report.reportTemplate.name;
      },
    },
  };
}
