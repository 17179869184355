import React from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import { TooltipInfoIcon, Typography } from "@lumar/shared";
import { kebabCase } from "lodash";

interface TileChartPanelTitleProps {
  title: string;
  description?: React.ReactNode;
}

export function TileChartPanelTitle({
  title,
  description,
}: TileChartPanelTitleProps): JSX.Element {
  const classes = useStyles();
  const titleTestAttribute = `${kebabCase(title.toLowerCase())}-title`;

  return (
    <div className={classes.root}>
      <Typography
        variant="h6Medium"
        data-testid={titleTestAttribute}
        noWrap
        color="textPrimary"
        className={classes.title}
      >
        {title}
      </Typography>
      {Boolean(description) && (
        <Tooltip
          title={
            <>
              <Typography
                component="h6"
                variant="subtitle3Medium"
                className={classes.tooltipTitle}
              >
                {title}
              </Typography>
              <Typography variant="caption">{description}</Typography>
            </>
          }
          arrow
          placement="top"
          interactive
          classes={{ tooltip: classes.tooltip }}
        >
          <TooltipInfoIcon className={classes.tooltipIcon} />
        </Tooltip>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(22),
    color: theme.palette.grey[800],
    fontWeight: 600,
    textOverflow: "ellipsis",
    padding: theme.spacing(0.75, 0.75, 0, 1.75),
  },
  tooltip: {
    padding: theme.spacing(1.5, 1.5, 2, 1.5),
    minWidth: "430px",
    maxWidth: "430px",
  },
  tooltipIcon: {
    fontSize: theme.typography.pxToRem(18),
    fill: theme.palette.grey[400],
    marginRight: theme.spacing(0.375),
  },
  tooltipTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
}));
