/* eslint-disable react/display-name */
import React from "react";
import { TFunction } from "i18next";

import {
  ChartConfigItem,
  LinkData,
  VisualisationTypes,
} from "../types/ChartConfig";
import { ReportTrendItem, ReportTypeCode } from "../../../graphql";
import { Routes } from "../../routing/routes";
import { ReportInResponse } from "./generateChartQuery";
import { RunNewCrawlMessage } from "../components/chart-messages/RunNewCrawlMessage";
import { Theme } from "@material-ui/core";
import { DateFormatter } from "@lumar/shared";

export function generateSingleReportTrendChart(reportTemplateCode: string) {
  return function (
    t: TFunction<"charts">,
    theme: Theme,
    formatter: DateFormatter,
  ): ChartConfigItem {
    return {
      visualisationFamily: "MultiDimensionalSeries",
      visualisationType: VisualisationTypes.Area,
      visualisationColors: [theme.palette.singleLineChart.color],
      visualisationFillColors: [theme.palette.singleLineChart.fillColor],
      additionalVisualisationTypes: [
        VisualisationTypes.StackedArea,
        VisualisationTypes.FullStackedArea,
      ],
      title: (reports) =>
        reports[0]?.reportTemplate.name ?? t("reportTrend.fallbackTitle"),
      reports: () => ({
        filter: {
          reportTypeCode: ReportTypeCode.Basic,
          reportTemplateCode,
        },
        fields: {
          reportTemplate: {
            name: true,
          },
          totalRows: true,
          reportTrend: {
            basic: true,
            crawlId: true,
            createdAt: true,
          },
        },
      }),
      bindings: {
        series: (
          reports: ReportInResponse[],
          { accountId, projectId, segmentId }: LinkData,
        ) =>
          reports.map((report) => {
            return {
              name: report.reportTemplate.name,
              reportTemplateCode: report.reportTemplate.code,
              data: (report.reportTrend ?? [])
                .filter(
                  (
                    trend,
                  ): trend is ReportTrendItem & {
                    createdAt: string;
                  } => !!trend?.createdAt,
                )
                .map((trend) => {
                  const createdAt = new Date(trend.createdAt);
                  return {
                    x: createdAt,
                    y: trend.basic ?? 0,
                    url: Routes.Report.getUrl({
                      accountId,
                      projectId,
                      crawlId: String(trend.crawlId),
                      segmentId,
                      reportTemplateCode: report.reportTemplate.code,
                      reportTypeCode: ReportTypeCode.Basic.toLowerCase(),
                    }),
                    reportTemplateCode: report.reportTemplate.code,
                    additionalTooltipLines: [
                      formatter(createdAt, {
                        dateStyle: "medium",
                        timeStyle: "short",
                      }),
                    ],
                  };
                }),
            };
          }),
      },
      incompleteSeriesTemplate: (
        <RunNewCrawlMessage message={t("runNewCrawlForTrendMessage")} />
      ),
      testAttributePrefix: "report-trend-chart",
    };
  };
}
