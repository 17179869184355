import {
  GetCrawlSettingsForDomainQuery,
  ModuleCode,
  WcagLevel,
} from "../../../../graphql";
import { formatCookiesSettingsFrom } from "../../settings/data/formatters/formatCookiesSettings";
import { formatUserAgentSettingsFrom } from "../../settings/data/formatters/formatUserAgentSettings";
import { FormValues } from "./types";

export function getFormValues(
  data: GetCrawlSettingsForDomainQuery | undefined,
  moduleCode: ModuleCode,
  accessibilityContainerId: string | undefined,
  hasJsRendering: boolean | undefined,
): FormValues {
  return {
    basic: {
      primaryDomain: data?.getProject?.primaryDomain ?? "",
      name: data?.getProject?.name ?? "",
      crawlSubDomains: data?.getProject?.includeSubdomains ?? false,
      crawlHttpAndHttps: data?.getProject?.includeHttpAndHttps ?? false,
      industry: data?.getProject?.industryCode ?? "",
      useRenderer:
        data?.getProject?.useRenderer ?? hasJsRendering ? true : false,
    },
    wcagLevelAndVersion: {
      wcagLevel: data?.getProject?.selectedWcagLevel ?? WcagLevel.Aaa,
      wcagVersion: data?.getProject?.selectedWcagVersion ?? 2.2,
      includeBestPractices: data?.getProject?.includeBestPractices ?? true,
    },
    userAgent: formatUserAgentSettingsFrom(data?.getProject, moduleCode),
    cookies: formatCookiesSettingsFrom(data?.getProject),
    customScripts: {
      accessibility:
        data?.getProject?.customMetricContainerProjects.nodes.find(
          (x) => x.customMetricContainer.id === accessibilityContainerId,
        )?.customJsScripts?.[0] || "",
    },
  };
}
