/* eslint-disable react/display-name */
import { DateFormatter } from "@lumar/shared";
import { GridColDef } from "@mui/x-data-grid-pro";
import { RoleCode } from "../../graphql";
import { TaskMutations } from "../data/useTaskMutations";
import { ActionsCell } from "./actions-cell/ActionsCell";
import { AssignedToCell } from "./AssignedToCell";
import { CreatedDateCell, createdDateSort } from "./CreatedDateCell";
import { DeadlineDateCell, deadlineDateSort } from "./DeadlineDateCell";
import { IdentifiedCell } from "./IdentifiedCell";
import { LinkCell } from "./LinkCell";
import { ProjectCell } from "./ProjectCell";
import { ReportCell } from "./ReportCell";
import { SeverityCell, severitySort } from "./SeverityCell";
import { StatusDeadlineCell, statusDeadlineSort } from "./StatusDeadlineCell";
import { TaskCell } from "./TaskCell/TaskCell";
import { TrendCell } from "./trend-cell/TrendCell";
import { UnresolvedCell } from "./UnresolvedCell";
import { UnresolvedPercentCell } from "./UnresolvedPercentCell";
import { TFunction } from "i18next";

export interface ColumnProps {
  t: TFunction<"taskManager">;
  dateFormatter: DateFormatter;
}

export type TaskManagerGridColumns = GridColDef & {
  minimumRole: RoleCode;
};

export const getSeverityColumn = (
  { t }: ColumnProps,
  sortable: boolean,
): TaskManagerGridColumns => ({
  field: "severity",
  headerName: t("tableColumns.severity"),
  flex: 1,
  minWidth: 100,
  sortable: sortable,
  filterable: false,
  disableColumnMenu: true,
  sortComparator: severitySort,
  valueGetter: (params) => params.row.priority,
  renderCell: (props) => <SeverityCell {...props} />,
  minimumRole: RoleCode.Viewer,
});

export const getTaskColumn = ({ t }: ColumnProps): TaskManagerGridColumns => ({
  field: "task",
  headerName: t("tableColumns.task"),
  flex: 2,
  minWidth: 100,
  sortable: false,
  filterable: true,
  disableColumnMenu: true,
  valueGetter: (params) => params.row.title,
  renderCell: (props) => <TaskCell {...props} />,
  minimumRole: RoleCode.Viewer,
});

export const getDescriptionColumn =
  ({}: ColumnProps): TaskManagerGridColumns => ({
    field: "description",
    headerName: "Description",
    minimumRole: RoleCode.Viewer,
    hide: true,
    disableExport: false,
  });

export const getProjectColumn = ({
  t,
}: ColumnProps): TaskManagerGridColumns => ({
  field: "project",
  headerName: t("tableColumns.project"),
  flex: 2,
  minWidth: 100,
  sortable: false,
  filterable: true,
  disableColumnMenu: true,
  valueGetter: (params) => params.row.project?.name,
  renderCell: (props) => <ProjectCell {...props} />,
  minimumRole: RoleCode.Viewer,
});

export const getReportColumn = (
  { t }: ColumnProps,
  sortable: boolean,
): TaskManagerGridColumns => ({
  field: "report",
  headerName: t("tableColumns.report"),
  flex: 1,
  minWidth: 100,
  sortable: sortable,
  filterable: true,
  disableColumnMenu: true,
  hide: true,
  valueGetter: (params) => params.row.reportTemplate?.name ?? "",
  renderCell: (props) => <ReportCell {...props} />,
  minimumRole: RoleCode.Viewer,
});

export const getStatusDeadlineColumn = (
  { t, dateFormatter }: ColumnProps,
  sortable: boolean,
): TaskManagerGridColumns => ({
  field: "statusDeadline",
  headerName: t("tableColumns.statusDeadline"),
  flex: 1,
  minWidth: 100,
  sortable: sortable,
  filterable: true,
  disableColumnMenu: true,
  sortComparator: statusDeadlineSort,
  valueGetter: ({ row: { fixedAt, deadlineAt } }) => {
    if (deadlineAt && !fixedAt)
      return dateFormatter(new Date(deadlineAt), { dateStyle: "medium" });
    if (fixedAt) return t("statusDeadlineCell.resolved");
    return t("statusDeadlineCell.outstanding");
  },
  renderCell: (props) => <StatusDeadlineCell {...props} />,
  minimumRole: RoleCode.Viewer,
  filterOperators: [
    {
      value: "showResolved",
      label: "showResolved",
      getApplyFilterFn:
        (filterItem) =>
        (params): boolean =>
          filterItem.value || !params.row.fixedAt,
    },
  ],
});

export const getCreatedAtColumn = (
  { t, dateFormatter }: ColumnProps,
  sortable: boolean,
): TaskManagerGridColumns => ({
  field: "createdAt",
  headerName: t("tableColumns.created"),
  flex: 1,
  minWidth: 100,
  sortable: sortable,
  filterable: true,
  disableColumnMenu: true,
  hide: true,
  sortComparator: createdDateSort,
  valueGetter: ({ row: { createdAt } }) =>
    dateFormatter(new Date(createdAt), { dateStyle: "medium" }),
  renderCell: (props) => <CreatedDateCell {...props} />,
  minimumRole: RoleCode.Viewer,
});

export const getDeadlineColumn = (
  { t, dateFormatter }: ColumnProps,
  sortable: boolean,
): TaskManagerGridColumns => ({
  field: "deadlineAt",
  headerName: t("tableColumns.deadline"),
  flex: 1,
  minWidth: 100,
  sortable: sortable,
  filterable: true,
  disableColumnMenu: true,
  hide: true,
  sortComparator: deadlineDateSort,
  valueGetter: ({ row: { deadlineAt } }) =>
    deadlineAt
      ? dateFormatter(new Date(deadlineAt), { dateStyle: "medium" })
      : "",
  renderCell: (props) => <DeadlineDateCell {...props} />,
  minimumRole: RoleCode.Viewer,
});

export const getTrendColumn = ({ t }: ColumnProps): TaskManagerGridColumns => ({
  field: "trend",
  headerName: t("tableColumns.trend"),
  flex: 1,
  minWidth: 192,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  disableExport: true,
  renderCell: (props) => <TrendCell {...props} />,
  minimumRole: RoleCode.Viewer,
});

export const getIdentifiedColumn = (
  { t }: ColumnProps,
  sortable: boolean,
): TaskManagerGridColumns => ({
  field: "identified",
  headerName: t("tableColumns.identified"),
  description: t("idefntifiedTooltip"),
  flex: 1,
  minWidth: 100,
  sortable: sortable,
  filterable: false,
  disableColumnMenu: true,
  hide: true,
  valueGetter: (params) => params.row.identified || 0,
  renderCell: (props) => <IdentifiedCell {...props} />,
  align: "right",
  minimumRole: RoleCode.Viewer,
});

export const getUnresolvedColumn = (
  { t }: ColumnProps,
  sortable: boolean,
): TaskManagerGridColumns => ({
  field: "unresolved",
  headerName: t("tableColumns.unresolved"),
  description: t("unresolvedTooltip"),
  flex: 1,
  minWidth: 100,
  sortable: sortable,
  filterable: false,
  disableColumnMenu: true,
  valueGetter: (params) => params.row.remaining || 0,
  renderCell: (props) => <UnresolvedCell {...props} />,
  align: "right",
  minimumRole: RoleCode.Viewer,
});

export const getUnresolvedPercentColumn = (
  { t }: ColumnProps,
  sortable: boolean,
): TaskManagerGridColumns => ({
  field: "unresolvedPercent",
  headerName: t("tableColumns.unresolvedPercent"),
  description: t("unresolvedPercentTooltip"),
  flex: 1,
  minWidth: 100,
  sortable: sortable,
  filterable: false,
  disableColumnMenu: true,
  valueGetter: (params) =>
    Boolean(params.row.identified)
      ? Math.ceil(((params.row.remaining || 0) / params.row.identified) * 100)
      : 0,
  renderCell: (props) => <UnresolvedPercentCell {...props} />,
  align: "right",
  minimumRole: RoleCode.Viewer,
});

export const getAssignedToColumn = ({
  t,
}: ColumnProps): TaskManagerGridColumns => ({
  field: "assignedTo",
  headerName: t("tableColumns.assignedTo"),
  flex: 1,
  minWidth: 100,
  sortable: false,
  filterable: false,
  valueGetter: (params) =>
    params.row.assignedTo && params.row.assignedTo.join(", "),
  renderCell: (props) => <AssignedToCell {...props} />,
  hide: true,
  disableColumnMenu: true,
  minimumRole: RoleCode.Viewer,
});

export const getLinkColumn = ({ t }: ColumnProps): TaskManagerGridColumns => ({
  field: "link",
  headerName: t("tableColumns.link"),
  flex: 1,
  minWidth: 100,
  sortable: false,
  filterable: false,
  disableExport: true,
  hide: true,
  renderCell: (props) => <LinkCell {...props} />,
  disableColumnMenu: true,
  minimumRole: RoleCode.Viewer,
});

export const getActionsColumn = (
  { t }: ColumnProps,
  { setResolved, removeTask }: TaskMutations,
): TaskManagerGridColumns => ({
  field: "actions",
  headerName: t("tableColumns.actions"),
  flex: 1,
  minWidth: 100,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  headerAlign: "center",
  align: "center",
  disableExport: true,
  renderCell: (props) => (
    <ActionsCell {...props} setResolved={setResolved} removeTask={removeTask} />
  ),
  minimumRole: RoleCode.Editor,
});
