import { ReportCategoryChartConfig } from "../../types";
import { getSiteSpeedLCPChart } from "../../../../../_common/charts/config/getSiteSpeedLCPChart";
import { getSiteSpeedLCPTrend } from "../../../../../_common/charts/config/getSiteSpeedLCPTrend";
import { getSiteSpeedErrorsChart } from "../../../../../_common/charts/config/getSiteSpeedErrorsChart";
import { getSiteSpeedErrorsTable } from "../../../../../_common/charts/config/getSiteSpeedErrorsTable";
import { getSiteSpeedAvgLCPChart } from "../../../../../_common/charts/config/getSiteSpeedAvgLCPChart";
import { getSiteSpeedAllReportsTable } from "../../../../../_common/charts/config/getSiteSpeedAllReportsTable";

export const generateSiteSpeedLargestContentfulPaintCategoryItems: ReportCategoryChartConfig =
  {
    errorsChart: getSiteSpeedErrorsChart,
    errorsTable: getSiteSpeedErrorsTable,
    allReportsTable: getSiteSpeedAllReportsTable,
    mainCharts: [
      // getSiteSpeedLCPMetricTrend,
      getSiteSpeedAvgLCPChart,
      getSiteSpeedLCPChart,
      getSiteSpeedLCPTrend,
    ],
  };
