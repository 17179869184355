import React from "react";
import { Typography, Grid, makeStyles } from "@material-ui/core";
import {
  FastField,
  Field,
  FastFieldProps,
  FieldProps,
  Formik,
  FormikProps,
} from "formik";
import { TextField as FormikTextField } from "formik-material-ui";
import {
  ExclamationCircleSolid,
  fieldToSelect,
  fieldToTextField,
  getProperty,
  SwitchField,
  TagInput,
  TextField,
  useBrand,
  useTranslation,
} from "@lumar/shared";

import { PrioritySelect } from "./PrioritySelect";
import { DeadlineSelect } from "./DeadlineSelect";
import { CustomSkeleton } from "../../_common/CustomSkeleton";
import { FormValues } from "./useFormValues";
import { useTaskSchema } from "./useTaskSchema";

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(0.5),
    fontWeight: 500,
  },
  input: {
    width: "100%",
  },
  skeleton: {
    transform: "none",
    background: theme.palette.grey[200],
  },
  errorIcon: {
    verticalAlign: "text-bottom",
    marginRight: theme.spacing(0.5),
  },
  errorTitle: {
    color: theme.palette.red[500],
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 600,
  },
  errorMessage: {
    color: theme.palette.red[500],
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(1.5),
  },
}));

export interface EditTaskFormProps {
  values: FormValues;
  loading?: boolean;
  error?: { title: string; message: string };
  disabled?: boolean;
  showStatus?: boolean;
  onSubmit: (values: FormValues) => Promise<boolean>;
  children: (
    form: React.ReactNode,
    props: FormikProps<FormValues>,
  ) => React.ReactNode;
}

export function EditTaskForm({
  values,
  loading,
  error,
  disabled,
  showStatus,
  onSubmit,
  children,
}: EditTaskFormProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("taskManager");
  const brand = useBrand();

  const schema = useTaskSchema();

  const form = (
    <Grid container spacing={1}>
      {error && (
        <Grid item xs={12}>
          <Typography
            className={classes.errorTitle}
            data-testid="edit-task-error"
          >
            <ExclamationCircleSolid className={classes.errorIcon} />
            {error.title}
          </Typography>
          <Typography className={classes.errorMessage}>
            {error.message}
          </Typography>
        </Grid>
      )}
      {showStatus && (
        <Grid item xs={4}>
          <LoadingWrapper loading={loading} height="61px">
            <FastField name="resolved">
              {({
                field: { value, name },
                form: { setFieldValue, isSubmitting },
              }: FastFieldProps<boolean>) => (
                <SwitchField
                  id="edit-task-resolved"
                  checked={value}
                  onChange={(_, value) => setFieldValue(name, value)}
                  label={t("editDialog.status")}
                  offLabel={t("editDialog.open")}
                  onLabel={t("editDialog.resolved")}
                  disabled={disabled || isSubmitting}
                  data-testid="edit-task-resolved"
                />
              )}
            </FastField>
          </LoadingWrapper>
        </Grid>
      )}
      <Grid item xs={3}>
        <LoadingWrapper loading={loading} height="61px">
          <FastField name="priority">
            {(props: FieldProps<string>) => (
              <PrioritySelect
                {...fieldToSelect(props)}
                className={classes.input}
                label={t("editDialog.priority")}
                disabled={disabled}
              />
            )}
          </FastField>
        </LoadingWrapper>
      </Grid>
      <Grid item xs={4}>
        <LoadingWrapper loading={loading} height="61px">
          <Typography className={classes.label}>
            {t("editDialog.deadline")}
          </Typography>
          <Field name="deadline">
            {({
              field: { value, name },
              form: { setFieldValue, isSubmitting },
            }: FieldProps<Date | null>) => (
              <DeadlineSelect
                value={value}
                onChange={(value: Date | null) => setFieldValue(name, value)}
                disabled={disabled || isSubmitting}
                data-testid="edit-task-deadline"
              />
            )}
          </Field>
        </LoadingWrapper>
      </Grid>
      <Grid item xs={12}>
        <LoadingWrapper loading={loading} height="60px">
          <FastField
            id="edit-task-title"
            name="title"
            component={FormikTextField}
            className={classes.input}
            variant="outlined"
            label={t("editDialog.title")}
            disabled={disabled}
            data-testid="edit-task-title"
          />
        </LoadingWrapper>
      </Grid>
      <Grid item xs={12}>
        <LoadingWrapper loading={loading} height="124px">
          <FastField name="description">
            {(props: FieldProps<string>) => (
              <TextField
                {...fieldToTextField(props)}
                id="edit-task-description"
                name="description"
                className={classes.input}
                label={t("editDialog.description")}
                placeholder={t("editDialog.desctiptionPlaceholder")}
                multiline
                maxRows={5}
                minRows={5}
                disabled={disabled || props.form.isSubmitting}
                data-testid="edit-task-description"
              />
            )}
          </FastField>
        </LoadingWrapper>
      </Grid>
      {brand.featureAvailability.taskManagerEmailAssignment ? (
        <Grid item xs={12}>
          <LoadingWrapper loading={loading} height="90px">
            <FastField name="assigneeEmail">
              {({
                field: { value, name },
                form: { setFieldValue, errors, isSubmitting },
              }: FastFieldProps<string[]>) => {
                const fieldErrors: string[] | undefined = getProperty(
                  errors,
                  name,
                );
                return (
                  <TagInput
                    id="edit-task-assignee-email"
                    value={value}
                    onChange={(value) => {
                      setFieldValue(name, value);
                    }}
                    variant="outlined"
                    addOnComma
                    addOnSpace
                    disabled={disabled || isSubmitting}
                    className={classes.input}
                    label={t("editDialog.assigneeEmail")}
                    placeholder={t("editDialog.assigneeEmailPlaceholder")}
                    error={fieldErrors?.find(Boolean)}
                    errors={fieldErrors?.map((error) => Boolean(error))}
                    data-testid="edit-task-assignee-email"
                    tagProps={{
                      "data-testid": "edit-task-assignee-email-item",
                    }}
                  />
                );
              }}
            </FastField>
          </LoadingWrapper>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <LoadingWrapper loading={loading} height="158px">
          <FastField name="howToFix">
            {(props: FieldProps<string>) => (
              <TextField
                {...fieldToTextField(props)}
                id="edit-task-howToFix"
                label={t("editDialog.howToFix")}
                placeholder={t("editDialog.howToFixPlaceholder")}
                disabled={disabled || props.form.isSubmitting}
                multiline
                maxRows={7}
                minRows={7}
                className={classes.input}
                data-testid="edit-task-howToFix"
              />
            )}
          </FastField>
        </LoadingWrapper>
      </Grid>
    </Grid>
  );

  return (
    <Formik
      initialValues={values}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {(formikProps) => children(form, formikProps)}
    </Formik>
  );
}

function LoadingWrapper({
  loading,
  children,
  height,
}: {
  loading?: boolean;
  children: React.ReactNode;
  height?: number | string;
}): JSX.Element {
  const classes = useStyles();

  return (
    <>
      {loading ? (
        <CustomSkeleton
          animation="wave"
          className={classes.skeleton}
          height={height}
        />
      ) : (
        children
      )}
    </>
  );
}
