/* eslint-disable react/display-name */
import { Theme, Typography } from "@material-ui/core";
import { Trans } from "react-i18next";
import { TFunction } from "i18next";
import { CrawlType, ReportTypeCode } from "../../../graphql";
import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getCrawlSourceOverlapSitemapsChart(
  t: TFunction<"charts">,
  theme: Theme,
): ChartConfigItem {
  return {
    visualisationFamily: "Venn",
    visualisationType: VisualisationTypes.Venn,
    defaultSelectedCrawlType: CrawlType.Sitemap,
    visualisationColors: [
      theme.palette.grey[500],
      theme.palette.yellow[300],
      theme.palette.green[500],
      theme.palette.red[500],
    ],
    title: () => t("crawlSourceOverlap.title"),
    description: () => (
      <Trans ns="charts" i18nKey="crawlSourceOverlap.description">
        <Typography paragraph variant="inherit">
          This chart shows the overlapping and non-overlapping URLs between a
          single &apos;primary&apos; source of URLs and all other sources
          included in the crawl.
        </Typography>
        <Typography paragraph variant="inherit">
          It can be used to identify groups of URLs which are only in the
          primary source, or only in the compared sources.
        </Typography>
        <Typography variant="inherit">
          The source with the largest number of URLs will be the default source
          against which the others are compared. This can be changed in the
          graph configuration.
        </Typography>
      </Trans>
    ),
    includeMultipleCrawlTypes: true,
    totalUrlsCount: { enabled: true, reportTemplateCode: "indexable_pages" },
    crawlTypeCounts: { enabled: true, reportTemplateCode: "indexable_pages" },
    reports: () => ({
      first: 1,
      filter: {
        reportTypeCode: ReportTypeCode.Basic,
        reportTemplateCode: ["indexable_pages"],
      },
      fields: {
        id: true,
      },
    }),
    bindings: {
      series: (_, linkData, totalUrls, crawl) => {
        const data = (crawl?.crawlTypeCounts || []).map(
          ({ crawlTypes, count }) => {
            return {
              sets: crawlTypes,
              value: count,
              totalUrls,
            };
          },
        );

        const supportedCrawlTypesData = data.filter((datum) =>
          datum.sets.every((set) => crawl?.crawlTypes.includes(set)),
        );

        return {
          name: "URLs",
          data: supportedCrawlTypesData,
        };
      },
    },
    testAttributePrefix: "crawl-source-overlap-chart",
  };
}
