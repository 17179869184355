import { getRawCrawlId, TimeZoneContext, useTranslation } from "@lumar/shared";
import { Options } from "highcharts";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "../_common/routing/routes";
import { OverviewType } from "../crawl-overview/types";
import {
  CrawlProgressFragment,
  CrawlStatus,
  CrawlType,
  RunningCrawlQuery,
  RunningCrawlUpdateQuery,
  useRunningCrawlQuery,
  useRunningCrawlUpdateQuery,
} from "../graphql";
import { getSpeedChartOptions } from "./crawl-progress/progress-dashboard/crawl-speed-chart/chartOptions";
import { getLevelsChartOptions } from "./crawl-progress/progress-dashboard/urls-crawled-chart/chartOptions";
import { getRequestRateChartOptions } from "./crawl-progress/progress-dashboard/crawl-request-rate-chart/chartOptions";

export interface RunningCrawl {
  id: string;
  pauseReason?: string;
  sources?: CrawlType[];
  status: CrawlStatus;
  activeMaxCrawlRate?: number;
  runningTotal: number;
  crawlSpeed: number;
  crawlStat?: CrawlProgressFragment["crawlStat"];
  updatedAt: string;
  finishedAt: string;
  createdAt: string;
}

interface PollerResponse {
  project?: RunningCrawlQuery;
  runningCrawl?: RunningCrawl;
  speedChartOptions: Options;
  requestRateChartOptions: Options;
  levelsChartOptions: Options;
}

export function useProjectPoller(): PollerResponse {
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId: string;
  }>();
  const history = useHistory();

  const { data: project, refetch } = useRunningCrawlQuery({
    variables: { projectId },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    skip: !projectId,
  });

  const runningCrawl = project?.getProject?.crawls?.edges[0]?.node;
  const runningCrawlId = runningCrawl?.id;

  const {
    data: crawl,
    error,
    startPolling,
    stopPolling,
  } = useRunningCrawlUpdateQuery({
    variables: {
      crawlId: runningCrawlId ?? "",
    },
    onCompleted: (crawl) => {
      if (crawl.getCrawl?.statusEnum === CrawlStatus.Finished) {
        history.push(
          Routes.CrawlOverview.getUrl({
            accountId,
            projectId,
            crawlId: getRawCrawlId(crawl.getCrawl.id),
            type: OverviewType.Dashboard,
          }),
        );
      }
      if (crawl.getCrawl === null) refetch();
    },
    onError: () => {
      stopPolling();
    },
    fetchPolicy: "network-only",
    skip: !runningCrawlId,
  });

  useEffect(() => {
    if (!runningCrawlId || error) return;
    startPolling(5000);
    return () => {
      stopPolling();
    };
  }, [runningCrawlId, startPolling, stopPolling, error]);

  const { speedChartOptions, requestRateChartOptions, levelsChartOptions } =
    useChartOptions(
      crawl?.getCrawl || runningCrawl || undefined,
      runningCrawl?.crawlSetting?.useStealthMode ?? false,
    );

  return {
    project,
    runningCrawl: getRunningCrawl(project, crawl),
    speedChartOptions,
    requestRateChartOptions,
    levelsChartOptions,
  };
}

function getRunningCrawl(
  project: RunningCrawlQuery | undefined,
  crawl: RunningCrawlUpdateQuery | undefined,
): RunningCrawl | undefined {
  const projectCrawl = project?.getProject?.crawls?.edges[0]?.node;
  const crawlUpdate = crawl?.getCrawl ? crawl.getCrawl : projectCrawl;

  if (!projectCrawl || !crawlUpdate) return;
  return {
    id: getRawCrawlId(projectCrawl.id),
    sources: projectCrawl?.crawlTypes,
    status: crawlUpdate.statusEnum,
    pauseReason: crawlUpdate.pauseReason ?? undefined,
    activeMaxCrawlRate:
      crawlUpdate.crawlSetting?.activeMaximumCrawlRateAdvanced
        ?.maximumCrawlRate,
    runningTotal:
      crawlUpdate.crawlStat?.crawlLevels.reduce(
        (acc, p) => p.stepsProcessed + acc,
        0,
      ) || 0,
    crawlSpeed: crawlUpdate.crawledPerSecond ?? 0,
    crawlStat: crawlUpdate.crawlStat,
    updatedAt: projectCrawl.updatedAt ?? "",
    finishedAt: projectCrawl.finishedAt ?? "",
    createdAt: projectCrawl.createdAt,
  };
}

function useChartOptions(
  crawl: CrawlProgressFragment | undefined,
  isUsingStealthMode: boolean,
): Pick<
  PollerResponse,
  "speedChartOptions" | "requestRateChartOptions" | "levelsChartOptions"
> {
  const { t } = useTranslation("crawlProgress");
  const { timeZone } = React.useContext(TimeZoneContext);

  const offsetInMs = timeZone.offset * 60 * 1000;

  const limits = React.useRef<[number, number][]>([]);

  return React.useMemo(() => {
    const limit = getCurrentCrawlRateLimit(crawl, isUsingStealthMode);
    // eslint-disable-next-line fp/no-mutating-methods
    if (limit) limits.current.push([new Date().getTime(), limit]);

    const progressData =
      crawl?.crawlStat?.crawlRates.map((x) => {
        const time = new Date(x.crawlingAt).getTime();
        return {
          time,
          rate: x.rate,
          limit: getClosestCrawlRateLimit(limits.current, time),
        };
      }) || [];

    return {
      speedChartOptions: getSpeedChartOptions(
        t,
        {
          speed: progressData.map((x) => [x.time, x.rate]),
          limit: progressData.map((x) => [x.time, x.limit]),
        },
        offsetInMs,
      ),
      requestRateChartOptions: getRequestRateChartOptions(t, crawl, offsetInMs),
      levelsChartOptions: getLevelsChartOptions(t, crawl),
    };
  }, [t, crawl, offsetInMs, isUsingStealthMode]);
}

function getCurrentCrawlRateLimit(
  crawl: CrawlProgressFragment | undefined,
  isUsingStealthMode: boolean,
): number {
  const maxRate = crawl?.crawlSetting?.maximumCrawlRate;
  const restrictedRate =
    crawl?.crawlSetting?.activeMaximumCrawlRateAdvanced?.maximumCrawlRate;
  //NOTE: In stealth mode the maximum crawl rate is limited to 1/3 URLs per second.
  return isUsingStealthMode ? 0.333 : restrictedRate ?? maxRate ?? 0;
}

function getClosestCrawlRateLimit(
  limits: [number, number][],
  time: number,
): number {
  return limits.reduce<[number, number]>(
    (min, curr) => {
      const minDiff = Math.abs(min[0] - time);
      const diff = Math.abs(curr[0] - time);
      return diff < minDiff ? curr : min;
    },
    [0, 0],
  )[1];
}
