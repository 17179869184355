import { isArray, sortBy } from "lodash";

import {
  ConnectionStringFilterInput,
  CrawlType,
  ReportOrder,
} from "../../../graphql";
import {
  ChartQueryResponse,
  ChartQueryResponseReportEdge,
} from "./generateChartQuery";
import { isArrayOfStrings } from "../../isArrayOfStrings";
import { isReportTemplateCodeFilterInputForRequiredSource } from "./isReportTemplateCodeFilterInputForRequiredSource";
import { ChartConfigItem, TileChartConfigItem } from "../types/ChartConfig";
import {
  EnhancedReportTemplateCodeValue,
  ReportTemplateCodeFilterInputForRequiredSource,
} from "../types/ChartConfigItemBase";

function getRequiredSource(
  reportTemplateCode: string,
  reportTemplateCodeFilter:
    | ConnectionStringFilterInput
    | ReportTemplateCodeFilterInputForRequiredSource
    | ReportTemplateCodeFilterInputForRequiredSource[],
): CrawlType | undefined {
  if (Array.isArray(reportTemplateCodeFilter)) {
    const filter = reportTemplateCodeFilter.find(
      (filter) => filter?.eq === reportTemplateCode,
    );
    return filter?.requiredSource;
  } else if (
    isReportTemplateCodeFilterInputForRequiredSource(reportTemplateCodeFilter)
  ) {
    return reportTemplateCodeFilter.requiredSource;
  }
  return undefined;
}

function filterOutReportsWithMissingRequiredSource(
  reportEdges: ChartQueryResponseReportEdge[],
  crawlTypes: CrawlType[],
  reportTemplateCodeFilter?: EnhancedReportTemplateCodeValue,
): ChartQueryResponseReportEdge[] {
  if (
    !reportTemplateCodeFilter ||
    typeof reportTemplateCodeFilter === "string" ||
    isArrayOfStrings(reportTemplateCodeFilter)
  ) {
    return reportEdges;
  }

  return reportEdges.filter(({ node }) => {
    const reportTemplateCode = node.reportTemplate.code;

    const requiredSource = getRequiredSource(
      reportTemplateCode,
      reportTemplateCodeFilter,
    );

    return !requiredSource || crawlTypes.includes(requiredSource);
  });
}

function orderReportsByReportTemplateCode(
  reportEdges: ChartQueryResponseReportEdge[],
  reportTemplateCodeFilter: EnhancedReportTemplateCodeValue,
  reportOrder?: ReportOrder | ReportOrder[],
): ChartQueryResponseReportEdge[] {
  if (!reportOrder && isArrayOfStrings(reportTemplateCodeFilter)) {
    return sortBy(reportEdges, (reportEdge) =>
      reportTemplateCodeFilter.indexOf(reportEdge.node.reportTemplate.code),
    );
  }

  return reportEdges;
}

function transformReportEdges(
  reportEdges: ChartQueryResponseReportEdge[],
  crawlTypes: CrawlType[],
  chartConfig: ChartConfigItem | TileChartConfigItem,
): ChartQueryResponseReportEdge[] {
  const reportTemplateCodeFilter =
    chartConfig.reports().filter?.reportTemplateCode;
  const reportOrder = chartConfig.reports().orderBy;

  const filteredEdges = filterOutReportsWithMissingRequiredSource(
    reportEdges,
    crawlTypes,
    reportTemplateCodeFilter,
  );
  const sortedEdges = orderReportsByReportTemplateCode(
    filteredEdges,
    reportTemplateCodeFilter,
    reportOrder,
  );

  return sortedEdges;
}

export function transformQueryResultData(
  data: ChartQueryResponse,
  chartConfig: ChartConfigItem | TileChartConfigItem,
): ChartQueryResponse {
  if (!data?.getCrawl) {
    return data;
  }

  const reportsData = isArray(data.getCrawl.reports)
    ? {
        edges: data.getCrawl.reports.map((report) => ({ node: report })),
        totalCount: data.getCrawl.reports.length,
      }
    : data.getCrawl.reports;

  const reports = {
    ...reportsData,
    edges: transformReportEdges(
      reportsData.edges,
      data.getCrawl.crawlTypes,
      chartConfig,
    ),
  };

  return {
    ...data,
    getCrawl: {
      ...data.getCrawl,
      reports,
    },
  };
}
