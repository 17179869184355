import { generatePath } from "react-router-dom";
import queryString, { StringifyOptions, UrlObject } from "query-string";
import { encodeConnectionFilterSearchParam } from "@lumar/shared";
import { ConnectionFilter } from "../connection-filtering/types";
import { get } from "lodash";
import { sanitizeDigestForPageId } from "./sanitizeDigestForPageId";
import { ModuleCode } from "../../graphql";

export interface SharedCrawlPrefixRouteParams {
  accountId: string;
  projectId: string;
  crawlId: string;
}

const ROUTE_MATCHER_PREFIX_ACCOUNT = `/accounts/:accountId([1-9]\\d*)`;
const ROUTE_MATCHER_PREFIX_PROJECT = `${ROUTE_MATCHER_PREFIX_ACCOUNT}/projects/:projectId([1-9]\\d*)`;
const ROUTE_MATCHER_PREFIX_CRAWL = `${ROUTE_MATCHER_PREFIX_PROJECT}/crawls/:crawlId(\\d+)`;
const ROUTE_MATCHER_PREFIX_REPORT = `${ROUTE_MATCHER_PREFIX_CRAWL}/reports/:reportTemplateCodeWithTypeCode`;

function createURL(
  object: Omit<UrlObject, "query"> & Required<Pick<UrlObject, "query">>,
  options?: StringifyOptions,
): string {
  const shareLinkToken = new URLSearchParams(window.location.search).get(
    "token",
  );

  if (shareLinkToken) {
    // eslint-disable-next-line fp/no-mutation
    object.query.token = shareLinkToken;
  }

  return queryString.stringifyUrl(object, options);
}

export const Routes = {
  Login: {
    ROUTE: "/login",
  },
  CrawlOverview: {
    ROUTE: ROUTE_MATCHER_PREFIX_CRAWL,
    getUrl({
      accountId,
      projectId,
      crawlId,
      segmentId,
      type,
      category,
      changes,
      sorting,
      resolvePath,
    }: SharedCrawlPrefixRouteParams & {
      segmentId?: string | null;
      type?: string | null;
      category?: string | null;
      changes?: string | null;
      sorting?: { field: string; sort: "asc" | "desc" }[];
      resolvePath?: boolean;
    }): string {
      const path = generatePath(Routes.CrawlOverview.ROUTE, {
        accountId,
        projectId,
        crawlId,
      });

      const query = {
        segmentId: segmentId || undefined,
        type,
        category: category,
        changes,
        sorting: sorting && btoa(JSON.stringify(sorting)),
        resolvePath,
      };
      return createURL({ url: path, query });
    },
  },
  SegmentManager: {
    ROUTE: ROUTE_MATCHER_PREFIX_CRAWL + "/segments",
    getUrl({
      accountId,
      projectId,
      crawlId,
    }: SharedCrawlPrefixRouteParams): string {
      const url = generatePath(Routes.SegmentManager.ROUTE, {
        accountId,
        projectId,
        crawlId: crawlId ?? "0",
      });

      return createURL({ url, query: {} });
    },
  },
  ReportAdjustment: {
    ROUTE: ROUTE_MATCHER_PREFIX_CRAWL + "/report-adjustment",
    getUrl({
      accountId,
      projectId,
      crawlId,
    }: SharedCrawlPrefixRouteParams): string {
      const url = generatePath(Routes.ReportAdjustment.ROUTE, {
        accountId,
        projectId,
        crawlId,
      });
      return createURL({ url, query: {} });
    },
  },
  Reports: {
    ROUTE: ROUTE_MATCHER_PREFIX_CRAWL + "/reports",
    getUrl({
      accountId,
      projectId,
      crawlId,
      segmentId,
      filter,
      category,
    }: SharedCrawlPrefixRouteParams & {
      segmentId?: string;
      filter?: string;
      category?: string;
    }): string {
      const url = generatePath(Routes.Reports.ROUTE, {
        accountId,
        projectId,
        crawlId,
      });

      return createURL({
        url,
        query: { filter, category, segmentId },
      });
    },
  },
  Report: {
    ROUTE: ROUTE_MATCHER_PREFIX_REPORT,
    getUrl({
      accountId,
      projectId,
      crawlId,
      segmentId,
      filter,
      category,
      resolvePath,
      ...props
    }: SharedCrawlPrefixRouteParams &
      ReportParams & {
        segmentId?: string | null;
        filter?: "preserve" | ConnectionFilter;
        category?: string;
        resolvePath?: boolean;
      }): string {
      const reportTemplateCodeWithTypeCode =
        props.reportTemplateCodeWithTypeCode ||
        [props.reportTemplateCode, props.reportTypeCode].join("_");

      const path = generatePath(Routes.Report.ROUTE, {
        accountId,
        projectId,
        crawlId,
        reportTemplateCodeWithTypeCode:
          reportTemplateCodeWithTypeCode.toLowerCase(),
      });

      // FIXME: Good enough, but not correct solution because
      // it assumes that react router refers to the window object
      // what doesn't have to be the case.
      // For example: What will happen if memory router is not in-sync
      // with window object? - Michal
      const searchParams = new URLSearchParams(window.location.search);

      function getParam(
        value: string | null | undefined,
        key: string,
      ): string | undefined {
        if (value) return value;
        if (value === null) return;
        return searchParams.get(key) ?? undefined;
      }

      function getFilterParam(): string | undefined {
        if (filter === "preserve")
          return searchParams.get("filter") ?? undefined;
        if (filter) return encodeConnectionFilterSearchParam(filter);
        return undefined;
      }

      const query = {
        segmentId: getParam(segmentId, "segmentId"),
        filter: getFilterParam(),
        category,
        resolvePath,
      };

      return createURL({
        url: path,
        query,
      });
    },
  },
  DataExplorer: {
    ROUTE: ROUTE_MATCHER_PREFIX_CRAWL + "/data-explorer",
    getUrl({
      accountId,
      projectId,
      crawlId,
    }: SharedCrawlPrefixRouteParams): string {
      const url = generatePath(Routes.DataExplorer.ROUTE, {
        accountId,
        projectId,
        crawlId,
      });
      return createURL({ url, query: {} });
    },
  },
  Projects: {
    ROUTE: ROUTE_MATCHER_PREFIX_ACCOUNT + "/projects",
    getUrl({
      accountId,
      type,
      pagination,
    }: {
      accountId: string;
      type?: AccountProjectsVariant;
      pagination?: string;
    }): string {
      const url = generatePath(Routes.Projects.ROUTE, {
        accountId,
      });
      return createURL({
        url,
        query: { type: type ?? AccountProjectsVariant.All, pagination },
      });
    },
  },
  Crawls: {
    ROUTE: ROUTE_MATCHER_PREFIX_PROJECT + "/crawls",
    getUrl(
      props: { accountId: string; projectId: string } & (
        | { tab: "edit"; step?: number }
        | { tab: "progress"; start?: boolean; unarchive?: string }
        | { tab: "history"; unarchive?: string }
      ),
    ): string {
      const { accountId, projectId, tab } = props;
      const path = generatePath(Routes.Crawls.ROUTE, {
        accountId,
        projectId,
      });
      const step = get(props, "step");
      const start = get(props, "start");
      const unarchive = get(props, "unarchive");

      const query = {
        tab: tab && String(tab),
        step: step && String(step),
        unarchive: unarchive && String(unarchive),
        start: start ? true : undefined,
      };

      return createURL({ url: path, query });
    },
  },
  NewProject: {
    ROUTE: "/accounts/:accountId/projects/crawls/new",
    getUrl({
      accountId,
      module,
    }: {
      accountId: string;
      module?: ModuleCode;
    }): string {
      const url = generatePath(Routes.NewProject.ROUTE, {
        accountId,
      });
      const query = {
        tab: "edit",
        module,
      };

      return createURL({ url: url, query });
    },
  },
  Tasks: {
    ROUTE: ROUTE_MATCHER_PREFIX_CRAWL + "/issues",
    getUrl({
      accountId,
      projectId,
      crawlId,
    }: SharedCrawlPrefixRouteParams): string {
      const url = generatePath(Routes.Tasks.ROUTE, {
        accountId,
        projectId,
        crawlId,
      });

      return createURL({ url, query: {} });
    },
  },
  AccountTasks: {
    ROUTE: ROUTE_MATCHER_PREFIX_ACCOUNT + "/issues",
    getUrl({ accountId }: { accountId: string }): string {
      const url = generatePath(Routes.AccountTasks.ROUTE, {
        accountId,
      });

      return createURL({ url, query: {} });
    },
  },
  ResourceDetail: {
    ROUTE: ROUTE_MATCHER_PREFIX_REPORT + "/resources/:resourceId",
    getUrl({
      accountId,
      projectId,
      crawlId,
      resourceId,
      type,
      sourceReportTemplateCode,
      ...props
    }: SharedCrawlPrefixRouteParams &
      ReportParams & {
        resourceId: string;
        sourceReportTemplateCode?: string;
        type?: string;
      }): string {
      const reportTemplateCodeWithTypeCode =
        props.reportTemplateCodeWithTypeCode ||
        [props.reportTemplateCode, props.reportTypeCode].join("_");

      const url = generatePath(Routes.ResourceDetail.ROUTE, {
        accountId,
        projectId,
        crawlId,
        reportTemplateCodeWithTypeCode,
        resourceId: sanitizeDigestForPageId(resourceId),
      });

      return createURL({
        url,
        query: { type, source: sourceReportTemplateCode },
      });
    },
  },
  Account: {
    ROUTE: ROUTE_MATCHER_PREFIX_ACCOUNT,
    getUrl({ accountId }: { accountId: string }): string {
      const url = generatePath(Routes.Account.ROUTE, { accountId });
      return createURL({ url, query: {} });
    },
  },
  CustomReports: {
    ROUTE: ROUTE_MATCHER_PREFIX_CRAWL + "/custom-reports",
    getUrl({
      accountId,
      projectId,
      crawlId,
      segmentId,
      type,
    }: {
      accountId: string;
      projectId: string;
      crawlId: string;
      segmentId?: string;
      type?: string;
    }): string {
      const url = generatePath(Routes.CustomReports.ROUTE, {
        accountId,
        projectId,
        crawlId,
      });

      return createURL({
        url,
        query: {
          segmentId,
          type,
        },
      });
    },
  },
  CustomReport: {
    ROUTE:
      ROUTE_MATCHER_PREFIX_CRAWL + "/custom-reports/:customReportTemplateId",
    getUrl({
      accountId,
      projectId,
      crawlId,
      customReportTemplateId,
      segmentId,
    }: {
      accountId: string;
      projectId: string;
      crawlId: string;
      customReportTemplateId: string;
      segmentId?: string;
    }): string {
      const url = generatePath(Routes.CustomReport.ROUTE, {
        accountId,
        projectId,
        crawlId,
        customReportTemplateId,
      });

      return createURL({ url, query: { segmentId } });
    },
  },
  CustomReportTemplateManager: {
    ROUTE: ROUTE_MATCHER_PREFIX_CRAWL + "/custom-reports-manager",
    getUrl({
      accountId,
      projectId,
      crawlId,
      from,
    }: SharedCrawlPrefixRouteParams & {
      from?: "projectSettings" | "customReports";
    }): string {
      const url = generatePath(Routes.CustomReportTemplateManager.ROUTE, {
        accountId,
        projectId,
        crawlId: crawlId ?? "0",
      });

      return createURL({ url, query: { from } });
    },
  },
};

export enum AccountProjectsVariant {
  All = "all",
  SEO = "seo",
  Accessibility = "accessibility",
  SiteSpeed = "site_speed",
  Running = "running",
  TestSuite = "test_suite",
}

type ReportParams =
  | {
      reportTemplateCode: string;
      reportTypeCode: string;
      reportTemplateCodeWithTypeCode?: undefined;
    }
  | {
      reportTemplateCodeWithTypeCode: string;
      reportTemplateCode?: undefined;
      reportTypeCode?: undefined;
    };
