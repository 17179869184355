import React, { ReactNode } from "react";
import { makeStyles, Tooltip } from "@material-ui/core";
import { Trans } from "react-i18next";
import { TooltipInfoIcon, Typography } from "@lumar/shared";
import { kebabCase } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "end",
    alignItems: "center",
    borderBottomColor: theme.palette.grey[200],
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    padding: "10px 10px 10px 15px",
    overflowX: "clip",
  },
  titleContainer: {
    flexBasis: 0,
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    minWidth: "100px",
    height: 35,
  },
  childrenContainer: {
    flexBasis: 0,
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    height: 35,
    paddingLeft: theme.spacing(1),
  },
  title: {
    textOverflow: "ellipsis",
    fontSize: "1rem",
  },
  segmentName: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "0.786em",
    textTransform: "uppercase",
  },
  tooltip: {
    padding: theme.spacing(1.5, 1.5, 2, 1.5),
    minWidth: "490px",
    maxWidth: "490px",
  },
  tooltipIcon: {
    boxSizing: "content-box",
    marginLeft: theme.spacing(0.5),
    width: "22px",
    height: "22px",
  },
  tooltipTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
}));

interface ChartPanelTitleProps {
  title: string;
  description?: ReactNode;
  segmentName?: string;
  children?: React.ReactNode;
}

export function ChartPanelTitle({
  title,
  description,
  children,
  segmentName,
}: ChartPanelTitleProps): JSX.Element {
  const classes = useStyles();
  const titleTestAttribute = `${kebabCase(title.toLowerCase())}-title`;
  const segmentTestAttribute = `${kebabCase(title.toLowerCase())}-segment`;

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography
          variant="h6Medium"
          data-testid={titleTestAttribute}
          noWrap
          color="textPrimary"
          className={classes.title}
        >
          {segmentName ? (
            <Trans
              ns="charts"
              i18nKey="chartTitleWithSegmentName"
              values={{
                title,
                segmentName,
              }}
            >
              {title}
              <span
                className={classes.segmentName}
                data-testid={segmentTestAttribute}
              >
                {segmentName}
              </span>
            </Trans>
          ) : (
            title
          )}
        </Typography>
        {Boolean(description) && (
          <Tooltip
            title={
              <>
                <Typography
                  component="h6"
                  variant="subtitle3Medium"
                  className={classes.tooltipTitle}
                >
                  {title}
                </Typography>
                <Typography variant="caption">{description}</Typography>
              </>
            }
            arrow
            placement="top"
            interactive
            classes={{ tooltip: classes.tooltip }}
          >
            <TooltipInfoIcon
              className={classes.tooltipIcon}
              color="inherit"
              fontSize="small"
            />
          </Tooltip>
        )}
      </div>
      <div className={classes.childrenContainer}>{children}</div>
    </div>
  );
}
