import React from "react";
import {
  ApolloError,
  BlueDataGridProps,
  UrlServerPagination,
  useUrlServerPagination,
} from "@lumar/shared";
import {
  LegacyTaskConnectionFilterInput,
  LegacyTaskOrder,
  LegacyTaskOrderField,
  OrderDirection,
  useAccountTasksQuery,
} from "../../graphql";
import { Task } from "./types";
import { formatTasksRows } from "./formatTaskRows";
import { GridFilterModel } from "@mui/x-data-grid-pro";

interface AccountTasksProps {
  accountId: string;
}

interface AccountTasksData {
  rows: Task[];
  rowCount: number;
  totalRowCount: number;
  error: ApolloError | undefined;
  loading: boolean;
  pagination: Pick<
    BlueDataGridProps,
    | "paginationMode"
    | "paginationState"
    | "onPaginationStateChange"
    | "pageSize"
  >;
}

const ACCOUNT_TASKS_PAGE_SIZE = 20;

export function useAccountTasksData({
  accountId,
}: AccountTasksProps): AccountTasksData {
  const { pageInfo, ...pagination } = useAccountTasksPagination();

  const { data, error, loading } = useAccountTasksQuery({
    variables: {
      accountId,
      first: pageInfo.first,
      after: pageInfo.after,
      orderBy: getApiOrderBy(pageInfo.orderBy),
      filter: getApiFilter(pagination.paginationState.filterModel),
    },
    fetchPolicy: "no-cache",
  });

  const rows: Task[] = React.useMemo(() => {
    return formatTasksRows({
      tasks: data?.getAccount?.legacyTasks.edges.map((x) => x.node),
    });
  }, [data]);

  return {
    rows,
    rowCount: data?.getAccount?.legacyTasks.totalCount ?? 0,
    totalRowCount: data?.getAccount?.unresolvedTasks.totalCount ?? 0,
    error,
    loading,
    pagination: {
      paginationMode: "server",
      pageSize: ACCOUNT_TASKS_PAGE_SIZE,
      ...pagination,
    },
  };
}

function useAccountTasksPagination(): UrlServerPagination {
  const { paginationState, ...pagination } = useUrlServerPagination(
    ACCOUNT_TASKS_PAGE_SIZE,
    LegacyTaskOrderField.Priority,
    OrderDirection.Desc,
  );

  return {
    ...pagination,
    paginationState: {
      ...paginationState,
      filterModel: paginationState.filterModel ?? {
        items: [
          {
            id: "showResolved",
            columnField: "statusDeadline",
            operatorValue: "showResolved",
            value: false,
          },
        ],
      },
      sortModel: paginationState.sortModel ?? [
        { field: "severity", sort: "desc" },
      ],
    },
  };
}

function getApiOrderBy(
  orderBy: UrlServerPagination["pageInfo"]["orderBy"],
): LegacyTaskOrder[] | undefined {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fieldMap: Record<any, string> = {
    severity: LegacyTaskOrderField.Priority,
    unresolved: LegacyTaskOrderField.Remaining,
  };

  const supportedFields = Object.values(LegacyTaskOrderField);

  const formattedOrderBy = orderBy
    ?.map((x) => ({
      field: fieldMap[x.field] || x.field,
      direction: x.direction,
    }))
    .filter((x) => supportedFields.includes(x.field));
  return formattedOrderBy?.length ? formattedOrderBy : undefined;
}

function getApiFilter(
  filters: GridFilterModel | undefined,
): LegacyTaskConnectionFilterInput | undefined {
  const andFilters: LegacyTaskConnectionFilterInput[] = [];

  const resolvedFilter = filters?.items.find((x) => x.id === "showResolved");
  if (resolvedFilter?.value !== true) {
    // eslint-disable-next-line fp/no-mutating-methods
    andFilters.push({ fixedAt: { isNull: true } });
  }

  const searchFilter = filters?.items.find((x) => x.id === "task:contains");
  if (searchFilter?.value?.length) {
    // eslint-disable-next-line fp/no-mutating-methods
    andFilters.push({ title: { contains: searchFilter?.value } });
  }

  return andFilters.length ? { _and: andFilters } : undefined;
}
