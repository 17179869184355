import React from "react";
import { useNumberFormatter, useTranslation } from "@lumar/shared";

import { ReportTemplateUnit } from "../../../graphql";
import { useSizeFormatter } from "../format-size/useSizeFormatter";

export type ReportTemplateUnitFormatter = (
  value: number,
  unit: ReportTemplateUnit | string | undefined,
) => string;

export function useReportTemplateUnitFormatter(): ReportTemplateUnitFormatter {
  const { t } = useTranslation("units");

  const formatNumber = useNumberFormatter();
  const formatSize = useSizeFormatter();

  return React.useCallback<ReportTemplateUnitFormatter>(
    (count, unit) => {
      switch (unit) {
        case ReportTemplateUnit.UrLs:
          return t("url", { count });
        case ReportTemplateUnit.Issues:
          return t("issue", { count });
        case ReportTemplateUnit.Instances:
          return t("instance", { count });
        case "word":
          return `${formatNumber(count, { maximumFractionDigits: 2 })} ${t(
            "word",
            { count },
          )}`;
        case "second":
          return `${formatNumber(count, { maximumFractionDigits: 2 })} ${t(
            "second",
            { count },
          )}`;
        case "ms":
          return `${formatNumber(count, { maximumFractionDigits: 2 })} ${t(
            "msUnit",
          )}`;
        case "pixel":
          return `${formatNumber(count, { maximumFractionDigits: 2 })} ${t(
            "pixelUnit",
            { count },
          )}`;
        case "character":
          return `${formatNumber(count, { maximumFractionDigits: 2 })} ${t(
            "characterUnit",
            { count },
          )}`;
        case "percent":
          return `${formatNumber(count, { maximumFractionDigits: 2 })} ${t(
            "percentUnit",
            { count },
          )}`;
        case "byte": {
          const { unit, value } = formatSize(count ?? 0);
          return `${value} ${unit}`;
        }
        case "KiB":
          return `${formatNumber(count, { maximumFractionDigits: 2 })} ${t(
            "kibUnit",
          )}`;
        case "point":
          return `${formatNumber(count, { maximumFractionDigits: 2 })} ${t(
            "pointUnit",
            { count },
          )}`;
        case "secs":
          return `${formatNumber(count, { maximumFractionDigits: 2 })} ${t(
            "secsUnit",
          )}`;
        default:
          return `${t("number", { count, maximumFractionDigits: 2 })} ${
            unit ? unit : ""
          }`;
      }
    },
    [t, formatNumber, formatSize],
  );
}
