import React, { ReactNode } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
import { chunk } from "lodash";

import { ChartPanel } from "../components/chart-components/ChartPanel";
import { ChartComponent } from "../components/chart-components/ChartComponent";
import { ChartData } from "../components/chart-components/ChartData";
import { ChartConfig, ChartConfigItem } from "../types/ChartConfig";
import { ChartSkeleton } from "../components/chart-components/ChartSkeleton";
import { useChartDataContext } from "../components/chart-components/ChartDataContext";

interface Props {
  config: ChartConfig[] | null;
}

function ChartCarouselGridContainer(props: {
  children: ReactNode;
}): JSX.Element {
  return (
    <Grid container spacing={2}>
      {props.children}
    </Grid>
  );
}

function ChartCarouselGridItem(props: { children: ReactNode }): JSX.Element {
  return (
    <Grid item xs={12} md={6}>
      {props.children}
    </Grid>
  );
}

const useChartCarouselStyles = makeStyles((theme) => ({
  indicatorContainer: {
    marginTop: theme.spacing(1.5),
    minHeight: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  carouselWithoutIndicator: {
    paddingBottom: theme.spacing(3),
  },
  indicatorIconButton: {
    width: 90,
    background: theme.palette.grey[300],
    height: 4,
    borderRadius: 2,
    marginLeft: 10,
    "& svg": {
      display: "none",
    },
  },
  activeCarouselButton: {
    background: theme.palette.grey[500],
  },
}));

export function ChartCarousel({ config }: Props): JSX.Element | null {
  const classes = useChartCarouselStyles();

  function getKey(config: ChartConfigItem): string {
    return config.title([]) + config.visualisationType;
  }

  if (config) {
    const showIndicators = config.length > 2;

    return (
      <Carousel
        autoPlay={false}
        navButtonsAlwaysInvisible={true}
        timeout={0}
        indicators={showIndicators}
        indicatorContainerProps={{
          className: classes.indicatorContainer,
        }}
        indicatorIconButtonProps={{
          className: classes.indicatorIconButton,
        }}
        activeIndicatorIconButtonProps={{
          className: classes.activeCarouselButton,
        }}
        className={
          showIndicators ? undefined : classes.carouselWithoutIndicator
        }
      >
        {chunk(config, 2).map((chartConfigItems, i) => (
          <ChartCarouselGridContainer key={i}>
            <>
              {chartConfigItems.map((chartConfigItem) => (
                <ChartData key={getKey(chartConfigItem)} {...chartConfigItem}>
                  <ChartRender chartConfigItem={chartConfigItem} />
                </ChartData>
              ))}
            </>
          </ChartCarouselGridContainer>
        ))}
      </Carousel>
    );
  } else {
    return null;
  }
}

function ChartRender({
  chartConfigItem,
}: {
  chartConfigItem: ChartConfig;
}): JSX.Element {
  const { loading } = useChartDataContext();

  return (
    <ChartCarouselGridItem>
      <ChartPanel>
        {loading ? <ChartSkeleton /> : <ChartComponent {...chartConfigItem} />}
      </ChartPanel>
    </ChartCarouselGridItem>
  );
}

const useChartCarouselSkeletonStyles = makeStyles((theme) => ({
  wrapper: {
    paddingBottom: theme.spacing(6),
  },
}));

export function ChartCarouselSkeleton(): JSX.Element {
  const classes = useChartCarouselSkeletonStyles();

  return (
    <div className={classes.wrapper} data-testid="chart-carousel-skeleton">
      <ChartCarouselGridContainer>
        {Array.from({ length: 2 }, (_, i) => (
          <ChartCarouselGridItem key={i}>
            <ChartPanel>
              <ChartSkeleton />
            </ChartPanel>
          </ChartCarouselGridItem>
        ))}
      </ChartCarouselGridContainer>
    </div>
  );
}
