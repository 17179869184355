/* eslint-disable react/display-name */
import { TFunction } from "i18next";

import { ChartConfigItem, VisualisationTypes } from "../types/ChartConfig";

export function getNon200PagesChart(t: TFunction<"charts">): ChartConfigItem {
  return {
    visualisationFamily: "MultiSeries",
    visualisationType: VisualisationTypes.Pie,
    //visualisationColors: [theme.palette.red[500]],
    title: () => t("non200Pages.title"),
    description: () => t("non200Pages.description"),
    reports: () => ({
      filter: {
        reportTemplateCode: [
          "301_redirects",
          "non_301_redirects",
          "unauthorised_pages",
          "uncategorised_http_response_codes",
          "4xx_errors",
          "5xx_errors",
          "failed_urls",
        ],
      },
      fields: {
        reportTemplate: {
          code: true,
        },
        totalRows: true,
      },
    }),
    bindings: {
      name: (report) => {
        const reportTemplateCode = report.reportTemplate.code;
        return reportTemplateCode === "non-200_pages"
          ? t("non200Pages.total")
          : report.reportTemplate.name;
      },
      count: (report) => {
        return report.totalRows ?? 0;
      },
    },
    testAttributePrefix: "non-200-pages-chart",
    pendoAttributePrefix: "non-200-pages-chart",
  };
}
