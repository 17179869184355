import React from "react";
import { Button, ArrowLeftV11, useTranslation } from "@lumar/shared";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { ProjectViewer } from "../sidebar/project-viewer/ProjectViewer";
import { useResourceDetailData } from "./data/useResourceDetailData";
import { Link, useParams } from "react-router-dom";
import { Routes } from "../_common/routing/routes";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(-0.5),
    paddingBottom: theme.spacing(0.75),
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  button: {
    boxShadow: "none",
    width: "100%",
    background: "none",
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },
  icon: {
    fontSize: theme.typography.pxToRem(20),
  },
  loadingIndicator: {
    display: "block",
    margin: "auto",
    marginTop: "64px",
  },
}));

export function ResourceDetailSidebarContent(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("resourceDetail");
  const { accountId, projectId, crawlId, reportTemplateCodeWithTypeCode } =
    useParams<{
      accountId: string;
      projectId: string;
      crawlId: string;
      reportTemplateCodeWithTypeCode: string;
    }>();

  const { data, loading } = useResourceDetailData();

  const reportLink = Routes.Report.getUrl({
    accountId,
    crawlId,
    projectId,
    reportTemplateCodeWithTypeCode,
  });

  return (
    <div>
      <ProjectViewer />
      <div>
        <div className={classes.buttonContainer}>
          <Button
            component={Link}
            to={reportLink}
            variant="contained"
            color="secondary"
            size="large"
            className={classes.button}
          >
            {t("backToReport")}
            <ArrowLeftV11 className={classes.icon} />
          </Button>
        </div>
        <div>{data && <data.view.sidebar data={data} />}</div>
        {loading ? (
          <CircularProgress size="2rem" className={classes.loadingIndicator} />
        ) : null}
      </div>
    </div>
  );
}
