import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.grey[500],
  },
}));

export function ReportCell(params: GridRenderCellParams): JSX.Element {
  const classes = useStyles();
  const { value } = params;

  return (
    <Typography
      variant="caption"
      className={classes.text}
      data-testid="tasks-report"
    >
      {value}
    </Typography>
  );
}
