import { ApolloError, useSession } from "@lumar/shared";
import React, { ReactNode } from "react";
import { useHistory, useParams } from "react-router-dom";
import { insertIf } from "../_common/insertIf";
import { isNonNullable } from "../_common/isNonNullable";
import { Routes } from "../_common/routing/routes";
import { useURLSearchParams } from "../_common/routing/useURLSearchParams";
import {
  CrawlContextAllReportCategoriesQuery,
  CrawlContextDataQuery,
  ModuleCode,
  ReportTypeCode,
  useCrawlContextAllReportCategoriesQuery,
  useCrawlContextCustomReportsQuery,
  useCrawlContextDataQuery,
  useCrawlContextIndustryBenchmarksQuery,
  useCrawlContextSegmentsQuery,
  useCrawlContextSegmentsUpdateQuery,
  CrawlContextCustomReportsQuery,
} from "../graphql";
import { useCrawlContextHelpersFunctions } from "./CrawlContextHelpers";

export type CrawlContextCrawl = Pick<
  NonNullable<CrawlContextDataQuery["getCrawl"]>,
  "id" | "rawID" | "incomplete" | "createdAt" | "crawlTypes" | "comparedTo"
>;

export type CrawlContextCrawlProject = NonNullable<
  CrawlContextDataQuery["getCrawl"]
>["project"];

export type CrawlContextCrawlReport = NonNullable<
  NonNullable<CrawlContextDataQuery["getCrawl"]>["reports"]["edges"][number]
>["node"] & {
  customReportTemplate:
    | NonNullable<
        NonNullable<CrawlContextCustomReportsQuery["getCrawl"]>["customReports"]
      >[number]["customReportTemplate"]
    | null;
};

export type CrawlContextCrawlSegment = NonNullable<
  NonNullable<CrawlContextDataQuery["getCrawl"]>["crawlSegments"]
>["edges"][number]["node"];

export type CrawlContextCrawlSetting = NonNullable<
  NonNullable<CrawlContextDataQuery["getCrawl"]>["crawlSetting"]
>;

export interface CrawlReportCategoryTreeNode {
  code: string;
  name: string;
  hasHealthScore: boolean;
  reports: CrawlContextCrawlReport[];
  nodes: CrawlReportCategoryTreeNode[];
}

export interface CrawlReportCategoryListNode {
  parentCode?: string;
  code: string;
  name: string;
  hasHealthScore: boolean;
  reports: NonNullable<CrawlContextValue["data"]>["crawlReports"];
}

export interface CrawlContextValueHelpers {
  /**
   * Allows to select a crawl segment programatically.
   */
  selectCrawlSegment: (id: string | null) => void;
  /**
   * Returns crawl reports from a specific category.
   * It includes crawl reports of child categories!
   */
  getCrawlReportCategoryReportsList(
    reportCategoryCode: string,
    searchTerm?: string,
  ): CrawlContextCrawlReport[];
  getCrawlCustomReportsList(searchTerm?: string): CrawlContextCrawlReport[];
  getProjectAllCustomReportTemplatesList(
    searchTerm?: string,
  ): CrawlContextCrawlReport[];
  /**
   * Returns sorted, by impact, error crawl reports from a specific category.
   * It includes crawl reports of child categories!
   */
  getCrawlReportCategoryErrorReportsList(
    reportCategoryCode: string,
    searchTerm?: string,
  ): CrawlContextCrawlReport[];
  getCrawlErrorCustomReportsList(
    searchTerm?: string,
  ): CrawlContextCrawlReport[];
  /**
   * Returns sorted, by change weight, changed crawl reports from a specific category.
   * It includes crawl reports of child categories!
   */
  getCrawlReportCategoryChangedReportsList(
    reportCategoryCode: string,
    searchTerm?: string,
  ): CrawlContextCrawlReport[];
  /**
   * Returns a specific node from crawl report category tree.
   */
  getCrawlReportCategoryTreeNode(
    crawlReportCategoryCode: string,
    searchTerm?: string,
  ): CrawlReportCategoryTreeNode | undefined;
  /**
   * Returns a specific node from crawl report category tree that includes changed crawl reports.
   */
  getChangedCrawlReportCategoryTreeNode(
    crawlReportCategoryCode: string,
    searchTerm?: string,
  ): CrawlReportCategoryTreeNode | undefined;
  /**
   * Returns a specific node from crawl report category tree that includes error crawl reports.
   */
  getErrorCrawlReportCategoryTreeNode(
    crawlReportCategoryCode: string,
    searchTerm?: string,
  ): CrawlReportCategoryTreeNode | undefined;
  /**
   * Returns a flat crawl report categories list with reports that match the search term.
   * It includes parent categories, even if they don't have matching reports but their descendants do!
   * The search term is tested against the report's name, code and its' tags.
   */
  getCrawlReportCategoriesListWithReportsMatchingSearchTerm(
    searchTerm: string,
  ): CrawlReportCategoryListNode[];
  /**
   * Fetches additional segments from the API.
   */
  fetchMoreSegments: () => Promise<void>;
  /**
   * Returns the industry benchmark based on the provided category and industry codes.
   */
  getIndustryHealthScoreCategoryBenchmark(
    categoryCode?: string | null,
    industryCode?: string | null,
  ): number | undefined;
}

export interface CrawlContextValueData {
  /**
   * The currently viewed crawl.
   */
  crawl: CrawlContextCrawl;
  /**
   * The module code of the project.
   */
  moduleCode: ModuleCode;
  /**
   * Crawl setting of the currently viewed crawl.
   */
  crawlSetting?: CrawlContextCrawlSetting;
  /**
   * Segments in this crawl.
   */
  crawlSegments: CrawlContextCrawlSegment[];
  /**
   * Indicates whether there are more crawl segments available for fetching.
   */
  hasMoreCrawlSegments: boolean;
  /**
   * Currently selected crawl segment.
   */
  selectedCrawlSegment?: CrawlContextCrawlSegment;
  /**
   * Project this crawl belongs to.
   */
  crawlProject: CrawlContextCrawlProject;
  /**
   * All reports that exist in this crawl.
   */
  crawlReports: CrawlContextCrawlReport[];
  crawlCustomReports: CrawlContextCrawlReport[];
  crawlAllCustomReportsTemplateIds: string[];
  projectAllCustomReportTemplates: CrawlContextCrawlReport[];
  /**
   * Tree of all report categories that exist in this crawl.
   * Reports arrays are populated by reports that treat report category as primary.
   * If the primary report category is not specified in the report template, then first matching report category is associated.
   */
  crawlReportCategoriesTree: CrawlReportCategoryTreeNode[];
  crawlCustomReportCategoriesTree: CrawlReportCategoryTreeNode[];
  /**
   * List of all report categories that exist in this crawl.
   * Reports arrays are populated by reports that treat report category as primary.
   * If the primary report category is not specified in the report template, then first matching report category is associated.
   */
  crawlReportCategoriesList: CrawlReportCategoryListNode[];
  crawlCustomReportCategoriesList: CrawlReportCategoryListNode[];
}

export interface CrawlContextValue {
  errors?: ApolloError[];
  loading: boolean;
  crawlNotFound: boolean;
  crawlArchived: boolean;
  noCategoriesInTheSystem: boolean;
  helpers?: CrawlContextValueHelpers;
  data?: CrawlContextValueData;
}

export const CrawlContext = React.createContext<CrawlContextValue | null>(null);

export function useCrawlContext(): CrawlContextValue {
  const context = React.useContext(CrawlContext);

  if (context === null) {
    throw new Error(
      "`useCrawlContext` has been called without CrawlContextProvider in the tree.",
    );
  }

  return context;
}

export function useCrawlContextHelpers(): NonNullable<
  CrawlContextValue["helpers"]
> {
  const context = React.useContext(CrawlContext);

  if (context === null) {
    throw new Error(
      "`useCrawlContextHelpers` has been called without CrawlContextProvider in the tree.",
    );
  }

  if (!context.helpers) {
    throw new Error(
      "`useCrawlContextHelpers` has been called when data wasn't loaded.",
    );
  }

  return context.helpers;
}

export function useCrawlContextData(): NonNullable<CrawlContextValue["data"]> {
  const context = React.useContext(CrawlContext);

  if (context === null) {
    throw new Error(
      "`useCrawlContextData` has been called without CrawlContextProvider in the tree.",
    );
  }

  if (!context.data) {
    throw new Error(
      "`useCrawlContextData` has been called when data wasn't loaded.",
    );
  }

  return context.data;
}

export function CrawlContextProvider(props: {
  children: ReactNode;
}): JSX.Element {
  const history = useHistory();
  const searchParams = useURLSearchParams();
  const { accountId, projectId, crawlId } = useParams<{
    crawlId: string;
    accountId: string;
    projectId: string;
  }>();
  const {
    isDeepCrawlAdmin,
    isDeepCrawlAdminEnabled,
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();

  const {
    data: crawlContextData,
    loading: crawlContextDataLoading,
    error: crawlContextDataError,
  } = useCrawlContextDataQuery({
    fetchPolicy: "cache-first",
    skip: !crawlId, // FIXME: delete after moving to proper container
    variables: {
      crawlId,
      shouldQueryCrawlSegments: segmentationAvailable,
      segmentIdFilter: searchParams.get("segmentId")
        ? { eq: searchParams.get("segmentId") }
        : { isNull: true },
      // Showing admin reports if super admin uses admin features.
      // Making sure that `admin` field is filtered when user is a super admin.
      // Otherwise, API will throw an error.
      reportTemplateFilter:
        isDeepCrawlAdmin && !isDeepCrawlAdminEnabled
          ? ({ admin: { eq: false } } as Record<string, unknown>)
          : null,
    },
    onCompleted(data) {
      if (data.getCrawl && data.getCrawl.incomplete) {
        history.replace(
          Routes.Crawls.getUrl({
            accountId,
            projectId,
            tab: "progress",
          }),
        );
      }
    },
  });

  const {
    data: customReportsData,
    loading: customReportsLoading,
    error: customReportsError,
  } = useCrawlContextCustomReportsQuery({
    variables: { crawlId, projectId, segmentId: searchParams.get("segmentId") },
  });

  const { fetchMore: fetchMoreSegments } = useCrawlContextSegmentsQuery({
    variables: { crawlId },
    fetchPolicy: "cache-first",
    skip: true,
  });

  const {
    data: allReportCategoriesData,
    loading: allReportCategoriesLoading,
    error: allReportCategoriesError,
    fetchMore: fetchMoreReportCategories,
  } = useCrawlContextAllReportCategoriesQuery({
    skip: !crawlId, // FIXME: delete after moving to proper container
    fetchPolicy: "cache-first",
  });

  const segmentIdsToUpdate =
    crawlContextData?.getCrawl?.crawlSegments?.edges
      .filter((x) => !x.node.generatedAt && !x.node.failedAt)
      .map((x) => x.node.segment.id) || [];

  const { startPolling, stopPolling, error } =
    useCrawlContextSegmentsUpdateQuery({
      variables: {
        crawlId,
        segmentIds: segmentIdsToUpdate,
      },
      fetchPolicy: "cache-first",
      onError: () => {
        stopPolling();
      },
      skip: !segmentIdsToUpdate.length,
    });

  React.useEffect(() => {
    if (!segmentIdsToUpdate.length || error) return;

    startPolling(3000);
    return () => stopPolling();
  }, [segmentIdsToUpdate.length, startPolling, stopPolling, error]);

  // FIXME: Paginating breaks cache. Not an issue while writing this
  // but it will bite us later down the line.
  const reportCategoriesPageInfo =
    allReportCategoriesData?.getReportCategories.pageInfo;
  if (reportCategoriesPageInfo?.hasNextPage) {
    fetchMoreReportCategories({
      variables: {
        cursor: reportCategoriesPageInfo.endCursor,
      },
    });
  }

  const {
    data: industryBenchmarksData,
    loading: industryBenchmarksLoading,
    error: industryBenchmarksError,
    fetchMore: fetchMoreIndustryBenchmarks,
  } = useCrawlContextIndustryBenchmarksQuery({
    fetchPolicy: "cache-first",
  });

  const industryBenchmarksPageInfo =
    industryBenchmarksData?.getIndustryBenchmarks.pageInfo;
  if (industryBenchmarksPageInfo?.hasNextPage) {
    fetchMoreIndustryBenchmarks({
      variables: {
        cursor: industryBenchmarksPageInfo.endCursor,
      },
    });
  }

  const value = React.useMemo<Omit<CrawlContextValue, "helpers">>(() => {
    if (
      crawlContextDataLoading ||
      allReportCategoriesLoading ||
      industryBenchmarksLoading ||
      customReportsLoading ||
      allReportCategoriesData?.getReportCategories.pageInfo.hasNextPage
    ) {
      return {
        loading: true,
        crawlNotFound: false,
        crawlArchived: false,
        noCategoriesInTheSystem: false,
      };
    }

    if (
      crawlContextDataError ||
      allReportCategoriesError ||
      industryBenchmarksError ||
      customReportsError
    ) {
      return {
        loading: false,
        crawlNotFound: false,
        crawlArchived: Boolean(crawlContextData?.getCrawl?.archivedAt),
        noCategoriesInTheSystem: false,
        errors: [
          crawlContextDataError,
          allReportCategoriesError,
          industryBenchmarksError,
          customReportsError,
        ].filter(isNonNullable),
      };
    }

    if (!crawlContextData?.getCrawl || !customReportsData?.getCrawl) {
      return {
        loading: false,
        crawlNotFound: true,
        crawlArchived: false,
        noCategoriesInTheSystem: false,
      };
    }

    if (!allReportCategoriesData) {
      return {
        loading: false,
        crawlNotFound: false,
        crawlArchived: Boolean(crawlContextData.getCrawl.archivedAt),
        noCategoriesInTheSystem: true,
      };
    }

    const crawlReports: CrawlContextCrawlReport[] =
      crawlContextData.getCrawl.reports.edges
        .map((edge) => edge.node)
        // Applying correct custom extraction report name and filtering out custom extraction reports
        // that are not defined in crawl setting.
        .map((crawlReport) => {
          if (crawlReport.reportTemplate.code.includes("custom_extraction")) {
            const definedCustomExtraction =
              crawlContextData.getCrawl?.crawlSetting?.customExtractions?.find(
                (customExtraction) =>
                  customExtraction.reportTemplateCode ===
                  crawlReport.reportTemplate.code,
              );

            if (definedCustomExtraction) {
              return {
                ...crawlReport,
                reportTemplate: {
                  ...crawlReport.reportTemplate,
                  name: definedCustomExtraction.label,
                },
                customReportTemplate: null,
              };
            }

            return null;
          }
          return { ...crawlReport, customReportTemplate: null };
        })
        .filter(isNonNullable);

    const crawlCustomReports: CrawlContextCrawlReport[] =
      customReportsData.getCrawl.customReports.map((customReport) => {
        const addedDiff = customReport.added ?? 0;
        const removedDiff = customReport.removed ?? 0;
        const missingDiff = customReport.missing ?? 0;

        return {
          ...customReport,
          id: `customReport_${customReport.customReportTemplate.rawID}`,
          crawlId,
          change: addedDiff - removedDiff - missingDiff,
          totalRows: customReport.basic,
          templateTotalSign: customReport.customReportTemplateTotalSign,
          templateTotalWeight: customReport.customReportTemplateTotalWeight,
          reportTrend: customReport.trend.map((trend) => ({
            ...trend,
            crawlId: customReport.crawlId,
          })),
          reportTemplate: {
            ...customReport.reportTemplate,
            name: customReport.customReportTemplate.name,
            description: customReport.customReportTemplate.description,
          },
          diffReportTotals: [
            {
              totalRows: customReport.added ?? 0,
              reportTypeCode: ReportTypeCode.Added,
            },
            {
              totalRows: customReport.removed ?? 0,
              reportTypeCode: ReportTypeCode.Removed,
            },
            {
              totalRows: customReport.missing ?? 0,
              reportTypeCode: ReportTypeCode.Missing,
            },
          ],
          customReportTemplate: {
            ...customReport.customReportTemplate,
          },
        };
      });

    const projectAllCustomReportTemplates = (
      customReportsData.getProject?.customReportTemplates.nodes ?? []
    ).map((customReportTemplate) => ({
      id: `customReportTemplate_${customReportTemplate.rawID}`,
      crawlId,
      change: null,
      totalRows: null,
      templateTotalSign: customReportTemplate.totalSign,
      templateTotalWeight: customReportTemplate.totalWeight,
      reportTrend: [],
      reportTemplate: {
        ...customReportTemplate.reportTemplate,
        name: customReportTemplate.name,
        description: customReportTemplate.description,
      },
      diffReportTotals: [],
      customReportTemplate: null,
    }));

    const crawlReportCategoriesTree = createCrawlReportCategoriesTree(
      allReportCategoriesData,
      crawlReports,
    );
    const crawlCustomReportCategoriesTree = createCrawlReportCategoriesTree(
      allReportCategoriesData,
      crawlCustomReports,
    );

    const crawlReportCategoriesList = createCrawlReportCategoriesList(
      crawlReportCategoriesTree,
    );
    const crawlCustomReportCategoriesList = createCrawlReportCategoriesList(
      crawlCustomReportCategoriesTree,
    );

    return {
      loading: false,
      crawlNotFound: false,
      crawlArchived: Boolean(crawlContextData.getCrawl.archivedAt),
      noCategoriesInTheSystem: false,
      data: {
        crawl: {
          id: crawlContextData.getCrawl.id,
          rawID: crawlContextData.getCrawl.rawID,
          incomplete: crawlContextData.getCrawl.incomplete,
          createdAt: crawlContextData.getCrawl.createdAt,
          crawlTypes: crawlContextData.getCrawl.crawlTypes,
          comparedTo: crawlContextData.getCrawl.comparedTo,
        },
        moduleCode: crawlContextData.getCrawl.project.moduleCode,
        crawlSetting: crawlContextData.getCrawl.crawlSetting ?? undefined,
        ...getSegmentsData(crawlContextData),
        crawlProject: crawlContextData.getCrawl.project,
        crawlReports,
        crawlReportCategoriesTree,
        crawlReportCategoriesList,
        crawlCustomReports,
        crawlAllCustomReportsTemplateIds:
          customReportsData.getCrawl.allCustomReports.map(
            (cr) => cr.customReportTemplate.rawID,
          ),
        projectAllCustomReportTemplates,
        crawlCustomReportCategoriesTree,
        crawlCustomReportCategoriesList,
      },
    };
  }, [
    allReportCategoriesData,
    allReportCategoriesError,
    allReportCategoriesLoading,
    industryBenchmarksLoading,
    industryBenchmarksError,
    customReportsError,
    customReportsLoading,
    customReportsData,
    crawlContextData,
    crawlContextDataError,
    crawlContextDataLoading,
    crawlId,
  ]);

  const helpers = useCrawlContextHelpersFunctions({
    data: value.data,
    allReportCategoriesData,
    crawlContextData,
    fetchMoreSegments,
    industryBenchmarksData,
  });

  return (
    <CrawlContext.Provider
      value={React.useMemo(() => ({ ...value, helpers }), [value, helpers])}
    >
      {props.children}
    </CrawlContext.Provider>
  );
}

export function createCrawlReportCategoriesTree(
  allReportCategoriesData: CrawlContextAllReportCategoriesQuery,
  crawlReports: CrawlContextCrawlReport[],
  parentCode: string | null = null,
): CrawlReportCategoryTreeNode[] {
  /* eslint-disable fp/no-mutating-methods*/
  const parentNodes = allReportCategoriesData.getReportCategories.edges
    .filter((edge) => edge.node.parentCode === parentCode)
    .map((edge) => edge.node)
    .sort((a, b) => a.position - b.position);

  const wholeCrawlReportCategoryTree = parentNodes.map((parentNode) => {
    return {
      code: parentNode.code,
      name: parentNode.name || parentNode.code,
      hasHealthScore: parentNode.healthScore,
      reports: crawlReports
        .filter((crawlReport) => {
          if (crawlReport.reportTemplate.primaryReportCategoryCode) {
            return (
              crawlReport.reportTemplate.primaryReportCategoryCode ===
              parentNode.code
            );
          }
          return Boolean(
            crawlReport.reportTemplate.reportCategories.find(
              (reportCategory) => reportCategory.code === parentNode.code,
            ),
          );
        })
        .sort(
          (a, b) =>
            (a.reportTemplate.position ?? 0) - (b.reportTemplate.position ?? 0),
        ),
      nodes: createCrawlReportCategoriesTree(
        allReportCategoriesData,
        crawlReports,
        parentNode.code,
      ),
    };
  });

  return filterCrawlReportCategoriesTreeNodesThatIncludeReports(
    wholeCrawlReportCategoryTree,
  );
  /* eslint-enable fp/no-mutating-methods*/
}

function filterCrawlReportCategoriesTreeNodesThatIncludeReports(
  nodes: CrawlReportCategoryTreeNode[],
): CrawlReportCategoryTreeNode[] {
  return nodes
    .map((node) => {
      const descendantNodesIncludingReports =
        filterCrawlReportCategoriesTreeNodesThatIncludeReports(node.nodes);

      if (
        descendantNodesIncludingReports.length > 0 ||
        node.reports.length > 0
      ) {
        return {
          ...node,
          nodes: descendantNodesIncludingReports,
        };
      }

      return null;
    })
    .filter(isNonNullable);
}

export function getCrawlReportCategoryTreeNode(
  crawlReportCategoryCode: string,
  crawlReportCategoryTree: CrawlReportCategoryTreeNode[],
): CrawlReportCategoryTreeNode | undefined {
  const foundCategory = crawlReportCategoryTree.find(
    (node) => node.code === crawlReportCategoryCode,
  );

  if (!foundCategory) {
    const descendantNodes = crawlReportCategoryTree.flatMap(
      (node) => node.nodes,
    );

    if (descendantNodes.length === 0) {
      return;
    }

    return getCrawlReportCategoryTreeNode(
      crawlReportCategoryCode,
      descendantNodes,
    );
  }

  return foundCategory;
}

export function createCrawlReportCategoriesList(
  crawlReportCategoriesTree: CrawlReportCategoryTreeNode[],
  parentCode?: string,
): CrawlReportCategoryListNode[] {
  return crawlReportCategoriesTree.flatMap((node) => {
    return [
      {
        name: node.name,
        code: node.code,
        hasHealthScore: node.hasHealthScore,
        reports: node.reports,
        parentCode: parentCode,
      },
      ...createCrawlReportCategoriesList(node.nodes, node.code),
    ];
  });
}

export function getCrawlReportsMatchingSearchTerm(
  crawlReports: CrawlContextCrawlReport[],
  searchTerm: string,
): CrawlContextCrawlReport[] {
  return crawlReports.filter((crawlReport) => {
    const term = searchTerm.toLowerCase();

    const isNameMatching = crawlReport.reportTemplate.name
      .toLocaleLowerCase()
      .includes(term);

    if (isNameMatching) {
      return true;
    }

    const isCodeMatching = crawlReport.reportTemplate.code
      .toLowerCase()
      .includes(term);

    if (isCodeMatching) {
      return true;
    }

    const areTagsMatching = !!crawlReport.reportTemplate.tags?.find((tag) =>
      tag.toLocaleLowerCase().includes(term),
    );

    return areTagsMatching;
  });
}

export function getCrawlReportCategoryTreeNodeReportsList(
  node: CrawlReportCategoryTreeNode,
): CrawlContextCrawlReport[] {
  return [
    ...node.reports,
    ...node.nodes.flatMap((node) =>
      getCrawlReportCategoryTreeNodeReportsList(node),
    ),
  ];
}

function getSegmentsData(
  data: CrawlContextDataQuery,
): Pick<
  CrawlContextValueData,
  "crawlSegments" | "hasMoreCrawlSegments" | "selectedCrawlSegment"
> {
  const crawlSegments =
    data.getCrawl?.crawlSegments?.edges.map((edge) => edge.node) ?? [];
  const selectedCrawlSegment = data.getCrawl?.selectedSegment?.nodes[0];

  return {
    crawlSegments: [
      ...crawlSegments,
      ...insertIf(
        selectedCrawlSegment &&
          !crawlSegments.find(
            (x) => x.segment.id === selectedCrawlSegment.segment.id,
          ),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selectedCrawlSegment!,
      ),
    ],
    hasMoreCrawlSegments: Boolean(
      data.getCrawl?.crawlSegments?.pageInfo.hasNextPage,
    ),
    selectedCrawlSegment,
  };
}
