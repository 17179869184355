import {
  GenerateChartConfigItemFunction,
  GenerateTileChartConfigItemFunction,
} from "../../../_common/charts/types/ChartConfig";
import { Theme } from "@material-ui/core";
import { ModuleCode } from "../../../graphql";
import { TFunction } from "i18next";
import { ReportsChartColumnCustomDefinition } from "../data-visualization/charts/useTopErrorsSparklineTableChartData";
import { ReportsDefaultColumnTypes } from "../../reports-table/reportsTableDefaultColumns";
import { ReportsTableColumnCustomDefinition } from "../../reports-table/useReportsTableColumns";

enum PredefinedChart {
  TopChanges = "topChanges",
}

export type PredefinedChartConfig = {
  predefinedChartType: `${PredefinedChart}`;
  supportedModules?: ModuleCode[];
};

export type ReportCategoryChartConfigItem =
  | GenerateChartConfigItemFunction
  | PredefinedChartConfig;

export type ReportCategoryChartConfig = {
  errorsChart?: GenerateErrorsChartConfigFunction;
  allReportsTable?: GenerateReportsTableConfigFunction;
  errorsTable?: GenerateReportsTableConfigFunction;
  tileCharts?: GenerateTileChartConfigItemFunction[];
  mainCharts: ReportCategoryChartConfigItem[];
};

export function isPredefinedChartConfig(
  value: unknown,
): value is PredefinedChartConfig {
  return (
    typeof value === "object" &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.values(PredefinedChart).includes((value as any)?.predefinedChartType)
  );
}

export type ErrorsChartConfigItem = {
  columns: (
    | "name"
    | "trend"
    | "total"
    | "change"
    | ReportsChartColumnCustomDefinition
  )[];
  orderBy?: {
    field: string;
    direction: "asc" | "desc";
  };
};

export type GenerateErrorsChartConfigProps = {
  theme: Theme;
  t: TFunction<"charts">;
};

export type GenerateErrorsChartConfigFunction = (
  props: GenerateErrorsChartConfigProps,
) => ErrorsChartConfigItem;

export type ReportsTablePredefinedColumnDefinition = {
  predefinedColumn: ReportsDefaultColumnTypes;
  hide?: boolean;
};

export type ReportsTableColumnDefinition =
  | ReportsTablePredefinedColumnDefinition
  | ReportsTableColumnCustomDefinition;

export type ReportsTableConfigItem = {
  columns: ReportsTableColumnDefinition[];
  orderBy?: {
    field: string;
    direction: "asc" | "desc";
  };
};

export type GenerateReportsTableConfigProps = {
  theme: Theme;
  t: TFunction<"charts">;
};

export type GenerateReportsTableConfigFunction = (
  props: GenerateReportsTableConfigProps,
) => ReportsTableConfigItem;

export function isReportsTableColumnCustomDefinition(
  value: ReportsTableColumnDefinition,
): value is ReportsTableColumnCustomDefinition {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof (value as any).field === "string";
}
