import { padStart } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  AdvancedCrawlRateDay,
  AdvancedCrawlRateTime,
  AdvancedCrawlRateType,
  CrawlType,
  GetCrawlSettingsForLimitsQuery,
  UpdateLimitsMutationVariables,
} from "../../../graphql";
import { assert } from "../../../_common/assert";
import { translationNamespace } from "../CrawlSettings";
import { useSession } from "@lumar/shared";

export interface crawlRateAdvanced {
  type: AdvancedCrawlRateType;
  start: Date;
  end: Date;
  crawlRate: string;
}

export interface FormValues {
  maximumCrawlRate: string;
  maximumCrawlRateAdvanced: crawlRateAdvanced[];
  limitLevelsMax: string;
  limitUrlsMax: number;
  customLimitPagesMax: string;
  autoFinalizeOnCrawlLimits: "enabled" | "disabled";
}

export const DefaultValues: FormValues = {
  maximumCrawlRate: "0.3",
  maximumCrawlRateAdvanced: [],
  limitLevelsMax: "1",
  limitUrlsMax: -1,
  customLimitPagesMax: "1",
  autoFinalizeOnCrawlLimits: "disabled",
};

export const LimitUrlsMaxValues = [
  10, 100, 1000, 10000, 100000, 1000000, 10000000,
];

export function BuildFormData(
  data: GetCrawlSettingsForLimitsQuery | undefined,
): FormValues {
  if (!data) {
    return DefaultValues;
  }

  assert(data.getProject);
  assert(data.getAccount);

  const isLimitLevelsMaxCustom = !LimitUrlsMaxValues.includes(
    data.getProject.limitUrlsMax,
  );

  return {
    maximumCrawlRate: data.getProject.maximumCrawlRate.toString(),
    maximumCrawlRateAdvanced:
      data.getProject.maximumCrawlRateAdvanced?.map((crawlRate) => ({
        type: crawlRate.type,
        start: convertTimeFrom(crawlRate.start),
        end: convertTimeFrom(crawlRate.end),
        crawlRate: crawlRate.maximumCrawlRate.toString(),
      })) ?? [],
    limitLevelsMax: data.getProject.limitLevelsMax.toString(),
    limitUrlsMax: isLimitLevelsMaxCustom ? 0 : data.getProject.limitUrlsMax,
    customLimitPagesMax: isLimitLevelsMaxCustom
      ? data.getProject.limitUrlsMax.toString()
      : "1",
    autoFinalizeOnCrawlLimits: data.getProject.autoFinalizeOnCrawlLimits
      ? "enabled"
      : "disabled",
  };
}

export function BuildMutationData(
  projectId: string,
  values: FormValues,
): UpdateLimitsMutationVariables {
  assert(values);

  return {
    projectId,
    maximumCrawlRate: parseFloat(values.maximumCrawlRate),
    maximumCrawlRateAdvanced: values.maximumCrawlRateAdvanced.map(
      (crawlRate) => ({
        maximumCrawlRate: parseFloat(crawlRate.crawlRate),
        type: crawlRate.type,
        start: convertTimeTo(crawlRate.start, crawlRate.type),
        end: convertTimeTo(crawlRate.end, crawlRate.type),
      }),
    ),
    limitLevelsMax: convertNumber(values.limitLevelsMax) ?? 1,
    limitUrlsMax:
      values.limitUrlsMax > 0
        ? values.limitUrlsMax
        : convertNumber(values.customLimitPagesMax) ?? 1,
    autoFinalizeOnCrawlLimits: values.autoFinalizeOnCrawlLimits === "enabled",
  };
}

export interface ContextValues {
  maxCrawlRate: number;
  limitLevelsMax: number;
  limitPagesMax: number;
  projectIsInStealthMode: boolean;
  isWebCrawl: boolean;
}

export const DefaultContextValues: ContextValues = {
  maxCrawlRate: 1,
  limitLevelsMax: 1,
  limitPagesMax: 1,
  projectIsInStealthMode: false,
  isWebCrawl: false,
};

export const SettingsContext = React.createContext(DefaultContextValues);

export function BuildContextData(
  data: GetCrawlSettingsForLimitsQuery | undefined,
): ContextValues {
  if (!data) {
    return DefaultContextValues;
  }

  assert(data.getAccount);
  assert(data.getProject);

  return {
    maxCrawlRate: data.getAccount.maxCrawlRate,
    limitLevelsMax: Math.min(
      data.getAccount.limitLevelsMax,
      data.me.overallLimitLevelsMax,
    ),
    limitPagesMax: Math.min(
      data.getAccount.limitPagesMax,
      data.me.overallLimitPagesMax,
    ),
    projectIsInStealthMode: data.getProject.useStealthMode,
    isWebCrawl: Boolean(data.getProject.crawlTypes?.includes(CrawlType.Web)),
  };
}

export function useValidationSchema(
  maxLevel: number,
  maxPage: number,
  maxCrawlRate: number,
): unknown {
  const { t } = useTranslation(translationNamespace);

  const required = t("limits.required");

  return Yup.object().shape({
    maximumCrawlRate: Yup.number()
      .required(required)
      .test(
        "crawlDepthLimit",
        t("limits.crawlRateError", { value: maxCrawlRate }),
        (value) => isCrawlRateValid(value, maxCrawlRate),
      ),
    limitLevelsMax: Yup.number()
      .required(required)
      .min(1)
      .max(maxLevel, t("limits.maxNumberError", { max: maxLevel })),
    customLimitPagesMax: Yup.number()
      .required(required)
      .min(1)
      .max(maxPage, t("limits.maxNumberError", { max: maxPage })),
    maximumCrawlRateAdvanced: Yup.array().of(
      Yup.object().shape({
        crawlRate: Yup.number()
          .required(required)
          .test(
            "crawlDepthLimit",
            t("limits.crawlRateError", { value: maxCrawlRate }),
            (value) => isCrawlRateValid(value, maxCrawlRate),
          ),
      }),
    ),
  });
}

function convertTimeFrom(time: AdvancedCrawlRateTime): Date {
  // Note: Converts day of the week and time (HH:mm) to Date type relative to 2012-12-17 (monday)
  const day = days.findIndex((day) => day === time.day);
  if (time.hour.match(/^(([0-1][0-9])|(2[0-3])):[0-5][0-9]$/)) {
    return new Date(`2012-12-${16 + day}T${time.hour}:00Z`);
  }
  return new Date("2012-12-17T00:00:00Z");
}

function convertTimeTo(
  time: Date,
  crawlRate: AdvancedCrawlRateType,
): AdvancedCrawlRateTime {
  return {
    day:
      crawlRate === AdvancedCrawlRateType.Daily
        ? undefined
        : days[time.getUTCDay()],
    hour: `${padStart(time.getUTCHours().toString(), 2, "0")}:${padStart(
      time.getUTCMinutes().toString(),
      2,
      "0",
    )}`,
  };
}

const days = [
  AdvancedCrawlRateDay.Sunday,
  AdvancedCrawlRateDay.Monday,
  AdvancedCrawlRateDay.Tuesday,
  AdvancedCrawlRateDay.Wednesday,
  AdvancedCrawlRateDay.Thursday,
  AdvancedCrawlRateDay.Friday,
  AdvancedCrawlRateDay.Saturday,
];

function convertNumber(value: string): number | null {
  const convertedValue: number = +value;
  return isNaN(convertedValue) ? null : convertedValue;
}

export function useCrawlRateSuggestions(): string[] {
  const { account } = useSession();

  return [
    0.33, 0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 18, 20, 25, 30, 40,
    50, 75, 100, 125, 150, 175, 200, 250, 300,
  ]
    .filter((x) => x <= account.maxCrawlRate)
    .map((x) => x.toString());
}

export function isCrawlRateValid(value: number, maxCralwRate: number): boolean {
  return (
    value === 0.33 ||
    value === 0.5 ||
    (value >= 1 && value <= maxCralwRate && Number.isInteger(value))
  );
}
